/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/style-prop-object */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable consistent-return */
/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useHistory } from 'react-router-dom';

import { toast } from 'react-toastify';
// import LabelDomain from '~/components/LabelDomain';
import AboutModal from '~/components/AboutModal';
import ReturnPolicyModal from '~/components/ReturnPolicyModal';
import {
  Button,
  Description,
  DivButton,
  DivButtonText,
  Divider,
  // H2,
  H3,
  H4,
  H5,
  InputText,
  Label,
} from '~/designsystem/Fonts';
import LoaderComponent from '~/components/Loader';
import SocialMediaModal from '../../../components/SocialMediaModal';
import CallModal from '../../../components/CallModal';
import ProPlanModal from '../../../components/Popups/ProPlan';
import AddAddress from '../../../components/AddAddress';

import ImgPreview from './ImagePreview';
import ColorButton from './ColorButton';

// import GridProducts from './GridStyles';

import api from '../../../services/api';

// import NoImgIcon from '../../../assets/icons/noimg.svg';
// import xIcon from '../../../assets/icons/settings/x.svg';

import imgIcon from '../../../assets/icons/img.svg';

import PhoneIcon from '../../../assets/icons/settings/PhoneIcon';
import DesktopIcon from '../../../assets/icons/settings/DesktopIcon';

import DownArrowIcon from '../../../assets/icons/personalize/downarrow.svg';
import UpArrowIcon from '../../../assets/icons/personalize/uparrow.svg';

import SquareIcon from '../../../assets/icons/personalize/grid/format/square';
import RectangularIcon from '../../../assets/icons/personalize/grid/format/rectangular';
import FullImageIcon from '../../../assets/icons/personalize/grid/format/full';

import TextAlignLeftIcon from '../../../assets/icons/personalize/grid/textalign/left';
import TextAlignCenterIcon from '../../../assets/icons/personalize/grid/textalign/center';

import StraightBorderIcon from '../../../assets/icons/personalize/grid/borderstyles/straightborder';
import RoundedBorderIcon from '../../../assets/icons/personalize/grid/borderstyles/roundedborder';

// import PenIcon from '../../../assets/icons/personalize/alternativepen.svg';

// import loadingGif from '../../../assets/icons/loading.gif';
import loaderGif from '../../../assets/icons/loader.gif';

import Header from '../../../components/HeaderMobile';

// import AboveHeaderModal from './Modals/AboveHeaderModal';
// import HeaderModal from './Modals/HeaderModal';
// import FontsModal from './Modals/FontsModal';
// import StyleGridModal from './Modals/StyleGridModal';
// import ButtonsModal from './Modals/ButtonsModal';

import {
  Container,
  // Button,
  Div,
  DivTabs,
  DivSubTab,
  ContainerTab,
  // Label,
  // DivTitleColors,
  DivTab,
  // DivColor,
  DivColors,
  DivProfile,
  DivShare,
  // DivInput,
  // Label,
  Card,
  DivCheckBox,
  ButtonAdd,
  DivDescription,
  DivUsername,
  DescriptionSize,
  DivAvatar,
  DivLogo,
  DivAvatarFormat,
  DivAvatarLeft,
  DivTitle,
  DivModel,
  DivStoreType,
  // ButtonStoreType,
  DivEditPage,
  DivThemes,
  Theme,
  // DivHeadBar,
  // DivHeadBarText,
  // EditButton,
  // DivHeader,
  // DivHead,
  DivFonts,
  DivFontWeight,
  // Font,
  FontWeight,
  BuyButtonStyle,
  DivStyleButtons,
  InputModel,
  // DivStyleButton,
  // H1,
  // Span,
  DivLoader,
  Page,
  // PageHeader,
  // PageMiddle,
  // DivFooter,
  // DivSocialIcons,
  // DivAvatarRight,
  // DivTheme,
  // DivHeaderType,
  // Quadrado,
  // QuadradoBanner,
  // Retangulo,
  // RetanguloBanner,
  // DivBanners,
  // DivImagesBanners,
  // Icon,
  DivDevice,
  DivBannerImages,
  // ImgBannerFormat,
  LabelImage,
  // DivPreview,
  // DivTitulo,
  DivImages,
  DivImageFormats,
  DivItem,
  DivContentItem,
  DivLoading,
  DivBorder,
  // DivFImages,
  // DivSpanProPlan,
} from './styles';

import {
  updateProfileRequest,
  saveImageRequest,
  removeImageRequest,
  updateOrderBannerRequest,
  updateOrderBannerDesktopRequest,
} from '../../../store/modules/user/actions';

export default function Personalize() {
  const [category, setCategory] = useState([]);
  const [pro, setPro] = useState();

  const [about, setAbout] = useState();
  const [phone, setPhone] = useState();
  const [contact_email, setContactEmail] = useState();
  const [cep, setCep] = useState();
  const [address, setAddress] = useState();
  const [number, setNumber] = useState();
  const [district, setDistrict] = useState();
  const [uf, setUf] = useState();
  const [city, setCity] = useState();
  const [cnpj, setCnpj] = useState();
  const [policy, setPolicy] = useState();

  const [showStoreAddress, setshowStoreAddress] = useState(true);

  // const [userObject, setUserObject] = useState([]);
  const [user, setUser] = useState();
  const [pageTitle, setPageTitle] = useState();
  const [pageDescription, setPageDescription] = useState();
  const [facebookPixel, setFacebookPixel] = useState();
  const [googleAnalytics, setGoogleAnalytics] = useState();
  // const [gridOrList, setGridOrList] = useState(0);
  const [banners, setBanners] = useState();
  const [bannersDesktop, setBannersDesktop] = useState();
  const [showProPlanPopUp, setShowProPlanPopUp] = useState(false);
  const [showAddAddress, setShowAddAddress] = useState(false);

  const [selectedFontFamily, setSelectedFontFamily] = useState();
  const [selectedTitleFontWeight, setSelectedTitleFontWeight] = useState();
  const [titleColor, setTitleColor] = useState();
  const [highlightedTextColor, setHighlightedTextColor] = useState();
  const [
    selectedFontFamilyParagraph,
    setSelectedFontFamilyParagraph,
  ] = useState();

  const [selectedFormatImage, setSelectedFormatImage] = useState();
  const [selectedBorderStyleImage, setSelectedBorderStyleImage] = useState();
  const [selectedTextAlignImage, setSelectedTextAlignImage] = useState();
  const [uppercaseTitle, setUppercaseTitle] = useState();
  const [selectedBuyButtonStyle, setSelectedBuyButtonStyle] = useState();
  const [selectInputStyle, setSelectInputStyle] = useState();
  const [backgroundButtonColor, setBackgroundButtonColor] = useState();
  const [textButtonColor, setTextButtonColor] = useState();

  // const [showAboveHeaderModal, setShowAboveHeaderModal] = useState(false);
  // const [showHeaderModal, setShowHeaderModal] = useState(false);
  // const [showStyleGridModal, setShowStyleGridModal] = useState(false);
  // const [showButtonsModal, setShowButtonsModal] = useState(false);
  // const [showAboveHeaderModal, setShowAboveHeaderModal] = useState(false);

  const [domain, setDomain] = useState();
  const [themes, setThemes] = useState([]);
  // const [userTheme, setUserTheme] = useState();
  const [userThemeId, setUserThemeId] = useState(0);
  const [selectedThemeId, setSelectedThemeId] = useState(0);

  const [errorUpdateStore, setErrorUpdateStore] = useState('');

  // const [inputPhoneFocus, setInputPhoneFocus] = useState(false);
  const [inputPhone, setInputPhone] = useState();

  const [headerType, setHeaderType] = useState(0);
  const [bannerFormatImage, setBannerFormatImage] = useState(0);
  const [primaryColor, setPrimaryColor] = useState('#000');

  const [showGeneral, setShowGeneral] = useState(true);
  const [showHeader, setShowHeader] = useState(true);
  const [showFonts, setShowFonts] = useState(true);
  const [showProductList, setShowProductList] = useState(true);
  const [showButtons, setShowButtons] = useState(true);

  const [backgroundColor, setBackgroundColor] = useState();
  const [textColor, setTextColor] = useState();

  // const [activeTabInfo, setActiveTabInfo] = useState(false);
  // const [activeTabDesign, setActiveTabDesign] = useState(false);

  const [disableButtonStoreModel, setDisableButtonStoreModel] = useState(false);

  // const [activeTabTracking, setActiveTabTracking] = useState(false);

  const [selectedDevice, setSelectedDevice] = useState(0);
  const [tabSelected, setTabSelected] = useState('informations'); // informations or style

  const [storeModel, setStoreModel] = useState('catalog');

  const [showModal, setShowModal] = useState(false);
  const [showAboutModal, setShowAboutModal] = useState(false);
  const [showReturnPolicyModalModal, setShowReturnPolicyModalModal] = useState(
    false
  );

  const [showCallModal, setShowCallModal] = useState(false);
  // const [showFontsModal, setShowFontsModal] = useState(false);

  // const [fixedPositionTop, setFixedPositionTop] = useState(0);
  const [loading, setLoading] = useState(true);
  const [
    loadingButtonSelelectedTheme,
    setLoadingButtonSelelectedTheme,
  ] = useState(false);

  const [modalIsOpen, setModalIsOpen] = useState(false);

  // const refInput = useRef();
  const refUploadImage = useRef(null);
  const refUploadAvatarInput = useRef(null);
  const ref = useRef(null);

  const dispatch = useDispatch();
  const history = useHistory();

  // function blockDiv() {
  //   setFixedPositionTop(window.pageYOffset);
  //   setModalIsOpen(true);
  // }

  const {
    // id,
    username,
    email,
    name,
    facebook,
    twitter,
    instagram,
    whatsapp,
    telegram,
    messenger,
    page_title,
    description,
    facebook_pixel,
    google_analytics,
    model_view,
    avatar,
    header_type,
    banner_format_image,
    primary_color,
    second_color,
    type_text_products,
    banners: rBanners,
    banners_desktop: rBannersDesktop,
    category: initilCat,
    about: rAbout,
    phone: rPhone,
    cep: rCep,
    address: rAddress,
    number: rNumber,
    city: rCity,
    district: rDistrict,
    uf: rUf,
    cnpj: rCnpj,
    contact_email: rContactEmail,
  } = useSelector(state => state.user.profile);

  const loadingAvatar = useSelector(state => state.user.profile.loadingAvatar);
  const loadingBanner = useSelector(state => state.user.profile.loadingBanner);
  const loadingBannerDesktop = useSelector(
    state => state.user.profile.loadingBannerDesktop
  );

  async function handleChangeView(view) {
    // setGridOrList(view);

    const data = {
      user: username,
      name,
      email,
      model_view: view,
    };
    dispatch(updateProfileRequest(data));
  }

  async function handleChangeTextInfo(type) {
    // setTextInfo(type);

    const data = {
      user: username,
      name,
      email,
      type_text_products: type,
    };

    dispatch(updateProfileRequest(data));
  }

  async function handleChangeHeaderType(type, hiddenToast) {
    setHeaderType(type);
    setBannerFormatImage(type === 0 ? 0 : 2);

    const data = {
      user: username,
      name,
      email,
      header_type: type,
      banner_format_image: type === 0 ? 0 : 2,
    };

    dispatch(updateProfileRequest(data, hiddenToast));
  }

  async function handleChangeBannerFormatImage(type) {
    setBannerFormatImage(type);

    const data = {
      user: username,
      name,
      email,
      banner_format_image: type,
    };

    dispatch(updateProfileRequest(data));
  }

  async function handleChangeDefaultColors() {
    setPrimaryColor('#000000');

    const data = {
      user: username,
      name,
      email,
      primary_color: '#000000',
      second_color: '#ffffff',
    };

    dispatch(updateProfileRequest(data));
  }

  async function handleChangeButtonShowStoreAddress(value) {
    setshowStoreAddress(value);

    await api.post('/updatefreight', {
      show_store_address: value,
    });

    toast.success('Alteração salva');
  }

  async function handleUpdateUserConfigurations(data) {
    await api.put('/userconfigurations', data);

    toast.success('Alteração salva');
  }

  async function handleUpdateStoreModel(value) {
    try {
      setDisableButtonStoreModel(true);

      if (pro >= 1) {
        if (value === 'store') {
          const products = await api.get('/linkscount');
          if (products.data < 2) {
            setErrorUpdateStore(
              'Você precisa adicionar pelo menos 2 produtos para ativar o modelo Loja virtual profissional'
            );

            return 0;
          }

          setErrorUpdateStore('');
        }

        setStoreModel(value);
        handleUpdateUserConfigurations({
          store_model: value,
        });

        if (value === 'store' && headerType === 0) {
          handleChangeHeaderType(2, true);
        }

        if (value === 'store') {
          await api.get('/verifyexistscomponents');
        }
      } else if (value === 'catalog') {
        setStoreModel(value);
        handleUpdateUserConfigurations({
          store_model: value,
        });
      }

      if (pro === 0 && value === 'store') {
        setShowProPlanPopUp(true);
      }

      return 0;
    } finally {
      setDisableButtonStoreModel(false);
    }
  }

  // função criada para fazer o merge do tema com as variações do usuário
  function mergeObjects(obj1, obj2) {
    const merged = {};
    for (const prop in obj1) {
      if (prop in obj2) {
        if (typeof obj1[prop] === 'object' && typeof obj2[prop] === 'object') {
          merged[prop] = mergeObjects(obj1[prop], obj2[prop]);
        } else {
          merged[prop] = obj2[prop];
        }
      } else {
        merged[prop] = obj1[prop];
      }
    }
    for (const prop in obj2) {
      if (!(prop in obj1)) {
        merged[prop] = obj2[prop];
      }
    }
    return merged;
  }

  useEffect(() => {
    async function search() {
      // await api.get('/users').then(response => setUserObject(response.data));

      const proplan = await api.get('/proplan');
      setPro(proplan.data);

      await api.get('/userconfigurations').then(async response => {
        if (response && response.data) {
          setPolicy(response.data.exchange_and_return_policy);
          setStoreModel(response.data.store_model);
        }
      });

      await api.get('/configfreight').then(async response => {
        if (response && response.data) {
          setshowStoreAddress(response.data.show_store_address);
        }
      });

      await api.get('/domain').then(res => {
        if (res && res.data) {
          setDomain(res.data);
        }
      });

      await api.get('/themes').then(res => {
        if (res && res.data) {
          setThemes(res.data);
        }
      });

      try {
        const th = await api.get('/themes');

        await api.get('/userthemeconfigurations').then(res => {
          if (res && res.data) {
            const obj = mergeObjects(
              res.data.theme.config,
              res.data.variations
            );
            setSelectedThemeId(res.data.theme_id);
            // setUserTheme(obj);
            setUserThemeId(res.data.id);

            // header
            setBackgroundColor(obj.header.background_color);
            setTextColor(obj.header.text_color);

            // fonts
            setSelectedFontFamily(obj.text.title_font_family);
            setHighlightedTextColor(obj.text.highlighted_text_color);
            setTitleColor(obj.text.title_color);
            setSelectedTitleFontWeight(obj.text.title_font_weight);
            setSelectedFontFamilyParagraph(obj.text.paragraph_font_family);

            // grid
            setSelectedFormatImage(obj.product.grid_style);
            setSelectedBorderStyleImage(obj.product.grid_image_border_style);
            setSelectedTextAlignImage(obj.product.grid_text_alignment);
            setSelectedBuyButtonStyle(obj.product.grid_buy_button_style);
            setUppercaseTitle(obj.product.uppercase_title);

            // buttons
            setBackgroundButtonColor(obj.button.background_color);
            setTextButtonColor(obj.button.text_color);
            setSelectInputStyle(obj.button.border_style);

            // Faz uma ordenação para colocar o tema selecionado em primeiro na lista
            const ordenedThemes = th.data.sort(t => {
              if (t.id === res.data.theme_id) {
                return -1;
              }

              return 0;
            });

            setThemes(ordenedThemes);
          } else {
            setThemes(th.data);
          }
        });
      } finally {
        setLoading(false);
      }

      // const paymentmethods = await api.get('/paymentmethods');

      // if (paymentmethods.data) {
      //   setPaymentMethods(paymentmethods.data);

      //   setMoney(paymentmethods.data.money);
      //   setCreditCard(paymentmethods.data.credit_card);
      //   setDebitCard(paymentmethods.data.debit_card);
      //   setBillet(paymentmethods.data.billet);
      //   setBankTransfer(paymentmethods.data.bank_transfer);
      //   setPicpay(paymentmethods.data.picpay);
      //   setMealTicket(paymentmethods.data.meal_ticket);
      //   setPix(paymentmethods.data.pix);
      // }

      if (proplan.data === 0 && banner_format_image > 0) {
        handleChangeBannerFormatImage(0);
      }

      if (proplan.data === 0 && model_view > 1) {
        handleChangeView(0);
      }

      if (proplan.data === 0 && type_text_products > 0) {
        handleChangeTextInfo(0);
      }

      if (
        proplan.data === 0 &&
        primary_color !== '#000000' &&
        second_color !== '#ffffff'
      ) {
        handleChangeDefaultColors();
      }
    }

    const string =
      whatsapp &&
      whatsapp.substring(
        whatsapp.length === 13 ? whatsapp.length - 11 : whatsapp.length - 10
      );

    setInputPhone(string);

    setUser(username || null);
    setPageTitle(page_title || null);
    setPageDescription(description || null);
    setFacebookPixel(facebook_pixel || null);
    setGoogleAnalytics(google_analytics || null);
    setCategory(initilCat || null);
    // setGridOrList(model_view || 0);
    setHeaderType(header_type || 0);
    setBannerFormatImage(banner_format_image || 0);
    setPrimaryColor(primary_color || '');
    // setTextInfo(type_text_products || 0);

    setAbout(rAbout);
    setPhone(rPhone);
    setContactEmail(rContactEmail);
    setCep(rCep);
    setAddress(rAddress);
    setNumber(rNumber);
    setCity(rCity);
    setDistrict(rDistrict);
    setUf(rUf);
    setCnpj(rCnpj);

    search();

    if (!whatsapp) {
      history.push('/responsivecustomizesocialmedia');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   if (location && location.tab) {
  //     const { tab } = location;
  //     setTabSelected(tab.tab);
  //   }
  // }, [location]);

  useEffect(() => {
    setBanners(rBanners);
  }, [rBanners]);

  useEffect(() => {
    setBannersDesktop(rBannersDesktop);
  }, [rBannersDesktop]);

  function modal(_modal) {
    setShowModal(_modal);
  }

  function handleShowAboutModal(_modal) {
    setShowAboutModal(_modal);
  }

  function handleShowReturnPolicyModalModal(_modal) {
    setShowReturnPolicyModalModal(_modal);
  }

  function modalCall(_modal) {
    setShowCallModal(_modal);
  }

  async function handlePrimaryColor(color) {
    setPrimaryColor(color);
    // dispatch(updateProfileColor(title, color));
  }

  // async function updatePaymentMethods(data) {
  //   await api.put('/paymentmethods', data);
  // }

  function handleSetShowModal() {
    setModalIsOpen(false);
    setShowAddAddress(false);
    // setShowAboveHeaderModal(false);
    // setShowHeaderModal(false);
    // setShowFontsModal(false);
    // setShowStyleGridModal(false);
    // setShowButtonsModal(false);

    // setTimeout(() => {
    //   window.scrollTo(0, fixedPositionTop);
    // }, 50);
  }

  function isValidNumber(value) {
    // eslint-disable-next-line no-restricted-globals
    const isNotNumber = isNaN(value);

    if (isNotNumber || value.trim().length < 10) {
      return false;
    }

    return true;
  }

  async function handleSubmitPhoneData() {
    try {
      if (phone && !isValidNumber(phone)) {
        toast.error('Digite um número de telefone válido Ex: 51999999999');

        return 0;
      }

      const data = {
        user,
        email,
        phone,
      };

      dispatch(updateProfileRequest(data));

      return 0;
    } catch (err) {
      toast.error('Erro ao processar requisição');

      return 0;
    }
  }

  async function handleSubmitData() {
    try {
      const data = {
        user,
        email,
        about,
        cnpj,
        cep,
        address,
        number,
        district,
        city,
        uf,
        contact_email,
      };

      dispatch(updateProfileRequest(data));
    } catch (err) {
      toast.error('Erro ao processar requisição');
    }
  }

  // async function handleSubmitTheme(obj) {
  //   try {
  //     await api.put(`/userconfigurations/themes/${userThemeId}`, {
  //       variations: obj,
  //     });
  //   } catch (err) {
  //     toast.error('Ocorreu um erro ao alterar o header');
  //   }
  // }

  // async function verifyColor(color) {
  //   let obj = {};

  //   if (userTheme.text.highlighted_text_color === '#06C86B') {
  //     obj = { text: { highlighted_text_color: color } };
  //   }

  //   if (userTheme.button.background_color === '#202020') {
  //     obj = { ...obj, button: { background_color: color } };
  //   }

  //   handleSubmitTheme(obj);
  // }

  // async function handleUpdatePaymentMethods(value) {
  //   try {
  //     switch (value) {
  //       case 'money': {
  //         setMoney(!money);

  //         const data = {
  //           id: paymentMethods && paymentMethods.id,
  //           money: !money,
  //         };

  //         updatePaymentMethods(data);

  //         break;
  //       }
  //       case 'credit_card': {
  //         setCreditCard(!credit_card);

  //         const data = {
  //           id: paymentMethods && paymentMethods.id,
  //           credit_card: !credit_card,
  //         };

  //         updatePaymentMethods(data);

  //         break;
  //       }
  //       case 'debit_card': {
  //         setDebitCard(!debit_card);

  //         const data = {
  //           id: paymentMethods && paymentMethods.id,
  //           debit_card: !debit_card,
  //         };

  //         updatePaymentMethods(data);

  //         break;
  //       }
  //       case 'billet': {
  //         setBillet(!billet);

  //         const data = {
  //           id: paymentMethods && paymentMethods.id,
  //           billet: !billet,
  //         };

  //         updatePaymentMethods(data);

  //         break;
  //       }
  //       case 'bank_transfer': {
  //         setBankTransfer(!bank_transfer);

  //         const data = {
  //           id: paymentMethods && paymentMethods.id,
  //           bank_transfer: !bank_transfer,
  //         };

  //         updatePaymentMethods(data);

  //         break;
  //       }
  //       case 'picpay': {
  //         setPicpay(!picpay);

  //         const data = {
  //           id: paymentMethods && paymentMethods.id,
  //           picpay: !picpay,
  //         };

  //         updatePaymentMethods(data);

  //         break;
  //       }
  //       case 'meal_ticket': {
  //         setMealTicket(!mealTicket);

  //         const data = {
  //           id: paymentMethods && paymentMethods.id,
  //           meal_ticket: !mealTicket,
  //         };

  //         updatePaymentMethods(data);

  //         break;
  //       }
  //       case 'pix': {
  //         setPix(!pix);

  //         const data = {
  //           id: paymentMethods && paymentMethods.id,
  //           pix: !pix,
  //         };

  //         updatePaymentMethods(data);

  //         break;
  //       }

  //       default:
  //     }
  //   } catch (err) {
  //     // toast.error('Erro ao processar requisição');
  //   }
  // }

  async function handleChangeImage(e, imageType) {
    if (e.target.files[0]) {
      dispatch(saveImageRequest(e.target.files[0], imageType));

      if (headerType === 0) {
        handleChangeHeaderType(2, true);
      }
    }
  }

  async function handleSubmit() {
    try {
      if (
        user !== username ||
        pageTitle !== page_title ||
        pageDescription !== description ||
        facebookPixel !== facebook_pixel ||
        googleAnalytics !== google_analytics
      ) {
        if (!user) {
          toast.error('Preencha o usuário');
          return 0;
        }

        const data = {
          user: user.toLowerCase(),
          email,
          page_title: pageTitle,
          description: pageDescription,
          facebook_pixel: facebookPixel,
          google_analytics: googleAnalytics,
          category_id: (category && category.id) || null,
        };

        dispatch(updateProfileRequest(data));
      }
    } catch (err) {
      toast.error(err);
    }

    return 0;
  }

  const descriptionSize = useMemo(
    () => (pageDescription && pageDescription.length) || 0,
    [pageDescription]
  );

  useEffect(() => {
    if (user && user.length > 0) {
      const newUser = user && user.replace(/[^a-zA-Z0-9]+/g, '');
      setUser(newUser);
    }
  }, [user]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  async function onDragEndBanners(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const reorderedImages = reorder(
      banners,
      result.source.index,
      result.destination.index
    );

    setBanners(reorderedImages);

    dispatch(updateOrderBannerRequest(reorderedImages));
  }

  async function onDragEndBannersDesktop(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const reorderedImages = reorder(
      bannersDesktop,
      result.source.index,
      result.destination.index
    );

    setBannersDesktop(reorderedImages);

    dispatch(updateOrderBannerDesktopRequest(reorderedImages));
  }

  async function deleteImages(imageType, imageId) {
    dispatch(removeImageRequest(imageType, imageId));
  }

  function ProPlanPopUp(pro_modal) {
    setShowProPlanPopUp(pro_modal);
  }

  // function inputValidate(value) {
  //   const newText = value && value.replace(/[^0-9]+/g, '');

  //   if (newText && newText.charAt(0) === '0') {
  //     const hasZero = newText.substr(1);
  //     return hasZero;
  //   }

  //   return newText;
  // }

  function handleSubmitPhone() {
    try {
      // setInputPhoneFocus(false);

      if (!isValidNumber(inputPhone)) {
        toast.error('Digite um número válido Ex: 51999999999');

        return 0;
      }

      const data = {
        whatsapp: `55${inputPhone}`,
        email,
        user,
      };

      dispatch(updateProfileRequest(data));
    } catch (err) {
      toast.error('Erro ao processar requisição');
    }

    return 0;
  }

  // function handleSetUserTheme(obj) {
  //   setUserTheme(obj);

  //   handleSetShowModal();
  // }

  async function handleSelectTheme(themeId) {
    try {
      setLoadingButtonSelelectedTheme(true);
      await api
        .post(`/userconfigurations/themes/${themeId}/activate`)
        .then(res => {
          setSelectedThemeId(res.data.theme_id);
        });
    } catch (err) {
      toast.error('Ocorreu um erro ao selecionar tema');
    } finally {
      setLoadingButtonSelelectedTheme(false);
    }
  }

  async function handleSubmitPersonalize(obj) {
    try {
      if (pro === 0) {
        setShowProPlanPopUp(true);
        return 0;
      }

      await api.put(`/userconfigurations/themes/${userThemeId}`, {
        variations: obj,
      });
    } catch (err) {
      toast.error('Ocorreu um erro ao alterar o header');
    }
  }

  return (
    <>
      <ProPlanModal showModal={showProPlanPopUp} Modal={ProPlanPopUp} />

      <Container
        ref={ref}
        hasActiveModal={modalIsOpen}
        // top={`-${fixedPositionTop}px`}
      >
        <Header title="Editar" url="/dashboard" />

        <DivTabs>
          <div>
            <div
              className={tabSelected === 'informations' ? 'selected' : ''}
              onClick={() => {
                setTabSelected('informations');
                window.scrollTo(0, 0);
              }}
            >
              Informações
            </div>

            <div
              className={tabSelected === 'style' ? 'selected' : ''}
              onClick={() => {
                setTabSelected('style');
                window.scrollTo(0, 0);
              }}
            >
              Design
            </div>
          </div>
        </DivTabs>

        <>
          {/* <DivTab>
            <div
              onClick={() => {
                setActiveTabInfo(!activeTabInfo);
                setActiveTabDesign(activeTabInfo === true && false);
              }}
            >
              <h4 className={activeTabInfo && 'blue'}>Informações</h4>

              {activeTabInfo ? (
                <ArrowTopBoldIcon color="#0d85ff" />
              ) : (
                <ArrowDownBoldIcon />
              )}
            </div>
          </DivTab> */}

          {tabSelected === 'informations' && (
            <>
              <DivSubTab margin="96px 0 0 0">
                <div>
                  <H3>Informações gerais</H3>
                </div>
              </DivSubTab>

              <DivShare first>
                <DivTitle margin="0 0 4px 0">
                  <H5>Logotipo</H5>
                </DivTitle>

                <DivAvatarFormat enable={headerType === 1}>
                  <div className="div-avatar-format">
                    <div className="div-images">
                      <DivAvatarLeft>
                        {(headerType >= 2 && (
                          <span>
                            Tamanho recomendado: 105x327(altura x largura)
                          </span>
                        )) || (
                          <span>
                            Tamanho recomendado: 120x120(altura x largura)
                          </span>
                        )}

                        <DivAvatar
                          // horizontal={pro >= 1 && header_type >= 2}
                          horizontal
                        >
                          <button
                            type="button"
                            onClick={() => refUploadAvatarInput.current.click()}
                          >
                            <svg
                              width="14"
                              height="14"
                              viewBox="0 0 14 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M13 9V11.6667C13 12.0203 12.8595 12.3594 12.6095 12.6095C12.3594 12.8595 12.0203 13 11.6667 13H2.33333C1.97971 13 1.64057 12.8595 1.39052 12.6095C1.14048 12.3594 1 12.0203 1 11.6667V9"
                                stroke="black"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M10.3346 4.33333L7.0013 1L3.66797 4.33333"
                                stroke="black"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M7 1V9"
                                stroke="black"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                            {avatar && avatar.url
                              ? 'Alterar logotipo'
                              : 'Inserir logotipo'}
                          </button>

                          {pro > -1 && headerType && (
                            <DivLogo
                              hasImage={avatar && avatar.url}
                              // horizontal={pro >= 1 && headerType >= 2}
                              horizontal
                            >
                              {loadingAvatar && (
                                <DivLoading>
                                  <LoaderComponent size={24} />
                                </DivLoading>
                              )}

                              {avatar && avatar.url && (
                                <>
                                  <img
                                    src={avatar && avatar.image_url}
                                    alt="logo"
                                    className="selected-image"
                                  />

                                  <button
                                    type="button"
                                    onClick={() =>
                                      deleteImages('avatar', avatar.id)
                                    }
                                  >
                                    <svg
                                      width="20"
                                      height="20"
                                      viewBox="0 0 20 20"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M2.5 5H4.16667H17.5"
                                        stroke="#9D9D9D"
                                        strokeWidth="1.25"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                      <path
                                        d="M15.8337 4.99984V16.6665C15.8337 17.1085 15.6581 17.5325 15.3455 17.845C15.0329 18.1576 14.609 18.3332 14.167 18.3332H5.83366C5.39163 18.3332 4.96771 18.1576 4.65515 17.845C4.34259 17.5325 4.16699 17.1085 4.16699 16.6665V4.99984M6.66699 4.99984V3.33317C6.66699 2.89114 6.84259 2.46722 7.15515 2.15466C7.46771 1.8421 7.89163 1.6665 8.33366 1.6665H11.667C12.109 1.6665 12.5329 1.8421 12.8455 2.15466C13.1581 2.46722 13.3337 2.89114 13.3337 3.33317V4.99984"
                                        stroke="#9D9D9D"
                                        strokeWidth="1.25"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                      <path
                                        d="M8.33301 9.1665V14.1665"
                                        stroke="#9D9D9D"
                                        strokeWidth="1.25"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                      <path
                                        d="M11.667 9.1665V14.1665"
                                        stroke="#9D9D9D"
                                        strokeWidth="1.25"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </button>
                                </>
                              )}
                            </DivLogo>
                          )}

                          <input
                            type="file"
                            ref={refUploadAvatarInput}
                            accept="image/*"
                            onChange={e => handleChangeImage(e, 'avatar')}
                            style={{ display: 'none' }}
                          />
                        </DivAvatar>
                      </DivAvatarLeft>
                    </div>
                  </div>
                </DivAvatarFormat>
              </DivShare>

              {/* <Divider margin="32px 16px" /> */}

              <DivProfile>
                <InputText
                  title="Nome da loja"
                  placeholder="Título da página"
                  value={pageTitle}
                  onChange={e => setPageTitle(e.target.value)}
                  onBlur={handleSubmit}
                />

                <DivDescription>
                  <InputText
                    title="Descrição"
                    placeholder="Descrição do seu negócio"
                    value={pageDescription}
                    onChange={e => setPageDescription(e.target.value)}
                    onBlur={handleSubmit}
                    maxLength={240}
                    style="textarea"
                  />
                  <DescriptionSize>{descriptionSize}/240</DescriptionSize>
                </DivDescription>

                <H5>Seu link</H5>
                <DivUsername>
                  <input
                    type="text"
                    className="input-user"
                    placeholder="Usuário"
                    value={user}
                    onChange={e => setUser(e.target.value)}
                    onBlur={handleSubmit}
                  />
                  <div>
                    <span>.offstore.me</span>
                  </div>
                </DivUsername>

                {domain && domain.domain && domain.active ? (
                  <a
                    href={`https://${domain.domain}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span>{domain.domain}</span>
                  </a>
                ) : (
                  <a
                    href={`https://${username}.offstore.me`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span>{`${username}.offstore.me`}</span>
                  </a>
                )}
              </DivProfile>

              <Divider margin="32px 16px" />

              <DivSubTab>
                <div>
                  <H3>Informações de contato</H3>
                </div>
              </DivSubTab>

              <DivShare first>
                <InputText
                  title="WhatsApp"
                  description="Através desse número seus clientes entrarão em contato e
                  poderão enviar pedidos"
                  placeholder="51999999999"
                  value={inputPhone}
                  onChange={e =>
                    setInputPhone(e.target.value.replace(/[^0-9]+/g, ''))
                  }
                  maxLength={11}
                  // onFocus={() => setInputPhoneFocus(true)}
                  onBlur={handleSubmitPhone}
                  style="tel"
                />
              </DivShare>

              <DivShare>
                <InputText
                  title="Telefone"
                  description="Telefone para contato com a loja"
                  placeholder="51999999999"
                  value={phone}
                  onChange={e =>
                    setPhone(e.target.value.replace(/[^0-9]+/g, ''))
                  }
                  maxLength={11}
                  onBlur={phone !== rPhone && handleSubmitPhoneData}
                  style="tel"
                />
              </DivShare>

              <DivShare>
                <InputText
                  title="E-mail"
                  description="E-mail para contato com a loja"
                  placeholder="E-mail"
                  value={contact_email}
                  onChange={e => setContactEmail(e.target.value)}
                  onBlur={contact_email !== rContactEmail && handleSubmitData}
                />
              </DivShare>

              <Divider margin="32px 16px" />

              <DivSubTab>
                <div>
                  <H3>Outras informações</H3>
                </div>
              </DivSubTab>

              <DivShare first>
                <H5>Sobre a loja</H5>

                {/* <DivInput>
                      <textarea
                        rows={8}
                        maxLength="1000"
                        placeholder="Digite aqui"
                        onChange={e => setAbout(e.target.value)}
                        value={about}
                        onBlur={about !== rAbout && handleSubmitData}
                      />
                    </DivInput> */}

                <ButtonAdd
                  type="button"
                  onClick={() => {
                    setShowAboutModal(!showAboutModal);
                  }}
                >
                  + Adicionar texto sobre a loja
                </ButtonAdd>
              </DivShare>

              <DivShare>
                <H5>Política de troca e devolução</H5>

                {/* <DivInput>
                      <textarea
                        rows={8}
                        maxLength="1000"
                        placeholder="Digite aqui"
                        onChange={e => setPolicy(e.target.value)}
                        value={policy}
                        onBlur={() =>
                          handleUpdateUserConfigurations({
                            exchange_and_return_policy: policy,
                          })
                        }
                      />
                    </DivInput> */}

                <ButtonAdd
                  type="button"
                  onClick={() => {
                    setShowReturnPolicyModalModal(!showReturnPolicyModalModal);
                  }}
                >
                  + Adicionar políticas
                </ButtonAdd>
              </DivShare>

              <Card>
                <div>
                  {/* <img src={SocialMediaIcon} alt="social" /> */}
                  <H5>Redes sociais</H5>
                </div>
                <div>
                  <ButtonAdd
                    type="button"
                    onClick={() => {
                      setShowModal(!showModal);
                    }}
                  >
                    + Adicionar redes sociais
                  </ButtonAdd>
                </div>
              </Card>

              <Card>
                <div>
                  <H5>Endereço</H5>
                </div>
                <div>
                  <ButtonAdd
                    type="button"
                    onClick={() => {
                      setShowAddAddress(true);
                    }}
                  >
                    {rAddress ? 'Alterar endereço' : '+ Adicionar endereço'}
                  </ButtonAdd>
                </div>
              </Card>

              <DivCheckBox>
                <H5>Exibir endereço na sua loja</H5>

                <label className="switch">
                  <input
                    type="checkbox"
                    checked={showStoreAddress}
                    onChange={e =>
                      handleChangeButtonShowStoreAddress(e.target.checked)
                    }
                  />
                  <span className="slider round" />
                </label>
              </DivCheckBox>

              <DivShare last>
                <InputText
                  title="CNPJ"
                  placeholder="CNPJ"
                  value={cnpj}
                  onChange={e => setCnpj(e.target.value)}
                  onBlur={cnpj !== rCnpj && handleSubmitData}
                />
              </DivShare>
            </>
          )}

          {/* <DivTab>
            <div
              onClick={() => {
                setActiveTabDesign(!activeTabDesign);
                setActiveTabInfo(activeTabDesign === true && false);
              }}
            >
              <h4 className={activeTabDesign && 'blue'}>Design</h4>

              {activeTabDesign ? (
                <ArrowTopBoldIcon color="#0d85ff" />
              ) : (
                <ArrowDownBoldIcon />
              )}
            </div>
          </DivTab> */}

          {tabSelected === 'style' && (
            <ContainerTab>
              <DivModel>
                <DivTitle>
                  <div>
                    <H3>Modelo</H3>
                    {pro === 0 && <Label>PRO</Label>}
                  </div>

                  <Description>Selecione o modelo do seu site</Description>
                </DivTitle>

                <DivStoreType>
                  <DivButton
                    active={storeModel === 'catalog'}
                    onClick={() =>
                      disableButtonStoreModel
                        ? {}
                        : handleUpdateStoreModel('catalog')
                    }
                  >
                    Catálogo
                  </DivButton>

                  <DivButton
                    active={storeModel === 'store'}
                    onClick={() =>
                      disableButtonStoreModel
                        ? {}
                        : handleUpdateStoreModel('store')
                    }
                  >
                    Loja virtual profissional
                    {pro === 0 && storeModel === 'catalog' && (
                      <Label>PRO</Label>
                    )}
                  </DivButton>
                </DivStoreType>
                {errorUpdateStore && (
                  <span className="error">{errorUpdateStore}</span>
                )}
              </DivModel>

              {storeModel === 'store' && pro >= 1 && (
                <>
                  <Div margin="32px 0 0 0">
                    <DivTitle>
                      <H4>Páginas da loja</H4>
                      <Description>
                        Edite e adicione componentes nas páginas de sua loja{' '}
                      </Description>
                    </DivTitle>

                    <DivEditPage>
                      <Page>
                        <H4>Página inicial</H4>

                        <Button onClick={() => history.push('/elements')}>
                          <svg
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5.15519 11.837L1 13L2.16345 8.84643L9.77207 1.27771C9.85806 1.1898 9.96076 1.11995 10.0741 1.07226C10.1875 1.02457 10.3093 1 10.4323 1C10.5553 1 10.677 1.02457 10.7904 1.07226C10.9038 1.11995 11.0065 1.1898 11.0925 1.27771L12.7269 2.92068C12.8134 3.00648 12.8821 3.10857 12.929 3.22105C12.9759 3.33353 13 3.45417 13 3.57602C13 3.69787 12.9759 3.81851 12.929 3.93099C12.8821 4.04347 12.8134 4.14555 12.7269 4.23136L5.15519 11.837Z"
                              stroke="white"
                              strokeWidth="1.25"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                          Editar página
                        </Button>
                      </Page>
                    </DivEditPage>
                  </Div>
                </>
              )}

              {!selectedThemeId && !loading && (
                <>
                  <Divider />

                  <Div>
                    <DivTitle>
                      <div>
                        <H3>Selecionar tema</H3>
                      </div>

                      <Description>
                        Selecione e personalize um tema para sua loja virtual
                      </Description>
                    </DivTitle>

                    <DivThemes>
                      {themes &&
                        themes.map(th => (
                          <Theme key={th.id} active={selectedThemeId === th.id}>
                            <div>
                              <H4>{th.title}</H4>
                            </div>

                            <Description>{th.description}</Description>

                            {selectedThemeId === th.id ? (
                              <button
                                type="button"
                                // onClick={() => history.push('/personalizetheme')}
                              >
                                Ativo
                              </button>
                            ) : (
                              <button
                                type="button"
                                onClick={() => handleSelectTheme(th.id)}
                                disabled={loadingButtonSelelectedTheme}
                              >
                                Selecionar
                              </button>
                            )}
                          </Theme>
                        ))}

                      {themes && themes.length > 0 && (
                        <Theme disabled>
                          <div>
                            <H4>Moderno</H4>
                            <Label
                              background="#fafafa"
                              borderColor="#9D9D9D"
                              color="#9D9D9D"
                            >
                              Em breve
                            </Label>
                          </div>

                          <Description>Tema moderno</Description>

                          <button type="button" disabled>
                            Em breve
                          </button>
                        </Theme>
                      )}
                    </DivThemes>
                  </Div>
                </>
              )}

              {storeModel === 'catalog' && (
                <>
                  <Divider margin="32px 0" />

                  <Div>
                    <DivTitle>
                      <div>
                        <H3>Banners</H3>
                        {pro === 0 && <Label>PRO</Label>}
                      </div>
                    </DivTitle>

                    <Description>
                      Adicione banners para destacar sua marca, promoções e
                      mais.
                    </Description>

                    <DivDevice>
                      <div>
                        <div
                          onClick={
                            pro >= 1
                              ? () => setSelectedDevice(0)
                              : () => setShowProPlanPopUp(true)
                          }
                        >
                          <PhoneIcon
                            color={selectedDevice === 0 ? '#2b8ff5' : '#9d9d9d'}
                          />
                          <span
                            className={selectedDevice === 0 ? 'selected' : ''}
                          >
                            Celular/tablet
                          </span>
                        </div>

                        <div
                          onClick={
                            pro >= 1
                              ? () => setSelectedDevice(1)
                              : () => setShowProPlanPopUp(true)
                          }
                        >
                          <DesktopIcon
                            color={selectedDevice === 1 ? '#2b8ff5' : '#9d9d9d'}
                          />
                          <span
                            className={selectedDevice === 1 ? 'selected' : ''}
                          >
                            Desktop
                          </span>
                        </div>
                      </div>

                      {selectedDevice === 0 ? (
                        <>
                          {bannerFormatImage === 1 ? (
                            <Description>
                              Tamanho recomendado: 633x300(largura x altura)
                            </Description>
                          ) : (
                            <Description>
                              Tamanho recomendado: 562x300(largura x altura)
                            </Description>
                          )}
                        </>
                      ) : (
                        <Description>
                          Tamanho recomendado: 1280x256(largura x altura)
                        </Description>
                      )}
                    </DivDevice>

                    {selectedDevice === 0 ? (
                      <DivBannerImages
                        disabled={pro === 0}
                        onClick={
                          pro === 0 ? () => setShowProPlanPopUp(true) : ''
                        }
                      >
                        <button
                          type="button"
                          className="button-img"
                          disabled={pro === 0}
                          onClick={() => refUploadImage.current.click()}
                        >
                          <img src={imgIcon} alt="img" />
                        </button>
                        <LabelImage htmlFor="image">
                          <input
                            type="file"
                            ref={refUploadImage}
                            id="image"
                            className="custom-file-input"
                            accept="image/*"
                            onChange={e => handleChangeImage(e, 'banner')}
                            style={{ display: 'none' }}
                          />
                        </LabelImage>

                        <DragDropContext onDragEnd={onDragEndBanners}>
                          <Droppable
                            droppableId="droppable"
                            direction="horizontal"
                          >
                            {(provided, _) => (
                              <DivImages
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                              >
                                {banners &&
                                  banners.map((banner, index) => (
                                    <Draggable
                                      key={String(banner.id)}
                                      draggableId={String(banner.id)}
                                      index={index}
                                    >
                                      {(provided, _) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                        >
                                          <ImgPreview
                                            image={banner}
                                            type="banner"
                                            deleteImages={deleteImages}
                                            src={banner.image_url}
                                            alt=""
                                          />
                                        </div>
                                      )}
                                    </Draggable>
                                  ))}
                                {provided.placeholder}
                                {loadingBanner && (
                                  <img src={loaderGif} alt="loader" />
                                )}
                              </DivImages>
                            )}
                          </Droppable>
                        </DragDropContext>
                      </DivBannerImages>
                    ) : (
                      <DivBannerImages disabled={pro === 0} desktop>
                        <button
                          type="button"
                          className="button-img"
                          disabled={pro === 0}
                          onClick={() => refUploadImage.current.click()}
                        >
                          <img src={imgIcon} alt="img" />
                        </button>
                        <LabelImage htmlFor="image">
                          <input
                            type="file"
                            ref={refUploadImage}
                            id="image"
                            className="custom-file-input"
                            accept="image/*"
                            onChange={e =>
                              handleChangeImage(e, 'banner-desktop')
                            }
                            style={{ display: 'none' }}
                          />
                        </LabelImage>

                        <DragDropContext onDragEnd={onDragEndBannersDesktop}>
                          <Droppable
                            droppableId="droppable"
                            direction="horizontal"
                          >
                            {(provided, _) => (
                              <DivImages
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                              >
                                {bannersDesktop &&
                                  bannersDesktop.map((banner, index) => (
                                    <Draggable
                                      key={String(banner.id)}
                                      draggableId={String(banner.id)}
                                      index={index}
                                    >
                                      {(provided, _) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                        >
                                          <ImgPreview
                                            image={banner}
                                            type="banner-desktop"
                                            deleteImages={deleteImages}
                                            src={banner.image_url}
                                            alt=""
                                            desktop
                                          />
                                        </div>
                                      )}
                                    </Draggable>
                                  ))}
                                {provided.placeholder}
                                {loadingBannerDesktop && (
                                  <img src={loaderGif} alt="loader" />
                                )}
                              </DivImages>
                            )}
                          </Droppable>
                        </DragDropContext>
                      </DivBannerImages>
                    )}
                  </Div>
                </>
              )}

              <Divider />

              {/* <Div>
                <DivTitle>
                  <div>
                    <H3>Cor principal</H3>
                    {pro === 0 && <Label>PRO</Label>}
                  </div>

                  <Description>
                    Recomendamos que não utilize cores claras, pois alguns itens
                    do site podem não ficar muito visíveis
                  </Description>
                </DivTitle>

                <DivColors
                  disabled={pro === 0}
                  onClick={pro === 0 ? () => setShowProPlanPopUp(true) : ''}
                >
                  <ColorButton
                    handleColor={handlePrimaryColor}
                    type="primary"
                    colors={primaryColor || '#000000'}
                    disable={pro === 0}
                    saveOnExit
                    top="-65px"
                    left="57px"
                    handleSubmitTheme={color =>
                      handleSubmitPersonalize({
                        text: { highlighted_text_color: color },
                        button: { background_color: color },
                      })
                    }
                  />
                </DivColors>
              </Div>

              <Divider margin="32px 0" /> */}

              <DivTab
                disabled={themes && !themes.find(t => t.id === selectedThemeId)}
              >
                <div>
                  <H3>Personalizar estilo</H3>
                </div>
              </DivTab>

              <Div
                disabled={themes && !themes.find(t => t.id === selectedThemeId)}
              >
                <DivBorder first>
                  <DivItem onClick={() => setShowGeneral(!showGeneral)}>
                    <H4>Geral</H4>

                    <div>
                      <img
                        src={showGeneral ? UpArrowIcon : DownArrowIcon}
                        alt="arrow"
                      />
                    </div>
                  </DivItem>

                  {showGeneral && (
                    <DivContentItem
                      padding="0"
                      // disabled={pro === 0}
                    >
                      <Div>
                        <DivTitle>
                          <div>
                            <H5>Cor principal</H5>

                            {pro === 0 && <Label>PRO</Label>}
                          </div>
                        </DivTitle>

                        <DivColors
                          // disabled={pro === 0}
                          onClick={
                            pro === 0 ? () => setShowProPlanPopUp(true) : ''
                          }
                          margin="0"
                        >
                          <ColorButton
                            handleColor={handlePrimaryColor}
                            type="primary"
                            colors={primaryColor || '#000000'}
                            disable={pro === 0}
                            saveOnExit
                            top="-65px"
                            left="57px"
                            handleSubmitTheme={color =>
                              handleSubmitPersonalize({
                                text: { highlighted_text_color: color },
                                button: { background_color: color },
                              })
                            }
                          />
                        </DivColors>
                      </Div>
                    </DivContentItem>
                  )}
                </DivBorder>

                <DivBorder>
                  <DivItem onClick={() => setShowHeader(!showHeader)}>
                    <H4>Cabeçalho</H4>

                    <div>
                      <img
                        src={showHeader ? UpArrowIcon : DownArrowIcon}
                        alt="arrow"
                      />
                    </div>
                  </DivItem>

                  {showHeader && (
                    <DivContentItem
                      padding="0"
                      // disabled={pro === 0}
                    >
                      <Div>
                        <DivTitle>
                          <div>
                            <H5>Cor de fundo do cabeçalho</H5>

                            {pro === 0 && <Label>PRO</Label>}
                          </div>
                        </DivTitle>

                        <DivColors
                          // disabled={pro === 0}
                          onClick={
                            pro === 0 ? () => setShowProPlanPopUp(true) : ''
                          }
                        >
                          <ColorButton
                            handleColor={color => {
                              setBackgroundColor(color);
                            }}
                            type="primary"
                            disable={pro === 0}
                            colors={backgroundColor || '#000000'}
                            top="auto"
                            left="15px"
                            handleSubmitTheme={color =>
                              handleSubmitPersonalize({
                                header: { background_color: color },
                              })
                            }
                          />
                        </DivColors>
                      </Div>

                      <Div>
                        <DivTitle>
                          <div>
                            <H5>Cor dos textos do cabeçalho</H5>

                            {pro === 0 && <Label>PRO</Label>}
                          </div>
                        </DivTitle>

                        <DivColors
                          // disabled={pro === 0}
                          onClick={
                            pro === 0 ? () => setShowProPlanPopUp(true) : ''
                          }
                        >
                          <ColorButton
                            handleColor={color => {
                              setTextColor(color);
                            }}
                            disable={pro === 0}
                            type="primary"
                            colors={textColor || '#000000'}
                            top="auto"
                            left="15px"
                            handleSubmitTheme={color =>
                              handleSubmitPersonalize({
                                header: { text_color: color },
                              })
                            }
                          />
                        </DivColors>
                      </Div>
                    </DivContentItem>
                  )}
                </DivBorder>

                <DivBorder>
                  <DivItem onClick={() => setShowFonts(!showFonts)}>
                    <H4>Fontes</H4>

                    <div>
                      <img
                        src={showFonts ? UpArrowIcon : DownArrowIcon}
                        alt="arrow"
                      />
                    </div>
                  </DivItem>

                  {showFonts && (
                    <DivContentItem
                      padding="0"
                      // disabled={pro === 0}
                    >
                      <Div>
                        <DivTitle>
                          <div>
                            <H5>Fonte dos títulos</H5>

                            {pro === 0 && <Label>PRO</Label>}
                          </div>
                        </DivTitle>

                        <DivFonts>
                          <DivButtonText
                            active={selectedFontFamily === 'Inter'}
                            fontFamily="Inter"
                            fontWeight="600"
                            onClick={
                              pro === 0
                                ? () => setShowProPlanPopUp(true)
                                : () => {
                                    setSelectedFontFamily('Inter');
                                    handleSubmitPersonalize({
                                      text: {
                                        title_font_family: 'Inter',
                                        title_font_weight: '600',
                                      },
                                    });
                                  }
                            }
                            textUnderBox="Inter"
                          >
                            Text
                          </DivButtonText>

                          <DivButtonText
                            active={selectedFontFamily === 'Playfair Display'}
                            onClick={
                              pro === 0
                                ? () => setShowProPlanPopUp(true)
                                : () => {
                                    setSelectedFontFamily('Playfair Display');
                                    handleSubmitPersonalize({
                                      text: {
                                        title_font_family: 'Playfair Display',
                                        title_font_weight: '700',
                                      },
                                    });
                                  }
                            }
                            fontFamily="Playfair Display"
                            fontWeight="700"
                            textUnderBox="Playfair Display"
                          >
                            Text
                          </DivButtonText>

                          <DivButtonText
                            active={selectedFontFamily === 'Rubik'}
                            onClick={
                              pro === 0
                                ? () => setShowProPlanPopUp(true)
                                : () => {
                                    setSelectedFontFamily('Rubik');
                                    handleSubmitPersonalize({
                                      text: {
                                        title_font_family: 'Rubik',
                                        title_font_weight: '600',
                                      },
                                    });
                                  }
                            }
                            fontFamily="Rubik"
                            fontWeight="600"
                            textUnderBox="Rubik"
                          >
                            Text
                          </DivButtonText>

                          <DivButtonText
                            active={selectedFontFamily === 'Source Serif 4'}
                            onClick={
                              pro === 0
                                ? () => setShowProPlanPopUp(true)
                                : () => {
                                    setSelectedFontFamily('Source Serif 4');
                                    handleSubmitPersonalize({
                                      text: {
                                        title_font_family: 'Source Serif 4',
                                        title_font_weight: '600',
                                      },
                                    });
                                  }
                            }
                            fontFamily="'Source Serif 4'"
                            fontWeight="600"
                            textUnderBox="Source Serif 4"
                          >
                            Text
                          </DivButtonText>

                          <DivButtonText
                            active={selectedFontFamily === 'Montserrat'}
                            onClick={
                              pro === 0
                                ? () => setShowProPlanPopUp(true)
                                : () => {
                                    setSelectedFontFamily('Montserrat');
                                    handleSubmitPersonalize({
                                      text: {
                                        title_font_family: 'Montserrat',
                                        title_font_weight: '600',
                                      },
                                    });
                                  }
                            }
                            fontFamily="Montserrat"
                            fontWeight="600"
                            textUnderBox="Montserrat"
                          >
                            Text
                          </DivButtonText>

                          <DivButtonText
                            active={selectedFontFamily === 'Poppins'}
                            onClick={
                              pro === 0
                                ? () => setShowProPlanPopUp(true)
                                : () => {
                                    setSelectedFontFamily('Poppins');
                                    handleSubmitPersonalize({
                                      text: {
                                        title_font_family: 'Poppins',
                                        title_font_weight: '600',
                                      },
                                    });
                                  }
                            }
                            fontFamily="Poppins"
                            fontWeight="600"
                            textUnderBox="Poppins"
                          >
                            Text
                          </DivButtonText>

                          <DivButtonText
                            active={selectedFontFamily === 'Taviraj'}
                            onClick={
                              pro === 0
                                ? () => setShowProPlanPopUp(true)
                                : () => {
                                    setSelectedFontFamily('Taviraj');
                                    handleSubmitPersonalize({
                                      text: {
                                        title_font_family: 'Taviraj',
                                        title_font_weight: '500',
                                      },
                                    });
                                  }
                            }
                            fontFamily="Taviraj"
                            fontWeight="500"
                            textUnderBox="Taviraj"
                          >
                            Text
                          </DivButtonText>

                          <DivButtonText
                            active={selectedFontFamily === 'IBM Plex Mono'}
                            onClick={
                              pro === 0
                                ? () => setShowProPlanPopUp(true)
                                : () => {
                                    setSelectedFontFamily('IBM Plex Mono');
                                    handleSubmitPersonalize({
                                      text: {
                                        title_font_family: 'IBM Plex Mono',
                                        title_font_weight: '600',
                                      },
                                    });
                                  }
                            }
                            fontFamily="IBM Plex Mono"
                            fontWeight="600"
                            textUnderBox="IBM Plex Mono"
                          >
                            Text
                          </DivButtonText>

                          <DivButtonText
                            active={selectedFontFamily === 'Exo 2'}
                            onClick={
                              pro === 0
                                ? () => setShowProPlanPopUp(true)
                                : () => {
                                    setSelectedFontFamily('Exo 2');
                                    handleSubmitPersonalize({
                                      text: {
                                        title_font_family: 'Exo 2',
                                        title_font_weight: '700',
                                      },
                                    });
                                  }
                            }
                            fontFamily="'Exo 2'"
                            fontWeight="700"
                            textUnderBox="Exo 2"
                          >
                            Text
                          </DivButtonText>

                          <DivButtonText
                            active={selectedFontFamily === 'Fredoka'}
                            onClick={
                              pro === 0
                                ? () => setShowProPlanPopUp(true)
                                : () => {
                                    setSelectedFontFamily('Fredoka');
                                    handleSubmitPersonalize({
                                      text: {
                                        title_font_family: 'Fredoka',
                                        title_font_weight: '600',
                                      },
                                    });
                                  }
                            }
                            fontFamily="Fredoka"
                            fontWeight="600"
                            textUnderBox="Fredoka"
                          >
                            Text
                          </DivButtonText>

                          <DivButtonText
                            active={selectedFontFamily === 'Kaushan Script'}
                            onClick={
                              pro === 0
                                ? () => setShowProPlanPopUp(true)
                                : () => {
                                    setSelectedFontFamily('Kaushan Script');
                                    handleSubmitPersonalize({
                                      text: {
                                        title_font_family: 'Kaushan Script',
                                        title_font_weight: '400',
                                      },
                                    });
                                  }
                            }
                            fontFamily="Kaushan Script"
                            fontWeight="400"
                            textUnderBox="Kaushan Script"
                          >
                            Text
                          </DivButtonText>
                        </DivFonts>
                      </Div>

                      <Div>
                        <DivTitle>
                          <div>
                            <H5>Peso dos títulos</H5>

                            {pro === 0 && <Label>PRO</Label>}
                          </div>
                        </DivTitle>

                        <DivFontWeight>
                          <FontWeight
                            left
                            active={selectedTitleFontWeight === '500'}
                            selectedFontWeigth={selectedTitleFontWeight}
                            onClick={
                              pro === 0
                                ? () => setShowProPlanPopUp(true)
                                : () => {
                                    setSelectedTitleFontWeight('500');
                                    handleSubmitPersonalize({
                                      text: { title_font_weight: '500' },
                                    });
                                  }
                            }
                          >
                            Fina
                          </FontWeight>

                          <FontWeight
                            middle
                            active={selectedTitleFontWeight === '600'}
                            selectedFontWeigth={selectedTitleFontWeight}
                            onClick={
                              pro === 0
                                ? () => setShowProPlanPopUp(true)
                                : () => {
                                    setSelectedTitleFontWeight('600');
                                    handleSubmitPersonalize({
                                      text: { title_font_weight: '600' },
                                    });
                                  }
                            }
                          >
                            Média
                          </FontWeight>

                          <FontWeight
                            right
                            active={selectedTitleFontWeight === '700'}
                            selectedFontWeigth={selectedTitleFontWeight}
                            onClick={
                              pro === 0
                                ? () => setShowProPlanPopUp(true)
                                : () => {
                                    setSelectedTitleFontWeight('700');
                                    handleSubmitPersonalize({
                                      text: { title_font_weight: '700' },
                                    });
                                  }
                            }
                          >
                            Grossa
                          </FontWeight>
                        </DivFontWeight>
                      </Div>

                      <Div>
                        <DivTitle>
                          <div>
                            <H5>Fonte dos parágrafos</H5>

                            {pro === 0 && <Label>PRO</Label>}
                          </div>
                        </DivTitle>

                        <DivFonts>
                          <DivButtonText
                            active={selectedFontFamilyParagraph === 'Inter'}
                            onClick={
                              pro === 0
                                ? () => setShowProPlanPopUp(true)
                                : () => {
                                    setSelectedFontFamilyParagraph('Inter');
                                    handleSubmitPersonalize({
                                      text: { paragraph_font_family: 'Inter' },
                                    });
                                  }
                            }
                            fontFamily="Inter"
                            textUnderBox="Inter"
                          >
                            Text
                          </DivButtonText>

                          <DivButtonText
                            active={selectedFontFamilyParagraph === 'DM Sans'}
                            onClick={
                              pro === 0
                                ? () => setShowProPlanPopUp(true)
                                : () => {
                                    setSelectedFontFamilyParagraph('DM Sans');
                                    handleSubmitPersonalize({
                                      text: {
                                        paragraph_font_family: 'DM Sans',
                                      },
                                    });
                                  }
                            }
                            fontFamily="DM Sans"
                            textUnderBox="DM Sans"
                          >
                            Text
                          </DivButtonText>

                          <DivButtonText
                            active={selectedFontFamilyParagraph === 'Roboto'}
                            onClick={
                              pro === 0
                                ? () => setShowProPlanPopUp(true)
                                : () => {
                                    setSelectedFontFamilyParagraph('Roboto');
                                    handleSubmitPersonalize({
                                      text: { paragraph_font_family: 'Roboto' },
                                    });
                                  }
                            }
                            fontFamily="Roboto"
                            textUnderBox="Roboto"
                          >
                            Text
                          </DivButtonText>

                          <DivButtonText
                            active={selectedFontFamilyParagraph === 'Rubik'}
                            onClick={
                              pro === 0
                                ? () => setShowProPlanPopUp(true)
                                : () => {
                                    setSelectedFontFamilyParagraph('Rubik');
                                    handleSubmitPersonalize({
                                      text: { paragraph_font_family: 'Rubik' },
                                    });
                                  }
                            }
                            fontFamily="Rubik"
                            textUnderBox="Rubik"
                          >
                            Text
                          </DivButtonText>

                          <DivButtonText
                            active={
                              selectedFontFamilyParagraph === 'Montserrat'
                            }
                            onClick={
                              pro === 0
                                ? () => setShowProPlanPopUp(true)
                                : () => {
                                    setSelectedFontFamilyParagraph(
                                      'Montserrat'
                                    );
                                    handleSubmitPersonalize({
                                      text: {
                                        paragraph_font_family: 'Montserrat',
                                      },
                                    });
                                  }
                            }
                            fontFamily="Montserrat"
                            textUnderBox="Montserrat"
                          >
                            Text
                          </DivButtonText>

                          <DivButtonText
                            active={selectedFontFamilyParagraph === 'Taviraj'}
                            onClick={
                              pro === 0
                                ? () => setShowProPlanPopUp(true)
                                : () => {
                                    setSelectedFontFamilyParagraph('Taviraj');
                                    handleSubmitPersonalize({
                                      text: {
                                        paragraph_font_family: 'Taviraj',
                                      },
                                    });
                                  }
                            }
                            fontFamily="Taviraj"
                            textUnderBox="Taviraj"
                          >
                            Text
                          </DivButtonText>

                          <DivButtonText
                            active={
                              selectedFontFamilyParagraph === 'Playfair Display'
                            }
                            onClick={
                              pro === 0
                                ? () => setShowProPlanPopUp(true)
                                : () => {
                                    setSelectedFontFamilyParagraph(
                                      'Playfair Display'
                                    );
                                    handleSubmitPersonalize({
                                      text: {
                                        paragraph_font_family:
                                          'Playfair Display',
                                      },
                                    });
                                  }
                            }
                            fontFamily="Playfair Display"
                            textUnderBox="Playfair Display"
                          >
                            Text
                          </DivButtonText>

                          <DivButtonText
                            active={selectedFontFamilyParagraph === 'Exo 2'}
                            onClick={
                              pro === 0
                                ? () => setShowProPlanPopUp(true)
                                : () => {
                                    setSelectedFontFamilyParagraph('Exo 2');
                                    handleSubmitPersonalize({
                                      text: { paragraph_font_family: 'Exo 2' },
                                    });
                                  }
                            }
                            fontFamily="'Exo 2'"
                            textUnderBox="Exo 2"
                          >
                            Text
                          </DivButtonText>

                          <DivButtonText
                            active={selectedFontFamilyParagraph === 'Crimson'}
                            onClick={
                              pro === 0
                                ? () => setShowProPlanPopUp(true)
                                : () => {
                                    setSelectedFontFamilyParagraph('Crimson');
                                    handleSubmitPersonalize({
                                      text: {
                                        paragraph_font_family: 'Crimson',
                                      },
                                    });
                                  }
                            }
                            fontFamily="Crimson"
                            textUnderBox="Crimson"
                          >
                            Text
                          </DivButtonText>

                          <DivButtonText
                            active={
                              selectedFontFamilyParagraph === 'IBM Plex Mono'
                            }
                            onClick={
                              pro === 0
                                ? () => setShowProPlanPopUp(true)
                                : () => {
                                    setSelectedFontFamilyParagraph(
                                      'IBM Plex Mono'
                                    );
                                    handleSubmitPersonalize({
                                      text: {
                                        paragraph_font_family: 'IBM Plex Mono',
                                      },
                                    });
                                  }
                            }
                            fontFamily="IBM Plex Mono"
                            textUnderBox="IBM Plex Mono"
                          >
                            Text
                          </DivButtonText>
                        </DivFonts>
                      </Div>
                      <Div>
                        <DivTitle>
                          <div>
                            <H5>Cor dos títulos</H5>

                            {pro === 0 && <Label>PRO</Label>}
                          </div>
                        </DivTitle>

                        <DivColors
                          // disabled={pro === 0}
                          onClick={
                            pro === 0 ? () => setShowProPlanPopUp(true) : ''
                          }
                        >
                          <ColorButton
                            handleColor={color => setTitleColor(color)}
                            type="primary"
                            colors={titleColor}
                            top="auto"
                            left="15px"
                            disable={pro === 0}
                            handleSubmitTheme={color =>
                              handleSubmitPersonalize({
                                text: { title_color: color },
                              })
                            }
                          />
                        </DivColors>
                      </Div>

                      <Div>
                        <DivTitle>
                          <div>
                            <H5>Cor dos preços</H5>

                            {pro === 0 && <Label>PRO</Label>}
                          </div>
                        </DivTitle>

                        <DivColors
                          // disabled={pro === 0}
                          onClick={
                            pro === 0 ? () => setShowProPlanPopUp(true) : ''
                          }
                        >
                          <ColorButton
                            handleColor={color =>
                              setHighlightedTextColor(color)
                            }
                            type="primary"
                            colors={highlightedTextColor}
                            top="auto"
                            left="15px"
                            disable={pro === 0}
                            handleSubmitTheme={color =>
                              handleSubmitPersonalize({
                                text: { highlighted_text_color: color },
                              })
                            }
                          />
                        </DivColors>
                      </Div>
                    </DivContentItem>
                  )}
                </DivBorder>

                <DivBorder>
                  <DivItem onClick={() => setShowProductList(!showProductList)}>
                    <H4>Lista de produtos</H4>

                    <div>
                      <img
                        src={showProductList ? UpArrowIcon : DownArrowIcon}
                        alt="arrow"
                      />
                    </div>
                  </DivItem>

                  {showProductList && (
                    <DivContentItem
                      padding="0"
                      // disabled={pro === 0}
                    >
                      <Div>
                        <DivTitle>
                          <div>
                            <H5>Formato das imagens</H5>

                            {pro === 0 && <Label>PRO</Label>}
                          </div>
                        </DivTitle>

                        <DivImageFormats>
                          <div
                            onClick={
                              pro === 0
                                ? () => setShowProPlanPopUp(true)
                                : () => {
                                    setSelectedFormatImage('square');
                                    handleSubmitPersonalize({
                                      product: { grid_style: 'square' },
                                    });
                                  }
                            }
                          >
                            <div>
                              <SquareIcon
                                selected={selectedFormatImage === 'square'}
                              />
                            </div>

                            <span
                              className={
                                selectedFormatImage === 'square'
                                  ? 'selected'
                                  : 'none'
                              }
                            >
                              Quadrado
                            </span>
                          </div>

                          <div
                            onClick={
                              pro === 0
                                ? () => setShowProPlanPopUp(true)
                                : () => {
                                    setSelectedFormatImage('rectangular');
                                    handleSubmitPersonalize({
                                      product: { grid_style: 'rectangular' },
                                    });
                                  }
                            }
                          >
                            <div>
                              <RectangularIcon
                                selected={selectedFormatImage === 'rectangular'}
                              />
                            </div>

                            <span
                              className={
                                selectedFormatImage === 'rectangular'
                                  ? 'selected'
                                  : 'none'
                              }
                            >
                              Retângular
                            </span>
                          </div>

                          <div
                            onClick={
                              pro === 0
                                ? () => setShowProPlanPopUp(true)
                                : () => {
                                    setSelectedFormatImage('full');
                                    handleSubmitPersonalize({
                                      product: { grid_style: 'full' },
                                    });
                                  }
                            }
                          >
                            <div>
                              <FullImageIcon
                                selected={selectedFormatImage === 'full'}
                              />
                            </div>

                            <span
                              className={
                                selectedFormatImage === 'full'
                                  ? 'selected'
                                  : 'none'
                              }
                            >
                              Tela cheia
                            </span>
                          </div>
                        </DivImageFormats>
                      </Div>

                      <Div>
                        <DivTitle>
                          <div>
                            <H5>Estilo de bordas</H5>

                            {pro === 0 && <Label>PRO</Label>}
                          </div>
                        </DivTitle>

                        <DivImageFormats>
                          <div
                            onClick={
                              pro === 0
                                ? () => setShowProPlanPopUp(true)
                                : () => {
                                    setSelectedBorderStyleImage('straight');
                                    handleSubmitPersonalize({
                                      product: {
                                        grid_image_border_style: 'straight',
                                      },
                                    });
                                  }
                            }
                          >
                            <div>
                              <StraightBorderIcon
                                selected={
                                  selectedBorderStyleImage === 'straight'
                                }
                              />
                            </div>

                            <span
                              className={
                                selectedBorderStyleImage === 'straight'
                                  ? 'selected'
                                  : 'none'
                              }
                            >
                              Retas
                            </span>
                          </div>

                          <div
                            onClick={
                              pro === 0
                                ? () => setShowProPlanPopUp(true)
                                : () => {
                                    setSelectedBorderStyleImage('rounded');
                                    handleSubmitPersonalize({
                                      product: {
                                        grid_image_border_style: 'rounded',
                                      },
                                    });
                                  }
                            }
                          >
                            <div>
                              <RoundedBorderIcon
                                selected={
                                  selectedBorderStyleImage === 'rounded'
                                }
                              />
                            </div>

                            <span
                              className={
                                selectedBorderStyleImage === 'rounded'
                                  ? 'selected'
                                  : 'none'
                              }
                            >
                              Arredondadas
                            </span>
                          </div>
                        </DivImageFormats>
                      </Div>

                      <Div>
                        <DivTitle>
                          <div>
                            <H5>Alinhamento do texto</H5>

                            {pro === 0 && <Label>PRO</Label>}
                          </div>
                        </DivTitle>

                        <DivImageFormats>
                          <div
                            onClick={
                              pro === 0
                                ? () => setShowProPlanPopUp(true)
                                : () => {
                                    setSelectedTextAlignImage('left');
                                    handleSubmitPersonalize({
                                      product: { grid_text_alignment: 'left' },
                                    });
                                  }
                            }
                          >
                            <div>
                              <TextAlignLeftIcon
                                selected={selectedTextAlignImage === 'left'}
                              />
                            </div>

                            <span
                              className={
                                selectedTextAlignImage === 'left'
                                  ? 'selected'
                                  : 'none'
                              }
                            >
                              Esquerda
                            </span>
                          </div>

                          <div
                            onClick={
                              pro === 0
                                ? () => setShowProPlanPopUp(true)
                                : () => {
                                    setSelectedTextAlignImage('center');
                                    handleSubmitPersonalize({
                                      product: {
                                        grid_text_alignment: 'center',
                                      },
                                    });
                                  }
                            }
                          >
                            <div>
                              <TextAlignCenterIcon
                                selected={selectedTextAlignImage === 'center'}
                              />
                            </div>

                            <span
                              className={
                                selectedTextAlignImage === 'center'
                                  ? 'selected'
                                  : 'none'
                              }
                            >
                              Centro
                            </span>
                          </div>
                        </DivImageFormats>
                      </Div>

                      <Div>
                        <DivTitle>
                          <div>
                            <H5>Estilo de nome do produto</H5>

                            {pro === 0 && <Label>PRO</Label>}
                          </div>
                        </DivTitle>

                        <DivFontWeight>
                          <BuyButtonStyle
                            left
                            active={uppercaseTitle === 'true'}
                            selectedFontWeigth={uppercaseTitle}
                            onClick={
                              pro === 0
                                ? () => setShowProPlanPopUp(true)
                                : () => {
                                    setUppercaseTitle('true');
                                    handleSubmitPersonalize({
                                      product: { uppercase_title: 'true' },
                                    });
                                  }
                            }
                          >
                            Somente maiúsculas
                          </BuyButtonStyle>

                          <BuyButtonStyle
                            right
                            active={uppercaseTitle === 'false'}
                            selectedFontWeigth={uppercaseTitle}
                            onClick={
                              pro === 0
                                ? () => setShowProPlanPopUp(true)
                                : () => {
                                    setUppercaseTitle('false');
                                    handleSubmitPersonalize({
                                      product: { uppercase_title: 'false' },
                                    });
                                  }
                            }
                          >
                            Normal
                          </BuyButtonStyle>
                        </DivFontWeight>
                      </Div>

                      <Div>
                        <DivTitle>
                          <div>
                            <H5>Botão de comprar</H5>

                            {pro === 0 && <Label>PRO</Label>}
                          </div>
                        </DivTitle>

                        <DivFontWeight>
                          <BuyButtonStyle
                            left
                            active={selectedBuyButtonStyle === 'under'}
                            selectedFontWeigth={selectedBuyButtonStyle}
                            onClick={
                              pro === 0
                                ? () => setShowProPlanPopUp(true)
                                : () => {
                                    setSelectedBuyButtonStyle('under');
                                    handleSubmitPersonalize({
                                      product: {
                                        grid_buy_button_style: 'under',
                                      },
                                    });
                                  }
                            }
                          >
                            Abaixo do produto
                          </BuyButtonStyle>

                          <BuyButtonStyle
                            right
                            active={selectedBuyButtonStyle === 'none'}
                            selectedFontWeigth={selectedBuyButtonStyle}
                            onClick={
                              pro === 0
                                ? () => setShowProPlanPopUp(true)
                                : () => {
                                    setSelectedBuyButtonStyle('none');
                                    handleSubmitPersonalize({
                                      product: {
                                        grid_buy_button_style: 'none',
                                      },
                                    });
                                  }
                            }
                          >
                            Sem botão
                          </BuyButtonStyle>
                        </DivFontWeight>
                      </Div>
                    </DivContentItem>
                  )}
                </DivBorder>

                <DivBorder>
                  <DivItem
                    onClick={() => setShowButtons(!showButtons)}
                    last={!showButtons}
                  >
                    <H4>Botões</H4>

                    <div>
                      <img
                        src={showButtons ? UpArrowIcon : DownArrowIcon}
                        alt="arrow"
                      />
                    </div>
                  </DivItem>

                  {showButtons && (
                    <DivContentItem
                    // disabled={pro === 0}
                    >
                      <Div>
                        <DivTitle>
                          <div>
                            <H5>Estilo da borda de botões</H5>

                            {pro === 0 && <Label>PRO</Label>}
                          </div>
                        </DivTitle>

                        <DivStyleButtons>
                          <div
                            onClick={
                              pro === 0
                                ? () => setShowProPlanPopUp(true)
                                : () => {
                                    setSelectInputStyle('rounded');
                                    handleSubmitPersonalize({
                                      button: { border_style: 'rounded' },
                                    });
                                  }
                            }
                          >
                            <InputModel
                              borderRadius="6px"
                              selected={selectInputStyle === 'rounded'}
                            />

                            <span
                              className={
                                selectInputStyle === 'rounded'
                                  ? 'selected'
                                  : 'none'
                              }
                            >
                              Arredondada
                            </span>
                          </div>

                          <div
                            onClick={
                              pro === 0
                                ? () => setShowProPlanPopUp(true)
                                : () => {
                                    setSelectInputStyle('straight');
                                    handleSubmitPersonalize({
                                      button: { border_style: 'straight' },
                                    });
                                  }
                            }
                          >
                            <InputModel
                              selected={selectInputStyle === 'straight'}
                            />

                            <span
                              className={
                                selectInputStyle === 'straight'
                                  ? 'selected'
                                  : 'none'
                              }
                            >
                              Reta
                            </span>
                          </div>

                          <div
                            onClick={
                              pro === 0
                                ? () => setShowProPlanPopUp(true)
                                : () => {
                                    setSelectInputStyle('circle');
                                    handleSubmitPersonalize({
                                      button: { border_style: 'circle' },
                                    });
                                  }
                            }
                          >
                            <InputModel
                              borderRadius="50px"
                              selected={selectInputStyle === 'circle'}
                            />

                            <span
                              className={
                                selectInputStyle === 'circle'
                                  ? 'selected'
                                  : 'none'
                              }
                            >
                              Circular
                            </span>
                          </div>
                        </DivStyleButtons>
                      </Div>

                      <Div>
                        <DivTitle>
                          <div>
                            <H5>Cor de fundo</H5>

                            {pro === 0 && <Label>PRO</Label>}
                          </div>
                        </DivTitle>

                        <DivColors
                          // disabled={pro === 0}
                          onClick={
                            pro === 0 ? () => setShowProPlanPopUp(true) : ''
                          }
                        >
                          <ColorButton
                            handleColor={color =>
                              setBackgroundButtonColor(color)
                            }
                            type="primary"
                            colors={backgroundButtonColor || '#000000'}
                            top="auto"
                            left="15px"
                            disable={pro === 0}
                            handleSubmitTheme={color =>
                              handleSubmitPersonalize({
                                button: { background_color: color },
                              })
                            }
                          />
                        </DivColors>
                      </Div>

                      <Div>
                        <DivTitle>
                          <div>
                            <H5>Cor do texto</H5>

                            {pro === 0 && <Label>PRO</Label>}
                          </div>
                        </DivTitle>

                        <DivColors
                          // disabled={pro === 0}
                          onClick={
                            pro === 0 ? () => setShowProPlanPopUp(true) : ''
                          }
                        >
                          <ColorButton
                            handleColor={color => setTextButtonColor(color)}
                            type="primary"
                            colors={textButtonColor || '#000000'}
                            top="auto"
                            left="15px"
                            disable={pro === 0}
                            handleSubmitTheme={color =>
                              handleSubmitPersonalize({
                                button: { text_color: color },
                              })
                            }
                          />
                        </DivColors>
                      </Div>
                    </DivContentItem>
                  )}
                </DivBorder>
              </Div>

              {loading && (
                <DivLoader>
                  <img src={loaderGif} alt="loading" />
                </DivLoader>
              )}
            </ContainerTab>
          )}
        </>

        {/* <LabelDomain /> */}

        <SocialMediaModal
          showModal={showModal}
          Modal={modal}
          url_facebook={facebook}
          url_twitter={twitter}
          url_instagram={instagram}
          email={email}
          user={username}
          isModal
        />

        <AboutModal
          showModal={showAboutModal}
          Modal={handleShowAboutModal}
          pAbout={about}
          email={email}
          user={username}
          isModal
        />

        <ReturnPolicyModal
          showModal={showReturnPolicyModalModal}
          Modal={handleShowReturnPolicyModalModal}
          pPolicy={policy}
          email={email}
          user={username}
          isModal
        />

        <CallModal
          showModal={showCallModal}
          Modal={modalCall}
          url_whatsapp={whatsapp}
          url_telegram={telegram}
          url_messenger={messenger}
          email={email}
          user={username}
        />

        {showAddAddress && (
          <AddAddress
            handleSetShowAddAddress={handleSetShowModal}
            hasFunction={false}
            isModal
          />
        )}

        {/* {showAboveHeaderModal && (
          <AboveHeaderModal
            handleSetShowModal={handleSetShowModal}
            isModal
            config={userTheme}
            handleSetUserTheme={handleSetUserTheme}
            userThemeId={userThemeId}
          />
        )} */}

        {/* {showHeaderModal && (
          <HeaderModal
            handleSetShowModal={handleSetShowModal}
            isModal
            config={userTheme}
            handleSetUserTheme={handleSetUserTheme}
            userThemeId={userThemeId}
            primaryColor={primaryColor}
          />
        )} */}

        {/* {showFontsModal && (
          <FontsModal
            handleSetShowModal={handleSetShowModal}
            isModal
            config={userTheme}
            handleSetUserTheme={handleSetUserTheme}
            userThemeId={userThemeId}
          />
        )} */}

        {/* {showStyleGridModal && (
          <StyleGridModal
            handleSetShowModal={handleSetShowModal}
            isModal
            config={userTheme}
            handleSetUserTheme={handleSetUserTheme}
            userThemeId={userThemeId}
          />
        )} */}

        {/* {showButtonsModal && (
          <ButtonsModal
            handleSetShowModal={handleSetShowModal}
            isModal
            config={userTheme}
            handleSetUserTheme={handleSetUserTheme}
            userThemeId={userThemeId}
          />
        )} */}
      </Container>
    </>
  );
}
