/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useGetSellerInfo } from '~/hooks/use-get-seller-info';
import { cpfMask, cnpjMask } from '~/util/format';
import Select from 'react-select';

import StatusTag from '~/components/StatusTag';
import DiscountInput from '~/components/DiscountInput';
// import CustomSelectComponent from '~/components/CustomSelectComponent';
import { useUpdateSellerPaymentConfiguration } from '~/hooks/use-update-seller-payment-configuration';
import useScreenWidth from '~/hooks/use-screen-width';
import { Divider, H3, H5, Space } from '~/designsystem/Fonts';
import LoaderComponent from '~/components/Loader';
import DivHelpCenter from '~/components/OffstorePay/HelpCenter';
import { ModalChangeBank } from './Components/ModalChangeBank';
// import loader from '../../../../../../assets/icons/loader-grey.gif';
import {
  ConfigurationSection,
  ConfigurationSectionTitle,
  ConfigurationSectionSubtitle,
  BankDataWrapper,
  BankIcon,
  BankData,
  SelectButton,
  SelectButtonsWrapper,
  SelectButtonBottomInfoWrapper,
  SelectButtonWrapper,
  Card,
  CardsWrapper,
  BankInfosWrapper,
  OutlinedButton,
  ExcludeText,
  ButtonFeesAndTerms,
  DivFeesAndTerms,
  Modal,
  BackgroundModal,
  DivInstallmentTable,
  Installment,
  InstallmentTableBody,
  WarningSpan,
} from './styles';
import Edit from '../../../../../../assets/icons/offstorepay/edit.svg';
import BankIc from '../../../../../../assets/icons/paymentmethods/bank.svg';
import ResponsiveHeader from '../../../../../../components/HeaderMobile';
import feeOffstorepay from '../../../../../../data/fee_offstorepay.json';
import installmentTableOffstorepay from '../../../../../../data/installment_offstorepay.json';

const creditCardInstallments = [
  {
    id: 1,
    value: '1',
    label: '1',
  },
  {
    id: 2,
    value: '2',
    label: '2',
  },
  {
    id: 3,
    value: '3',
    label: '3',
  },
  {
    id: 4,
    value: '4',
    label: '4',
  },
  {
    id: 5,
    value: '5',
    label: '5',
  },
  {
    id: 6,
    value: '6',
    label: '6',
  },
  {
    id: 7,
    value: '7',
    label: '7',
  },
  {
    id: 8,
    value: '8',
    label: '8',
  },
  {
    id: 9,
    value: '9',
    label: '9',
  },
  {
    id: 10,
    value: '10',
    label: '10',
  },
  {
    id: 11,
    value: '11',
    label: '11',
  },
  {
    id: 12,
    value: '12',
    label: '12',
  },
];

const receivingMethods = [
  {
    id: 'thirty_days',
    name: 'Em 30 dias',
    tax: '3.99%',
    way: 'no cartão',
    active: true,
  },
  {
    id: 'fifteen_days',
    name: 'Em 15 dias',
    tax: '4.49%',
    way: 'no cartão',
    active: false,
  },
  {
    id: 'now',
    name: 'Na hora',
    tax: '4.99%',
    way: 'no cartão',
    active: false,
  },
];

const ReceivingMethods = ({
  onSelectReceivingMethod,
  currentReceivingMethod,
}) => {
  const [selectedReceivingMethod, setSelectedReceivingMethod] = useState(
    receivingMethods.find(method => method.id === currentReceivingMethod) ||
      receivingMethods[0]
  );

  const handleSelectReceivingMethod = method => {
    setSelectedReceivingMethod(method);
    onSelectReceivingMethod(method);
  };

  return (
    <SelectButtonsWrapper>
      {receivingMethods.map((method, index) => {
        if (index === 0) {
          return (
            <SelectButtonWrapper>
              <SelectButton
                onClick={() => handleSelectReceivingMethod(method)}
                className={
                  selectedReceivingMethod.id === method.id
                    ? 'left-edge is-selected'
                    : 'left-edge'
                }
                key={method.id}
              >
                <p>{method.name}</p>
              </SelectButton>
              <SelectButtonBottomInfoWrapper
                active={selectedReceivingMethod.id === method.id}
              >
                <p>{method.tax}</p>
                <p>{method.way}</p>
              </SelectButtonBottomInfoWrapper>
            </SelectButtonWrapper>
          );
        }

        if (index === receivingMethods.length - 1) {
          return (
            <SelectButtonWrapper>
              <SelectButton
                onClick={() =>
                  method.active && handleSelectReceivingMethod(method)
                }
                className={
                  selectedReceivingMethod.id === method.id
                    ? 'right-edge is-selected disabled'
                    : 'right-edge disabled'
                }
                key={method.id}
              >
                <p>{method.name}</p>
              </SelectButton>
              <SelectButtonBottomInfoWrapper
                active={selectedReceivingMethod.id === method.id}
                className="disabled"
              >
                <p>{method.tax}</p>
                <p>{method.way}</p>
              </SelectButtonBottomInfoWrapper>
            </SelectButtonWrapper>
          );
        }

        return (
          <SelectButtonWrapper>
            <SelectButton
              className={
                selectedReceivingMethod.id === method.id
                  ? 'is-selected disabled'
                  : 'disabled'
              }
              key={method.id}
              onClick={() =>
                method.active && handleSelectReceivingMethod(method)
              }
            >
              <p>{method.name}</p>
            </SelectButton>
            <SelectButtonBottomInfoWrapper
              active={selectedReceivingMethod.id === method.id}
              className="disabled"
            >
              <p>{method.tax}</p>
              <p>{method.way}</p>
            </SelectButtonBottomInfoWrapper>
          </SelectButtonWrapper>
        );
      })}
    </SelectButtonsWrapper>
  );
};

export function MainOffstorePayComponent() {
  const { isMobile } = useScreenWidth();
  const history = useHistory();
  const userId = useSelector(
    state => state.user && state.user.profile && state.user.profile.id
  );

  const { handleGetSellerInfo } = useGetSellerInfo();
  const [checkCreditCard, setCheckCreditCard] = useState(true);
  const [checkPix, setCheckPix] = useState(true);
  const [checkBoleto, setCheckBoleto] = useState(true);
  const [maxCreditCardInstallments, setMaxCreditCardInstallments] = useState();
  const [pixDiscount, setPixDiscount] = useState(0);
  const [currentReceivingMethod, setCurrentReceivingMethod] = useState('now');
  const [loading, setLoading] = useState(false);
  const [bankAccountInformation, setBankAccountInformation] = useState(null);
  const [sellerId, setSellerId] = useState(null);
  const [openChangeBankComponent, setOpenChangeBankComponent] = useState(false);
  const [showModalFeesAndTerms, setShowModalFeesAndTerms] = useState(false);
  const [showModalInstallmentTable, setShowModalInstallmentTable] = useState(
    false
  );

  const {
    handleUpdateSellerPaymentConfiguration,
  } = useUpdateSellerPaymentConfiguration({
    bank_slip_is_active: checkBoleto,
    credit_card_is_active: checkCreditCard,
    pix_is_active: checkPix,
    max_credit_card_installments:
      maxCreditCardInstallments &&
      maxCreditCardInstallments.value &&
      Number(maxCreditCardInstallments.value),
    pix_discount: pixDiscount,
    seller_id: sellerId,
    time_to_receive: currentReceivingMethod.id || currentReceivingMethod,
  });

  useEffect(() => {
    if (!sellerId) return;
    handleUpdateSellerPaymentConfiguration();
  }, [
    checkCreditCard,
    checkPix,
    checkBoleto,
    maxCreditCardInstallments,
    currentReceivingMethod,
  ]);

  useEffect(() => {
    async function load() {
      setLoading(true);
      try {
        const response = await handleGetSellerInfo({
          userId,
        });

        if (response && response.sellerInfo) {
          setBankAccountInformation(response.sellerInfo.bank_account);
          setCheckCreditCard(
            response.sellerInfo.payment_configurations.credit_card_is_active
          );
          setCheckPix(response.sellerInfo.payment_configurations.pix_is_active);
          setCheckBoleto(
            response.sellerInfo.payment_configurations.bank_slip_is_active
          );
          setCurrentReceivingMethod(
            response.sellerInfo.payment_configurations.time_to_receive
          );
          setPixDiscount(
            response.sellerInfo.payment_configurations.pix_discount
          );

          const max_credit_card_installments = creditCardInstallments.find(
            c =>
              Number(c.value) ===
              response.sellerInfo.payment_configurations
                .max_credit_card_installments
          );

          setMaxCreditCardInstallments(max_credit_card_installments);
          setSellerId(response.sellerInfo.id);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    }

    load();
  }, []);

  useEffect(() => {
    const handleKeyPress = event => {
      if (event.key === 'Escape') {
        setOpenChangeBankComponent(false);
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      // Remove o event listener quando o componente for desmontado
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  const documentFormat = (type, number) => {
    if (type === 'CPF') return cpfMask(number);
    return cnpjMask(number);
  };

  const changeBankClick = () => {
    if (isMobile)
      return history.push(
        '/paymentmethods/configurationoffstorepay/changebankaccount'
      );
    return setOpenChangeBankComponent(true);
  };

  function handleBlurDiscountPix() {
    handleUpdateSellerPaymentConfiguration();
  }

  return (
    <>
      {openChangeBankComponent && <ModalChangeBank />}
      {loading && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <LoaderComponent size={40} />
        </div>
      )}
      {!loading && (
        <>
          <ResponsiveHeader
            title="Offstore Pay - Configurações"
            url="/paymentmethods"
          />
          <ConfigurationSection>
            <ConfigurationSectionTitle>
              Dados da sua conta bancária
            </ConfigurationSectionTitle>
            <ConfigurationSectionSubtitle>
              Insira os dados de sua conta bancária para receber os repasses dos
              pagamentos que você receber em sua loja
            </ConfigurationSectionSubtitle>

            <BankDataWrapper
              confirmed={
                bankAccountInformation && bankAccountInformation.confirmed
              }
            >
              <BankInfosWrapper hasBankAccount={!!bankAccountInformation}>
                <BankIcon src={BankIc} />
                <BankData>
                  {bankAccountInformation && (
                    <>
                      <p>
                        Banco {bankAccountInformation.name} / Ag:{' '}
                        {bankAccountInformation.agency_number} / Conta:{' '}
                        {bankAccountInformation.account_number}
                      </p>
                      <p>
                        CPF/CNPJ:
                        {documentFormat(
                          bankAccountInformation.document_type,
                          bankAccountInformation.document_number
                        )}
                      </p>
                    </>
                  )}

                  <OutlinedButton onClick={() => changeBankClick()}>
                    <img src={Edit} alt="edit icon" /> Alterar conta bancária
                  </OutlinedButton>
                </BankData>
              </BankInfosWrapper>
            </BankDataWrapper>

            {!bankAccountInformation ||
              (bankAccountInformation && !bankAccountInformation.confirmed && (
                <WarningSpan>
                  Falha nos repasses para sua conta bancária. Adicione uma conta
                  bancária válida.
                </WarningSpan>
              ))}
          </ConfigurationSection>

          <Space height="24px" />

          <ConfigurationSection>
            <ConfigurationSectionTitle>
              Formas de pagamento
            </ConfigurationSectionTitle>
            <ConfigurationSectionSubtitle>
              Selecione as formas de pagamento que você irá aceitar
            </ConfigurationSectionSubtitle>
            <CardsWrapper>
              <Card active={checkCreditCard}>
                <div className="left">
                  <div>
                    <strong>Cartão de crédito</strong>
                    {checkCreditCard && <StatusTag tagName="Ativo" />}
                  </div>
                  <span>{feeOffstorepay.credit} por transação</span>
                </div>

                <div className="right">
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={checkCreditCard}
                      onChange={() => setCheckCreditCard(!checkCreditCard)}
                    />
                    <span className="slider round" />
                  </label>
                </div>
              </Card>
              <Card active={checkPix}>
                <div className="left">
                  <div>
                    <strong>PIX</strong>{' '}
                    {checkPix && <StatusTag tagName="Ativo" />}
                  </div>
                  <span>{feeOffstorepay.pix} por transação</span>
                </div>

                <div className="right">
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={checkPix}
                      onChange={() => setCheckPix(!checkPix)}
                    />
                    <span className="slider round" />
                  </label>
                </div>
              </Card>
              {/* <Card active={checkBoleto}>
                <div className="left">
                  <div>
                    <strong>Boleto</strong>
                    {checkBoleto && <StatusTag tagName="Ativo" />}
                  </div>
                  <span>{feeOffstorepay.bolet} por transação</span>
                </div>

                <div className="right">
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={checkBoleto}
                      onChange={() => setCheckBoleto(!checkBoleto)}
                    />
                    <span className="slider round" />
                  </label>
                </div>
              </Card> */}
            </CardsWrapper>
          </ConfigurationSection>

          <Space height="24px" />

          <ConfigurationSection>
            <ConfigurationSectionTitle>
              Tempo para receber o dinheiro
            </ConfigurationSectionTitle>
            <ConfigurationSectionSubtitle>
              Por enquanto somente a opção de recebimento em 30 dias está
              disponível, em breve você poderá alterar os prazos para
              recebimento
            </ConfigurationSectionSubtitle>
            <ReceivingMethods
              onSelectReceivingMethod={method =>
                setCurrentReceivingMethod(method)
              }
              currentReceivingMethod={currentReceivingMethod}
            />
            <ButtonFeesAndTerms onClick={() => setShowModalFeesAndTerms(true)}>
              Conferir taxas e prazos
            </ButtonFeesAndTerms>
          </ConfigurationSection>

          <Space height="24px" />

          <ConfigurationSection>
            <ConfigurationSectionTitle>
              Desconto para pagamento via PIX
            </ConfigurationSectionTitle>
            <ConfigurationSectionSubtitle>
              Defina um desconto para pagamentos via PIX
            </ConfigurationSectionSubtitle>
            <DiscountInput
              defaDiscountInputultValue={pixDiscount}
              onChange={e => setPixDiscount(Number(e.target.value))}
              onBlur={handleBlurDiscountPix}
              placeholder="0"
              maxWidth="143px"
              marginTop="12px"
              value={pixDiscount}
            />
          </ConfigurationSection>

          <Space height="24px" />

          <ConfigurationSection>
            <ConfigurationSectionTitle>
              Parcelamento sem juros no cartão
            </ConfigurationSectionTitle>
            <ConfigurationSectionSubtitle>
              Defina em quantas vezes você quer parcelar sem juros para seu
              cliente. Veja a{' '}
              <button
                type="button"
                onClick={() => setShowModalInstallmentTable(true)}
              >
                tabela de parcelamento aqui
              </button>
            </ConfigurationSectionSubtitle>
            {/* <CustomSelectComponent
              register={() => {}}
              onChange={e =>
                setMaxCreditCardInstallments(Number(e.target.value))
              }
              banksData={Array.from(
                { length: maxCreditCardInstallments },
                (_, i) => {
                  const text = `Até ${i + 1} ${i === 0 ? 'vez' : 'vezes'}`;
                  return { code: i + 1, name: text };
                }
              )}
            /> */}

            <Space height="12px" />

            <Select
              id="selected"
              placeholder="Selecionar"
              name="tg"
              styles={{
                control: (provided, state) => ({
                  ...provided,
                  borderWidth: '1px',
                  borderColor: state.isFocused ? '#000000' : '#cdcdcd',
                  borderRadius: '8px',
                  boxShadow: 'none',
                  height: '40px',
                  padding: '0 8px 0 16px',
                }),
                indicatorSeparator: provided => ({
                  ...provided,
                  background: 'none',
                }),
                indicatorsContainer: provided => ({
                  ...provided,
                  padding: '0',
                }),
                valueContainer: provided => ({
                  ...provided,
                  padding: '0',
                }),
              }}
              options={creditCardInstallments}
              value={maxCreditCardInstallments}
              onChange={e => setMaxCreditCardInstallments(e)}
            />

            <Space height="8px" />

            {maxCreditCardInstallments &&
              maxCreditCardInstallments.value > 1 && (
                <ConfigurationSectionSubtitle>
                  Nesse caso você pagará os juros no lugar do seu cliente, caso
                  ele parcele o valor em até{' '}
                  {maxCreditCardInstallments && maxCreditCardInstallments.value}{' '}
                  vezes
                </ConfigurationSectionSubtitle>
              )}
          </ConfigurationSection>

          <Space height="40px" />

          <DivHelpCenter />

          <ExcludeText>
            <p>
              Deseja excluir sua conta Offstore Pay?{' '}
              <b>Entre em contato com o suporte</b>
            </p>
          </ExcludeText>
        </>
      )}

      {showModalFeesAndTerms && (
        <Modal>
          <BackgroundModal onClick={() => setShowModalFeesAndTerms(false)} />
          <DivFeesAndTerms>
            <H3>Taxas e prazos</H3>

            <Divider margin="12px 0 16px 0" />

            <div>
              <H5>Cartão de crédito</H5>
              <span>{feeOffstorepay.credit} - Recebimento em 30 dias</span>
            </div>

            <Divider margin="16px 0" />

            <div>
              <H5>PIX</H5>
              <span>{feeOffstorepay.pix} - Recebimento em 1 dia útil</span>
            </div>

            {/* <Divider margin="16px 0" />

            <div>
              <H5>Boleto</H5>
              <span>{feeOffstorepay.bolet} - Recebimento em 3 dias úteis</span>
            </div> */}
          </DivFeesAndTerms>
        </Modal>
      )}

      {showModalInstallmentTable && (
        <Modal>
          <BackgroundModal
            onClick={() => setShowModalInstallmentTable(false)}
          />
          <DivInstallmentTable>
            <H3>Tabela de parcelamento</H3>

            <Divider margin="12px 0 16px 0" />

            <InstallmentTableBody>
              {installmentTableOffstorepay.map((i, index) => (
                <>
                  <Installment border={index > 0}>
                    <H5>{i.installment}</H5>
                    <span>{i.value}</span>
                  </Installment>
                </>
              ))}
            </InstallmentTableBody>
          </DivInstallmentTable>
        </Modal>
      )}
    </>
  );
}
