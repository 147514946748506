/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';

import { Container, DivSpace, FiCheckStyled } from './styles';

import history from '../../../../services/history';

// import Star from '../../../../assets/icons/star.svg';
// import YellowStar from '../../../../assets/icons/starfull.svg';

function ProductHeader({
  title,
  // tab,
  hasHighlighted,
  handleChangeStar,
  // url_return,
}) {
  const [enableStar, setEnableStar] = useState(false);

  useEffect(() => {
    setEnableStar(hasHighlighted);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasHighlighted]);

  function handleClickStar(eStar) {
    handleChangeStar(eStar);
  }

  function handleBackClick() {
    history.push('/');
  }

  return (
    <Container>
      <DivSpace>
        <div className="left">
          <div onClick={handleBackClick}>
            <span>Cancelar</span>
          </div>
        </div>
      </DivSpace>
      <div>
        <span>{title}</span>
      </div>
      <DivSpace>
        <div className="right">
          {(enableStar && (
            <span className="destacado" onClick={() => handleClickStar(false)}>
              <FiCheckStyled color="#BBBBBB" size={10} />
              Destacado
            </span>
          )) || (
            <span className="destaque" onClick={() => handleClickStar(true)}>
              Destacar
            </span>
          )}
        </div>
      </DivSpace>
    </Container>
  );
}

export default ProductHeader;
