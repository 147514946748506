import React, { useState, useCallback } from 'react';

import { useDispatch } from 'react-redux';

import NoImage from '../../../../assets/icons/noimage.png';

import TrashIcon from '../../../../assets/icons/trashIcon.svg';
import StarIcon from '../../../../assets/icons/dashboard/StarIcon';

import {
  UpdateActiveLinkRequest,
  UpdateHighlightedLinkRequest,
} from '../../../../store/modules/linkPrev/actions';
import {
  Div,
  // DivDrag,
  DivAll,
  Image,
  DivLeft,
  DivRight,
  DivPrice,
  SpanPrice,
  // FaRegTrashAlts,
} from './styles';

function LinkList({ linkk, deleteLink, handleClickModal }) {
  // const [title, setTitle] = useState('');
  // const [link, setLink] = useState('');
  // const [description, setDescription] = useState('');
  const [highlighted, setHighlighted] = useState(linkk.highlighted);
  const [active, setActive] = useState(linkk.active);
  // const [highlighted, setHighlighted] = useState(linkk.highlighted)
  // const [tags, setTags] = useState([]);

  // const [selected, setSelected] = useState([
  //   { value: 'Bê', label: 'Bê' },
  //   { value: 'Bê', label: 'Bê' },
  //   { value: 'Bê', label: 'Bê' },
  // ]);

  const dispatch = useDispatch();

  function Delete(id) {
    deleteLink(id);
  }

  // function saveData(id) {
  //   Blur(id, {
  //     title,
  //     link,
  //     description,
  //     highlighted,
  //     active,
  //     tags,
  //   });
  // }

  function handleButtonClick(id) {
    handleClickModal(id);
  }

  const onChangeCheck = useCallback(
    e => {
      setActive(e.target.checked);
      const isActive = e.target.checked;

      dispatch(UpdateActiveLinkRequest(linkk.id, isActive));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onChangeHighlighted = useCallback(
    active => {
      setHighlighted(active);
      dispatch(UpdateHighlightedLinkRequest(linkk.id, active));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <Div active={active}>
      <DivAll>
        <Image
          src={
            (linkk.images && linkk.images[0] && linkk.images[0].image_url) ||
            NoImage
          }
        />
        <DivLeft onClick={() => handleButtonClick(linkk.id)}>
          <span>{linkk.title}</span>

          {(linkk.gridattributes && linkk.gridattributes.length > 0 && (
            <strong>Contém variações</strong>
          )) || (
            <DivPrice>
              {linkk.promotion_price && (
                <SpanPrice color="#2B8FF5">
                  R$ {linkk.promotion_price}
                </SpanPrice>
              )}

              {linkk.price && (
                <SpanPrice hasPromo={linkk.promotion_price}>
                  R$ {linkk.price}
                </SpanPrice>
              )}
            </DivPrice>
          )}

          {/* <strong>{linkk.promotion_price || linkk.price}</strong> */}
        </DivLeft>
        <DivRight>
          {(highlighted && (
            <div onClick={() => onChangeHighlighted(false)}>
              <StarIcon color="#FFB525" borderColor="#FFB525" />
            </div>
          )) || (
            <div onClick={() => onChangeHighlighted(true)}>
              <StarIcon color="#FFF" borderColor="#9D9D9D" />
            </div>
          )}

          <img src={TrashIcon} alt="trash" onClick={() => Delete(linkk.id)} />

          <label className="switch">
            <input type="checkbox" checked={active} onChange={onChangeCheck} />
            <span className="slider round" />
          </label>
        </DivRight>
      </DivAll>
    </Div>
  );
}

export default LinkList;
