/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/prop-types */
import React from 'react';
// import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Container } from './styles';
import BackButtonIcon from '../../../../assets/icons/backButton.svg';

import { signOut } from '../../../../store/modules/auth/actions';

function HeaderMobile({ title }) {
  const dispatch = useDispatch();

  function handleSignOut() {
    dispatch(signOut());
  }

  function handleBackClick(e) {
    e.preventDefault();

    window.history.back();
  }

  return (
    <Container>
      <div className="div-space">
        {/* <Link to="settings"> */}
        <a href="#" onClick={handleBackClick}>
          <img src={BackButtonIcon} alt="" />
        </a>

        {/* </Link> */}
      </div>
      <div>
        <span>{title}</span>
      </div>
      <div className="div-space">
        <a href="#" onClick={handleSignOut}>
          Sair
        </a>
      </div>
    </Container>
  );
}

export default HeaderMobile;
