/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
// import { useSelector } from 'react-redux';
import dataPlans from '../../../../data/plans.json';

// import { toast } from 'react-toastify';

import LoadingContainer from '../../../../components/LoadingContainer';

import BuyButtonStripe from '../../../../components/BuyButtonStripe';

import CrownIcon from '../../../../assets/icons/plan/crown.svg';

import history from '../../../../services/history';

import api from '../../../../services/api';

import {
  Container,
  DivHeader,
  Div,
  DivFreePlan,
  DivProPlan,
  DivDetails,
  SpanMore,
  ButtonIsPro,
  DivPrice,
  // LabelPromotion,
  DivItemsPro,
} from './styles';

import backBlackButton from '../../../../assets/icons/backBlackButton.svg';

export default function CustomizeName() {
  // const whatsapp = useSelector(state => state.user.profile.whatsapp);
  const [showFreeDetails, setShowFreeDetails] = useState(false);
  const [pro, setPro] = useState();
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    async function loadPro() {
      try {
        await api.get('/proplan').then(response => {
          setPro(response.data);
          setLoader(false);
        });
      } catch (err) {
        setLoader(false);
      }
    }

    loadPro();
  }, []);

  // async function sendWelcomeMessage() {
  //   if (whatsapp) {
  //     const payload = {
  //       to: whatsapp,
  //       template: 'welcome',
  //     };
  //     await api.post('/send-whatsapp-message', payload);

  //     return true;
  //   }
  //   return false;
  // }

  return (
    <>
      <LoadingContainer loading={loader} />
      <Container>
        <DivHeader>
          <div className="div-space">
            <Link to="/responsivecustomizesocialmedia">
              <img src={backBlackButton} alt="back-button" />
            </Link>
          </div>
          <div className="div-text">
            <strong>Selecione seu plano</strong>
          </div>
          <div />
        </DivHeader>
        <Div>
          {/* <strong>Selecione um plano</strong>
        <span>Você poderá alterar seu plano a qualquer momento</span> */}

          {pro === 0 && (
            <DivFreePlan>
              <div>
                <span className="span-plan">Plano Free</span>
                <span className="span-free">R$0,00</span>
              </div>
              <button
                type="button"
                onClick={() => {
                  history.push('/');
                  // sendWelcomeMessage();
                }}
              >
                Continuar como free
              </button>

              {showFreeDetails && (
                <DivDetails>
                  {dataPlans.free.map(d => (
                    <span key={d}>{d}</span>
                  ))}
                </DivDetails>
              )}
              <SpanMore onClick={() => setShowFreeDetails(!showFreeDetails)}>
                {showFreeDetails ? 'Menos detalhes' : 'Mais detalhes'}
              </SpanMore>
            </DivFreePlan>
          )}

          <DivProPlan>
            <header>
              <span>50% de desconto</span>
            </header>

            <body>
              <strong>
                Plano PRO <img src={CrownIcon} alt="crown" />
              </strong>

              <DivPrice>
                <strong>
                  <span>R$</span>19,90<span>/mês</span>
                </strong>

                <span>R$ 39,90/mês</span>
              </DivPrice>

              <span>Você sempre pagará o valor promocional</span>

              <DivItemsPro>
                {pro >= 1 ? (
                  <ButtonIsPro onClick={() => history.push('/')}>
                    Você já é PRO
                  </ButtonIsPro>
                ) : (
                  <BuyButtonStripe text="Continuar como PRO" type="welcome" />
                )}

                {dataPlans.pro.items.map(d => (
                  <div key={d}>
                    <span>{d}</span>
                  </div>
                ))}

                {dataPlans.soon.items.map(d => (
                  <div key={d}>
                    <span className="breve">{d}</span>
                  </div>
                ))}
              </DivItemsPro>
            </body>
          </DivProPlan>
        </Div>
      </Container>
    </>
  );
}
