/* eslint-disable react/prop-types */
import React from 'react';
import {
  Container,
  Body,
  ContentWrapper,
  Instruction,
  NextButton,
} from './styles';
import stepsIcon from '../../../../../../assets/icons/offstorepay/steps.svg';
import arrowRightIcon from '../../../../../../assets/icons/offstorepay/arrow-right.svg';

export function FirstStepInstruction({ onNextClick }) {
  return (
    <Container desktopMode>
      <Body>
        <ContentWrapper>
          <img alt="imagem figurando um passo a passo" src={stepsIcon} />
          <Instruction>
            Você está a 2 passos de receber pagamentos no site!
          </Instruction>
        </ContentWrapper>
      </Body>
      <NextButton type="button" onClick={onNextClick}>
        <p>Continuar</p>
        <img alt="seta para direita" src={arrowRightIcon} />
      </NextButton>
    </Container>
  );
}
