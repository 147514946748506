import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
// import { useSelector } from 'react-redux';

import { ButtonSeeMore, Card, H2 } from '~/designsystem/Fonts';
// import { handleBuyStripe } from '~/functions/stripe';
import LoaderComponent from '~/components/Loader';
import ResponsiveHeader from '../../../components/HeaderMobile';

import {
  Container,
  DivBody,
  DivBodyCard,
  DivCards,
  DivImage,
  SelectButton,
} from './styles';

// import arrowRightIcon from '../../../assets/icons/plan/arrowRight.svg';
import cardIcon from '../../../assets/icons/plan/card.svg';
import boletIcon from '../../../assets/icons/plan/bolet.svg';

function SubscriptionForms() {
  const history = useHistory();
  const [showMoreCards, setShowMoreCards] = useState(false);
  const [loading, setLoading] = useState(false);

  // const email = useSelector(
  //   state => state.user && state.user.profile && state.user.profile.email
  // );

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        setLoading(false);
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  async function handleBuy() {
    try {
      setLoading(true);
      // await handleBuyStripe(email, null, null, 'subscriptionforms');
    } catch (err) {
      // console.log(err);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Container>
      <ResponsiveHeader
        title="Escolha a forma de pagamento"
        url="/pro"
        tab={1}
      />

      <DivBody>
        <H2>Formas de pagamento</H2>
        <span>Selecione uma forma de pagamento abaixo</span>

        <DivCards>
          <Card>
            <DivImage>
              <img src={cardIcon} alt="cartão de crédito" />
            </DivImage>
            <DivBodyCard>
              <strong>Cartão</strong>
              <span>Plano mensal</span>
              <span className="green">Recomendado</span>
            </DivBodyCard>

            <SelectButton
              type="button"
              onClick={() => handleBuy()}
              disabled={loading}
            >
              {loading ? <LoaderComponent size={20} /> : 'Selecionar'}
            </SelectButton>

            {/* <img src={arrowRightIcon} alt="seta para a direita" /> */}
          </Card>

          {showMoreCards && (
            <Card>
              <DivImage>
                <img src={boletIcon} alt="cartão de crédito" />
              </DivImage>
              <DivBodyCard>
                <strong>Boleto</strong>
                <span>Plano trimestral, semestral e anual</span>
              </DivBodyCard>

              <SelectButton
                type="button"
                onClick={() => history.push('/subscriptionperiod')}
              >
                Selecionar
              </SelectButton>
              {/* <img src={arrowRightIcon} alt="seta para a direita" /> */}
            </Card>
          )}
        </DivCards>

        <ButtonSeeMore
          type="button"
          onClick={() => setShowMoreCards(!showMoreCards)}
        >
          {showMoreCards ? (
            <>
              Menos formas de pagamento{' '}
              <svg
                width="14"
                height="9"
                viewBox="0 0 14 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 7.5L7 1.5L13 7.5"
                  stroke="#0D85FF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </>
          ) : (
            <>
              Mais formas de pagamento{' '}
              <svg
                width="14"
                height="9"
                viewBox="0 0 14 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1.5L7 7.5L13 1.5"
                  stroke="#0D85FF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </>
          )}
        </ButtonSeeMore>
      </DivBody>
    </Container>
  );
}

export default SubscriptionForms;
