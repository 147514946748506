import React from 'react';

import * as S from './styles';
import Out from '../../../../../assets/icons/outIcon.svg';

export default function OrderDetailsComponent() {
  return (
    <S.MainContainer>
      <S.TextContainer>
        <S.MainCode>#A73HJD83</S.MainCode>
        <S.Text>14 de maio de 2020 às 13:22h</S.Text>
        <S.Text>
          Via <b>loja virtual</b>
        </S.Text>
      </S.TextContainer>
      <S.ContainerActions>
        <S.Tag>Pago</S.Tag>
        <S.PaymentContainer>
          <S.Text>Via PIX</S.Text>
          <S.OutlinedButton>
            <img src={Out} alt="edit icon" /> ver pagamento
          </S.OutlinedButton>
        </S.PaymentContainer>
      </S.ContainerActions>
    </S.MainContainer>
  );
}
