/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { FirstStepInstruction } from './components/FirstStepInstruction';
import { SecondStepPersonalData } from './components/SecondStepPersonalData';
import { ThirdStepBankData } from './components/ThirdStepBankData';

function ConfigurationOffstorePay({ desktopMode, closeModal }) {
  const formMethods = useForm();
  const [step, setStep] = useState('firstStep');
  const [data, setData] = useState();
  const history = useHistory();

  const handleNextStep = _data => {
    setData(_data);

    const steps = {
      firstStep: 'secondStep',
      secondStep: 'thirdStep',
      thirdStep: () => history.push('/paymentmethods'),
    };

    setStep(steps[step]);
  };

  const handleGoBackStep = backStep => {
    setStep(backStep);
  };

  const render = {
    firstStep: (
      <FirstStepInstruction
        onNextClick={handleNextStep}
        desktopMode={desktopMode}
      />
    ),
    secondStep: (
      <SecondStepPersonalData
        closeModal={closeModal}
        onNextClick={handleNextStep}
        desktopMode={desktopMode}
        sellerData={data}
      />
    ),
    thirdStep: (
      <ThirdStepBankData
        onNextClick={handleNextStep}
        onGoBackClick={handleGoBackStep}
        desktopMode={desktopMode}
        sellerData={data}
      />
    ),
  };

  return <FormProvider {...formMethods}>{render[step]}</FormProvider>;
}

export default ConfigurationOffstorePay;
