/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useForm } from 'react-hook-form';
import { useGetSellerInfo } from '~/hooks/use-get-seller-info';
// import ResponsiveHeader from '../../../../../../../components/HeaderMobile';
import Select from 'react-select';

// import arrowDownIcon from '~/assets/icons/offstorepay/arrow-down.svg';
import LoaderComponent from '~/components/Loader';
import { toast } from 'react-toastify';
import {
  Container,
  Body,
  InputLabel,
  Input,
  InputContainer,
  Button,
  SelectWrapper,
  WarningSpan,
} from './styles';
import loader from '../../../../../../../assets/icons/loader-grey.gif';
// import { useCreateSeller } from '../../../../ConfigurationOffstorePay/components/ThirdStepBankData/hooks/use-create-seller';
import { useGetBanksData } from '../../../../ConfigurationOffstorePay/components/ThirdStepBankData/hooks/use-get-bank-data';
import { useCreateSellerBank } from '../../../../ConfigurationOffstorePay/components/ThirdStepBankData/hooks/use-create-seller-bank';

export function ChangeBankAccountComponent() {
  const { handleUpdateSellerBank } = useCreateSellerBank();
  const { banksData } = useGetBanksData();
  const userId = useSelector(
    state => state.user && state.user.profile && state.user.profile.id
  );
  const { handleGetSellerInfo } = useGetSellerInfo();
  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [bankAccountInformation, setBankAccountInformation] = useState(null);
  const [selectedBankAccount, setSelectedBankAccount] = useState();
  const [seller, setSeller] = useState();
  // const [hasBankAccount, setHasBankAccount] = useState();

  const history = useHistory();

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  const onSubmit = async bankData => {
    // console.log('Dados submetidos:', bankData);
    try {
      setLoadingButton(true);

      const documentNumber = getValues('document_number').replace(
        /[^0-9]/g,
        ''
      );

      if (documentNumber.length !== 11 && documentNumber.length !== 14) {
        toast.error('Digite um CPF/CNPJ válido');

        return 0;
      }

      const newBankData = {
        ...bankData,
        documentType: documentNumber.length === 14 ? 'CNPJ' : 'CPF',
      };
      await handleUpdateSellerBank({ userId, bankData: newBankData });

      history.push('/paymentmethods/configurationoffstorepay/edit');
    } catch (err) {
      // console.log(err);
    } finally {
      setLoadingButton(false);
    }
  };

  useEffect(() => {
    async function load() {
      setLoading(true);
      try {
        const response = await handleGetSellerInfo({
          userId,
        });

        setSeller(response.sellerInfo);

        setBankAccountInformation(response.sellerInfo.bank_account);

        // if (response.sellerInfo.bank_account) {
        //   setHasBankAccount(true);
        // } else {
        //   setHasBankAccount(false);
        // }

        setValue(
          'account_holder',
          response.sellerInfo.bank_account.account_holder
        );
        setValue(
          'document_number',
          response.sellerInfo.bank_account.document_number
        );
        setValue(
          'agency_number',
          response.sellerInfo.bank_account.agency_number
        );
        setValue(
          'account_number',
          response.sellerInfo.bank_account.account_number
        );
        setValue('name', response.sellerInfo.bank_account.name);
      } catch (err) {
        // console.log(err);
      } finally {
        setLoading(false);
      }
    }

    load();
  }, []);

  function handleChangeBank(data) {
    setSelectedBankAccount(data);
    setValue('bank', data.value);
    setValue('name', data.name);
  }

  useEffect(() => {
    async function load() {
      if (banksData && banksData.length > 0 && bankAccountInformation) {
        const findBankInfo = await banksData.find(
          bank => bank.code === bankAccountInformation.bank_code
        );

        if (findBankInfo) {
          handleChangeBank(findBankInfo);
          setValue('bank', `${findBankInfo.name} - ${findBankInfo.code}`);
        }
      }
    }

    load();
  }, [banksData, bankAccountInformation]);

  return (
    <>
      {loading && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            height: '100vh',
            alignItems: 'center',
          }}
        >
          <img src={loader} alt="loader" width={50} height={50} />
        </div>
      )}

      {!loading && (
        <>
          <Body>
            <Container>
              <form onSubmit={handleSubmit(onSubmit)}>
                <InputContainer>
                  <InputLabel marginTop="0">
                    Nome completo<span>*</span>
                  </InputLabel>
                  <Input
                    placeholder={
                      seller && seller.document_type === 'CPF'
                        ? 'Nome completo'
                        : 'Nome fantasia'
                    }
                    {...register('account_holder', {
                      required: 'Campo obrigatório',
                    })}
                    // disabled={hasBankAccount}
                  />
                  {errors.name && <span>{errors.name.message}</span>}
                </InputContainer>
                <InputContainer />
                <InputContainer>
                  <InputLabel>
                    {seller && seller.document_type
                      ? `${seller.document_type} da conta bancária`
                      : 'CPF ou CNPJ'}
                    <span>*</span>
                  </InputLabel>
                  <Input
                    placeholder="Número do documento"
                    {...register('document_number', {
                      required: 'Campo obrigatório',
                    })}
                    // disabled={hasBankAccount}
                  />
                  {errors.document_number && (
                    <span>{errors.document_number.message}</span>
                  )}

                  {seller && (
                    <WarningSpan>
                      A conta bancária precisa obrigatoriamente pertencer ao{' '}
                      {seller.document_type} <b>{seller.document}</b>
                      {seller.document_type === 'CNPJ'
                        ? '. Não utilize sua conta bancária de pessoa física, ou haverá falha nos repasses.'
                        : ', ou haverá falha nos repasses.'}
                    </WarningSpan>
                  )}
                </InputContainer>
                <InputContainer>
                  <InputLabel>Banco</InputLabel>
                  <SelectWrapper>
                    <Select
                      id="selected"
                      placeholder="Selecionar"
                      name="bank"
                      {...register('bank', { required: 'Campo obrigatório' })}
                      styles={{
                        control: provided => ({
                          ...provided,
                          borderWidth: '1px',
                          width: '100%',
                          borderColor: '#cdcdcd',
                          borderRadius: '8px',
                          boxShadow: 'none',
                          height: '48px',
                          padding: '0 8px 0 16px',
                          ':hover': {
                            borderColor: '#2B8FF5',
                          },
                        }),

                        container: provided => ({
                          ...provided,
                          width: '100%',
                        }),

                        indicatorSeparator: provided => ({
                          ...provided,
                          background: 'none',
                        }),
                        indicatorsContainer: provided => ({
                          ...provided,
                          padding: '0',
                        }),
                        valueContainer: provided => ({
                          ...provided,
                          padding: '0',
                        }),
                        menu: provided => ({
                          ...provided,
                          zIndex: 2,
                        }),
                      }}
                      options={banksData}
                      value={selectedBankAccount}
                      onChange={e => handleChangeBank(e)}
                    />
                    {/* <Select
                      {...register('bank_code', {
                        required: 'Campo obrigatório',
                      })}
                    >
                      {banksData.map(bank => (
                        <option value={`${bank.name} - ${bank.code}`}>
                          {bank.name} - {bank.code}
                        </option>
                      ))}
                    </Select>
                    <img alt="Seta indicando lista" src={arrowDownIcon} /> */}
                  </SelectWrapper>
                  {errors.bankCode && <span>{errors.bankCode.message}</span>}
                </InputContainer>
                <InputContainer>
                  <InputLabel>
                    Agência<span>*</span>
                  </InputLabel>
                  <Input
                    placeholder="Agência"
                    {...register('agency_number', {
                      required: 'Campo obrigatório',
                    })}
                  />
                  {errors.agency_number && (
                    <span>{errors.agency_number.message}</span>
                  )}
                </InputContainer>
                <InputContainer>
                  <InputLabel>
                    Número da conta<span>*</span>
                  </InputLabel>
                  <Input
                    placeholder="Nº da conta"
                    {...register('account_number', {
                      required: 'Campo obrigatório',
                    })}
                  />
                  {errors.account_number && (
                    <span>{errors.account_number.message}</span>
                  )}
                </InputContainer>
                <Button type="submit" disabled={loadingButton}>
                  {loadingButton ? (
                    <LoaderComponent size={20} white />
                  ) : (
                    'Salvar alterações'
                  )}
                </Button>
              </form>
            </Container>
          </Body>
        </>
      )}
    </>
  );
}
