/* eslint-disable react/jsx-no-bind */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import IntlCurrencyInput from 'react-intl-currency-input';
import { toast } from 'react-toastify';
import api from '../../../../services/api';

import BackButtonIcon from '../../../../assets/icons/backButton.svg';
import CheckIcon from '../../../../assets/icons/order/checkIcon';

import {
  Container,
  Body,
  DivMinValue,
  DivPrice,
  Div,
  DivSelectConfigurationLabel,
  DivSelect,
} from './styles';

function Configurations() {
  const [minPrice, setMinPrice] = useState();
  const [freight, setFreight] = useState([]);
  const [mercadoPago, setMercadoPago] = useState([]);

  const [showDeliveryAddress, setShowDeliveryAddress] = useState(false);
  const [showPersonalInformation, setShowPersonalInformation] = useState(true);
  const [showEmail, setShowEmail] = useState(false);
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [showCpfCnpj, setShowCpfCnpj] = useState(false);

  useEffect(() => {
    async function load() {
      await api.get('/mercadopago').then(response => {
        setMercadoPago(response && response.data);
      });

      const existsOrderConfigurations = await api.get('/orderconfigurations');

      if (existsOrderConfigurations && existsOrderConfigurations.data) {
        const {
          minimum_purchase_amount,
          show_delivery_address,
          show_email,
          show_payment_form,
          show_cpf_cnpj,
        } = existsOrderConfigurations.data;

        setMinPrice(minimum_purchase_amount);
        setShowDeliveryAddress(show_delivery_address);
        setShowEmail(show_email);
        setShowPaymentForm(show_payment_form);
        setShowCpfCnpj(show_cpf_cnpj);
      } else {
        const newOrderConfigurations = await api.post('/orderconfigurations');

        if (newOrderConfigurations && newOrderConfigurations.data) {
          const {
            minimum_purchase_amount,
            show_delivery_address,
            show_personal_information,
            show_email,
            show_payment_form,
            show_cpf_cnpj,
          } = newOrderConfigurations.data;

          setMinPrice(minimum_purchase_amount);
          setShowDeliveryAddress(show_delivery_address);
          setShowPersonalInformation(show_personal_information);
          setShowEmail(show_email);
          setShowPaymentForm(show_payment_form);
          setShowCpfCnpj(show_cpf_cnpj);
        }
      }

      await api.get('/configfreight').then(res => {
        if (res && res.data) {
          setFreight(res.data);
        }
      });
    }

    load();
  }, []);

  const handleChangePrice = (event, value) => {
    setMinPrice(value);
    event.preventDefault();

    // console.log(value); // value without mask (ex: 1234.56)
    // console.log(maskedValue); // masked value (ex: R$1234,56)
  };

  async function handleSaveData(data, showToast) {
    try {
      await api.put('/orderconfigurations', data);

      if (showToast) {
        toast.success('Dados salvos');
      }
    } catch (err) {
      toast.error('Erro ao salvar informações');
    }
  }

  function handleChangeOption() {
    if (!(freight && freight.enabled && showDeliveryAddress)) {
      setShowDeliveryAddress(!showDeliveryAddress);
      handleSaveData({ show_delivery_address: !showDeliveryAddress }, false);
    }
  }

  function handleChangeCpf() {
    if (mercadoPago && mercadoPago.active && showCpfCnpj) {
      toast.error('Você não pode desativar o Cpf com o Mercado Pago ativo');
      return 0;
    }

    setShowCpfCnpj(!showCpfCnpj);
    handleSaveData({ show_cpf_cnpj: !showCpfCnpj }, false);

    return 0;
  }

  return (
    <Container>
      <header>
        <div className="div-space">
          <Link to="/settings">
            <img src={BackButtonIcon} alt="" />
          </Link>
        </div>
        <div>
          <span>Configurações</span>
        </div>
        <div className="div-space" />
      </header>

      <Body>
        <DivMinValue>
          <strong>Pedido mínimo</strong>
          <span>Digite abaixo o valor mínimo aceito em um pedido</span>
          <DivPrice>
            <div>R$</div>
            <IntlCurrencyInput
              currency="BRL"
              className="currency-input"
              config={{
                locale: 'pt-BR',
                formats: {
                  number: {
                    BRL: {
                      // style: 'currency',
                      currency: 'BRL',
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    },
                  },
                },
              }}
              value={minPrice}
              onChange={handleChangePrice}
              onBlur={() =>
                handleSaveData({ minimum_purchase_amount: minPrice }, true)
              }
            />
          </DivPrice>
        </DivMinValue>

        {/* <Div>
          <strong>Solicitar informações do cliente</strong>
          <span>
            Se você desabilitar essa opção seus visitantes enviarão pedidos sem
            adicionar qualquer informação pessoal, de entrega e pagamento
          </span>

          <label className="switch">
            <input
              type="checkbox"
              // checked={activePac || activeSedex}
              // onChange={e => onChangeActiveCorreios(e.target.checked)}
            />
            <span className="slider round" />
          </label>
        </Div> */}

        <Div>
          <strong>Informações solicitadas na finalização do pedido</strong>
          <span>
            Selecione as informações que devem ser solicitadas ao seu cliente na
            tela de “finalizar pedido”
          </span>
        </Div>

        <DivSelectConfigurationLabel>
          {/* <div className={freight && freight.enabled ? 'has-opacity' : ''}> */}
          <div>
            <span>Endereço de entrega</span>

            <DivSelect
              enabled={showDeliveryAddress}
              onClick={() => handleChangeOption()}
            >
              {showDeliveryAddress && <CheckIcon color="#fff" />}
            </DivSelect>
          </div>

          <div className="has-opacity">
            <span>Informações pessoais</span>

            <DivSelect enabled={showPersonalInformation}>
              {showPersonalInformation && <CheckIcon color="#fff" />}
            </DivSelect>
          </div>

          <div>
            <span>Email</span>

            <DivSelect
              enabled={showEmail}
              onClick={() => {
                setShowEmail(!showEmail);
                handleSaveData({ show_email: !showEmail }, false);
              }}
            >
              {showEmail && <CheckIcon color="#fff" />}
            </DivSelect>
          </div>

          <div>
            <span>Forma de pagamento</span>

            <DivSelect
              enabled={showPaymentForm}
              onClick={() => {
                setShowPaymentForm(!showPaymentForm);
                handleSaveData({ show_payment_form: !showPaymentForm }, false);
              }}
            >
              {showPaymentForm && <CheckIcon color="#fff" />}
            </DivSelect>
          </div>

          <div>
            <span>CPF</span>

            <DivSelect enabled={showCpfCnpj} onClick={handleChangeCpf}>
              {showCpfCnpj && <CheckIcon color="#fff" />}
            </DivSelect>
          </div>
        </DivSelectConfigurationLabel>
      </Body>
    </Container>
  );
}

export default Configurations;
