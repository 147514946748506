import { takeLatest, call, put, all } from 'redux-saga/effects';
// import { toast } from 'react-toastify';

import api from '../../../services/api';

import { loadOrderByIdSuccess, loadProductsSuccess } from './actions';

export function* loadOrderById(id) {
  const response = yield call(api.post, `/orderrequestbyid/${id}`);
  yield put(loadOrderByIdSuccess(response.data));
}

export function* loadProducts() {
  const response = yield call(api.get, '/links');

  yield put(loadProductsSuccess(response.data));
}

export default all([
  takeLatest('@order/LOAD_ORDER_BY_ID_REQUEST', loadOrderById),
  takeLatest('@order/LOAD_PRODUCTS_REQUEST', loadProducts),
]);
