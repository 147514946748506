/* eslint-disable react/jsx-no-bind */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

// import axios from 'axios';

import { Container, Card, ButtonAddSocialMedia } from './styles';
import SocialMediaModal from '../../../../components/SocialMediaModal';

import HomeIcon from '../../../../assets/icons/tabAbout/home.svg';
// import ClockIcon from '../../../assets/icons/tabAbout/clock.svg';
import PhoneIcon from '../../../../assets/icons/tabAbout/phone.svg';
import EmailIcon from '../../../../assets/icons/tabAbout/email.svg';
// import LocalizationIcon from '../../../../assets/icons/tabAbout/localization.svg';
import CardIcon from '../../../../assets/icons/tabAbout/card.svg';
import PenIcon from '../../../../assets/icons/tabAbout/pen.svg';
//
import SocialMediaIcon from '../../../../assets/icons/tabAbout/socialmedia.svg';

import { updateProfileRequest } from '../../../../store/modules/user/actions';

import { updatePaymentMethodsRequest } from '../../../../store/modules/linkPrev/actions';

function TabAbout() {
  const [about, setAbout] = useState();
  const [phone, setPhone] = useState();
  const [contact_email, setContactEmail] = useState();
  const [cep, setCep] = useState();
  const [address, setAddress] = useState();
  const [number, setNumber] = useState();
  const [district, setDistrict] = useState();
  const [uf, setUf] = useState();
  const [city, setCity] = useState();
  const [cnpj, setCnpj] = useState();

  const [money, setMoney] = useState(false);
  const [credit_card, setCreditCard] = useState(false);
  const [debit_card, setDebitCard] = useState(false);
  const [billet, setBillet] = useState(false);
  const [bank_transfer, setBankTransfer] = useState(false);
  const [picpay, setPicpay] = useState(false);
  const [mealTicket, setMealTicket] = useState(false);
  const [pix, setPix] = useState(false);

  const [showModal, setShowModal] = useState(false);

  const dispatch = useDispatch();

  // adicionado r na frente da variave simbolizando que veio do "redux"
  const {
    email,
    username: user,
    about: rAbout,
    phone: rPhone,
    cep: rCep,
    address: rAddress,
    number: rNumber,
    city: rCity,
    district: rDistrict,
    uf: rUf,
    cnpj: rCnpj,
    contact_email: rContactEmail,
    facebook,
    twitter,
    instagram,
    pinterest,
    youtube,
    // header_type,
  } = useSelector(state => state.user.profile);

  const { payment_methods } = useSelector(state => state.linkPrev);

  useEffect(() => {
    setAbout(rAbout);
    setPhone(rPhone);
    setContactEmail(rContactEmail);
    setCep(rCep);
    setAddress(rAddress);
    setNumber(rNumber);
    setCity(rCity);
    setDistrict(rDistrict);
    setUf(rUf);
    setCnpj(rCnpj);

    setMoney(payment_methods && payment_methods.money);
    setCreditCard(payment_methods && payment_methods.credit_card);
    setDebitCard(payment_methods && payment_methods.debit_card);
    setBillet(payment_methods && payment_methods.billet);
    setBankTransfer(payment_methods && payment_methods.bank_transfer);
    setPicpay(payment_methods && payment_methods.picpay);
    setMealTicket(payment_methods && payment_methods.meal_ticket);
    setPix(payment_methods && payment_methods.pix);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function handleSubmit() {
    try {
      const data = {
        user,
        email,
        about,
        phone,
        cnpj,
        cep,
        address,
        number,
        district,
        city,
        uf,
        contact_email,
      };

      dispatch(updateProfileRequest(data));
    } catch (err) {
      toast.error('Erro ao processar requisição');
    }
  }

  async function handleUpdatePaymentMethods(value) {
    try {
      switch (value) {
        case 'money': {
          setMoney(!money);

          const data = {
            id: payment_methods && payment_methods.id,
            money: !money,
          };

          dispatch(updatePaymentMethodsRequest(data));

          break;
        }
        case 'credit_card': {
          setCreditCard(!credit_card);

          const data = {
            id: payment_methods && payment_methods.id,
            credit_card: !credit_card,
          };

          dispatch(updatePaymentMethodsRequest(data));

          break;
        }
        case 'debit_card': {
          setDebitCard(!debit_card);

          const data = {
            id: payment_methods && payment_methods.id,
            debit_card: !debit_card,
          };

          dispatch(updatePaymentMethodsRequest(data));

          break;
        }
        case 'billet': {
          setBillet(!billet);

          const data = {
            id: payment_methods && payment_methods.id,
            billet: !billet,
          };

          dispatch(updatePaymentMethodsRequest(data));

          break;
        }
        case 'bank_transfer': {
          setBankTransfer(!bank_transfer);

          const data = {
            id: payment_methods && payment_methods.id,
            bank_transfer: !bank_transfer,
          };

          dispatch(updatePaymentMethodsRequest(data));

          break;
        }
        case 'picpay': {
          setPicpay(!picpay);

          const data = {
            id: payment_methods && payment_methods.id,
            picpay: !picpay,
          };

          dispatch(updatePaymentMethodsRequest(data));

          break;
        }
        case 'meal_ticket': {
          setMealTicket(!mealTicket);

          const data = {
            id: payment_methods && payment_methods.id,
            meal_ticket: !mealTicket,
          };

          dispatch(updatePaymentMethodsRequest(data));

          break;
        }
        case 'pix': {
          setPix(!pix);

          const data = {
            id: payment_methods && payment_methods.id,
            pix: !pix,
          };

          dispatch(updatePaymentMethodsRequest(data));

          break;
        }

        default:
      }
    } catch (err) {
      // toast.error('Erro ao processar requisição');
    }
  }

  // async function handleSearchCEP() {
  //   axios.get(`https://viacep.com.br/ws/${cep}/json/`).then(response => {
  //     setAddress(response.data.logradouro);
  //     setDistrict(response.data.bairro);
  //     setCity(response.data.localidade);
  //     setUf(response.data.uf);

  //     setTimeout(() => {
  //       handleSubmit();
  //     }, 500);
  //   });
  // }

  function modal(_modal) {
    setShowModal(_modal);
  }

  return (
    <>
      <SocialMediaModal
        showModal={showModal}
        Modal={modal}
        url_facebook={facebook}
        url_twitter={twitter}
        url_instagram={instagram}
        url_pinterest={pinterest}
        url_youtube={youtube}
        email={email}
        user={user}
      />
      <Container>
        <span>Informações</span>
        <Card>
          <div>
            <img src={HomeIcon} alt="home" />
            <strong>Sobre</strong>
          </div>
          <div>
            <textarea
              rows={3}
              placeholder="Sobre"
              maxLength="1000"
              onChange={e => setAbout(e.target.value)}
              value={about}
              onBlur={about !== rAbout && handleSubmit}
            />
          </div>
        </Card>

        {/* <Card>
        <div>
          <img src={ClockIcon} alt="clock" />
          <strong>Horário de atendimento</strong>
        </div>
        <div>
          <input type="text" placeholder="Ex: 08:00 às 18:00" />
        </div>
      </Card> */}

        {/* {header_type === 1 && ( */}
        <Card>
          <div>
            <img src={SocialMediaIcon} alt="social" />
            <strong>Redes sociais</strong>
          </div>
          <div>
            <ButtonAddSocialMedia
              type="button"
              onClick={() => {
                setShowModal(!showModal);
              }}
            >
              + Adicionar redes sociais
            </ButtonAddSocialMedia>
          </div>
        </Card>
        {/* )} */}

        <Card>
          <div>
            <img src={PhoneIcon} alt="phone" />
            <strong>Telefone</strong>
          </div>
          <div>
            <input
              type="text"
              placeholder="Telefone"
              value={phone}
              onChange={e => setPhone(e.target.value)}
              onBlur={phone !== rPhone && handleSubmit}
            />
          </div>
        </Card>

        <Card>
          <div>
            <img src={EmailIcon} alt="email" />
            <strong>E-mail</strong>
          </div>
          <div>
            <input
              type="text"
              placeholder="E-mail"
              value={contact_email}
              onChange={e => setContactEmail(e.target.value)}
              onBlur={contact_email !== rContactEmail && handleSubmit}
            />
          </div>
        </Card>

        {/* <Card>
          <div>
            <img src={LocalizationIcon} alt="localization" />
            <strong>Endereço</strong>
          </div>
          <div className="div-endereco">
            <input
              type="text"
              placeholder="CEP"
              value={cep}
              onChange={e => setCep(e.target.value)}
              onBlur={handleSearchCEP}
            />
            <input
              type="text"
              placeholder="Endereço"
              value={address}
              onChange={e => setAddress(e.target.value)}
              onBlur={address !== rAddress && handleSubmit}
            />
            <div>
              <input
                type="text"
                placeholder="Número"
                value={number}
                onChange={e => setNumber(e.target.value)}
                onBlur={number !== rNumber && handleSubmit}
              />
              <input
                type="text"
                placeholder="Bairro"
                value={district}
                onChange={e => setDistrict(e.target.value)}
                onBlur={district !== rDistrict && handleSubmit}
              />
            </div>

            <div>
              <input
                type="text"
                placeholder="Cidade"
                value={city}
                onChange={e => setCity(e.target.value)}
                onBlur={city !== rCity && handleSubmit}
              />
              <input
                type="text"
                placeholder="UF"
                value={uf}
                onChange={e => setUf(e.target.value)}
                onBlur={uf !== rUf && handleSubmit}
              />
            </div>
          </div>
        </Card> */}

        <Card>
          <div>
            <img src={CardIcon} alt="card" />
            <strong>Formas de pagamento</strong>
          </div>
          <div className="div-buttons-payment-methods">
            <button
              type="button"
              className={money ? 'selected' : ''}
              onClick={() => handleUpdatePaymentMethods('money')}
            >
              Dinheiro
            </button>
            <button
              type="button"
              className={credit_card ? 'selected' : ''}
              onClick={() => handleUpdatePaymentMethods('credit_card')}
            >
              Cartão de crédito
            </button>
            <button
              type="button"
              className={billet ? 'selected' : ''}
              onClick={() => handleUpdatePaymentMethods('billet')}
            >
              Boleto
            </button>
            <button
              type="button"
              className={bank_transfer ? 'selected' : ''}
              onClick={() => handleUpdatePaymentMethods('bank_transfer')}
            >
              Transferência bancária
            </button>
            <button
              type="button"
              className={debit_card ? 'selected' : ''}
              onClick={() => handleUpdatePaymentMethods('debit_card')}
            >
              Cartão de débito
            </button>
            <button
              type="button"
              className={picpay ? 'selected' : ''}
              onClick={() => handleUpdatePaymentMethods('picpay')}
            >
              Picpay
            </button>
            <button
              type="button"
              className={mealTicket ? 'selected' : ''}
              onClick={() => handleUpdatePaymentMethods('meal_ticket')}
            >
              Vale refeição
            </button>
            <button
              type="button"
              className={pix ? 'selected' : ''}
              onClick={() => handleUpdatePaymentMethods('pix')}
            >
              Pix
            </button>
          </div>
        </Card>

        <Card>
          <div>
            <img src={PenIcon} alt="pen" />
            <strong>CNPJ</strong>
          </div>
          <div>
            <input
              type="text"
              placeholder="CNPJ"
              value={cnpj}
              onChange={e => setCnpj(e.target.value)}
              onBlur={cnpj !== rCnpj && handleSubmit}
            />
          </div>
        </Card>
      </Container>
    </>
  );
}

export default TabAbout;
