/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { updateProfileRequest } from '../../store/modules/user/actions';

import api from '../../services/api';

import BackButtonIcon from '../../assets/icons/backButton.svg';
import loader from '../../assets/icons/loader-grey.gif';

import {
  Container,
  Header,
  Background,
  Body,
  DivButton,
  DivCheckBox,
} from './styles';

// criado parâmetro hasFunction para saber de onde foi chamado esse compomente e
// se precisa retornar o handleCreateTableFlatRate ou não.
function AddAddress({
  handleCreateTableFlatRate,
  hasFunction,
  handleSetShowAddAddress,
  isModal,
}) {
  const dispatch = useDispatch();

  const [cep, setCep] = useState();
  const [address, setAddress] = useState();
  const [number, setNumber] = useState();
  const [district, setDistrict] = useState();
  const [uf, setUf] = useState();
  const [city, setCity] = useState();
  const [complement, setComplement] = useState();
  const [showStoreAddress, setshowStoreAddress] = useState(true);

  const [loading, setLoading] = useState(false);

  const {
    email,
    username: user,
    cep: rCep,
    address: rAddress,
    number: rNumber,
    city: rCity,
    district: rDistrict,
    uf: rUf,
    complement: rComplement,
  } = useSelector(state => state.user.profile);

  useEffect(() => {
    async function load() {
      await api.get('/configfreight').then(async response => {
        if (response && response.data) {
          setshowStoreAddress(response.data.show_store_address);
        }
      });
    }

    setCep(rCep);
    setAddress(rAddress);
    setNumber(rNumber);
    setCity(rCity);
    setDistrict(rDistrict);
    setUf(rUf);
    setComplement(rComplement);

    load();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function searchCep(pCep) {
    if (pCep.length !== 8) {
      toast.error('Preencha um CEP válido');
      return 0;
    }

    const data = {
      cep: pCep,
    };

    setAddress('');
    setDistrict('');

    const response = await api.post('/consultacep', data);

    if (response && response.data) {
      if (response.data.logradouro) {
        setAddress(response.data.logradouro);
      }

      if (response.data.bairro) {
        setDistrict(response.data.bairro);
      }

      setCity(response.data.localidade);
      setUf(response.data.uf);
    }

    return 0;
  }

  async function handleSubmit() {
    try {
      setLoading(true);
      if (!cep || (cep && cep.length !== 8)) {
        toast.error('Preencha um CEP válido');
        return 0;
      }

      if (isModal) {
        if (!address || !number || !city || !district || !uf) {
          toast.error('Preencha todos os campos');
          return 0;
        }

        // if (
        //   cep === rCep &&
        //   address === rAddress &&
        //   number === rNumber &&
        //   city === rCity &&
        //   district === rDistrict &&
        //   uf === rUf &&
        //   complement === rComplement
        // ) {
        //   if (hasFunction) {
        //     handleCreateTableFlatRate();
        //   } else {
        //     handleSetShowAddAddress(false, true);
        //   }
        //   return 0;
        // }

        const data = {
          user,
          email,
          cep,
          address,
          number,
          district,
          city,
          uf,
          complement,
        };

        dispatch(updateProfileRequest(data));

        await api.post('/updatefreight', {
          show_store_address: showStoreAddress,
        });
      } else {
        const response = await api.post('/consultacep', { cep });

        if (response && response.data) {
          const data = {
            user,
            email,
            cep,
            address: response.data.logradouro,
            district: response.data.bairro,
            city: response.data.localidade,
            uf: response.data.uf,
          };

          dispatch(updateProfileRequest(data));
        }
      }

      await api.get('/configfreight').then(async response => {
        if (response && response.data) {
          if (!response.data.cep_origin_pac_sedex && !response.data.free_cep) {
            await api.post('/updatefreight', {
              cep_origin_pac_sedex: cep,
              free_cep: cep,
            });
          } else if (
            !response.data.cep_origin_pac_sedex &&
            response.data.free_cep
          ) {
            await api.post('/updatefreight', {
              cep_origin_pac_sedex: cep,
            });
          } else if (
            response.data.cep_origin_pac_sedex &&
            !response.data.free_cep
          ) {
            await api.post('/updatefreight', {
              free_cep: cep,
            });
          }
        }
      });

      await api.get('/melhorenvio').then(async response => {
        if (response && response.data) {
          if (!response.data.cep_origin) {
            await api.put('/melhorenvio', {
              cep_origin: cep,
            });
          }
        }
      });

      if (hasFunction) {
        handleCreateTableFlatRate();
      } else {
        handleSetShowAddAddress(false, true);
      }

      return 0;
    } catch (err) {
      toast.error('Erro ao processar requisição');
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Background onClick={() => handleSetShowAddAddress(false, false)} />
      <Container isModal={isModal}>
        {isModal && (
          <div className="header">
            <h2>Endereço da loja</h2>
          </div>
        )}

        {!isModal && (
          <Header>
            <div onClick={() => handleSetShowAddAddress(false, false)}>
              <img src={BackButtonIcon} alt="back-icon" />
            </div>

            <span>Editar frete</span>
          </Header>
        )}

        <Body isModal>
          {(isModal && (
            <span>
              Digite abaixo o endereço de origem das entregas e/ou retirada
            </span>
          )) || (
            <>
              <h2>Insira seu CEP</h2>
              <span>Insira o CEP de sua loja para editar o frete</span>
            </>
          )}
          <input
            type="text"
            placeholder="CEP"
            autoComplete="no"
            value={cep}
            onChange={e => setCep(e.target.value.replace(/[^0-9.]/, ''))}
            onBlur={isModal ? () => searchCep(cep) : () => {}}
          />

          {(isModal && (
            <>
              <input
                type="text"
                placeholder="Cidade"
                autoComplete="no"
                value={city}
                onChange={e => setCity(e.target.value)}
              />
              <input
                type="text"
                placeholder="Bairro"
                autoComplete="no"
                value={district}
                onChange={e => setDistrict(e.target.value)}
              />
              <input
                type="text"
                placeholder="Rua"
                autoComplete="no"
                value={address}
                onChange={e => setAddress(e.target.value)}
              />
              <input
                type="text"
                placeholder="UF"
                autoComplete="no"
                value={uf}
                onChange={e => setUf(e.target.value)}
              />

              <div>
                <input
                  type="text"
                  className="first-input"
                  placeholder="Número"
                  autoComplete="no"
                  value={number}
                  onChange={e => setNumber(e.target.value)}
                />
                <input
                  type="text"
                  placeholder="Complemento"
                  autoComplete="no"
                  value={complement}
                  onChange={e => setComplement(e.target.value)}
                />
              </div>

              <DivCheckBox>
                <span>Exibir endereço na sua loja</span>

                <label className="switch">
                  <input
                    type="checkbox"
                    checked={showStoreAddress}
                    onChange={e => setshowStoreAddress(e.target.checked)}
                  />
                  <span className="slider round" />
                </label>
              </DivCheckBox>
            </>
          )) || <></>}
        </Body>

        <DivButton>
          <button type="button" onClick={handleSubmit} disabled={loading}>
            {(loading && <img src={loader} alt="loading" />) || (
              <>{(isModal && 'Salvar alterações') || 'Continuar'}</>
            )}
          </button>
        </DivButton>
      </Container>
    </>
  );
}

export default AddAddress;
