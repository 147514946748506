/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
// import goBackIcon from '~/assets/icons/offstorepay/go-back.svg';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useHistory } from 'react-router-dom';
import { formatPrice } from '~/util/format';
import PaymentStatusSteps from '~/components/PaymentStatusSteps';
// eslint-disable-next-line import/no-extraneous-dependencies

import { toast } from 'react-toastify';
// import LoaderComponent from '~/components/Loader';
import { Button } from '~/designsystem/Fonts';
import * as S from './styles';
import { Bottlesheet } from '../Bottlesheet';
import apiOffstorePay from '../../../../../../services/offstorePay';

export function PaymentPageComponent() {
  const history = useHistory();
  const [initialLoading, setInitialLoading] = useState(true);
  const [openBottomSheet, setOpenBottomSheet] = useState(false);
  const [amount, setAmount] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [paymentDate, setPaymentDate] = useState(null);
  const [liquidValue, setLiquidValue] = useState(null);
  const [totalFee, setTotalFee] = useState(0.0);
  const [transaction, setTransaction] = useState();
  const [creditData, setCreditData] = useState();
  const [modalRepaymentActive, setModalRepaymentActive] = useState(false);

  const [historyList, setHistoryList] = useState(null);

  const { id: userId } = useSelector(state => state.user.profile);

  const getDetailTransaction = async id => {
    try {
      const res = await apiOffstorePay.get(`/transactions/${id}`);
      // console.log(res.data);

      if (res && res.data && res.data.transaction.order.user_id === userId) {
        const offstore_fee = parseFloat(res.data.transaction.offstore_fee) || 0;
        const zoop_fee = parseFloat(res.data.transaction.zoop_fee) || 0;
        const TotalFee = offstore_fee + zoop_fee;
        const amoun_t = parseFloat(res.data.transaction.amount);
        setAmount(amoun_t || 0);
        setPaymentStatus(res.data.transaction.status);
        setPaymentDate(res.data.transaction.created_at);
        setLiquidValue(amoun_t - offstore_fee - zoop_fee);
        setTotalFee(TotalFee);
        setHistoryList(res.data.history);
        setTransaction(res.data.transaction);
        setCreditData(res.data.creditData);
      }
    } catch (err) {
      toast.error('Ocorreu um erro ao carregar a transação');
    } finally {
      setInitialLoading(false);
    }
  };

  useEffect(() => {
    const id = history.location.pathname.split('/');
    getDetailTransaction(id.pop());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formatDate = date => {
    const dataObjeto = new Date(date);

    const pattern = "d 'de' MMMM 'de' yyyy 'às' HH:mm'h'";
    const dataFormatada = format(dataObjeto, pattern, { locale: ptBR });

    return dataFormatada;
  };

  const formatToBrazilianStyle = value => {
    const _format = Number(value);
    return _format.toLocaleString('pt-BR', {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };
  // const formatHistoryDate = date => {
  //   const parsedDate = parse(date, 'yyyy-MM-dd HH:mm:ss', new Date());
  //   const formattedDate = format(parsedDate, "dd/MM/yy 'às' HH:mm'h'");
  //   return formattedDate;
  // };

  async function handleRepaymentOrder() {
    try {
      await apiOffstorePay
        .post(`/transactions/repayment`, {
          zoopSellerId: transaction && transaction.zoop_seller_id,
          zoopTransactionId: transaction && transaction.zoop_transaction_id,
          amount: transaction && Math.round(transaction.amount * 100),
        })
        .then(response => {
          if (response.status === 200) {
            setPaymentStatus('canceled');
          }
        });
    } catch (err) {
      toast.error('Erro ao fazer reembolso');
    } finally {
      setModalRepaymentActive(false);
    }
  }

  return (
    <>
      {/* {initialLoading && <LoaderComponent active inline="centered" />} */}

      {!initialLoading && (
        <>
          {openBottomSheet && (
            <Bottlesheet close={() => setOpenBottomSheet(false)} />
          )}
          <S.ContainerWrapper>
            <S.Header>Pagamento</S.Header>

            <S.PaymentWrapper>
              <S.Payment>R$ {formatToBrazilianStyle(amount)}</S.Payment>
              {paymentStatus === 'dispute' && (
                <S.Tag>Pagamento contestado</S.Tag>
              )}
              {paymentStatus === 'reembolsado' && (
                <S.Tag>Pagamento Reembolsado</S.Tag>
              )}
            </S.PaymentWrapper>
            <S.Description>
              {paymentDate && formatDate(paymentDate)}
            </S.Description>

            {paymentStatus === 'dispute' && (
              <S.Tag padding="16px">
                Esse pagamento foi contestado pelo comprador. Você pode aceitar
                a contestação, ou responder a contestação clicando em “Responder
                contestação”.
              </S.Tag>
            )}
            <S.Divider />
          </S.ContainerWrapper>

          <S.ContainerWrapper>
            <S.Header>Pedido</S.Header>
            {transaction && (
              <S.LinkButton
                onClick={() =>
                  history.push(`/myorders/${transaction.order_id}`)
                }
              >
                Ver pedido{' '}
                <svg
                  width="21"
                  height="12"
                  viewBox="0 0 21 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 6H19.5714"
                    stroke="#0D85FF"
                    strokeWidth="1.71429"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M14.5708 11L19.5708 6L14.5708 1"
                    stroke="#0D85FF"
                    strokeWidth="1.71429"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </S.LinkButton>
            )}

            <S.Divider />
          </S.ContainerWrapper>

          <S.ContainerWrapper>
            {transaction.order && (
              <>
                <S.Header>Dados do cliente</S.Header>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '8px',
                  }}
                >
                  <S.Description>{transaction.order.email}</S.Description>
                  <S.Description>{transaction.order.name}</S.Description>
                  <S.Description>{transaction.order.whatsapp}</S.Description>

                  {transaction.order.address && (
                    <S.Description>
                      {transaction.order.address}, {transaction.order.number},{' '}
                      {transaction.order.neighborhood}, {transaction.order.city}{' '}
                      - {transaction.order.uf} - {transaction.order.cep}
                    </S.Description>
                  )}
                </div>
                <S.Divider />
              </>
            )}
          </S.ContainerWrapper>

          <S.ContainerWrapper>
            <S.Header>Forma de pagamento</S.Header>

            {transaction && transaction.type === 'credit' && (
              <>
                {creditData && (
                  <S.Description>
                    Cartão de crédito (final {creditData.last4_digits})
                  </S.Description>
                )}
              </>
            )}

            {transaction && transaction.type === 'pix' && (
              <>
                <S.Description>Pix</S.Description>
              </>
            )}

            <S.Divider />
          </S.ContainerWrapper>

          <S.ContainerWrapper>
            <S.Header>Dados do pagamento</S.Header>

            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}
            >
              <S.Description>Valor: {formatPrice(amount)}</S.Description>
              <S.Description>Tarifa: {formatPrice(totalFee)}</S.Description>
              <S.Description>Líquido: {formatPrice(liquidValue)}</S.Description>
            </div>
            <S.Divider />
          </S.ContainerWrapper>

          {historyList && historyList.length > 0 && (
            <>
              <PaymentStatusSteps
                orderId={transaction && transaction.order_id}
                listOfStatus={historyList}
                creditData={creditData}
                detailsStatus={paymentStatus === 'canceled' ? 'Cancelado' : ''}
                setModalRepaymentActive={setModalRepaymentActive}
              />

              <S.Divider />
            </>

            // <S.ContainerWrapper>
            //   <S.Header>Histórico</S.Header>
            //   <div style={{ marginTop: '8px' }} />
            //   <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            //     {historyList.map(item => {
            //       return (
            //         <S.HistoryContainer>
            //           <div
            //             style={{
            //               display: 'flex',
            //               flexDirection: 'row',
            //               alignItems: 'center',
            //             }}
            //           >
            //             <S.Dot />
            //             <div>
            //               <S.HistoryHeader>{item.label}</S.HistoryHeader>
            //               <S.HistoryLabel>{item.payment_type}</S.HistoryLabel>
            //             </div>
            //           </div>
            //           <S.HistoryLabel>
            //             {formatHistoryDate(item.created_at)}
            //           </S.HistoryLabel>
            //         </S.HistoryContainer>
            //       );
            //     })}
            //   </div>
            //   <S.Divider />
            // </S.ContainerWrapper>
          )}

          {paymentStatus === 'dispute' && (
            <S.DivDisputeButton>
              <Button
                onClick={() =>
                  history.push(
                    `/paymentmethods/configurationoffstorepay/disputePage/${transaction &&
                      transaction.id}`
                  )
                }
              >
                Responder contestação
              </Button>

              {/* <Space height="8px" />

              <button type="button" className="grey" onClick={() => {}}>
                Aceitar contestação
              </button> */}
            </S.DivDisputeButton>
          )}

          {/* <S.Button type="repay" onClick={() => setOpenBottomSheet(true)}>
            <img src={goBackIcon} alt="go-back" />
            Reembolsar pagamento
          </S.Button>
          <S.Button
            type=""
            onClick={() =>
              history.push(
                '/paymentmethods/configurationoffstorepay/disputePage/00c78870-cbe3-4850-91e5-939193e204f3'
              )
            }
            margin="16px 0 0 0"
          >
            Responder contestação
          </S.Button> */}
        </>
      )}

      {modalRepaymentActive && (
        <S.ModalActive>
          <div
            className="container"
            onClick={() => setModalRepaymentActive(false)}
          />
          <div className="div">
            <strong>Deseja reembolsar o pagamento?</strong>
            <span>
              O valor da compra será reembolsado para a conta corrente ou cartão
              de crédito do comprador
            </span>

            <button
              type="button"
              className="grey-button"
              onClick={() => setModalRepaymentActive(false)}
            >
              Cancelar
            </button>

            <button
              type="button"
              className="red-button"
              onClick={() => handleRepaymentOrder()}
            >
              Confirmar reembolso
            </button>
          </div>
        </S.ModalActive>
      )}
    </>
  );
}
