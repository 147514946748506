import React, { useState, useCallback } from 'react';

import { useDispatch } from 'react-redux';

import NoImage from '../../assets/icons/noimage.png';

import LoaderGrey from '../../assets/icons/loader-grey.gif';

import trashIcon from '../../assets/icons/trashIcon.svg';
import StockIcon from '../../assets/icons/StockIcon';

import { UpdateActiveLinkRequest } from '../../store/modules/linkPrev/actions';
import {
  Div,
  // DivDrag,
  DivAll,
  Image,
  DivLeft,
  DivRight,
  // SpanPrice,
  // FaRegTrashAlts,
} from './styles';

function LinkList({ linkk, deleteLink, handleClickModal }) {
  // const [title, setTitle] = useState('');
  // const [link, setLink] = useState('');
  // const [description, setDescription] = useState('');
  // const [highlighted, setHighlighted] = useState(false);
  const [active, setActive] = useState(linkk.active);
  // const [tags, setTags] = useState([]);
  const [deleteLoaderId, setDeleteLoaderId] = useState(0);

  const dispatch = useDispatch();

  function Delete(id) {
    setDeleteLoaderId(id);
    deleteLink(id);
  }

  // function saveData(id) {
  //   Blur(id, {
  //     title,
  //     link,
  //     description,
  //     highlighted,
  //     active,
  //     tags,
  //   });
  // }

  function handleButtonClick(id) {
    handleClickModal(id);
  }

  const onChangeCheck = useCallback(
    e => {
      setActive(e.target.checked);
      const isActive = e.target.checked;

      dispatch(UpdateActiveLinkRequest(linkk.id, isActive));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <Div active={active}>
      <DivAll>
        <Image
          src={
            (linkk.images && linkk.images[0] && linkk.images[0].image_url) ||
            NoImage
          }
        />
        <DivLeft onClick={() => handleButtonClick(linkk.id)}>
          <span>{linkk.title}</span>

          {linkk && linkk.gridattributes && linkk.gridattributes.length === 0 && (
            <div>
              {linkk.stock_quantity === 0 ? (
                <strong className="red-stock">Sem estoque</strong>
              ) : (
                <strong>{linkk.promotion_price || linkk.price}</strong>
              )}

              {linkk.stock_quantity !== null && (
                <>
                  {linkk.stock_quantity === 1 && (
                    <>
                      <StockIcon color="#FF6C4B" />
                      <span className="red-stock">{linkk.stock_quantity}</span>
                    </>
                  )}

                  {linkk.stock_quantity === 2 && (
                    <>
                      <StockIcon color="#ffb525" />
                      <span className="yellow-stock">
                        {linkk.stock_quantity}
                      </span>
                    </>
                  )}

                  {linkk.stock_quantity > 2 && (
                    <>
                      <StockIcon color="#9D9D9D" />
                      <span>{linkk.stock_quantity}</span>
                    </>
                  )}
                </>
              )}
            </div>
          )}
        </DivLeft>
        <DivRight>
          <a href="#" onClick={e => e.preventDefault()}>
            {deleteLoaderId === linkk.id ? (
              <img className="loader" src={LoaderGrey} alt="loader" />
            ) : (
              <img src={trashIcon} alt="" onClick={() => Delete(linkk.id)} />
            )}
          </a>

          <label className="switch">
            <input type="checkbox" checked={active} onChange={onChangeCheck} />
            <span className="slider round" />
          </label>
        </DivRight>
      </DivAll>
    </Div>
  );
}

export default LinkList;
