import produce from 'immer';

const INITIAL_STATE = {
  profile: null,
  loadingAvatar: false,
  loadingCover: false,
  loadingBanner: false,
  loadingBannerDesktop: false,
};

export default function user(state = INITIAL_STATE, action) {
  // eslint-disable-next-line consistent-return
  return produce(state, draft => {
    switch (action.type) {
      case '@auth/SIGN_IN_SUCCESS': {
        draft.profile = action.payload.user;
        break;
      }
      case '@user/UPDATE_PROFILE_SUCCESS': {
        draft.profile = action.payload.profile;
        break;
      }
      case '@user/UPDATE_CUSTOMIZE_LINK_USER_SUCCESS': {
        draft.profile.link = action.payload.profile.link;
        break;
      }
      case '@user/SAVE_IMAGE_REQUEST': {
        if (action.payload.imageType === 'avatar') {
          draft.profile.loadingAvatar = true;
        } else if (action.payload.imageType === 'cover') {
          draft.profile.loadingCover = true;
        } else if (action.payload.imageType === 'banner') {
          draft.profile.loadingBanner = true;
        } else if (action.payload.imageType === 'banner-desktop') {
          draft.profile.loadingBannerDesktop = true;
        }
        break;
      }
      case '@user/SAVE_IMAGE_SUCCESS': {
        if (action.payload.imageType === 'avatar') {
          draft.profile.avatar = action.payload.avatar;
        } else if (action.payload.imageType === 'cover') {
          draft.profile.cover = action.payload.avatar;
        } else if (action.payload.imageType === 'banner') {
          draft.profile.banners.push(action.payload.avatar);
          draft.profile.loadingBanner = false;
        } else if (action.payload.imageType === 'banner-desktop') {
          draft.profile.banners_desktop.push(action.payload.avatar);
          draft.profile.loadingBannerDesktop = false;
        }

        break;
      }
      case '@user/SAVE_IMAGE_LOADING_SUCCESS': {
        draft.profile.loadingAvatar = false;

        break;
      }
      case '@user/REMOVE_IMAGE_SUCCESS': {
        if (action.payload.imageType === 'avatar') {
          draft.profile.avatar = null;
        } else if (action.payload.imageType === 'cover') {
          draft.profile.cover = null;
        } else if (action.payload.imageType === 'banner') {
          // const newBanners = draft.profile.banners.filter(
          //   b => b.id !== action.payload.imageId
          // );
          // draft.profile.banners = action.payload.banner;
        }

        break;
      }
      case '@user/IMAGE_BANNER_SUCCESS': {
        draft.profile.banners = action.payload.banners;

        break;
      }
      case '@user/IMAGE_BANNER_DESKTOP_SUCCESS': {
        draft.profile.banners_desktop = action.payload.banners;

        break;
      }
      case '@user/UPDATE_PROFILE_COLOR': {
        if (action.payload.title === 'Fundo') {
          draft.profile.background_color = action.payload.data;
        }
        if (action.payload.title === 'Botão') {
          draft.profile.button_color = action.payload.data;
        }
        if (action.payload.title === 'Texto') {
          draft.profile.text_color = action.payload.data;
        }
        if (action.payload.title === 'Detalhes') {
          draft.profile.details_color = action.payload.data;
        }

        break;
      }
      case '@auth/SIGN_OUT': {
        draft.profile = null;
        break;
      }
      default:
        return state;
    }
  });
}
