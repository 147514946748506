import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  min-height: 100vh;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 32px;
  position: relative;
`;

export const AddressContainer = styled.div`
  display: flex;
  flex-direction: column;

  > strong {
    font-size: 16px;
    font-weight: 600;
  }

  > span {
    color: #838383;
    font-size: 12px;
    font-weight: 400;
    margin-top: 4px;
  }

  .error {
    align-self: flex-end;
    color: #ff6c4b;
    font-size: 13px;
    font-weight: 400;
    padding-top: 4px;
    padding-left: 12px;
    text-align: right;
  }

  .number-address-input {
    width: 84px;
    margin-right: 8px;
  }

  .double-input-div {
    display: flex;
    flex-direction: row;
  }

  .error-border {
    border: 1px solid #ff303c;
  }

  .error-text {
    ::placeholder {
      color: #ff303c;
    }
  }

  > div {
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    border-radius: 8px;

    > input {
      height: 40px;
      width: 100%;
      border: none;
      border-radius: 8px;
      background-color: #f9f9f9;
      padding: 0 12px;
    }

    > span {
      color: #ff6c4b;
      font-size: 13px;
      font-weight: 400;
      padding-top: 4px;
      padding-left: 12px;
    }
  }
`;

export const TextIntroContainer = styled.div`
  margin-top: 32px;

  > strong {
    font-size: 16px;
    font-weight: 600;
  }

  > p {
    color: #838383;
    font-size: 12px;
    font-weight: 400;
    margin-top: 4px;
  }
`;

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  overflow-x: auto;

  ::-webkit-scrollbar {
    height: 4px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: transparent;
    width: 4px;

    border-radius: 10px;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 50%;
      height: 100%;
      background: transparent;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 50%;
      height: 100%;
      background: transparent;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-track-piece {
    background-color: transparent;
  }

  .table-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding-bottom: 12px;
    border-bottom: 1px solid #d8dee5;

    .first-column {
      margin-left: 60px;
    }

    .second-column {
      margin-left: 44px;
    }

    .third-column {
      margin-left: 75px;
    }

    .fourth-column {
      margin-left: 72px;
    }
  }

  > strong {
    font-size: 14px;
    font-weight: 600;
  }
`;

export const DivInputPrice = styled.div`
  display: flex;
  min-width: 92px;
  background: transparent;
  align-items: center;
  justify-content: center;
  opacity: ${props => (props.disabled ? '50%' : '100%')};
  color: ${props => (props.warning ? '#ff303c' : '#101010')};
  border: ${props => (props.warning ? '1px solid #ff303c' : 'none')};
  border-radius: 8px;

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  > div {
    padding: 4px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #efefef;
    border-radius: 8px 0px 0px 8px;
  }

  .currency-input {
    width: 64px;
    height: 36px;
    background: #f9f9f9;
    border: 1px solid #f9f9f9;
    box-sizing: border-box;
    border-radius: 0px 8px 8px 0px;
    padding: 0 8px;
    font-size: 13px;

    &::placeholder {
      color: ${props => (props.warning ? '#ff303c' : '#d1d1d1')};
    }
  }
`;

export const ItemDistance = styled.div`
  display: flex;
  flex-direction: column;
  opacity: ${props => (props.active ? '100%' : '40%')};

  .container {
    height: 50px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    /* border-top: 1px solid #d8dee5; */
    border-bottom: 1px solid #d8dee5;

    .active-switch {
      margin-right: 16px;
    }

    .distance {
      width: 103px;
      display: flex;
      align-items: center;

      > strong {
        width: 80px;
        font-size: 13px;
        font-weight: 500;
        line-height: 16px;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .value {
      min-width: 85px;
    }

    .delivery-time {
      display: flex;
      flex-direction: row;
      margin-left: 16px;

      input[type='number']::-webkit-inner-spin-button,
      input[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      > input {
        width: 60px;
        height: 36px;
        border-radius: 8px;
        background: #f9f9f9;
        border: none;
        padding: 0 12px;
        font-size: 13px;
        font-weight: 400;

        &::placeholder {
          color: '#d1d1d1';
        }
      }

      > div {
        position: relative;
        display: flex;
        align-items: center;
        font-size: 13px !important;
        width: 96px;
        margin-left: 8px;

        > select {
          height: 36px;
          padding-left: 12px;
          padding-right: 20px;
          border: 1px solid #d8dee5;
          border-radius: 8px;
          background: #fff;
          width: 100%;
          appearance: none !important;
          color: #101010;
          font-size: 13px !important;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        > img {
          position: absolute;
          right: 12px;
          width: 10px;
          height: 5px;
        }
      }
    }

    .title {
      margin-left: 12px;

      > input {
        width: 125px;
        height: 36px;
        border-radius: 8px;
        background: #f9f9f9;
        border: none;
        padding: 0 12px;
      }
    }

    .delete {
      margin-left: 16px;
      margin-right: 16px;

      > button {
        border: none;
        background-color: transparent;
      }
    }
  }
`;

export const AddDistanceButton = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  margin-bottom: 80px;

  > strong {
    color: #0d85ff;
    font-size: 14px;
    font-weight: 600;
  }
`;

export const Background = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100vh;
  float: 1;
  z-index: 550;
  background: rgba(238, 239, 239, 0.7);
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
`;

export const ContainerBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 20px 20px 20px;
`;

export const ContainerHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const ContainerGeneralModal = styled.div`
  position: fixed;
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 500px;
  background: #fff;
  top: auto;
  left: auto;
  bottom: auto;
  border-radius: 16px;
`;

export const ContainerModal = styled.div`
  display: 'flex';
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const HeaderModal = styled.div`
  display: flex;
  height: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 20px 0;

  > strong {
    font-size: 16px;
    font-weight: 600;
  }

  > button {
    width: 12px;
    height: 12px;
    position: absolute;
    right: 16px;
    border: none;
    background-color: transparent;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  .errorMessage {
    color: #ff6c4b;
    margin-top: 4px;
  }

  > input {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 12px;
    width: 78px;
    height: 40px;
    margin: 0 8px;
    border: none;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
`;

export const Divider = styled.div`
  display: flex;
  margin-bottom: 20px;
  height: 0.5px;
  width: 100%;
  background: #d8dee5;
`;

export const ContainerButtonModal = styled.div`
  margin-top: 24px;
  display: flex;

  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    background-color: #0d85ff;
    width: 100%;
    border: none;
    height: 50px;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;

    > strong {
      font-size: 14px;
      font-weight: 600;
      line-height: 17px;
    }

    > span {
      color: #0d85ff;
      font-size: 12px;
      font-weight: 400;
      margin-left: 4px;
    }
  }

  > span {
    color: #838383;
    font-size: 12px;
    font-weight: 400;
    margin-top: 4px;
  }
`;

export const SaveButton = styled.div`
  background-color: #fff;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  border: none;
  position: sticky;
  bottom: 0px;
  width: 100%;

  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50px;
    border: none;
    margin: 16px 16px 16px 16px;
    border-radius: 8px;
    background-color: #0d85ff;
    color: #fff;
  }
`;
