import offstorePay from '~/services/offstorePay';

// {
//     "name": "Nubank",
//     "bank_code": "260",
//     "agency_number": "0001",
//     "account_number": "14190340",
//     "account_type": "checking",
//     "account_holder": "Henrique Pires Espindola",
//     "document_type": "CPF",
//     "document_number": "44982764859",
//     "user_id": 1
// }

// {
// 	"bank_account_id": "73090bc9-2f88-41e6-b339-56aa6d19bff7",
// 	"seller_id": "231762ff-f8dc-44b7-97bd-753ad0afd9c0"
// }

export function useCreateSellerBank() {
  const handleCreateSellerBank = async ({ userId, sellerId, bankData }) => {
    const [bankName, bankCode] = bankData.bank.split('-');
    const { data: createdBank } = await offstorePay.post('/bankAccount', {
      name: bankName,
      bank_code: bankCode,
      agency_number: bankData.agency,
      account_number: bankData.account,
      account_type: 'checking',
      account_holder: bankData.name,
      document_type: bankData.documentType,
      document_number: bankData.documentNumber,
      user_id: userId,
    });
    await offstorePay.put('/seller/bankAccount', {
      bank_account_id: createdBank.bankAccount.id,
      seller_id: sellerId,
    });
    return {
      createdBank,
    };
  };
  const handleUpdateSellerBank = async ({ userId, bankData }) => {
    // eslint-disable-next-line no-unused-vars
    const [bankName, bankCode] = bankData.bank.split('- ');

    // console.log(bankData);
    // console.log(bankCode);

    const { data: updatedBank } = await offstorePay.put('/bankAccount', {
      name: bankData.name,
      bank_code: bankCode,
      agency_number: bankData.agency_number,
      account_number: bankData.account_number,
      account_type: 'checking',
      account_holder: bankData.account_holder,
      document_type: bankData.documentType || 'CPF',
      document_number: bankData.document_number,
      user_id: userId,
    });

    return {
      updatedBank,
    };
  };

  return {
    handleCreateSellerBank,
    handleUpdateSellerBank,
  };
}
