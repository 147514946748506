import React, { useState } from 'react';

// import { Link } from 'react-router-dom';
import { Input, Form } from '@rocketseat/unform';

import { toast } from 'react-toastify';
import api from '../../../services/api';

import { ButtonSubmit, Container, Div } from './styles';

export default function ResponsiveForgotPassword() {
  const [loading, setLoading] = useState(false);

  async function handleSubmit({ email }) {
    try {
      setLoading(true);

      const data = {
        email,
      };
      await api.post('/forgotpassword', data);

      setTimeout(() => {
        setLoading(false);
        toast.success('Verifique seu e-mail');
      }, 3000);
    } catch (err) {
      toast.error(err.response.data.error);
      setLoading(false);
    }
  }

  return (
    <Container>
      <Div>
        <h1>Esqueceu sua senha?</h1>
        <Form onSubmit={handleSubmit}>
          <strong>E-mail</strong>
          <div className="div-input">
            <Input name="email" type="email" placeholder="Digite seu e-mail" />
          </div>

          <ButtonSubmit disabled={loading} block={loading}>
            Solicitar nova senha
          </ButtonSubmit>
        </Form>
      </Div>
    </Container>
  );
}
