import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
// import { CopyToClipboard } from 'react-copy-to-clipboard';
import api from '~/services/api';

import { TOKEN_VERCEL, Connection } from '../../../../../services/config';

import loaderGif from '../../../../../assets/icons/loader-grey.gif';
import linkIcon from '../../../../../assets/icons/link-blue.svg';

import {
  Container,
  DivTitle,
  DivBody,
  DivInput,
  DivDomainSuccess,
} from './styles';

function Domain() {
  const [profile, setProfile] = useState();
  const [domain, setDomain] = useState([]);
  const [domainNotConfigured, setDomainNotConfigured] = useState(false);
  const [inputDomain, setInputDomain] = useState('');
  // const [showProPlanPopUp, setShowProPlanPopUp] = useState(false);
  // const [initialLoading, setInitialLoading] = useState(true);
  const [disableInput, setDisableInput] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showLoaderRemove, setShowLoaderRemove] = useState(false);

  // token configurado no env do projeto no site da Vercel
  const config = {
    headers: { Authorization: `Bearer ${TOKEN_VERCEL}` },
    params: {
      teamId: Connection.TEAM_VERCEL,
    },
  };

  async function CheckDomainConfigured(domainUpdated) {
    if (domainUpdated) {
      await axios
        .get(
          `https://api.vercel.com/v6/domains/${domainUpdated.domain}/config`,
          config
        )
        .then(async response => {
          if (response.status === 200) {
            setDomainNotConfigured(response.data.misconfigured);
            await api.put('/domain', { active: !response.data.misconfigured });
          }
        });
    } else if (domain && domain.domain) {
      await axios
        .get(
          `https://api.vercel.com/v6/domains/${domain.domain}/config`,
          config
        )
        .then(async response => {
          if (response.status === 200) {
            setDomainNotConfigured(response.data.misconfigured);
            await api.put('/domain', { active: !response.data.misconfigured });
          }
        });
    }
  }

  useEffect(() => {
    async function load() {
      try {
        // setInitialLoading(true);
        await api.get('/getuser').then(async response => {
          setProfile(response.data);

          await api.get('/domain').then(res => {
            if (res && res.data) {
              setDomain(res.data);

              if (res.data.domain) {
                setInputDomain(res.data.domain);
                setDisableInput(true);
                CheckDomainConfigured(res.data);
              }
            }
          });
        });
      } finally {
        // setInitialLoading(false);
      }
    }

    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function handleSubmitDomain() {
    try {
      setShowLoader(true);
      if (!inputDomain) {
        toast.error('Preencha o domínio');
        return 0;
      }

      if (inputDomain.search('.offstore.') > -1) {
        toast.error(
          'Você não pode adicionar um domínio offstore. Preencha um domínio próprio'
        );
        return 0;
      }

      const replacedDomain = inputDomain
        .replace('https://', '')
        .replace('http://', '')
        .replace('www.', '');

      const data = {
        domain: replacedDomain,
      };

      await axios
        .post(
          `https://api.vercel.com/v9/projects/${
            Connection.ENVIRONMENT === 'production'
              ? Connection.PROJECT_VERCEL_PRODUCTION
              : Connection.PROJECT_VERCEL_HOMOLOG
          }/domains`,
          { name: replacedDomain },
          config
        )
        .then(async response => {
          if (response.status === 200) {
            const domainUpdated = await api.put('/domain', data);
            setDisableInput(true);
            setDomain(domainUpdated.data);
            setInputDomain(domainUpdated.data.domain);
            toast.success('Dados salvos');
            CheckDomainConfigured(domainUpdated.data);

            await axios.post(
              `https://api.vercel.com/v9/projects/${
                Connection.ENVIRONMENT === 'production'
                  ? Connection.PROJECT_VERCEL_PRODUCTION
                  : Connection.PROJECT_VERCEL_HOMOLOG
              }/domains`,
              { name: `www.${replacedDomain}`, redirect: replacedDomain },
              config
            );
          }
        });
    } catch (err) {
      if (err.response.data) {
        const { error } = err.response.data;

        if (error.code === 'invalid_domain') {
          toast.error('Domínio inválido');
          return 0;
        }

        if (error.code === 'domain_already_in_use') {
          toast.error('Domínio já utilizado em outra loja');
          return 0;
        }
      }

      toast.error('Erro ao processar requisição');
    } finally {
      setShowLoader(false);
    }
  }

  function handleChangeInputDomain(e) {
    const value = e.target.value.toLowerCase();
    setInputDomain(value);
  }

  async function handleRemoveDomain() {
    try {
      setShowLoaderRemove(true);

      const data = {
        domain: null,
        active: false,
      };

      // await axios.delete(
      //   `https://api.vercel.com/v6/domains/www.${inputDomain}`,
      //   config
      // );

      await axios.delete(
        `https://api.vercel.com/v9/projects/${Connection.PROJECT_VERCEL}/domains/www.${inputDomain}?teamId=${Connection.TEAM_VERCEL}`,
        config
      );

      await axios.delete(
        `https://api.vercel.com/v9/projects/${Connection.PROJECT_VERCEL}/domains/${inputDomain}?teamId=${Connection.TEAM_VERCEL}`,
        config
      );

      try {
        const existsDomain = await axios.get(
          `https://api.vercel.com/v5/domains/${inputDomain}?teamId=${Connection.TEAM_VERCEL}`,
          config
        );

        if (existsDomain.data.domain) {
          await axios.delete(
            `https://api.vercel.com/v6/domains/${inputDomain}`,
            config
          );
        }
      } catch (err) {
        //
      }

      const domainUpdated = await api.put('/domain', data);
      setInputDomain('');
      setDisableInput(false);
      setDomain(domainUpdated.data);
      setDomainNotConfigured(false);
      toast.success('Dados salvos');
    } catch (err) {
      toast.error('Erro ao processar requisição');
    } finally {
      setShowLoaderRemove(false);
    }
  }

  // function ProPlanPopUp(pro_modal) {
  //   setShowProPlanPopUp(pro_modal);
  // }

  return (
    <>
      <Container>
        <DivTitle>
          <strong>Domínio próprio</strong>
        </DivTitle>

        <DivBody disableInput={disableInput}>
          <strong>Seu domínio</strong>
          <span>Insira seu domínio abaixo</span>

          <div>
            {disableInput ? (
              <DivInput>
                {/* <CopyToClipboard text={inputDomain}>
                  <span onClick={() => toast.success('Link copiado')}>
                    {inputDomain}
                  </span>
                </CopyToClipboard> */}

                <a
                  href={`https://${inputDomain}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>{inputDomain}</span>
                  <img src={linkIcon} alt="link-icon" />
                </a>
              </DivInput>
            ) : (
              <input
                type="text"
                placeholder="Ex: seudominio.com"
                value={inputDomain}
                onChange={handleChangeInputDomain}
                disabled={(profile && profile.pro === 0) || disableInput}
              />
            )}

            {!domainNotConfigured && (
              <button
                type="button"
                onClick={handleSubmitDomain}
                disabled={disableInput || showLoader}
                className="add-domain-button"
              >
                {showLoader ? (
                  <img src={loaderGif} alt="loader" />
                ) : (
                  <>{disableInput ? 'Ativo' : 'Adicionar'}</>
                )}
              </button>
            )}

            {domain.domain && (
              <button
                type="button"
                onClick={handleRemoveDomain}
                className="remove-domain-button"
              >
                {showLoaderRemove ? (
                  <img src={loaderGif} alt="loader" />
                ) : (
                  'Remover'
                )}
              </button>
            )}
          </div>

          {domainNotConfigured && (
            <span className="span-red">
              Seu domínio ainda não foi vinculado a nossa plataforma. Siga o
              passo a passo abaixo para vincular.
            </span>
          )}

          {(disableInput && !domainNotConfigured && (
            <DivDomainSuccess>
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.23077 7.92308L6.12308 9.43693C6.1739 9.47878 6.23341 9.50878 6.29728 9.52475C6.36115 9.54071 6.42778 9.54225 6.49231 9.52923C6.55747 9.51701 6.61922 9.4909 6.67338 9.45267C6.72754 9.41444 6.77282 9.365 6.80616 9.3077L9.76924 4.23077"
                  stroke="#0D85FF"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M7 13C10.3137 13 13 10.3137 13 7C13 3.68629 10.3137 1 7 1C3.68629 1 1 3.68629 1 7C1 10.3137 3.68629 13 7 13Z"
                  stroke="#0D85FF"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <span>Domínio vinculado com sucesso</span>
            </DivDomainSuccess>
          )) || (
            <>
              <strong className="strong-domain">
                Como vincular seu domínio
              </strong>
              <span>
                Siga o tutorial que preparamos para vincular seu domínio, é
                muito simples, e leva menos de 1 minuto.
              </span>

              <a
                href="https://intercom.help/offstore/pt-BR/articles/6513300-como-vincular-seu-dominio-com-sua-loja-da-offstore"
                target="_blank"
                rel="noopener noreferrer"
              >
                Veja como vincular seu domínio
              </a>

              <div className="div-buy-domain">
                <span>Ainda não tem um domínio?</span>
                <a
                  href="https://www.hostgator.com.br/28624.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Compre com desconto aqui
                </a>
              </div>
            </>
          )}
        </DivBody>
      </Container>
    </>
  );
}

export default Domain;
