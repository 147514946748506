import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useHistory } from 'react-router-dom';
import { formatPrice } from '~/util/format';
// import PaymentStatusSteps from '~/components/PaymentStatusSteps';
// eslint-disable-next-line import/no-extraneous-dependencies
// import { Loader } from 'semantic-ui-react';

import { toast } from 'react-toastify';
import { Divider, H2, H4, Space, Span } from '~/designsystem/Fonts';
import * as S from './styles';
import apiOffstorePay from '../../../../../services/offstorePay';

export function TransferPageComponent() {
  const history = useHistory();
  const [initialLoading, setInitialLoading] = useState(true);
  const [amount, setAmount] = useState(null);
  const [paymentDate, setPaymentDate] = useState(null);
  const [bankAccount, setBankAccount] = useState();

  const getDetailTransaction = async id => {
    try {
      const res = await apiOffstorePay.get(`/transfers/${id}`);
      console.log(res.data);

      if (res && res.data) {
        const amoun_t = parseFloat(res.data.transfer.amount);
        setAmount(amoun_t || 0);
        setPaymentDate(res.data.transfer.transfer_date);
        setBankAccount(res.data.transfer.bank_account);
      }
    } catch (err) {
      toast.error('Ocorreu um erro ao carregar a transação');
    } finally {
      setInitialLoading(false);
    }
  };

  useEffect(() => {
    const id = history.location.pathname.split('/');
    getDetailTransaction(id.pop());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formatDate = date => {
    const dataObjeto = new Date(date);

    const pattern = "d 'de' MMMM 'de' yyyy 'às' HH:mm'h'";
    const dataFormatada = format(dataObjeto, pattern, { locale: ptBR });

    return dataFormatada;
  };

  return (
    <>
      {/* {initialLoading && <Loader active inline="centered" />} */}

      {!initialLoading && (
        <S.Container>
          <S.Div>
            <H4>Pagamento</H4>
            <Space height="8px" />

            <H2 className="green">{formatPrice(amount)}</H2>
            <Space height="4px" />

            <Span fontSize="13px">{formatDate(paymentDate)}</Span>
          </S.Div>

          <Divider margin="24px 0" />

          <S.Div>
            <H4>Dados da transação</H4>
            <Space height="12px" />

            <Span fontSize="13px">Valor:</Span>
            <Space height="8px" />

            <Span fontSize="13px">Tarifa:</Span>
            <Space height="8px" />

            <Span fontSize="13px">Líquido: {formatPrice(amount)}</Span>
          </S.Div>

          <Divider margin="24px 0" />

          {bankAccount && (
            <S.Div>
              <H4>Banco</H4>
              <Space height="12px" />

              <Span fontSize="13px">
                {bankAccount.bank_name}. ****
                {bankAccount.account_number &&
                  bankAccount.account_number.slice(-4)}
              </Span>
            </S.Div>
          )}

          <Divider margin="24px 0" />
        </S.Container>
      )}
    </>
  );
}
