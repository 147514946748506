/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-no-bind */
import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { Link, useHistory } from 'react-router-dom';
import LabelPremium from '~/components/ProPlan/Labels/LabelPremium';
import ShortCardPremiumPlan from '~/components/ProPlan/Cards/CardPremiumPlan/Short';
import { isValidUserToPlan } from '~/functions/user';
import { seePlansMoreOptionsMenuEvent } from '~/functions/events';
import api from '../../../services/api';

import BackButtonIcon from '../../../assets/icons/backButton.svg';
// import ExitIcon from '../../../assets/icons/menu/exit.svg';

import { signOut } from '../../../store/modules/auth/actions';

import PremiumPlanModal from '../../../components/Popups/PremiumPlan';

import { Container, Body, DivOtherLinks, DivMaintenance } from './styles';

function Settings() {
  const dispatch = useDispatch();
  const [profile, setProfile] = useState();
  const [showProPlanPopUp, setShowProPlanPopUp] = useState(false);
  const [maintenanceMode, setMaintenanceMode] = useState(false);
  // const [userConfigurations, setUserConfigurations] = useState(false);

  const history = useHistory();

  function handleSignOut() {
    dispatch(signOut());
  }

  function ProPlanPopUp(pro_modal) {
    setShowProPlanPopUp(pro_modal);
  }

  useEffect(() => {
    async function load() {
      await api.get('/getuser').then(response => {
        setProfile(response.data);
      });

      await api.get('/userconfigurations').then(response => {
        // setUserConfigurations(response.data);
        setMaintenanceMode(response.data.maintenance);
      });
    }

    load();
  }, []);

  function handleClickButton(url) {
    if (profile && isValidUserToPlan(profile)) {
      history.push(url);
      return 0;
    }

    setShowProPlanPopUp(true);
    return 0;
  }

  const onChangeCheck = useCallback(
    async e => {
      setMaintenanceMode(e.target.checked);

      await api.put('/userconfigurations', {
        maintenance: e.target.checked,
      });

      // setUserConfigurations(response.data);
      toast.success('Alteração salva');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <Container>
      <PremiumPlanModal showModal={showProPlanPopUp} Modal={ProPlanPopUp} />

      <header>
        <div className="div-space">
          <Link to={{ pathname: '/dashboard', tab: { tab: 1 } }}>
            <img src={BackButtonIcon} alt="" />
          </Link>
        </div>
        <div>
          <span>Mais opções</span>
        </div>
        <div className="div-space">
          <button type="button" onClick={handleSignOut}>
            Sair
          </button>
        </div>
      </header>

      <Body>
        <Link to="/myaccount">Minha conta</Link>

        <Link to="/myplan">Meu plano</Link>

        <Link to="/pro" onClick={() => seePlansMoreOptionsMenuEvent()}>
          Planos
        </Link>

        <Link to="/configurations">Configurações</Link>

        <DivOtherLinks>
          {/* <Link to="/integrations/instagram">
            <span>Vincular ao Instagram e Facebook</span>
            {pro === 0 && <span className="pro">PRO</span>}
          </Link> */}

          {/* <Link to="/integrations/domain">
            <span>Domínio próprio</span>
            {pro === 0 && <span className="pro">PRO</span>}
          </Link>

          <Link to="/conversionpixel">
            <span>Pixels de conversão</span>
            {pro === 0 && <span className="pro">PRO</span>}
          </Link> */}

          <button
            type="button"
            onClick={() => handleClickButton('/integrations/domain')}
          >
            Domínio próprio
            {profile && !isValidUserToPlan(profile) && <LabelPremium />}
          </button>

          <button
            type="button"
            onClick={() => handleClickButton('/conversionpixel')}
          >
            Pixels de conversão
            {profile && !isValidUserToPlan(profile) && <LabelPremium />}
          </button>
        </DivOtherLinks>

        <DivMaintenance>
          <strong>Colocar site em manutenção</strong>

          <label className="switch">
            <input
              type="checkbox"
              checked={maintenanceMode}
              onChange={onChangeCheck}
            />
            <span className="slider round" />
          </label>
        </DivMaintenance>

        {/* <Link to="#" className="disabled">
          Frete
        </Link>

        <Link to="#" className="disabled">
          Sacola
        </Link>

        <Link to="#" className="disabled">
          Pedidos
        </Link>

        <Link to="#" className="disabled">
          Estoque
        </Link> */}

        {profile && profile.pro > 0 && !isValidUserToPlan(profile) && (
          <ShortCardPremiumPlan />
        )}
      </Body>
    </Container>
  );
}

export default Settings;
