/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { toast } from 'react-toastify';
import LoaderComponent from '~/components/Loader';
import AddAddress from '../../../../components/AddAddress';

import loader from '../../../../assets/icons/loader.gif';

import ProPlanModal from '../../../../components/Popups/ProPlan';

import { updateFreightRequest } from '../../../../store/modules/freight/actions';

import {
  Container,
  Body,
  DivLoader,
  DivInitialLoader,
  DivText,
  DivCards,
  Card,
  LabelPro,
  DivMethod,
  Radio,
  DivCep,
  SpanStatus,
  // DivDeliveryTypes,
  // GapColumn,
  // CardDeliveryType,
} from './styles';
import ResponsiveHeader from '../../../../components/HeaderMobile';

import api from '../../../../services/api';

function Configuration() {
  const [pro, setPro] = useState();
  // const [active, setActive] = useState();
  const [radioSelected, setRadioSelected] = useState(1);
  const [activeFlatRate, setActiveFlatRate] = useState(false);
  const [activeCustomFreight, setActiveCustomFreight] = useState(false);
  const [activeFreightPerKm, setActiveFreightPerKm] = useState(false);
  const [activePac, setActivePac] = useState(false);
  const [activeSedex, setActiveSedex] = useState(false);
  const [activeMiniEnvios, setActiveMiniEnvios] = useState(false);
  const [activeFree, setActiveFree] = useState(false);
  const [activeMelhorEnvio, setActiveMelhorEnvio] = useState(false);
  const [hasCepPacSedex, setHasCepPacSedex] = useState(false);
  const [melhorEnvioList, setMelhorEnvioList] = useState([]);
  const [showAddAddress, setShowAddAddress] = useState(false);
  const [activeTokenME, setActiveTokenME] = useState(false);
  const [hasGoogleAddressee, setHasGoogleAddressee] = useState(false);
  // const [hasGoogleFullAddress, setHasGoogleFullAddress] = useState(false);

  const [showLoader, setShowLoader] = useState(false);
  const [showInitialLoader, setShowInitialLoader] = useState(true);

  const [showProPlanPopUp, setShowProPlanPopUp] = useState(false);

  // const [showMoreCards, setShowMoreCards] = useState(false);
  const [melhorEnvio, setMelhorEnvio] = useState();

  // const freight = useSelector(state => state.freight.freight);

  const {
    id,
    cep,
    address,
    number,
    city,
    district,
    uf,
    // complement,
  } = useSelector(state => state.user.profile);

  const [customFreights, setCustomFreights] = useState([]);
  const [existsRegion, setExistsRegion] = useState([]);

  const history = useHistory();
  const dispatch = useDispatch();

  async function saveData(data) {
    await api.post('/updatefreight', data);
    toast.success('Informação salva');
  }

  useEffect(() => {
    async function load() {
      const response = await api.get('/configfreight');

      if (response && response.data) {
        setActiveFlatRate(response.data.flatrate);
        setActiveCustomFreight(response.data.custom_freight);
        setActivePac(response.data.pac);
        setActiveSedex(response.data.sedex);
        setActiveMiniEnvios(response.data.mini_envios);
        setActiveFree(response.data.free);
        setRadioSelected(response.data.receivement);
        setActiveFreightPerKm(response.data.has_freight_per_km);

        if (response.data.cep_origin_pac_sedex) {
          setHasCepPacSedex(true);
        } else {
          setHasCepPacSedex(false);
        }
      }

      await api.get('/existsregion').then(res => {
        setExistsRegion(res && res.data);
      });

      const custom_freights = await api.get('/customfreight');

      if (
        custom_freights &&
        custom_freights.data &&
        custom_freights.data.length > 0
      ) {
        setCustomFreights(custom_freights.data);
      } else {
        setActiveCustomFreight(false);
        dispatch(updateFreightRequest('CUSTOM_FREIGHT', false, false, true));
      }

      const getPro = await api.get(`/proplan`);
      setPro(getPro && getPro.data);

      await api.get('/melhorenvio').then(async me => {
        setMelhorEnvio(me.data);
        setActiveMelhorEnvio(me.data && me.data.active);

        if (me.data && getPro && getPro.data === 0) {
          await api.put('/melhorenvio', { active: false });
        }
      });

      await api
        .post('/melhorenvio/checktokenisvalid')
        .then(res => {
          if (res.data === 'Success') setActiveTokenME(true);
          else setActiveTokenME(false);
        })
        .catch(() => setActiveTokenME(false));

      await api.get('/melhorenviolist').then(services => {
        setMelhorEnvioList(services.data);
      });

      await api
        .get('/google-addresses/list')
        .then(res => {
          if (res.data && res.data.id) {
            setHasGoogleAddressee(true);
            // setHasGoogleFullAddress(!!res.data.full_address);
          } else setHasGoogleAddressee(false);
        })
        .catch(() => setHasGoogleAddressee(false));

      // dispatch(loadFreightRequest());

      setShowInitialLoader(false);
    }
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function updateOrderConfigurations() {
    await api.put('/orderconfigurations', {
      show_delivery_address: true,
    });
  }

  async function changeFreightStatus(value, type) {
    await api
      .post('/freight/status', {
        value,
        type,
      })
      .then(response => {
        setActiveFree(response.data.free);
        setActiveFlatRate(response.data.flatrate);
        setActiveCustomFreight(response.data.custom_freight);
        setActivePac(response.data.pac);
        setActiveSedex(response.data.sedex);
        setActiveMiniEnvios(response.data.mini_envios);
        setActiveFreightPerKm(response.data.has_freight_per_km);
        setActiveMelhorEnvio(response.data.melhor_envio_active);
      });
  }

  function onChangeActiveFlatRate(value) {
    if (value) {
      updateOrderConfigurations();
    }

    changeFreightStatus(value, 'FLATRATE');
  }

  function onChangeActiveCustomFreight(value) {
    if (value) {
      if (customFreights && customFreights.length === 0) {
        toast.error('Cadastre pelo menos um local antes de habilitar o frete');
        return 0;
      }

      updateOrderConfigurations();
    }

    changeFreightStatus(value, 'CUSTOM_FREIGHT');

    return 0;
  }

  function onChangeActiveCorreios(value) {
    if (value) {
      updateOrderConfigurations();
    }

    changeFreightStatus(value, 'PAC_SEDEX_MINI_ENVIOS');
  }

  function onChangeActiveFree(value) {
    if (value) {
      updateOrderConfigurations();
    }

    changeFreightStatus(value, 'FREE');
  }

  async function onChangeActiveMelhorEnvio(value) {
    if (value) {
      updateOrderConfigurations();
    }

    changeFreightStatus(value, 'MELHORENVIO');
  }

  async function onChangeFreightPerKm(value) {
    changeFreightStatus(value, 'FREIGHT_PER_KM');
  }

  function updateRadio(numberRadio) {
    setRadioSelected(numberRadio);

    const data = {
      receivement: numberRadio,
    };

    saveData(data);
  }

  function ProPlanPopUp(pro_modal) {
    setShowProPlanPopUp(pro_modal);
  }

  function handleSetShowAddAddress(value) {
    setShowAddAddress(value);
  }

  async function loadFlatRate() {
    try {
      setShowLoader(true);

      const region1 = await api.post('/userregion', { regionId: 1 });

      // Criando a região Norte
      await api.post('/createuseruf', {
        ufId: 1,
        userRegionid: region1.data.id,
      });
      await api.post('/createuseruf', {
        ufId: 2,
        userRegionid: region1.data.id,
      });
      await api.post('/createuseruf', {
        ufId: 3,
        userRegionid: region1.data.id,
      });
      await api.post('/createuseruf', {
        ufId: 4,
        userRegionid: region1.data.id,
      });
      await api.post('/createuseruf', {
        ufId: 5,
        userRegionid: region1.data.id,
      });
      await api.post('/createuseruf', {
        ufId: 6,
        userRegionid: region1.data.id,
      });
      await api.post('/createuseruf', {
        ufId: 7,
        userRegionid: region1.data.id,
      });

      const region2 = await api.post('/userregion', { regionId: 2 });

      // Criando a região Centro Oeste
      await api.post('/createuseruf', {
        ufId: 8,
        userRegionid: region2.data.id,
      });
      await api.post('/createuseruf', {
        ufId: 9,
        userRegionid: region2.data.id,
      });
      await api.post('/createuseruf', {
        ufId: 10,
        userRegionid: region2.data.id,
      });
      await api.post('/createuseruf', {
        ufId: 11,
        userRegionid: region2.data.id,
      });

      const region3 = await api.post('/userregion', { regionId: 3 });

      // Criando a região Nordeste
      await api.post('/createuseruf', {
        ufId: 12,
        userRegionid: region3.data.id,
      });
      await api.post('/createuseruf', {
        ufId: 13,
        userRegionid: region3.data.id,
      });
      await api.post('/createuseruf', {
        ufId: 14,
        userRegionid: region3.data.id,
      });
      await api.post('/createuseruf', {
        ufId: 15,
        userRegionid: region3.data.id,
      });
      await api.post('/createuseruf', {
        ufId: 16,
        userRegionid: region3.data.id,
      });
      await api.post('/createuseruf', {
        ufId: 17,
        userRegionid: region3.data.id,
      });
      await api.post('/createuseruf', {
        ufId: 18,
        userRegionid: region3.data.id,
      });
      await api.post('/createuseruf', {
        ufId: 19,
        userRegionid: region3.data.id,
      });
      await api.post('/createuseruf', {
        ufId: 20,
        userRegionid: region3.data.id,
      });

      const region4 = await api.post('/userregion', { regionId: 4 });

      // Criando a região Sudeste
      await api.post('/createuseruf', {
        ufId: 21,
        userRegionid: region4.data.id,
      });
      await api.post('/createuseruf', {
        ufId: 22,
        userRegionid: region4.data.id,
      });
      await api.post('/createuseruf', {
        ufId: 23,
        userRegionid: region4.data.id,
      });
      await api.post('/createuseruf', {
        ufId: 24,
        userRegionid: region4.data.id,
      });

      const region5 = await api.post('/userregion', { regionId: 5 });

      // Criando a região Sul
      await api.post('/createuseruf', {
        ufId: 25,
        userRegionid: region5.data.id,
      });
      await api.post('/createuseruf', {
        ufId: 26,
        userRegionid: region5.data.id,
      });
      await api.post('/createuseruf', {
        ufId: 27,
        userRegionid: region5.data.id,
      });

      setShowLoader(false);
      history.push('/freight/flatrate');
    } catch (err) {
      setShowLoader(false);
      toast.error('Erro ao ativar frete taxa fixa');
    }
  }

  async function createInitialRecordsFromFreightPerKm() {
    await api.post('/freight-per-km/initial-records', {
      user_id: id,
    });
  }

  const activeServicesMEFind =
    melhorEnvioList.find(mel => mel.active) !== undefined;

  const checkServicesME =
    hasCepPacSedex &&
    melhorEnvio &&
    melhorEnvio.token !== null &&
    melhorEnvio.refresh_token !== null &&
    melhorEnvioList &&
    activeServicesMEFind;

  const checkTokenAndRefreshTokenME =
    activeMelhorEnvio &&
    melhorEnvio &&
    melhorEnvio.token !== null &&
    melhorEnvio.refresh_token !== null;

  const SpanStatusComponent = () => {
    if (!activeMelhorEnvio)
      return <SpanStatus color="#AFAFAF">Desativado</SpanStatus>;
    if (checkTokenAndRefreshTokenME && checkServicesME && activeTokenME)
      return <SpanStatus color="#2B8FF5">Ativo</SpanStatus>;
    if (!checkTokenAndRefreshTokenME && activeTokenME)
      return (
        <SpanStatus color="#FFB525">
          O Melhor Envio mudou, integre novamente
        </SpanStatus>
      );
    if (!checkServicesME && activeTokenME)
      return (
        <SpanStatus color="#FFB525">Nenhuma transportadora ativa</SpanStatus>
      );
    if (!activeTokenME || !checkTokenAndRefreshTokenME)
      return (
        <SpanStatus color="#FFB525">
          Token inválido, integre novamente
        </SpanStatus>
      );
    return null;
  };

  return (
    <Container>
      <ProPlanModal showModal={showProPlanPopUp} Modal={ProPlanPopUp} />
      <ResponsiveHeader title="Frete" url="/dashboard" tab={1} />
      <Body>
        <DivText>
          <strong>Formas de entrega</strong>
          {/* <span>Selecione os tipos de entrega que você irá utilizar</span> */}
        </DivText>

        <DivCards>
          <Card>
            <div
              className="left"
              onClick={() => history.push('/freight/customfreight')}
            >
              <span>Frete personalizado</span>
              {(activeCustomFreight && (
                <SpanStatus color="#2B8FF5">Ativo</SpanStatus>
              )) || <SpanStatus color="#AFAFAF">Desativado</SpanStatus>}
              {/* s */}
            </div>

            <div className="right">
              <button
                type="button"
                onClick={() => history.push('/freight/customfreight')}
              >
                Editar
              </button>
              <label className="switch">
                <input
                  type="checkbox"
                  checked={activeCustomFreight}
                  onChange={e => onChangeActiveCustomFreight(e.target.checked)}
                />
                <span className="slider round" />
              </label>
            </div>
          </Card>

          <Card
            switchColorActive={
              ((activePac || activeSedex) && !hasCepPacSedex) ||
              ((activePac || activeSedex) && activeMelhorEnvio)
                ? '#FFB525'
                : '#2B8FF5'
            }
          >
            <div
              className="left"
              onClick={() => history.push('/freight/configurationcorreios')}
            >
              <span>Correios</span>
              {(activePac || activeSedex || activeMiniEnvios) &&
                hasCepPacSedex && (
                  <>
                    {activeMelhorEnvio ? (
                      <SpanStatus color="#FFB525">
                        Ativo no Melhor Envio
                      </SpanStatus>
                    ) : (
                      <SpanStatus color="#2B8FF5">Ativo</SpanStatus>
                    )}
                  </>
                )}

              {(activePac || activeSedex || activeMiniEnvios) &&
                !hasCepPacSedex && (
                  <SpanStatus color="#FFB525">Não configurado</SpanStatus>
                )}

              {!activePac && !activeSedex && !activeMiniEnvios && (
                <SpanStatus color="#AFAFAF">Desativado</SpanStatus>
              )}
            </div>

            <div className="right">
              <button
                type="button"
                onClick={() => history.push('/freight/configurationcorreios')}
              >
                Editar
              </button>
              <label className="switch">
                <input
                  type="checkbox"
                  checked={activePac || activeSedex || activeMiniEnvios}
                  onChange={e => onChangeActiveCorreios(e.target.checked)}
                />
                <span className="slider round" />
              </label>
            </div>
          </Card>

          <Card
            switchColorActive={
              !hasGoogleAddressee && activeFreightPerKm ? '#FFB525' : '#2B8FF5'
            }
          >
            <div
              className="left"
              onClick={
                pro >= 1
                  ? () => {
                      createInitialRecordsFromFreightPerKm();
                      history.push('/freight/configurationfreightperkm');
                    }
                  : () => setShowProPlanPopUp(true)
              }
            >
              <div>
                <span>Frete por KM de distância</span>
                {pro === 0 && (
                  <div className="label-pro">
                    <span>PRO</span>
                  </div>
                )}
              </div>
              <SpanStatus
                color={
                  !hasGoogleAddressee && activeFreightPerKm
                    ? '#FFB525'
                    : '#AFAFAF'
                }
              >
                {!hasGoogleAddressee && activeFreightPerKm
                  ? 'Não configurado'
                  : activeFreightPerKm
                  ? 'Ativado'
                  : 'Desativado'}
              </SpanStatus>
            </div>

            <div className="right">
              <button
                type="button"
                onClick={
                  pro >= 1
                    ? () => {
                        createInitialRecordsFromFreightPerKm();
                        onChangeFreightPerKm(true);
                        history.push('/freight/configurationfreightperkm');
                      }
                    : () => setShowProPlanPopUp(true)
                }
              >
                Editar
              </button>
              <label className="switch">
                <input
                  type="checkbox"
                  checked={activeFreightPerKm}
                  onChange={
                    pro >= 1
                      ? e => onChangeFreightPerKm(e.target.checked)
                      : () => setShowProPlanPopUp(true)
                  }
                />
                <span className="slider round" />
              </label>
            </div>
          </Card>

          <Card
            switchColorActive={
              checkTokenAndRefreshTokenME && checkServicesME && activeTokenME
                ? '#2B8FF5'
                : '#FFB525'
            }
          >
            <div className="left">
              <span>Melhor Envio</span>
              <SpanStatusComponent />

              {pro === 0 && (
                <LabelPro>
                  <span>PRO</span>
                </LabelPro>
              )}
            </div>

            <div className="right">
              <button
                type="button"
                onClick={
                  pro >= 1
                    ? () => history.push('/freight/melhorenviofreight')
                    : () => setShowProPlanPopUp(true)
                }
              >
                {melhorEnvio && melhorEnvio.token ? 'Editar' : 'Integrar'}
              </button>
              {melhorEnvio && melhorEnvio.token ? (
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={activeMelhorEnvio}
                    onChange={
                      pro >= 1
                        ? e => onChangeActiveMelhorEnvio(e.target.checked)
                        : () => {}
                    }
                  />
                  <span className="slider round" />
                </label>
              ) : null}
            </div>
          </Card>

          {existsRegion && existsRegion.length > 0 && (
            <Card>
              <div className="left">
                <span>Taxa fixa</span>
                {(activeFlatRate && (
                  <SpanStatus color="#2B8FF5">Ativo</SpanStatus>
                )) || <SpanStatus color="#AFAFAF">Desativado</SpanStatus>}
              </div>

              <div className="right">
                {existsRegion && existsRegion.length > 0 ? (
                  <>
                    <button
                      type="button"
                      onClick={() => history.push('/freight/flatrate')}
                    >
                      Editar
                    </button>
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={activeFlatRate}
                        onChange={e => onChangeActiveFlatRate(e.target.checked)}
                      />
                      <span className="slider round" />
                    </label>
                  </>
                ) : (
                  <button type="button" onClick={loadFlatRate}>
                    Ativar
                  </button>
                )}
              </div>
            </Card>
          )}

          {/* <Card
            switchColorActive={
              (activeSedex && !hasCepPacSedex) ||
              (activeSedex && activeMelhorEnvio)
                ? '#FFB525'
                : '#2B8FF5'
            }
          >
            <div
              className="left"
              onClick={() => history.push('/freight/configurationsedex')}
            >
              <span>Sedex - Correios</span>
              {activeSedex && hasCepPacSedex && (
                <>
                  {activeMelhorEnvio ? (
                    <SpanStatus color="#FFB525">
                      Ativo no Melhor Envio
                    </SpanStatus>
                  ) : (
                    <SpanStatus color="#2B8FF5">Ativo</SpanStatus>
                  )}
                </>
              )}

              {activeSedex && !hasCepPacSedex && (
                <SpanStatus color="#FFB525">Não configurado</SpanStatus>
              )}

              {!activeSedex && (
                <SpanStatus color="#AFAFAF">Desativado</SpanStatus>
              )}
            </div>

            <div className="right">
              <button
                type="button"
                onClick={() => history.push('/freight/configurationsedex')}
              >
                Editar
              </button>
              <label className="switch">
                <input
                  type="checkbox"
                  checked={activeSedex}
                  onChange={e => onChangeActiveSedex(e.target.checked)}
                />
                <span className="slider round" />
              </label>
            </div>
          </Card> */}

          <Card>
            <div
              className="left"
              onClick={() => history.push('/freight/freefreight')}
            >
              <span>Frete grátis</span>
              {(activeFree && (
                <SpanStatus color="#2B8FF5">Ativo</SpanStatus>
              )) || <SpanStatus color="#AFAFAF">Desativado</SpanStatus>}
            </div>

            <div className="right">
              <button
                type="button"
                onClick={() => history.push('/freight/freefreight')}
              >
                Editar
              </button>
              <label className="switch">
                <input
                  type="checkbox"
                  checked={activeFree}
                  onChange={e => onChangeActiveFree(e.target.checked)}
                />
                <span className="slider round" />
              </label>
            </div>
          </Card>

          {/* {showMoreCards && (
            <>
              <Card background="#fafafa">
                <div className="left">
                  <div>
                    <span>Melhor envio</span>
                    <LabelPro>
                      <span>PRO</span>
                    </LabelPro>
                    <LabelSoon>
                      <span>Em breve</span>
                    </LabelSoon>
                  </div>

                  <SpanStatus color="#AFAFAF">Desativado</SpanStatus>
                </div>

                <div className="right">
                  <SpanStatus color="#AFAFAF" fontSize="13px" fontWeight="500">
                    Integrar
                  </SpanStatus>
                  <label className="switch">
                    <input type="checkbox" disabled />
                    <span className="slider round" />
                  </label>
                </div>
              </Card>

              <Card background="#fafafa">
                <div className="left">
                  <div>
                    <span>Frenet</span>
                    <LabelPro>
                      <span>PRO</span>
                    </LabelPro>
                    <LabelSoon>
                      <span>Em breve</span>
                    </LabelSoon>
                  </div>

                  <SpanStatus color="#AFAFAF">Desativado</SpanStatus>
                </div>

                <div className="right">
                  <SpanStatus color="#AFAFAF" fontSize="13px" fontWeight="500">
                    Integrar
                  </SpanStatus>
                  <label className="switch">
                    <input type="checkbox" disabled />
                    <span className="slider round" />
                  </label>
                </div>
              </Card>
            </>
          )} */}

          {/* {(showMoreCards && (
            <button type="button" onClick={() => setShowMoreCards(false)}>
              Menos opções
            </button>
          )) || (
            <button type="button" onClick={() => setShowMoreCards(true)}>
              Mais opções
            </button>
          )} */}
        </DivCards>

        <DivMethod>
          <span>Como seus clientes podem receber seus produtos?</span>

          <Radio>
            <div
              className={radioSelected === 0 && 'selected'}
              onClick={() => updateRadio(0)}
            >
              <div />
            </div>
            <span>Somente entrega</span>
          </Radio>

          <Radio>
            <div
              className={radioSelected === 1 && 'selected'}
              onClick={() => updateRadio(1)}
            >
              <div />
            </div>
            <span>Entrega e retirada no local</span>
          </Radio>

          <Radio>
            <div
              className={radioSelected === 2 && 'selected'}
              onClick={() => updateRadio(2)}
            >
              <div />
            </div>
            <span>Somente retirada no local</span>
          </Radio>
        </DivMethod>

        <DivCep>
          <strong>Endereço da loja</strong>
          <span>Endereço de origem das entregas e/ou retirada</span>

          <div>
            <span>{cep}</span>
            {address && (
              <span>
                {address}, {number}
              </span>
            )}
            <span>
              {district && `${district},`} {city} - {uf}
            </span>
          </div>

          <button type="button" onClick={() => setShowAddAddress(true)}>
            Alterar endereço
          </button>
        </DivCep>
      </Body>

      {showLoader && (
        <DivLoader>
          <img src={loader} alt="loader" />
          <span>Aguarde alguns segundos...</span>
        </DivLoader>
      )}

      {showInitialLoader && (
        <DivInitialLoader sizeImg="25px">
          <LoaderComponent size={32} white={false} />
        </DivInitialLoader>
      )}

      {showAddAddress && (
        <AddAddress
          handleSetShowAddAddress={handleSetShowAddAddress}
          hasFunction={false}
          isModal
        />
      )}
    </Container>
  );
}

export default Configuration;
