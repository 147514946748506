/* eslint-disable react/jsx-no-bind */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { toast } from 'react-toastify';

import { isValidCnpj, isValidCpf } from '~/services/functions';
import api from '../../../services/api';

import Loader from '../../../assets/icons/loader-grey.gif';

import {
  Container,
  All,
  Div,
  DivLostPass,
  Input,
  Button,
  ErrorMessage,
} from './styles';

import Header from './Header';

import PasswordModal from '../../../components/PasswordModal';

import { updateProfileRequest } from '../../../store/modules/user/actions';

export default function MyAccount() {
  const dispatch = useDispatch();
  const profile = useSelector(state => state.user.profile);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [oldPass, setOldPass] = useState();
  const [newPass, setNewPass] = useState();
  const [confirmPass, setConfirmPass] = useState();
  // const [name, setName] = useState();
  const [user, setUser] = useState();
  const [phone, setPhone] = useState();
  const [cpf, setCpf] = useState();
  const [cnpj, setCnpj] = useState();
  const [cpfError, setCpfError] = useState('');
  const [cnpjError, setCnpjError] = useState('');

  // const [link, setLink] = useState();
  const [email, setEmail] = useState();

  useEffect(() => {
    // setName(profile && profile.name);
    setEmail(profile && profile.email);
    setUser(profile && profile.username);
    setPhone(profile && profile.phone);
    // setLink(profile && profile.link);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  useEffect(() => {
    async function getUserConfigurations() {
      await api.get('/userconfigurations').then(res => {
        setCnpj(res.data.cnpj);
        setCpf(res.data.cpf);
      });
    }

    getUserConfigurations();
  }, []);

  function modal(_modal) {
    setShowModal(_modal);
  }

  async function handleChangeData() {
    try {
      if (!email) {
        toast.error('Preencha o e-mail');
        return;
      }

      if (email !== profile.email) {
        setShowModal(true);
        return;
      }

      const data = {
        name: profile && profile.name,
        user,
        email: profile && profile.email,
        phone,
      };
      dispatch(updateProfileRequest(data));
    } catch (err) {
      toast.error('Erro ao processar requisição');
    }
  }

  async function handleChangePassword() {
    try {
      if (!oldPass || !newPass || !confirmPass) {
        toast.error('Preencha todos os campos de senha');
        return;
      }
      if (oldPass === newPass) {
        toast.error('Sua nova senha não pode ser igual a anterior');
        return;
      }
      if (newPass !== confirmPass) {
        toast.error('Nova senha e confirmação de senha não coincidem');
        return;
      }

      const response = await api.put('/changepassword', {
        user: profile && profile.user,
        name: profile && profile.name,
        email: profile && profile.email,
        oldPassword: oldPass,
        password: newPass,
        confirmPassword: confirmPass,
      });

      if (response.status === 200) {
        toast.success('Senha atualizada!');

        setOldPass('');
        setNewPass('');
        setConfirmPass('');
      }
    } catch (err) {
      toast.error('Erro ao processar requisição');
    }
  }

  async function handleLostPassword() {
    try {
      setLoading(true);

      if (profile && profile.email) {
        const data = {
          email: profile.email,
        };
        await api.post('/forgotpassword', data);

        setTimeout(() => {
          setLoading(false);
          toast.success('Verifique seu e-mail');
        }, 3000);
      }
    } catch (err) {
      toast.error(err.response.data.error);
      setLoading(false);
    }
  }

  async function handleChangeBusinessData() {
    const data = {};
    let hasErrorCpf = false;
    let hasErrorCnpj = false;

    if (cpf !== '' && cpf !== undefined && cpf !== undefined) {
      const isValidCpfVerification = isValidCpf(cpf);
      if (isValidCpfVerification) {
        setCpfError('');
        hasErrorCpf = false;

        data.cpf = cpf;
      } else {
        hasErrorCpf = true;
        setCpfError('CPF inválido');
      }
    }
    if (cnpj !== '' && cnpj !== undefined && cnpj !== null) {
      const isValidCnpjVerification = isValidCnpj(cnpj);
      if (isValidCnpjVerification) {
        setCnpjError('');
        hasErrorCnpj = false;

        data.cnpj = cnpj;
      } else {
        hasErrorCnpj = true;
        setCnpjError('CNPJ inválido');
      }
    }
    if (!hasErrorCnpj && !hasErrorCpf) {
      if (!data.cnpj) data.cnpj = '';
      if (!data.cpf) data.cpf = '';

      await api
        .put('/userconfigurations', data)
        .then(() => {
          return toast.success('Informação salva!');
        })
        .catch(() => {
          return toast.error('Erro');
        });
    }
  }

  return (
    <>
      <PasswordModal
        showModal={showModal}
        Modal={modal}
        // name={name}
        user={user}
        email={profile.email}
        newEmail={email}
      />
      <All>
        <Header title="Minha conta" />
        <Container>
          <span>Dados cadastrais</span>
          <Div>
            {/* <span>Nome</span>
            <Input
              type="text"
              placeholder="Nome"
              value={name}
              onChange={e => setName(e.target.value)}
            /> */}

            <span>E-mail</span>
            <Input
              type="text"
              placeholder="E-mail"
              value={email}
              onChange={e => setEmail(e.target.value)}
            />

            {/* <span>Telefone</span>
            <Input
              type="text"
              placeholder="Telefone"
              value={phone}
              onChange={e => setPhone(e.target.value)}
            /> */}

            {/* <span>Link customizado</span>
            <Input
              type="text"
              placeholder="Link customizado"
              value={link}
              onChange={e => setLink(e.target.value)}
            /> */}

            <Button onClick={handleChangeData}>Salvar alterações</Button>
          </Div>
          <span>Dados do negócio</span>
          <Div>
            <span>CPF do responsável</span>
            <Input
              type="text"
              placeholder="CPF"
              value={cpf}
              onChange={e => setCpf(e.target.value)}
            />
            {cpfError && <ErrorMessage>{cpfError}</ErrorMessage>}

            <span>CNPJ</span>
            <Input
              type="text"
              placeholder="CNPJ"
              value={cnpj}
              onChange={e => setCnpj(e.target.value)}
            />
            {cnpjError && <ErrorMessage>{cnpjError}</ErrorMessage>}

            <Button onClick={handleChangeBusinessData}>
              Salvar alterações
            </Button>
          </Div>
          <span>Alterar senha</span>
          <Div>
            <span>Senha atual</span>
            <Input
              type="text"
              placeholder="Senha atual"
              value={oldPass}
              onChange={e => setOldPass(e.target.value)}
            />

            <span>Nova senha</span>
            <Input
              type="text"
              placeholder="Nova senha"
              value={newPass}
              onChange={e => setNewPass(e.target.value)}
            />

            <span>Confirmar nova senha</span>
            <Input
              type="text"
              placeholder="Confirmar nova senha"
              value={confirmPass}
              onChange={e => setConfirmPass(e.target.value)}
            />
            <Button onClick={() => handleChangePassword()}>
              Salvar alterações
            </Button>
          </Div>
          <DivLostPass>
            <div>
              <span>Perdeu a senha?</span>
              <p>
                Ao clicar no botão, uma nova senha será criada e enviada para o
                seu e-mail
              </p>
            </div>
            <div>
              <Button
                width="100%"
                background="#F4F2F7"
                color="#000"
                hover="#F4F2F7"
                type="button"
                onClick={() => handleLostPassword()}
                disabled={loading}
              >
                {loading ? <img src={Loader} alt="loader" /> : 'Perdi a senha'}
              </Button>
            </div>
          </DivLostPass>
          {/* <DivLostPass>
            <span>Zona de perigo</span>
            <p>Ao apagar sua conta, você perderá todas as suas informações</p>
            <a href="#">Excluir conta</a>
          </DivLostPass> */}
        </Container>
      </All>
    </>
  );
}
