import styled from 'styled-components';
// import { device } from '../../../config/device';

export const Container = styled.div`
  margin-top: 55px;
  height: 100%;
  width: 500px;
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 0 20px;

  h1 {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  > span {
    margin-top: 25px;
    font-size: 15px !important;
  }

  span {
    font-size: 13px;
    margin-bottom: 10px;
    font-weight: 500;
  }

  p {
    font-size: 10px;
    font-weight: 300;
    margin-bottom: 10px;
  }
`;

export const ErrorMessage = styled.span`
  margin-top: 5px;
  margin-bottom: 5px;
  color: #ff6c4b;
`;

export const All = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const Div = styled.div`
  width: 100%;
  height: ${props => props.height || 'auto'};
  border: 0.5px solid #d8dee5;
  padding: 30px 20px;
  display: flex;
  flex-direction: ${props => props.direction || 'column'};
  border-radius: 15px;
  margin-bottom: 10px;

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
  }
`;

export const DivLostPass = styled.div`
  margin-top: 25px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }

  span {
    font-size: 15px;
    color: #000;
  }

  p {
    font-size: 13px;
    color: #a5a9b1;
  }

  a {
    color: #ff3642;
    font-size: 14px;
  }

  img {
    width: 25px;
    height: 25px;
  }
`;

export const Input = styled.input`
  border: 1px solid #eee;
  background: #f5f5f7;
  padding: 10px 15px;
  border-radius: 7px;
  font-size: 13px;
  width: 100% !important;
  max-height: 35px;
  margin-bottom: 10px;

  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-size: 13px;
    color: #c6cad2;
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    font-size: 13px;
    color: #c6cad2;
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    font-size: 13px;
    color: #c6cad2;
  }
  :-moz-placeholder {
    /* Firefox 18- */
    font-size: 13px;
    color: #c6cad2;
  }
`;

export const Button = styled.button`
  background: ${props => props.background || 'rgb(15, 97, 241, 0.87)'};
  border: 0;
  padding: 0 15px;
  border-radius: 7px;
  font-size: 13px;
  color: ${props => props.color || '#fff'};
  min-height: 40px;
  width: ${props => props.width || '100%'};
  margin-bottom: 5px;
  margin-top: 5px;

  display: flex;
  justify-content: center;
  align-items: center;
  transition: background 0.2s;

  &:hover {
    background: ${props => props.hover || 'rgb(15, 97, 241, 0.87)'};
  }

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.6;
  }
`;
