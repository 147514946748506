/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
// import { useSelector } from 'react-redux';

import { toast } from 'react-toastify';
import api from '~/services/api';

import LabelDomain from '~/components/LabelDomain';
import SquareIcon from '../../../assets/icons/personalize/grid/format/square';
import RectangularIcon from '../../../assets/icons/personalize/grid/format/rectangular';
import FullImageIcon from '../../../assets/icons/personalize/grid/format/full';

import StraightBorderIcon from '../../../assets/icons/personalize/grid/borderstyles/straightborder';
import RoundedBorderIcon from '../../../assets/icons/personalize/grid/borderstyles/roundedborder';

// import WithBorderIcon from '../../../assets/icons/personalize/grid/borders/withborder';
// import NoBorderIcon from '../../../assets/icons/personalize/grid/borders/noborder';

import TextAlignLeftIcon from '../../../assets/icons/personalize/grid/textalign/left';
import TextAlignCenterIcon from '../../../assets/icons/personalize/grid/textalign/center';

import DownArrowIcon from '../../../assets/icons/personalize/downarrow.svg';
import UpArrowIcon from '../../../assets/icons/personalize/uparrow.svg';

import ResponsiveHeader from '../../../components/HeaderMobile';
import ProPlanModal from '../../../components/Popups/ProPlan';

// import loader from '../../../assets/icons/loader-grey.gif';

import {
  Container,
  DivBody,
  DivItem,
  DivContentItem,
  Div,
  DivTitle,
  // DivButtons,
  // DivColorButton,
  // Text,
  DivFonts,
  Label,
  BuyButtonStyle,
  Font,
  DivStyleButtons,
  FontWeight,
  DivImages,
  InputModel,
} from './styles';
import ColorButton from '../Personalize/ColorButton';

function PersonalizeTheme() {
  const [pro, setPro] = useState();
  // const [userTheme, setUserTheme] = useState();
  const [userThemeId, setUserThemeId] = useState(0);

  // const [loading, setLoading] = useState(false);
  // const [primaryColor, setPrimaryColor] = useState();

  const [showProPlanPopUp, setShowProPlanPopUp] = useState(false);

  const [backgroundColor, setBackgroundColor] = useState();
  const [textColor, setTextColor] = useState();

  const [selectedFontFamily, setSelectedFontFamily] = useState();
  const [highlightedTextColor, setHighlightedTextColor] = useState();
  const [titleColor, setTitleColor] = useState();
  // const [paragraphColor, setParagraphColor] = useState();
  const [selectedTitleFontWeight, setSelectedTitleFontWeight] = useState();
  const [
    selectedFontFamilyParagraph,
    setSelectedFontFamilyParagraph,
  ] = useState();

  const [selectedFormatImage, setSelectedFormatImage] = useState();
  const [selectedBorderStyleImage, setSelectedBorderStyleImage] = useState();
  const [selectedTextAlignImage, setSelectedTextAlignImage] = useState();
  // const [selectedBorderImage, setSelectedBorderImage] = useState();

  const [backgroundButtonColor, setBackgroundButtonColor] = useState();
  const [textButtonColor, setTextButtonColor] = useState();
  const [selectInputStyle, setSelectInputStyle] = useState();
  const [selectedBuyButtonStyle, setSelectedBuyButtonStyle] = useState();
  const [uppercaseTitle, setUppercaseTitle] = useState();
  // const [cardShadow, setCardShadow] = useState();

  const [showHeader, setShowHeader] = useState(true);
  const [showFonts, setShowFonts] = useState(false);
  const [showProductList, setShowProductList] = useState(false);
  const [showButtons, setShowButtons] = useState(false);

  // const { primary_color } = useSelector(state => state.user.profile);

  // função criada para fazer o merge do tema com as variações do usuário
  function mergeObjects(obj1, obj2) {
    const merged = {};
    for (const prop in obj1) {
      if (prop in obj2) {
        if (typeof obj1[prop] === 'object' && typeof obj2[prop] === 'object') {
          merged[prop] = mergeObjects(obj1[prop], obj2[prop]);
        } else {
          merged[prop] = obj2[prop];
        }
      } else {
        merged[prop] = obj1[prop];
      }
    }
    for (const prop in obj2) {
      if (!(prop in obj1)) {
        merged[prop] = obj2[prop];
      }
    }
    return merged;
  }

  useEffect(() => {
    async function load() {
      try {
        await api.get('/proplan').then(res => setPro(res.data));

        await api.get('/userthemeconfigurations').then(res => {
          if (res && res.data) {
            const obj = mergeObjects(
              res.data.theme.config,
              res.data.variations
            );
            // setSelectedThemeId(res.data.theme_id);
            // setUserTheme(obj);
            setUserThemeId(res.data.id);

            // header
            setBackgroundColor(obj.header.background_color);
            setTextColor(obj.header.text_color);

            // fonts
            setSelectedFontFamily(obj.text.title_font_family);
            setHighlightedTextColor(obj.text.highlighted_text_color);
            setTitleColor(obj.text.title_color);
            setSelectedTitleFontWeight(obj.text.title_font_weight);
            setSelectedFontFamilyParagraph(obj.text.paragraph_font_family);

            // grid
            setSelectedFormatImage(obj.product.grid_style);
            setSelectedBorderStyleImage(obj.product.grid_image_border_style);
            setSelectedTextAlignImage(obj.product.grid_text_alignment);
            setSelectedBuyButtonStyle(obj.product.grid_buy_button_style);
            // setSelectedBorderImage(obj.product.grid_border_style);
            setUppercaseTitle(obj.product.uppercase_title);
            // setCardShadow(obj.product.card_shadow);

            // buttons
            setBackgroundButtonColor(obj.button.background_color);
            setTextButtonColor(obj.button.text_color);
            setSelectInputStyle(obj.button.border_style);
          }
        });

        // setPrimaryColor(primary_color);
      } finally {
        // setLoading(false);
      }
    }

    load();
  }, []);

  async function handleSubmit(obj) {
    // header: {
    //   background_color: backgroundColor,
    //   text_color: textColor,
    // },
    try {
      await api.put(`/userconfigurations/themes/${userThemeId}`, {
        variations: obj,
      });
    } catch (err) {
      toast.error('Ocorreu um erro ao alterar o header');
    }
  }

  function ProPlanPopUp(pro_modal) {
    setShowProPlanPopUp(pro_modal);
  }

  return (
    <Container>
      <ResponsiveHeader title="Personalizar tema" url="/personalize" tab={1} />

      <DivBody>
        <DivItem first onClick={() => setShowHeader(!showHeader)}>
          <h2>Cabeçalho</h2>

          <div>
            <img src={showHeader ? UpArrowIcon : DownArrowIcon} alt="arrow" />
          </div>
        </DivItem>

        {showHeader && (
          <DivContentItem>
            <Div>
              <DivTitle>
                <strong>Cor de fundo do cabeçalho</strong>

                {pro === 0 && <Label>PRO</Label>}
              </DivTitle>

              <ColorButton
                handleColor={
                  pro === 0
                    ? () => setShowProPlanPopUp(true)
                    : color => {
                        setBackgroundColor(color);
                      }
                }
                type="primary"
                colors={backgroundColor || '#000000'}
                top="auto"
                left="15px"
                handleSubmitTheme={
                  pro === 0
                    ? () => setShowProPlanPopUp(true)
                    : color =>
                        handleSubmit({
                          header: { background_color: color },
                        })
                }
              />

              {/* <DivButtons>
                <div>
                  <DivColorButton
                    background="#ffffff"
                    borderColor="#D8DEE5"
                    active={backgroundColor === '#ffffff'}
                    onClick={
                      pro === 0
                        ? () => setShowProPlanPopUp(true)
                        : () => {
                            setBackgroundColor('#ffffff');
                            handleSubmit({
                              header: { background_color: '#ffffff' },
                            });
                          }
                    }
                  >
                    <div />
                  </DivColorButton>

                  <Text>Branco</Text>
                </div>

                <div>
                  <DivColorButton
                    background="#000000"
                    active={backgroundColor === '#000000'}
                    onClick={
                      pro === 0
                        ? () => setShowProPlanPopUp(true)
                        : () => {
                            setBackgroundColor('#000000');
                            handleSubmit({
                              header: {
                                background_color: '#000000',
                                text_color: '#ffffff',
                              },
                            });
                          }
                    }
                  >
                    <div />
                  </DivColorButton>

                  <Text>Preto</Text>
                </div>

                <div>
                  <DivColorButton
                    background={primaryColor}
                    active={backgroundColor === primaryColor}
                    onClick={
                      pro === 0
                        ? () => setShowProPlanPopUp(true)
                        : () => {
                            setBackgroundColor(primaryColor);
                            handleSubmit({
                              header: {
                                background_color: primaryColor,
                                text_color: '#ffffff',
                              },
                            });
                          }
                    }
                  >
                    <div />
                  </DivColorButton>

                  <Text>Cor primária</Text>
                </div>
              </DivButtons> */}
            </Div>

            <Div>
              <DivTitle>
                <strong>Cor dos textos do cabeçalho</strong>

                {pro === 0 && <Label>PRO</Label>}
              </DivTitle>

              <ColorButton
                handleColor={
                  pro === 0
                    ? () => setShowProPlanPopUp(true)
                    : color => {
                        setTextColor(color);
                      }
                }
                type="primary"
                colors={textColor || '#000000'}
                top="auto"
                left="15px"
                handleSubmitTheme={
                  pro === 0
                    ? () => setShowProPlanPopUp(true)
                    : color =>
                        handleSubmit({
                          header: { text_color: color },
                        })
                }
              />
            </Div>
          </DivContentItem>
        )}

        <DivItem onClick={() => setShowFonts(!showFonts)}>
          <h2>Fontes</h2>

          <div>
            <img src={showFonts ? UpArrowIcon : DownArrowIcon} alt="arrow" />
          </div>
        </DivItem>

        {showFonts && (
          <DivContentItem>
            <Div>
              <DivTitle>
                <strong>Fonte dos títulos</strong>

                {pro === 0 && <Label>PRO</Label>}
              </DivTitle>

              <DivFonts>
                <Font
                  active={selectedFontFamily === 'Inter'}
                  onClick={
                    pro === 0
                      ? () => setShowProPlanPopUp(true)
                      : () => {
                          setSelectedFontFamily('Inter');
                          handleSubmit({
                            text: {
                              title_font_family: 'Inter',
                              title_font_weight: '600',
                            },
                          });
                        }
                  }
                  fontFamily="Inter"
                  fontWeight="600"
                >
                  <div>Text</div>
                  <span>Inter</span>
                </Font>

                <Font
                  active={selectedFontFamily === 'Playfair Display'}
                  onClick={
                    pro === 0
                      ? () => setShowProPlanPopUp(true)
                      : () => {
                          setSelectedFontFamily('Playfair Display');
                          handleSubmit({
                            text: {
                              title_font_family: 'Playfair Display',
                              title_font_weight: '700',
                            },
                          });
                        }
                  }
                  fontFamily="Playfair Display"
                  fontWeight="700"
                >
                  <div>Text</div>
                  <span>Playfair Display</span>
                </Font>

                <Font
                  active={selectedFontFamily === 'Rubik'}
                  onClick={
                    pro === 0
                      ? () => setShowProPlanPopUp(true)
                      : () => {
                          setSelectedFontFamily('Rubik');
                          handleSubmit({
                            text: {
                              title_font_family: 'Rubik',
                              title_font_weight: '600',
                            },
                          });
                        }
                  }
                  fontFamily="Rubik"
                  fontWeight="600"
                >
                  <div>Text</div>
                  <span>Rubik</span>
                </Font>

                <Font
                  active={selectedFontFamily === 'Source Serif 4'}
                  onClick={
                    pro === 0
                      ? () => setShowProPlanPopUp(true)
                      : () => {
                          setSelectedFontFamily('Source Serif 4');
                          handleSubmit({
                            text: {
                              title_font_family: 'Source Serif 4',
                              title_font_weight: '600',
                            },
                          });
                        }
                  }
                  fontFamily="'Source Serif 4'"
                  fontWeight="600"
                >
                  <div>Text</div>
                  <span>Source Serif 4</span>
                </Font>

                <Font
                  active={selectedFontFamily === 'Montserrat'}
                  onClick={
                    pro === 0
                      ? () => setShowProPlanPopUp(true)
                      : () => {
                          setSelectedFontFamily('Montserrat');
                          handleSubmit({
                            text: {
                              title_font_family: 'Montserrat',
                              title_font_weight: '600',
                            },
                          });
                        }
                  }
                  fontFamily="Montserrat"
                  fontWeight="600"
                >
                  <div>Text</div>
                  <span>Montserrat</span>
                </Font>

                <Font
                  active={selectedFontFamily === 'Poppins'}
                  onClick={
                    pro === 0
                      ? () => setShowProPlanPopUp(true)
                      : () => {
                          setSelectedFontFamily('Poppins');
                          handleSubmit({
                            text: {
                              title_font_family: 'Poppins',
                              title_font_weight: '600',
                            },
                          });
                        }
                  }
                  fontFamily="Poppins"
                  fontWeight="600"
                >
                  <div>Text</div>
                  <span>Poppins</span>
                </Font>

                <Font
                  active={selectedFontFamily === 'Taviraj'}
                  onClick={
                    pro === 0
                      ? () => setShowProPlanPopUp(true)
                      : () => {
                          setSelectedFontFamily('Taviraj');
                          handleSubmit({
                            text: {
                              title_font_family: 'Taviraj',
                              title_font_weight: '500',
                            },
                          });
                        }
                  }
                  fontFamily="Taviraj"
                  fontWeight="500"
                >
                  <div>Text</div>
                  <span>Taviraj</span>
                </Font>

                <Font
                  active={selectedFontFamily === 'IBM Plex Mono'}
                  onClick={
                    pro === 0
                      ? () => setShowProPlanPopUp(true)
                      : () => {
                          setSelectedFontFamily('IBM Plex Mono');
                          handleSubmit({
                            text: {
                              title_font_family: 'IBM Plex Mono',
                              title_font_weight: '600',
                            },
                          });
                        }
                  }
                  fontFamily="IBM Plex Mono"
                  fontWeight="600"
                >
                  <div>Text</div>
                  <span>IBM Plex Mono</span>
                </Font>

                <Font
                  active={selectedFontFamily === 'Exo 2'}
                  onClick={
                    pro === 0
                      ? () => setShowProPlanPopUp(true)
                      : () => {
                          setSelectedFontFamily('Exo 2');
                          handleSubmit({
                            text: {
                              title_font_family: 'Exo 2',
                              title_font_weight: '700',
                            },
                          });
                        }
                  }
                  fontFamily="'Exo 2'"
                  fontWeight="700"
                >
                  <div>Text</div>
                  <span>Exo 2</span>
                </Font>

                <Font
                  active={selectedFontFamily === 'Fredoka'}
                  onClick={
                    pro === 0
                      ? () => setShowProPlanPopUp(true)
                      : () => {
                          setSelectedFontFamily('Fredoka');
                          handleSubmit({
                            text: {
                              title_font_family: 'Fredoka',
                              title_font_weight: '600',
                            },
                          });
                        }
                  }
                  fontFamily="Fredoka"
                  fontWeight="600"
                >
                  <div>Text</div>
                  <span>Fredoka</span>
                </Font>

                <Font
                  active={selectedFontFamily === 'Kaushan Script'}
                  onClick={
                    pro === 0
                      ? () => setShowProPlanPopUp(true)
                      : () => {
                          setSelectedFontFamily('Kaushan Script');
                          handleSubmit({
                            text: {
                              title_font_family: 'Kaushan Script',
                              title_font_weight: '400',
                            },
                          });
                        }
                  }
                  fontFamily="Kaushan Script"
                  fontWeight="400"
                >
                  <div>Text</div>
                  <span>Kaushan Script</span>
                </Font>
              </DivFonts>
            </Div>

            <Div>
              <DivTitle>
                <strong>Peso dos títulos</strong>

                {pro === 0 && <Label>PRO</Label>}
              </DivTitle>

              <DivFonts>
                <FontWeight
                  left
                  active={selectedTitleFontWeight === '500'}
                  selectedFontWeigth={selectedTitleFontWeight}
                  onClick={
                    pro === 0
                      ? () => setShowProPlanPopUp(true)
                      : () => {
                          setSelectedTitleFontWeight('500');
                          handleSubmit({
                            text: { title_font_weight: '500' },
                          });
                        }
                  }
                >
                  Fina
                </FontWeight>

                <FontWeight
                  middle
                  active={selectedTitleFontWeight === '600'}
                  selectedFontWeigth={selectedTitleFontWeight}
                  onClick={
                    pro === 0
                      ? () => setShowProPlanPopUp(true)
                      : () => {
                          setSelectedTitleFontWeight('600');
                          handleSubmit({
                            text: { title_font_weight: '600' },
                          });
                        }
                  }
                >
                  Média
                </FontWeight>

                <FontWeight
                  right
                  active={selectedTitleFontWeight === '700'}
                  selectedFontWeigth={selectedTitleFontWeight}
                  onClick={
                    pro === 0
                      ? () => setShowProPlanPopUp(true)
                      : () => {
                          setSelectedTitleFontWeight('700');
                          handleSubmit({
                            text: { title_font_weight: '700' },
                          });
                        }
                  }
                >
                  Grossa
                </FontWeight>
              </DivFonts>
            </Div>

            <Div>
              <DivTitle>
                <strong>Fonte dos parágrafos</strong>

                {pro === 0 && <Label>PRO</Label>}
              </DivTitle>

              <DivFonts>
                <Font
                  active={selectedFontFamilyParagraph === 'Inter'}
                  onClick={
                    pro === 0
                      ? () => setShowProPlanPopUp(true)
                      : () => {
                          setSelectedFontFamilyParagraph('Inter');
                          handleSubmit({
                            text: { paragraph_font_family: 'Inter' },
                          });
                        }
                  }
                  fontFamily="Inter"
                >
                  <div>Text</div>
                  <span>Inter</span>
                </Font>

                <Font
                  active={selectedFontFamilyParagraph === 'DM Sans'}
                  onClick={
                    pro === 0
                      ? () => setShowProPlanPopUp(true)
                      : () => {
                          setSelectedFontFamilyParagraph('DM Sans');
                          handleSubmit({
                            text: { paragraph_font_family: 'DM Sans' },
                          });
                        }
                  }
                  fontFamily="DM Sans"
                >
                  <div>Text</div>
                  <span>DM Sans</span>
                </Font>

                <Font
                  active={selectedFontFamilyParagraph === 'Roboto'}
                  onClick={
                    pro === 0
                      ? () => setShowProPlanPopUp(true)
                      : () => {
                          setSelectedFontFamilyParagraph('Roboto');
                          handleSubmit({
                            text: { paragraph_font_family: 'Roboto' },
                          });
                        }
                  }
                  fontFamily="Roboto"
                >
                  <div>Text</div>
                  <span>Roboto</span>
                </Font>

                <Font
                  active={selectedFontFamilyParagraph === 'Rubik'}
                  onClick={
                    pro === 0
                      ? () => setShowProPlanPopUp(true)
                      : () => {
                          setSelectedFontFamilyParagraph('Rubik');
                          handleSubmit({
                            text: { paragraph_font_family: 'Rubik' },
                          });
                        }
                  }
                  fontFamily="Rubik"
                >
                  <div>Text</div>
                  <span>Rubik</span>
                </Font>

                <Font
                  active={selectedFontFamilyParagraph === 'Montserrat'}
                  onClick={
                    pro === 0
                      ? () => setShowProPlanPopUp(true)
                      : () => {
                          setSelectedFontFamilyParagraph('Montserrat');
                          handleSubmit({
                            text: { paragraph_font_family: 'Montserrat' },
                          });
                        }
                  }
                  fontFamily="Montserrat"
                >
                  <div>Text</div>
                  <span>Montserrat</span>
                </Font>

                <Font
                  active={selectedFontFamilyParagraph === 'Taviraj'}
                  onClick={
                    pro === 0
                      ? () => setShowProPlanPopUp(true)
                      : () => {
                          setSelectedFontFamilyParagraph('Taviraj');
                          handleSubmit({
                            text: { paragraph_font_family: 'Taviraj' },
                          });
                        }
                  }
                  fontFamily="Taviraj"
                >
                  <div>Text</div>
                  <span>Taviraj</span>
                </Font>

                <Font
                  active={selectedFontFamilyParagraph === 'Playfair Display'}
                  onClick={
                    pro === 0
                      ? () => setShowProPlanPopUp(true)
                      : () => {
                          setSelectedFontFamilyParagraph('Playfair Display');
                          handleSubmit({
                            text: { paragraph_font_family: 'Playfair Display' },
                          });
                        }
                  }
                  fontFamily="Playfair Display"
                >
                  <div>Text</div>
                  <span>Playfair Display</span>
                </Font>

                <Font
                  active={selectedFontFamilyParagraph === 'Exo 2'}
                  onClick={
                    pro === 0
                      ? () => setShowProPlanPopUp(true)
                      : () => {
                          setSelectedFontFamilyParagraph('Exo 2');
                          handleSubmit({
                            text: { paragraph_font_family: 'Exo 2' },
                          });
                        }
                  }
                  fontFamily="'Exo 2'"
                >
                  <div>Text</div>
                  <span>Exo 2</span>
                </Font>

                <Font
                  active={selectedFontFamilyParagraph === 'Crimson'}
                  onClick={
                    pro === 0
                      ? () => setShowProPlanPopUp(true)
                      : () => {
                          setSelectedFontFamilyParagraph('Crimson');
                          handleSubmit({
                            text: { paragraph_font_family: 'Crimson' },
                          });
                        }
                  }
                  fontFamily="Crimson"
                >
                  <div>Text</div>
                  <span>Crimson</span>
                </Font>

                <Font
                  active={selectedFontFamilyParagraph === 'IBM Plex Mono'}
                  onClick={
                    pro === 0
                      ? () => setShowProPlanPopUp(true)
                      : () => {
                          setSelectedFontFamilyParagraph('IBM Plex Mono');
                          handleSubmit({
                            text: { paragraph_font_family: 'IBM Plex Mono' },
                          });
                        }
                  }
                  fontFamily="IBM Plex Mono"
                >
                  <div>Text</div>
                  <span>IBM Plex Mono</span>
                </Font>
              </DivFonts>
            </Div>
            <Div>
              <DivTitle>
                <strong>Cor dos títulos</strong>

                {pro === 0 && <Label>PRO</Label>}
              </DivTitle>

              <ColorButton
                handleColor={
                  pro === 0
                    ? () => setShowProPlanPopUp(true)
                    : color => setTitleColor(color)
                }
                type="primary"
                colors={titleColor}
                top="auto"
                left="15px"
                handleSubmitTheme={
                  pro === 0
                    ? () => setShowProPlanPopUp(true)
                    : color =>
                        handleSubmit({
                          text: { title_color: color },
                        })
                }
              />
            </Div>

            {/* <Div>
          <strong>Cor dos parágrafos</strong>
          <ColorButton
            handleColor={color => setParagraphColor(color)}
            type="primary"
            colors={paragraphColor}
            top="auto"
            left="15px"
            handleSubmitTheme={color =>
              handleSubmitHeader({
                text: { paragraph_color: color },
              })
            }
          />
          </Div> */}

            <Div>
              <DivTitle>
                <strong>Cor dos textos em destaque</strong>

                {pro === 0 && <Label>PRO</Label>}
              </DivTitle>

              <ColorButton
                handleColor={
                  pro === 0
                    ? () => setShowProPlanPopUp(true)
                    : color => setHighlightedTextColor(color)
                }
                type="primary"
                colors={highlightedTextColor}
                top="auto"
                left="15px"
                handleSubmitTheme={
                  pro === 0
                    ? () => setShowProPlanPopUp(true)
                    : color =>
                        handleSubmit({
                          text: { highlighted_text_color: color },
                        })
                }
              />
            </Div>
          </DivContentItem>
        )}

        <DivItem onClick={() => setShowProductList(!showProductList)}>
          <h2>Lista de produtos</h2>

          <div>
            <img
              src={showProductList ? UpArrowIcon : DownArrowIcon}
              alt="arrow"
            />
          </div>
        </DivItem>

        {showProductList && (
          <DivContentItem>
            <Div>
              <DivTitle>
                <strong>Formato</strong>

                {pro === 0 && <Label>PRO</Label>}
              </DivTitle>

              <DivImages>
                <div
                  onClick={
                    pro === 0
                      ? () => setShowProPlanPopUp(true)
                      : () => {
                          setSelectedFormatImage('square');
                          handleSubmit({
                            product: { grid_style: 'square' },
                          });
                        }
                  }
                >
                  <div>
                    <SquareIcon selected={selectedFormatImage === 'square'} />
                  </div>

                  <span
                    className={
                      selectedFormatImage === 'square' ? 'selected' : 'none'
                    }
                  >
                    Quadrado
                  </span>
                </div>

                <div
                  onClick={
                    pro === 0
                      ? () => setShowProPlanPopUp(true)
                      : () => {
                          setSelectedFormatImage('rectangular');
                          handleSubmit({
                            product: { grid_style: 'rectangular' },
                          });
                        }
                  }
                >
                  <div>
                    <RectangularIcon
                      selected={selectedFormatImage === 'rectangular'}
                    />
                  </div>

                  <span
                    className={
                      selectedFormatImage === 'rectangular'
                        ? 'selected'
                        : 'none'
                    }
                  >
                    Retângular
                  </span>
                </div>

                <div
                  onClick={
                    pro === 0
                      ? () => setShowProPlanPopUp(true)
                      : () => {
                          setSelectedFormatImage('full');
                          handleSubmit({
                            product: { grid_style: 'full' },
                          });
                        }
                  }
                >
                  <div>
                    <FullImageIcon selected={selectedFormatImage === 'full'} />
                  </div>

                  <span
                    className={
                      selectedFormatImage === 'full' ? 'selected' : 'none'
                    }
                  >
                    Tela cheia
                  </span>
                </div>
              </DivImages>
            </Div>

            {/* <Div>
              <strong>Bordas</strong>

              <DivImages>
                <div
                  onClick={() => {
                    setSelectedBorderImage('with');
                    handleSubmit({
                      product: { grid_border_style: 'with' },
                    });
                  }}
                >
                  <div>
                    <WithBorderIcon selected={selectedBorderImage === 'with'} />
                  </div>

                  <span
                    className={
                      selectedBorderImage === 'with' ? 'selected' : 'none'
                    }
                  >
                    Com borda
                  </span>
                </div>

                <div
                  onClick={() => {
                    setSelectedBorderImage('none');
                    handleSubmit({
                      product: { grid_border_style: 'none' },
                    });
                  }}
                >
                  <div>
                    <NoBorderIcon selected={selectedBorderImage === 'none'} />
                  </div>

                  <span
                    className={
                      selectedBorderImage === 'none' ? 'selected' : 'none'
                    }
                  >
                    Sem borda
                  </span>
                </div>
              </DivImages>
            </Div> */}

            <Div>
              <DivTitle>
                <strong>Estilo de bordas</strong>

                {pro === 0 && <Label>PRO</Label>}
              </DivTitle>

              <DivImages>
                <div
                  onClick={
                    pro === 0
                      ? () => setShowProPlanPopUp(true)
                      : () => {
                          setSelectedBorderStyleImage('straight');
                          handleSubmit({
                            product: { grid_image_border_style: 'straight' },
                          });
                        }
                  }
                >
                  <div>
                    <StraightBorderIcon
                      selected={selectedBorderStyleImage === 'straight'}
                    />
                  </div>

                  <span
                    className={
                      selectedBorderStyleImage === 'straight'
                        ? 'selected'
                        : 'none'
                    }
                  >
                    Retas
                  </span>
                </div>

                <div
                  onClick={
                    pro === 0
                      ? () => setShowProPlanPopUp(true)
                      : () => {
                          setSelectedBorderStyleImage('rounded');
                          handleSubmit({
                            product: { grid_image_border_style: 'rounded' },
                          });
                        }
                  }
                >
                  <div>
                    <RoundedBorderIcon
                      selected={selectedBorderStyleImage === 'rounded'}
                    />
                  </div>

                  <span
                    className={
                      selectedBorderStyleImage === 'rounded'
                        ? 'selected'
                        : 'none'
                    }
                  >
                    Arredondadas
                  </span>
                </div>
              </DivImages>
            </Div>

            <Div>
              <DivTitle>
                <strong>Alinhamento do texto</strong>

                {pro === 0 && <Label>PRO</Label>}
              </DivTitle>

              <DivImages>
                <div
                  onClick={
                    pro === 0
                      ? () => setShowProPlanPopUp(true)
                      : () => {
                          setSelectedTextAlignImage('left');
                          handleSubmit({
                            product: { grid_text_alignment: 'left' },
                          });
                        }
                  }
                >
                  <div>
                    <TextAlignLeftIcon
                      selected={selectedTextAlignImage === 'left'}
                    />
                  </div>

                  <span
                    className={
                      selectedTextAlignImage === 'left' ? 'selected' : 'none'
                    }
                  >
                    Esquerda
                  </span>
                </div>

                <div
                  onClick={
                    pro === 0
                      ? () => setShowProPlanPopUp(true)
                      : () => {
                          setSelectedTextAlignImage('center');
                          handleSubmit({
                            product: { grid_text_alignment: 'center' },
                          });
                        }
                  }
                >
                  <div>
                    <TextAlignCenterIcon
                      selected={selectedTextAlignImage === 'center'}
                    />
                  </div>

                  <span
                    className={
                      selectedTextAlignImage === 'center' ? 'selected' : 'none'
                    }
                  >
                    Centro
                  </span>
                </div>
              </DivImages>
            </Div>

            {/* <Div>
              <DivTitle>
                <strong>Sombra no card de produto</strong>

                {pro === 0 && <Label>PRO</Label>}
              </DivTitle>

              <DivFonts>
                <BuyButtonStyle
                  left
                  active={cardShadow === 'true'}
                  selectedFontWeigth={cardShadow}
                  onClick={
                    pro === 0
                      ? () => setShowProPlanPopUp(true)
                      : () => {
                          setCardShadow('true');
                          handleSubmit({
                            product: { card_shadow: 'true' },
                          });
                        }
                  }
                >
                  Com sombra
                </BuyButtonStyle>

                <BuyButtonStyle
                  right
                  active={cardShadow === 'false'}
                  selectedFontWeigth={cardShadow}
                  onClick={
                    pro === 0
                      ? () => setShowProPlanPopUp(true)
                      : () => {
                          setCardShadow('false');
                          handleSubmit({
                            product: { card_shadow: 'false' },
                          });
                        }
                  }
                >
                  Sem sombra
                </BuyButtonStyle>
              </DivFonts>
            </Div> */}

            <Div>
              <DivTitle>
                <strong>Estilo de nome do produto</strong>

                {pro === 0 && <Label>PRO</Label>}
              </DivTitle>

              <DivFonts>
                <BuyButtonStyle
                  left
                  active={uppercaseTitle === 'true'}
                  selectedFontWeigth={uppercaseTitle}
                  onClick={
                    pro === 0
                      ? () => setShowProPlanPopUp(true)
                      : () => {
                          setUppercaseTitle('true');
                          handleSubmit({
                            product: { uppercase_title: 'true' },
                          });
                        }
                  }
                >
                  Somente maiúsculas
                </BuyButtonStyle>

                <BuyButtonStyle
                  right
                  active={uppercaseTitle === 'false'}
                  selectedFontWeigth={uppercaseTitle}
                  onClick={
                    pro === 0
                      ? () => setShowProPlanPopUp(true)
                      : () => {
                          setUppercaseTitle('false');
                          handleSubmit({
                            product: { uppercase_title: 'false' },
                          });
                        }
                  }
                >
                  Normal
                </BuyButtonStyle>
              </DivFonts>
            </Div>

            <Div>
              <DivTitle>
                <strong>Botão de comprar</strong>

                {pro === 0 && <Label>PRO</Label>}
              </DivTitle>

              <DivFonts>
                {/* <BuyButtonStyle
                  left
                  active={selectedBuyButtonStyle === 'inside'}
                  selectedFontWeigth={selectedBuyButtonStyle}
                  onClick={() => {
                    setSelectedBuyButtonStyle('inside');
                    handleSubmit({
                      product: { grid_buy_button_style: 'inside' },
                    });
                  }}
                >
                  Sobre o produto
                </BuyButtonStyle> */}

                <BuyButtonStyle
                  left
                  active={selectedBuyButtonStyle === 'under'}
                  selectedFontWeigth={selectedBuyButtonStyle}
                  onClick={
                    pro === 0
                      ? () => setShowProPlanPopUp(true)
                      : () => {
                          setSelectedBuyButtonStyle('under');
                          handleSubmit({
                            product: { grid_buy_button_style: 'under' },
                          });
                        }
                  }
                >
                  Abaixo do produto
                </BuyButtonStyle>

                <BuyButtonStyle
                  right
                  active={selectedBuyButtonStyle === 'none'}
                  selectedFontWeigth={selectedBuyButtonStyle}
                  onClick={
                    pro === 0
                      ? () => setShowProPlanPopUp(true)
                      : () => {
                          setSelectedBuyButtonStyle('none');
                          handleSubmit({
                            product: { grid_buy_button_style: 'none' },
                          });
                        }
                  }
                >
                  Sem botão
                </BuyButtonStyle>
              </DivFonts>
            </Div>
          </DivContentItem>
        )}

        <DivItem
          onClick={() => setShowButtons(!showButtons)}
          last={!showButtons}
        >
          <h2>Botões</h2>

          <div>
            <img src={showButtons ? UpArrowIcon : DownArrowIcon} alt="arrow" />
          </div>
        </DivItem>

        {showButtons && (
          <DivContentItem>
            <Div>
              <DivTitle>
                <strong>Estilo da borda de botões</strong>

                {pro === 0 && <Label>PRO</Label>}
              </DivTitle>

              <DivStyleButtons>
                <div
                  onClick={
                    pro === 0
                      ? () => setShowProPlanPopUp(true)
                      : () => {
                          setSelectInputStyle('rounded');
                          handleSubmit({
                            button: { border_style: 'rounded' },
                          });
                        }
                  }
                >
                  <InputModel
                    borderRadius="6px"
                    selected={selectInputStyle === 'rounded'}
                  />

                  <span
                    className={
                      selectInputStyle === 'rounded' ? 'selected' : 'none'
                    }
                  >
                    Arredondada
                  </span>
                </div>

                <div
                  onClick={
                    pro === 0
                      ? () => setShowProPlanPopUp(true)
                      : () => {
                          setSelectInputStyle('straight');
                          handleSubmit({
                            button: { border_style: 'straight' },
                          });
                        }
                  }
                >
                  <InputModel selected={selectInputStyle === 'straight'} />

                  <span
                    className={
                      selectInputStyle === 'straight' ? 'selected' : 'none'
                    }
                  >
                    Reta
                  </span>
                </div>

                <div
                  onClick={
                    pro === 0
                      ? () => setShowProPlanPopUp(true)
                      : () => {
                          setSelectInputStyle('circle');
                          handleSubmit({
                            button: { border_style: 'circle' },
                          });
                        }
                  }
                >
                  <InputModel
                    borderRadius="50px"
                    selected={selectInputStyle === 'circle'}
                  />

                  <span
                    className={
                      selectInputStyle === 'circle' ? 'selected' : 'none'
                    }
                  >
                    Circular
                  </span>
                </div>
              </DivStyleButtons>
            </Div>

            <Div>
              <DivTitle>
                <strong>Cor de fundo</strong>

                {pro === 0 && <Label>PRO</Label>}
              </DivTitle>

              <ColorButton
                handleColor={
                  pro === 0
                    ? () => setShowProPlanPopUp(true)
                    : color => setBackgroundButtonColor(color)
                }
                type="primary"
                colors={backgroundButtonColor || '#000000'}
                top="auto"
                left="15px"
                handleSubmitTheme={
                  pro === 0
                    ? () => setShowProPlanPopUp(true)
                    : color =>
                        handleSubmit({
                          button: { background_color: color },
                        })
                }
              />
            </Div>

            <Div>
              <DivTitle>
                <strong>Cor do texto</strong>

                {pro === 0 && <Label>PRO</Label>}
              </DivTitle>

              <ColorButton
                handleColor={
                  pro === 0
                    ? () => setShowProPlanPopUp(true)
                    : color => setTextButtonColor(color)
                }
                type="primary"
                colors={textButtonColor || '#000000'}
                top="auto"
                left="15px"
                handleSubmitTheme={
                  pro === 0
                    ? () => setShowProPlanPopUp(true)
                    : color =>
                        handleSubmit({
                          button: { text_color: color },
                        })
                }
              />
            </Div>
          </DivContentItem>
        )}
      </DivBody>

      <LabelDomain />

      <ProPlanModal showModal={showProPlanPopUp} Modal={ProPlanPopUp} />
    </Container>
  );
}

export default PersonalizeTheme;
