import React from 'react';

import Box from '../../../../../assets/icons/box.svg';
import Download from '../../../../../assets/icons/Download';
import Shipping from '../../../../../assets/icons/shipping.svg';
import EyeClosed from '../../../../../assets/icons/eyeClosed.svg';
import Cancel from '../../../../../assets/icons/order/cancel-red.svg';
import { Divider } from '../OrderInformation/styles';
import * as S from './styles';

export default function OrderStatus() {
  return (
    <S.MainContainer>
      <S.Title>Status do pedido</S.Title>
      <S.Subtitle>
        Informe para seu cliente sobre o andamento do pedido. Seu cliente
        receberá um e-mail sempre que você alterar o status.
      </S.Subtitle>
      <S.ButtonStatus status="inPreparation">
        Alterar status para “Em preparação” <img src={Box} alt="box_ico" />
      </S.ButtonStatus>
      <S.ButtonStatus status="dispatched">
        Alterar status para “Pedido enviado”
        <img src={Shipping} alt="box_ico" />
      </S.ButtonStatus>
      <S.ButtonStatus status="canceled">
        Cancelar pedido <img src={Cancel} alt="box_ico" />
      </S.ButtonStatus>
      <Divider />
      <S.Title>Opções</S.Title>
      <S.Button>
        Imprimir etiqueta <Download />
      </S.Button>
      <S.OutlinedButton>
        <Download color="#838383" /> Imprimir declaração de conteúdo
      </S.OutlinedButton>
      <S.OutlinedButton color="#0D85FF">
        <img src={EyeClosed} alt="box_ico" /> Marcar como não lido
      </S.OutlinedButton>
    </S.MainContainer>
  );
}
