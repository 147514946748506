import React from 'react';

import { Container } from './styles';
// import loader from '../../assets/icons/loader-grey.gif';
import LoaderComponent from '../Loader';

function LoadingContainer({ loading }) {
  return (
    <Container loading={loading}>
      <LoaderComponent size={50} white={false} />
      {/* <img src={loader} alt="loader" /> */}
    </Container>
  );
}

export default LoadingContainer;
