/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';

import { toast } from 'react-toastify';
import ResponsiveHeader from '../../../../components/HeaderMobile';

import api from '../../../../services/api';

import {
  Container,
  Body,
  DivMinValue,
  DivValidLocales,
  DivMethod,
  Radio,
  DivCep,
} from './styles';

function ConfigurationFreeFreight() {
  const [minPrice, setMinPrice] = useState();
  const [radioSelected, setRadioSelected] = useState(0);
  const [cep, setCep] = useState();

  useEffect(() => {
    async function load() {
      const response = await api.get('/configfreight');

      if (response && response.data) {
        setMinPrice(response.data.min_value_free);
        setRadioSelected(response.data.free_type);
        setCep(response.data.free_cep);
      }
    }
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function handleSaveData(data) {
    try {
      await api.post('/updatefreight', data);
      toast.success('Dados salvos');
    } catch (err) {
      toast.error('Erro ao salvar informações');
    }
  }

  function saveMinPrice() {
    const data = {
      min_value_free: minPrice || null,
    };

    handleSaveData(data);
  }

  function saveFreeType(numberSelected) {
    setRadioSelected(numberSelected);

    const data = {
      free_type: numberSelected,
    };

    handleSaveData(data);
  }

  function saveFreeCep() {
    if (radioSelected === 1 || radioSelected === 2) {
      if (!cep || cep.length < 8) {
        toast.error('Preencha um cep válido para salvar as alterações');
        return 0;
      }
    }

    const data = {
      free_cep: cep,
    };

    handleSaveData(data);

    return 0;
  }

  return (
    <Container>
      <ResponsiveHeader title="Frete grátis" url="/freight/configuration" />
      <Body>
        <DivMinValue>
          <strong>Valor mínimo em compras</strong>
          <span>
            Defina o valor mínimo em compras para habilitar o frete grátis.
            Deixe em branco para habilitar para qualquer valor.
          </span>
          <input
            type="number"
            placeholder="R$"
            value={minPrice}
            onChange={e => setMinPrice(e.target.value)}
            onBlur={saveMinPrice}
          />
        </DivMinValue>

        <DivValidLocales>
          <strong>Locais válidos</strong>
          <span>
            Defina os locais em que o frete grátis é válido. Deixe em branco
            para habilitar para qualquer lugar.
          </span>

          <DivMethod>
            <Radio>
              <div
                className={radioSelected === 0 && 'selected'}
                onClick={() => saveFreeType(0)}
              >
                <div />
              </div>
              <span>Todos</span>
            </Radio>

            <Radio>
              <div
                className={radioSelected === 1 && 'selected'}
                onClick={() => saveFreeType(1)}
              >
                <div />
              </div>
              <span>Apenas para minha cidade</span>
            </Radio>

            <Radio>
              <div
                className={radioSelected === 2 && 'selected'}
                onClick={() => saveFreeType(2)}
              >
                <div />
              </div>
              <span>Apenas para meu estado</span>
            </Radio>
          </DivMethod>
        </DivValidLocales>

        {radioSelected !== 0 && (
          <DivCep>
            <div>
              <span>Digite seu CEP</span>
              <span className="span-label">Obrigatório</span>
            </div>

            <input
              type="number"
              placeholder="00000 000"
              value={cep}
              onChange={e => setCep(e.target.value)}
              onBlur={saveFreeCep}
            />
          </DivCep>
        )}
      </Body>
    </Container>
  );
}

export default ConfigurationFreeFreight;
