/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import React from 'react';

import { Div } from './styles';
import CancelIcon from '../../../../../assets/icons/cancel-grey.svg';

function ImagePreview({ src, image, deleteImages, desktop, type }) {
  async function handleClickDelete() {
    deleteImages(type, image.id);
  }

  return (
    <Div desktop={desktop}>
      <img src={src} className="product-image" alt="productimage" />
      <img
        src={CancelIcon}
        className="cancel"
        alt="cancel"
        onClick={handleClickDelete}
      />
      {/* <Container src={src} /> */}
    </Div>
  );
}

export default ImagePreview;
