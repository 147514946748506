import styled, { css } from 'styled-components';
// import { Link } from 'react-router-dom';

export const Container = styled.div`
  display: flex;
  height: 64px;
  align-items: center;
  padding: 0 24px 0 20px;

  ${props =>
    props.environment === 'development' &&
    css`
      background: #b2e9df;
    `}

  ${props =>
    props.environment === 'homolog' &&
    css`
      background: #9cc7f3;
    `}

  > div {
    display: flex;
    width: 100%;
    justify-content: space-between;
    /* width: 33.33%; */
  }

  > img {
    display: flex;
  }

  /* .div-avatar {
    justify-content: flex-start;
  } */

  .div-logo {
    /* justify-content: center; */
  }

  .div-menu {
    align-items: center;
    justify-content: flex-end;

    > div {
      > img {
        width: 19px;
      }
    }

    /* > div {
      padding: 8px;
    } */
  }

  /* .avatar {
    width: 48px;
    height: 48px;
    border: 0.5px solid #d8dee5;
    border-radius: 50%;
    object-fit: cover;
  } */

  .logo {
    width: 100px;
  }

  .menu {
    width: 26px !important;
    margin-left: 24px;
  }
`;

export const DivMenu = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 80vw;
  background: #fff;
  border-left: 0.5px solid #d8dee5;
  top: 0;
  right: 0;
  z-index: 999;

  transition: all 200ms linear;
  opacity: ${props => (props.active ? '1' : '0.85')};
  transform: ${props => (props.active ? 'translateX(0)' : 'translateX(100%)')};

  header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 65px;
    align-items: center;
    padding: 0 24px;
    border-bottom: 0.5px solid #d8dee5;

    strong {
      font-weight: 500;
      font-size: 18px;
      color: #000000;
    }

    > a {
      text-decoration: none;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      > img {
        margin-right: 8px;
      }

      > span {
        font-weight: 500;
        font-size: 14px;
        color: #2b8ff5;
      }
    }
  }

  footer {
    display: flex;
    flex-direction: column;
    position: fixed;
    bottom: 24px;
    /* left: 25vw; */
    padding-left: 23px;
    /* right: 75vw; */

    > a {
      display: flex;
      align-items: flex-start;
      margin-bottom: 26px;
      text-decoration: none;

      > div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        margin-right: 15px;
      }

      > span {
        font-size: 14px;
        color: #000000;
      }
    }
  }
`;

export const DivOrder = styled.div`
  width: 100%;
  height: 74px;
  background: #fafafa;

  > a {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 0 24px;
    text-decoration: none;

    > strong {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #ff6c4b;
    }

    > span {
      font-size: 13px;
      line-height: 18px;
      color: #838383;
    }
  }
`;

export const PromoNotification = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 19px;
  height: 19px;
  background: ${props => props.color};
  /* background: ${props =>
    !props.color || props.color === '#fff' || props.color === '#ffffff'
      ? '#ff5757'
      : props.color}; */
  border-radius: 50%;
  margin-top: -33px;
  margin-left: 8px;
  position: absolute;

  span {
    font-size: 13px;
    color: #ffffff;
  }
`;

export const OrderNotification = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 17px !important;
  height: 17px;
  background: #ff6c4b;
  /* background: ${props =>
    !props.color || props.color === '#fff' || props.color === '#ffffff'
      ? '#ff6c4b'
      : props.color}; */
  border-radius: 50%;
  margin-right: 0 !important;
  margin-left: 6px;

  span {
    font-size: 10px;
    color: #ffffff;
  }
`;

export const MenuItem = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 23px 0 23px;
  /* padding: 37px 23px 0 23px; */
  position: relative;

  > div {
    display: flex;
    align-items: flex-start;
    margin-bottom: 26px;
    text-decoration: none;

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      margin-right: 15px;
    }

    > span {
      font-size: 14px;
      color: #000000;
    }
  }

  .span-promo {
    font-size: 12px;
    color: #ff1f89;
    text-align: center;
    width: 100%;
    font-weight: 600;
    margin-top: 8px;
  }

  .disabled {
    opacity: 50%;
  }

  .link {
    > span {
      color: #2b8ff5;
      font-weight: 500;
    }
  }

  > a {
    display: flex;
    align-items: flex-start;
    margin-bottom: 26px;
    text-decoration: none;

    .soon-div {
      opacity: 0.5;
    }

    .pro {
      border: 1.5px solid rgb(43, 143, 245);
      border-radius: 4px;
      height: 100%;
      padding: 1px 6px;
      color: rgb(43, 143, 245);
      font-size: 13px;
      max-height: 23px;
      font-weight: 500;
      margin-left: 8px;
    }

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      margin-right: 15px;
    }

    > span {
      font-size: 14px;
      color: #000000;
    }
  }

  .new-label {
    color: #2b8ff5;
    border: 1px solid #2b8ff5;
    border-radius: 4px;
    font-size: 13px;
    padding: 0 4px;
    margin-left: 8px;
  }

  .soon-label {
    color: #9d9d9d;
    border: 1px solid #9d9d9d;
    border-radius: 4px;
    font-size: 13px;
    padding: 0 4px;
    margin-left: 8px;
  }
`;

export const ButtonFreight = styled.button`
  display: flex;
  align-items: flex-start;
  margin-bottom: 26px;
  text-decoration: none;
  border: none;
  background: none;

  /* > img {
    width: 20px;
    height: 20px;
    margin-left: 8px;
  } */

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    margin-right: 15px;
  }

  > span {
    font-size: 14px;
    color: #000000;
  }
`;

export const DivInviteFriends = styled.div`
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  /* flex-direction: column; */
  padding: 0 12px;
  background: linear-gradient(
    238.72deg,
    #ff6534 -43.51%,
    #ff0e9f 37.82%,
    #a925fa 106.7%
  );
  height: 50px;
  border-radius: 8px;
  position: relative;
  /* height: 103px; */
  margin-bottom: 0 !important;
  text-decoration: none;

  > img {
    margin-right: 16px;
  }

  > svg {
    margin-left: 8px;
  }

  > strong {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    font-weight: 600;
    /* text-align: left; */
    color: #fff;
  }

  > span {
    font-size: 11px !important;
    display: flex;
    border: solid 1px #fff;
    color: #fff !important;
    border-radius: 4px;
    padding: 1px 6px;
    margin-left: 8px;
  }

  > div {
    padding: 4px 10px;
    width: auto !important;
    position: absolute;
    top: 16px;
    right: 16px;
    display: flex;
    align-items: center;
    color: #ffffff;
    margin: 0 !important;
    border: 1px solid #ffffff;
    border-radius: 13px;

    > span {
      display: flex;
      font-weight: 500;
      font-size: 10px;
      line-height: 15px;
    }
    /* width: 120px !important; */
  }

  > a {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    width: 100%;
    border-top: 1px solid rgba(255, 255, 255, 0.19);
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    color: #ffffff;

    > img {
      position: absolute;
      right: 16px;
    }
  }
`;

export const DivLoader = styled.div`
  display: flex;
  flex-direction: column;
  width: 100% !important;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  background: #fff;
  z-index: 10;

  > img {
    width: 98px;
    margin-bottom: 8px;
  }

  > span {
    font-size: 13px;
    line-height: 19px;
    color: #9d9d9d;
  }
`;

export const DivHelp = styled.div`
  display: flex;
  width: 100%;

  > strong {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
  }

  > a {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    height: 45px;
    background: #efefef;
    border: 1px solid #efefef;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 0 16px;
    text-decoration: none;
    margin-top: 8px;

    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    color: #101010;

    > img {
      margin-right: 8px;
      width: 18px;
    }
  }
`;
