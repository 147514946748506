/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// import { toast } from 'react-toastify';
import api from '../../../services/api';

import { Container, DivBody, Card } from './styles';

// import TrashIcon from '../../../assets/icons/trashIcon.svg';

import ResponsiveHeader from '../../../components/HeaderMobile';
import ProPlanModal from '../../../components/Popups/ProPlan';

function Coupons() {
  const [coupons, setCoupons] = useState([]);
  const [pro, setPro] = useState(0);
  const [showProPlanPopUp, setShowProPlanPopUp] = useState(false);

  const history = useHistory();

  useEffect(() => {
    async function load() {
      const proplan = await api.get('/proplan');
      setPro(proplan.data);

      await api.get('/coupons').then(response => setCoupons(response.data));
    }

    load();
  }, []);

  // async function handleDelete(id) {
  //   const newCoupons = coupons.filter(c => c.id !== id);
  //   setCoupons(newCoupons);

  //   toast.success('Cupom removido');

  //   await api.delete(`/coupons/${id}`);
  // }

  async function onChangeCheck(id) {
    const index = coupons.findIndex(t => t.id === id);
    const arr = [...coupons];

    arr[index].active = !arr[index].active;

    setCoupons(arr);

    await api.put(`/coupons/${id}`, {
      active: arr[index].active,
      code: arr[index].code,
    });
  }

  function handleAddCoupon() {
    if (pro === 0) {
      setShowProPlanPopUp(true);
      return 0;
    }

    history.push('/coupons/0');
    return 0;
  }

  function handleUpdateCoupon(id) {
    if (pro === 0) {
      setShowProPlanPopUp(true);
      return 0;
    }

    history.push(`/coupons/${id}`);
    return 0;
  }

  function ProPlanPopUp(pro_modal) {
    setShowProPlanPopUp(pro_modal);
  }

  return (
    <Container>
      <ProPlanModal showModal={showProPlanPopUp} Modal={ProPlanPopUp} />

      <ResponsiveHeader
        title="Cupons"
        url="/dashboard"
        tab={1}
        // hasLabelPro={pro === 0}
      />
      <DivBody>
        <span>
          Adicione cupons de desconto para que seus clientes possam utilizar na
          hora da compra
        </span>
        <button type="button" onClick={handleAddCoupon}>
          Adicionar cupom
        </button>
        {/* <Link to="/coupons/0">Adicionar cupom</Link> */}

        <div>
          {coupons &&
            coupons.map(c => (
              <Card>
                <div className="left" onClick={() => handleUpdateCoupon(c.id)}>
                  <strong>{c.code}</strong>

                  {(c.type === 'I' && (
                    <span>R$ {c.value} de desconto</span>
                  )) || <span>{Math.trunc(c.value)}% de desconto</span>}
                </div>

                <div className="right">
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={c.active}
                      onChange={() => onChangeCheck(c.id)}
                    />
                    <span className="slider round" />
                  </label>
                </div>
              </Card>
            ))}
        </div>
      </DivBody>
    </Container>
  );
}

export default Coupons;
