import React from 'react';
// import CustomizeDesktop from '../Signup/CustomizeAvatar';
import CustomizeResponsive from '../Responsive/Signup/ResponsiveCustomizeAvatar';
// import Maintenance from '../Maintenance';

function Customize() {
  return <CustomizeResponsive />;
}

export default Customize;
