import React from 'react';
// import SigninDesktop from '../Signin';
import SigninResponsive from '../Responsive/Signin';
// import Maintenance from '../Maintenance';

function Signin() {
  return <SigninResponsive />;
}

export default Signin;
