/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Link } from 'react-router-dom';
import { Input, Form } from '@rocketseat/unform';

// import UserIcon from '../../../assets/icons/login/user.svg';
// import LockIcon from '../../../assets/icons/login/lock.svg';

import { signInRequest } from '../../../store/modules/auth/actions';

import { ButtonSubmit, Container, Div } from './styles';

export default function ResponsiveSignin() {
  const dispatch = useDispatch();
  const loading = useSelector(state => state.auth.loading);

  async function handleSubmit({ user_email, password }) {
    dispatch(signInRequest(user_email, password));
  }

  return (
    <Container>
      <Div>
        <h1>Entre na sua conta</h1>
        <Form onSubmit={handleSubmit}>
          <strong>E-mail ou username</strong>
          <div className="div-input">
            <Input
              name="user_email"
              type="text"
              placeholder="Digite seu e-mail ou username"
            />
          </div>

          <strong>Senha</strong>
          <div className="div-input">
            <Input
              name="password"
              type="password"
              placeholder="Mínimo de 6 caracteres"
            />
          </div>

          <Link to="/forgotpassword" className="forgot-password">
            Esqueceu a senha?
          </Link>
          <ButtonSubmit disabled={loading} block={loading}>
            {loading ? 'Carregando...' : 'Entrar'}
          </ButtonSubmit>
        </Form>
        <span>Não tem uma conta?</span>
        <Link to="/register" className="link-register">
          Cadastre-se
        </Link>
      </Div>
    </Container>
  );
}
