/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ChromePicker } from 'react-color';
import { updateProfileRequest } from '../../../../store/modules/user/actions';

import { Div, DivColor } from './styles';

export default function ColorButton({
  handleColor,
  type,
  colors,
  disable,
  title,
  saveOnExit,
  top,
  left,
  handleSubmitTheme,
}) {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [colorr, setColor] = useState(colors || '#000000');
  const { name, email, username } = useSelector(state => state.user.profile);
  const dispatch = useDispatch();

  function getColor() {
    if (type === 'primary') {
      handleColor(colorr || '#000000');
    } else {
      handleColor(colorr || '#ffffff');
    }
  }

  useEffect(() => {
    setColor(colors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleClick() {
    if (!disable) {
      setDisplayColorPicker(!displayColorPicker);
    }
  }

  function sendData(color) {
    if (color) {
      setColor(color);
      handleSubmitTheme(color);
    }

    if (saveOnExit) {
      if (type === 'primary') {
        const data = {
          name,
          email,
          user: username,
          primary_color: colorr || '#000000',
        };
        dispatch(updateProfileRequest(data));
      } else {
        const data = {
          name,
          email,
          user: username,
          second_color: colorr || '#ffffff',
        };
        dispatch(updateProfileRequest(data));
      }
    }
  }

  function handleClose() {
    setDisplayColorPicker(false);
    sendData(null);
    handleSubmitTheme(colorr);
  }

  function handleChange(color) {
    setColor(color.hex);
    getColor();
  }

  useEffect(() => {
    getColor();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [colorr]);

  const styles = {
    color: {
      width: '48px',
      height: '48px',
      borderRadius: '50%',
      boxShadow: '0px 3px 18px 0px rgb(0 0 0 / 6%)',
      background: colors,
    },
    swatch: {
      padding: '0px',
      borderRadius: '30px',
      // boxShadow: '4px 6px 18px 0px rgba(0, 0, 0, 0.05)',
      display: 'inline-block',
      cursor: 'pointer',
    },
    popover: {
      position: 'absolute',
      zIndex: '50',
      top: top || '-133px',
      left: left || '20px',
    },
    cover: {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    },
  };

  const handleChangeInput = e => {
    const inputValue = e.target.value;

    const cleanedInput = inputValue.replace(/[^0-9a-fA-F#]/g, '');

    setColor(cleanedInput);
  };

  return (
    <>
      <Div>
        {title && <span>{title}</span>}
        <DivColor>
          <div style={styles.swatch} onClick={handleClick}>
            <div style={styles.color} />
          </div>
          {displayColorPicker ? (
            <div style={styles.popover}>
              <div style={styles.cover} onClick={handleClose} />
              <ChromePicker
                color={colors}
                onChange={handleChange}
                disableAlpha
              />
            </div>
          ) : null}

          <input
            type="text"
            name="color-hex"
            id="color-hex"
            value={colors}
            placeholder="#000000"
            maxLength={7}
            onChange={handleChangeInput}
            onBlur={e => sendData(e.target.value)}
            disabled={disable}
          />
        </DivColor>
      </Div>
    </>
  );
}
