/* eslint-disable no-restricted-globals */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect, useMemo, useCallback } from 'react';

import { parseISO, format } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';
import { toast } from 'react-toastify';
import OrderStatusSteps from '~/components/OrderStatusSteps';
import PaymentStatusSteps from '~/components/PaymentStatusSteps';
import offstorePay from '~/services/offstorePay';
import { formatPrice } from '../../../../../util/format';

import api from '../../../../../services/api';

import CancelModal from './CancelModal';

import WhatsappIcon from '../../../../../assets/icons/whatsapp.svg';
// import CheckIcon from '../../../../../assets/icons/order/check.svg';
// import CheckBlueIcon from '../../../../../assets/icons/order/check-blue.svg';
// import BoxIcon from '../../../../../assets/icons/order/box.svg';
import CancelIcon from '../../../../../assets/icons/order/cancel-red.svg';
import BookIcon from '../../../../../assets/icons/order/book.svg';
import NotViewedIcon from '../../../../../assets/icons/order/not-viewed.svg';

import TicketIcon from '../../../../../assets/icons/order/ticket.svg';
import Out from '../../../../../assets/icons/outIcon.svg';
// import TicketIconBlack from '../../../../../assets/icons/order/ticketBlack.svg';

import Header from '../Header';

import {
  Container,
  Div,
  DivPaymentStatus,
  DivBody,
  Title,
  Span,
  // DivButtons,
  ModalActive,
  DivInput,
  DivClient,
  OutlinedButton,
} from './styles';

function MyOrdersDetails({ handleClickButton, id }) {
  // const history = useHistory();

  const [order, setOrder] = useState();
  const [products, setProducts] = useState();
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [trackingCode, setTrackingCode] = useState('');
  const [modalActive, setModalActive] = useState(false);
  const [domain, setDomain] = useState();

  const [transaction, setTransaction] = useState();
  const [listOfStatus, setListOfStatus] = useState([]);
  const [detailsStatus, setDetailsStatus] = useState('');
  const [creditData, setCreditData] = useState();
  const [modalRepaymentActive, setModalRepaymentActive] = useState(false);

  const [typeFreightCorreios, setTypeFreightCorreios] = useState(false);

  useEffect(() => {
    async function load() {
      await api.post(`/orderrequestbyid/${id}`).then(async response => {
        setOrder({
          ...response.data,
          date: format(
            parseISO(response.data.createdAt),
            "dd 'de' MMMM 'de' yyyy ' às 'HH:mm'h'",
            {
              locale: pt,
            }
          ),
        });

        setTrackingCode(response.data.tracking_code);

        setProducts(
          response.data.products.map(r => ({
            ...r,
            totalPrice: formatPrice(r.price),
          }))
        );

        if (
          response.data.type_freight &&
          response.data.type_freight.toLowerCase().includes('correios')
        ) {
          setTypeFreightCorreios(true);
        }
      });

      await api.get('/domain').then(res => {
        if (res && res.data) {
          setDomain(res.data);
        }
      });
    }

    load();
  }, [id]);

  useEffect(() => {
    async function load() {
      if (id) {
        await api.get(`/order/movements/paymentstatus/${id}`).then(response => {
          setListOfStatus(response && response.data);
        });
      }
    }

    load();
  }, [id]);

  useEffect(() => {
    async function load() {
      if (id) {
        await offstorePay.get(`/transactions/${id}`).then(response => {
          if (response && response.data) {
            setTransaction(response.data.transaction);
            setListOfStatus(response.data.history);
            setCreditData(response.data.creditData);
          }
        });
      }
    }

    load();
  }, [id]);

  async function callAPI() {
    const res = await api.get(`/order/status/${id}`);
    setDetailsStatus(res.data.status);
  }

  useEffect(() => {
    callAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  function cancelModal(modal, canceled) {
    setShowCancelModal(modal);

    if (canceled) {
      setOrder({ ...order, status: 'Cancelado' });
      setDetailsStatus('Cancelado');
    }
  }

  function genarateText() {
    const codeNoRash = order && order.code.replace('#', '');

    let string = `Olá, esse é o seu pedido: %0A
    %0A*Cod.:* ${codeNoRash}
    %0A*Data:* ${order && order.date}
    %0A
    %0A*Subtotal:* ${order && order.subtotal}
    %0A*Taxa de entrega:* ${order && order.rate}
    %0A*Total:* ${order && order.total}
    %0A
    %0A*Produtos:*
    %0A`;

    // Promise.all(
    products &&
      products.map(product => {
        const stringProduct = `
      %0A*${product.amount}x* ${product.name} ${product.totalPrice ||
          'Sob consulta'} %0A`;
        string += stringProduct;
        return product;
      });
    // );

    string += `
    %0A
    %0A*Forma de recebimento:*
    %0A${order && order.receivement === 0 ? 'Entrega' : 'Retirar no local'}
    ${order &&
      order.receivement === 0 &&
      order.type_freight &&
      ` (${order.type_freight})`}
    %0A
    ${order &&
      (order.address ||
        order.number ||
        order.complement ||
        order.neighborhood ||
        order.city ||
        order.uf ||
        order.cep) &&
      `%0A*Endereço:*
        %0A${order.address}, ${order.number}, ${order.complement &&
        `${order.complement},`} ${order.neighborhood}, ${order.city} - ${
        order.uf
      }`}
    %0A
    %0A*Forma de pagamento:*
    ${order && order.payment_method === '0' ? 'Dinheiro' : ''}
    ${order && order.change ? `%0ATroco para: ${order.change}` : ''}
    ${order && order.payment_method === '1' ? 'Cartão de crédito' : ''}
    ${order && order.payment_method === '2' ? 'Boleto bancário' : ''}
    ${
      order && order.payment_method === '3'
        ? 'Transferência bancária(DOC/TED)'
        : ''
    }
    ${order && order.payment_method === '4' ? 'Cartão de débito' : ''}
    ${order && order.payment_method === '5' ? 'Picpay' : ''}
    ${order && order.payment_method === '6' ? 'Vale alimentação' : ''}
    ${order && order.payment_method === '7' ? 'Pix' : ''}
    %0A
    ${
      order && order.observation
        ? `%0A*Observação:*
      %0A${order.observation}`
        : ''
    }

    `;

    return string;
  }

  // async function changeStatus() {
  //   if (order && order.mercado_pago_payment_id) {
  //     toast.error('Você não pode aprovar um pagamento feito pelo Mercado Pago');

  //     return 0;
  //   }

  //   if (order && order.status === 'Aprovado') {
  //     await api.put(`/orderrequest/${order && order.id}`, {
  //       status: 'Pendente',
  //     });

  //     setOrder({ ...order, status: 'Pendente' });
  //     toast.success('Pedido retornado a pendente');
  //   } else {
  //     await api.put(`/orderrequest/${order && order.id}`, {
  //       status: 'Aprovado',
  //     });

  //     setOrder({ ...order, status: 'Aprovado' });
  //     toast.success('Pedido aprovado');
  //   }

  //   return 0;
  // }

  async function handleViewed(viewed) {
    await api.put(`/orderrequest/${order && order.id}`, { viewed });
    setOrder({ ...order, viewed });

    if (viewed) {
      toast.success('Visualizado');
    } else {
      toast.success('Não visualizado');
    }
  }

  function handleClickBack() {
    handleClickButton();
  }

  function selectUrl(itemId) {
    if (domain && domain.active && domain.domain) {
      return `https://${domain.domain}/p/${itemId}`;
    }

    return `https://${order && order.User.user}.offstore.me/p/${itemId}`;
  }

  const subtotal = useMemo(() => {
    const value = order && order.subtotal && order.subtotal.replace(',', '.');
    const response = value && parseFloat(value.slice(2)).toFixed(2);
    return response;
  }, [order]);

  const rate = useMemo(() => {
    const value = order && order.rate && order.rate.replace(',', '.');
    const response = value && parseFloat(value.slice(2)).toFixed(2);
    return response;
  }, [order]);

  async function handleSendNotify() {
    await api.put(`/orderrequesttrackingcode/${order && order.id}`, {
      tracking_code: trackingCode,
      sendEmail: true,
    });

    setOrder({ ...order, tracking_code: trackingCode });

    setModalActive(false);
  }

  function calcPixDiscount() {
    const total_as_number = parseFloat(subtotal) + parseFloat(rate);
    const result = (total_as_number * order.pix_discount) / 100;

    return formatPrice(result);
  }

  async function handleRepaymentOrder() {
    try {
      await offstorePay
        .post(`/transactions/repayment`, {
          zoopSellerId: transaction && transaction.zoop_seller_id,
          zoopTransactionId: transaction && transaction.zoop_transaction_id,
          amount: transaction && Math.round(transaction.amount * 100),
        })
        .then(response => {
          if (response.status === 200) {
            setDetailsStatus('Cancelado');
          }
        });
    } catch (err) {
      toast.error('Erro ao fazer reembolso');
    } finally {
      setModalRepaymentActive(false);
    }
  }

  function SomeProductHasNotPrice() {
    const result =
      products &&
      products.filter(
        product =>
          (!product.price && !product.variation) ||
          (!product.price &&
            product.variation &&
            !product.variation.promotion_price &&
            !product.variation.price)
      );

    if (result.length > 0) {
      return true;
    }

    return false;
  }

  const getRate = useCallback(() => {
    if (order && order.type_freight) {
      return order.rate;
    }
    return null;
  }, [order]);

  return (
    <Container>
      <CancelModal
        showModal={showCancelModal}
        Modal={cancelModal}
        OrderId={order && order.id}
      />
      <Header title="Detalhes do pedido" handleClickBack={handleClickBack} />
      <DivBody>
        <Div first>
          <Title size="13px">{order && order.code}</Title>
          <Span size="11px">{order && order.date}</Span>

          <DivPaymentStatus color={detailsStatus}>
            <div>{detailsStatus}</div>
          </DivPaymentStatus>

          {/* <DivPaymentStatus
            color={order && order.mercado_pago_payment_status}
            orderStatus={order && order.status}
          >
            {order &&
              order.mercado_pago_payment_status === 'approved' &&
              order.status !== 'Cancelado' && <div>Pago via Mercado Pago</div>}

            {order &&
              order.status === 'Aprovado' &&
              !order.mercado_pago_payment_id && <div>Pago</div>}

            {order && order.mercado_pago_payment_status === 'pending' && (
              <div>Aguardando pgto.</div>
            )}

            {order &&
              (order.mercado_pago_payment_status === 'cancelled' ||
                order.status === 'Cancelado') && <div>Cancelado</div>}

            {order &&
              order.status === 'Pendente' &&
              !order.mercado_pago_payment_id && <div>Pgto a combinar</div>}
          </DivPaymentStatus> */}
        </Div>
        <Div>
          <div>
            <Span size="12px">Subtotal</Span>
            <Title fontWeight="400" size="12px">
              {products && SomeProductHasNotPrice()
                ? 'Sob consulta'
                : order && order.subtotal}
            </Title>
          </div>

          {order && order.receivement === 0 && getRate() && (
            <div>
              <Span size="12px">Taxa de entrega</Span>

              <Title fontWeight="400" size="12px">
                {getRate()}
              </Title>
            </div>
          )}

          {order && order.receivement === 1 && (
            <div>
              <Span size="12px">Taxa de entrega</Span>

              <Title fontWeight="400" size="12px">
                Retirar no local
              </Title>
            </div>
          )}

          {order && order.coupon && (
            <div>
              <Span size="12px">Cupom de desconto({order.coupon.code})</Span>
              <Title fontWeight="400" size="12px">
                {order.coupon.type === 'P'
                  ? `- ${formatPrice((subtotal * order.coupon.value) / 100)}`
                  : `- ${formatPrice(order.coupon.value)}`}
              </Title>
            </div>
          )}

          {order && order.pix_discount && (
            <div>
              <Span size="12px">Descontos PIX({`${order.pix_discount}%`})</Span>
              <Title fontWeight="400" size="12px">
                {`- ${calcPixDiscount()}`}
              </Title>
            </div>
          )}

          <div>
            <Title>
              Total{' '}
              {order && order.installments && `(em ${order.installments}x)`}
            </Title>
            <Title>
              {products && SomeProductHasNotPrice()
                ? 'Sob consulta'
                : order && order.total}
            </Title>
          </div>
        </Div>
        <Div>
          <Title>Produtos</Title>

          {products &&
            products.map(product => (
              <div key={product.id}>
                <div>
                  <Span>
                    {product.amount} x {product.name}
                  </Span>
                  <a
                    href={selectUrl(product.product_id)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Ver produto
                  </a>
                </div>

                <Span>
                  {!product.price ? 'Sob consulta' : product.totalPrice}
                </Span>
              </div>
            ))}
        </Div>
        <Div>
          <Title>Dados do comprador</Title>
          <Span>{order && order.name}</Span>
          <Span>
            {order && order.whatsapp}{' '}
            <a
              href={`https://wa.me/+55${order && order.whatsapp}`}
              target="_blank"
              rel="noreferrer"
            >
              Chamar no whatsapp
            </a>
          </Span>
          <Span>{order && order.email}</Span>
          <Span>{order && order.cpf}</Span>
        </Div>
        <Div>
          <Title>Como deseja receber o produto?</Title>
          <Span>
            {order && order.receivement === 0 ? 'Entrega' : 'Retirar no local'}
            {order &&
              order.receivement === 0 &&
              order.type_freight &&
              ` (${order.type_freight})`}
          </Span>
        </Div>

        {order &&
          (order.address ||
            order.number ||
            order.complement ||
            order.neighborhood ||
            order.city ||
            order.uf ||
            order.cep) && (
            <Div>
              <Title>Endereço</Title>
              {order && (
                <Span>
                  {order.address}, {order.number},{' '}
                  {order.complement && `${order.complement},`}{' '}
                  {order.neighborhood}, {order.city} - {order.uf}
                </Span>
              )}

              <Span>{order && order.cep}</Span>
            </Div>
          )}

        <Div>
          <Title>Forma de pagamento</Title>

          {order && order.payment_gateway === 'Mercado Pago' && (
            <Span>{order.payment_method}(Mercado Pago)</Span>
          )}

          {order && order.payment_gateway === 'PagBank' && (
            <Span>{order.payment_method}(Pagbank)</Span>
          )}

          {order && order.payment_gateway === 'Offstore Pay' && (
            <div style={{ justifyContent: 'flex-start', gap: '8px' }}>
              <Span>
                {order.payment_method}{' '}
                {order.installments && `${order.installments}x`}
              </Span>
              <OutlinedButton
              // onClick={() =>
              //   history.push(
              //     `/paymentmethods/configurationoffstorepay/payment/${transaction &&
              //       transaction.id}`
              //   )
              // }
              >
                <img src={Out} alt="edit icon" /> ver pagamento
              </OutlinedButton>
            </div>
          )}

          {/* Verifica se o tipo de pagamento é numérico (whatsapp) */}
          {order && !isNaN(order.payment_method) && (
            <>
              <Span>
                Combinar via Whatsapp{' '}
                {order && order.payment_method === '0' && (
                  <Span>(Dinheiro)</Span>
                )}
                {order && order.payment_method === '1' && (
                  <Span>(Cartão de crédito)</Span>
                )}
                {order && order.payment_method === '2' && (
                  <Span>(Boleto bancário)</Span>
                )}
                {order && order.payment_method === '3' && (
                  <Span>(Transferência bancária(DOC/TED))</Span>
                )}
                {order && order.payment_method === '4' && (
                  <Span>(Cartão de débito)</Span>
                )}
                {order && order.payment_method === '5' && <Span>(Picpay)</Span>}
                {order && order.payment_method === '6' && (
                  <Span>(Vale alimentação)</Span>
                )}
                {order && order.payment_method === '7' && <Span>(Pix)</Span>}
              </Span>

              {order && order.payment_method === '0' && (
                <Span>
                  Troco para:{' '}
                  {order && order.change && formatPrice(order.change)}
                </Span>
              )}
            </>
          )}
        </Div>

        {order && (
          <>
            <Div alignItems="flex-start">
              <OrderStatusSteps
                setDetailsStatus={setDetailsStatus}
                orderId={id}
                handleSendNotify={handleSendNotify}
                orderReceivement={order && order.receivement}
              />
            </Div>

            {listOfStatus &&
              listOfStatus.length > 0 &&
              order &&
              order.payment_gateway === 'Offstore Pay' && (
                <Div alignItems="flex-start">
                  <PaymentStatusSteps
                    orderId={id}
                    listOfStatus={listOfStatus}
                    creditData={creditData}
                    detailsStatus={detailsStatus}
                    setModalRepaymentActive={setModalRepaymentActive}
                  />
                </Div>
              )}
          </>
        )}

        {order && order.observation && (
          <Div>
            <Title>Observação</Title>
            <Span>{order && order.observation}</Span>
          </Div>
        )}

        {/* {order && order.status !== 'Cancelado' && (
          <DivClient padding="24px 16px 0 16px">
            <Title>Acompanhamento do cliente</Title>

            {order && !order.mercado_pago_payment_id && (
              <button
                type="button"
                className={
                  order && order.status === 'Aprovado'
                    ? 'paid disabled'
                    : 'paid'
                }
                onClick={changeStatus}
              >
                {order && order.status === 'Aprovado'
                  ? 'Pago'
                  : 'Marcar como pago'}
                <img src={CheckIcon} alt="check" />
              </button>
            )}

            {order && order.receivement === 0 && (
              <button
                type="button"
                className={trackingCode ? 'notify disabled' : 'notify'}
                onClick={() => setModalActive(true)}
              >
                {trackingCode ? 'Pedido enviado' : 'Notificar envio do pedido'}

                {trackingCode ? (
                  <img src={CheckBlueIcon} alt="check" />
                ) : (
                  <img src={BoxIcon} alt="box" />
                )}
              </button>
            )}
          </DivClient>
        )} */}

        <DivClient padding="24px 16px 24px 16px">
          <Title>Opções</Title>

          <a
            href={`https://wa.me/+55${order &&
              order.whatsapp}?text=${genarateText()}`}
          >
            Chamar no Whatsapp <img src={WhatsappIcon} alt="whatsapp" />
          </a>

          <button
            type="button"
            onClick={() => handleClickButton(3)}
            className="ticket"
          >
            Imprimir etiqueta
            <img src={TicketIcon} alt="cancel" />
          </button>

          <button
            type="button"
            onClick={() => setShowCancelModal(!showCancelModal)}
            className="cancel"
            disabled={order && order.status === 'Cancelado'}
          >
            {order && order.status === 'Cancelado'
              ? 'Pedido cancelado'
              : 'Cancelar pedido'}

            <img src={CancelIcon} alt="cancel" />
          </button>

          {typeFreightCorreios && (
            <button
              type="button"
              onClick={() => handleClickButton(4)}
              className="content-declaration-button"
            >
              <img src={BookIcon} alt="content-declaration" />
              Imprimir declaração de conteúdo
            </button>
          )}

          {order && order.status !== 'Cancelado' && (
            <>
              {(order && order.viewed && (
                <button
                  type="button"
                  onClick={() => handleViewed(false)}
                  className="not-viewed"
                >
                  <img src={NotViewedIcon} alt="not-viewed" />
                  Marcar como não lido
                </button>
              )) || (
                <button
                  type="button"
                  onClick={() => handleViewed(true)}
                  className="not-viewed"
                >
                  Marcar como lido
                </button>
              )}
            </>
          )}
        </DivClient>

        {/* <DivButtons>
          <a
            href={`https://wa.me/+55${order &&
              order.whatsapp}?text=${genarateText()}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Chamar no Whatsapp <img src={WhatsappIcon} alt="whatsapp" />
          </a>
          <button
            type="button"
            onClick={() => setShowCancelModal(!showCancelModal)}
            disabled={order && order.status === 'Cancelado'}
          >
            {order && order.status === 'Cancelado'
              ? 'Pedido cancelado'
              : 'Cancelar pedido'}
          </button>
          <span>
            Todos os produtos incluidos no pedido voltarão para o estoque
          </span>
        </DivButtons> */}
      </DivBody>

      {modalActive && (
        <ModalActive>
          <div className="container" onClick={() => setModalActive(false)} />
          <div className="div">
            <strong>Notificar envio do pedido</strong>
            <span>
              Um e-mail será enviado para o comprador informando que o pedido
              foi enviado
            </span>

            <DivInput>
              <div>
                <strong>Código de rastreio</strong>
                <span>opcional</span>
              </div>

              <input
                type="text"
                value={trackingCode}
                onChange={e => setTrackingCode(e.target.value)}
              />
            </DivInput>

            <button type="button" onClick={handleSendNotify}>
              Notificar envio
            </button>
          </div>
        </ModalActive>
      )}

      {modalRepaymentActive && (
        <ModalActive>
          <div
            className="container"
            onClick={() => setModalRepaymentActive(false)}
          />
          <div className="div">
            <strong>Deseja reembolsar o pagamento?</strong>
            <span>
              O valor da compra será reembolsado para a conta corrente ou cartão
              de crédito do comprador
            </span>

            <button
              type="button"
              className="grey-button"
              onClick={() => setModalRepaymentActive(false)}
            >
              Cancelar
            </button>

            <button
              type="button"
              className="red-button"
              onClick={() => handleRepaymentOrder()}
            >
              Confirmar reembolso
            </button>
          </div>
        </ModalActive>
      )}
    </Container>
  );
}

export default MyOrdersDetails;
