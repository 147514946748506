import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import api from '../../../../../services/api';

import Header from '../Header';

import SeeProductsModal from './SeeProductsModal';

import { formatPrice } from '../../../../../util/format';

import ArrowRightIcon from '../../../../../assets/icons/arrowRight.svg';
import NoImage from '../../../../../assets/icons/noimage.png';

import MinusIcon from '../../../../../assets/icons/order/minus.svg';
import PlusIcon from '../../../../../assets/icons/order/plus.svg';

import {
  loadProductsRequest,
  AddAmountProductSuccess,
  RemAmountProductSuccess,
} from '../../../../../store/modules/order/actions';

import {
  Container,
  DivBody,
  DivInput,
  StyledDebounceInput,
  DivProducts,
  DivRem,
  Card,
  ButtonAdd,
  ButtonRem,
  DivFooter,
} from './styles';

function MyOrdersDetailsModal({ handleClickButton }) {
  // const [search, setSearch] = useState();
  const [showFooter] = useState(true);
  const [showSeeProductsModal, setShowSeeProductsModal] = useState(false);

  const dispatch = useDispatch();

  const products = useSelector(state => state.order.products);
  const selectedProducts = useSelector(
    state =>
      state.order &&
      state.order.products &&
      state.order.products.filter(p => p.amount > 0)
  );

  const total = useSelector(state =>
    formatPrice(
      state.order.products &&
        state.order.products.reduce((totalSum, p) => {
          return totalSum + (p.promotion_price || p.price) * p.amount;
        }, 0)
    )
  );

  useEffect(() => {
    dispatch(loadProductsRequest());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleAddAmountProduct(id) {
    dispatch(AddAmountProductSuccess(id));
  }

  function handleRemAmountProduct(id) {
    dispatch(RemAmountProductSuccess(id));
  }

  function handleClickBack() {
    handleClickButton();
  }

  async function handleSubmit() {
    await api.post('/orderrequestmanual', {
      total,
      cart: selectedProducts,
    });

    handleClickBack();
  }

  function seeProductsModal(modal) {
    setShowSeeProductsModal(modal);
  }

  return (
    <Container>
      <SeeProductsModal
        showModal={showSeeProductsModal}
        Modal={seeProductsModal}
        products={selectedProducts}
      />
      <Header title="Registrar pedido" handleClickBack={handleClickBack} />
      <DivBody>
        <DivInput>
          <StyledDebounceInput
            placeholder="Buscar produto"
            minLength={1}
            debounceTimeout={500}
            // onChange={e => setSearch(e.target.value)}
          />
        </DivInput>
        <DivProducts>
          {products &&
            products.map(p => (
              <Card key={p.id}>
                {p.amount > 0 && (
                  <DivRem>
                    <ButtonRem onClick={() => handleRemAmountProduct(p.id)}>
                      <img src={MinusIcon} alt="minus" />
                    </ButtonRem>
                  </DivRem>
                )}

                <div
                  className="body"
                  onClick={() => handleAddAmountProduct(p.id)}
                >
                  <img
                    src={
                      (p.images && p.images[0] && p.images[0].image_url) ||
                      NoImage
                    }
                    alt=""
                  />

                  <div>
                    <strong>{p.title}</strong>
                    <span>{p.promotion_price || p.price}</span>
                  </div>

                  <ButtonAdd active={p.amount > 0}>
                    {p.amount > 0 ? (
                      p.amount
                    ) : (
                      <img src={PlusIcon} alt="plus" />
                    )}
                  </ButtonAdd>
                </div>
              </Card>
            ))}
        </DivProducts>
      </DivBody>

      {/* {showProductsOrder && (
        <DivProductsOrder>
          <div>
            <header>
              <strong>Informações do pedido</strong>
              <img
                src={CancelIcon}
                alt="cancel"
                onClick={() => {
                  setShowFooter(true);
                  setShowProductsOrder(false);
                }}
              />
            </header>
            <span>Produtos</span>

            <DivProductOrder>
              {selectedProducts &&
                selectedProducts.map(p => (
                  <div key={p.id}>
                    <img
                      src={
                        (p.images && p.images[0] && p.images[0].image_url) || NoImage
                      }
                      alt="images"
                    />

                    <div>
                      <strong>
                        {p.amount > 1 ? `${p.amount}x` : ''} {p.title}
                      </strong>
                      <span>{p.promotion_price || p.price}</span>
                    </div>
                  </div>
                ))}
            </DivProductOrder>
          </div>
        </DivProductsOrder>
      )} */}

      {showFooter && (
        <DivFooter
          hasBackground={selectedProducts && selectedProducts.length > 0}
        >
          {selectedProducts && selectedProducts.length > 0 && (
            <button
              type="button"
              className="see-order"
              onClick={() => setShowSeeProductsModal(!showSeeProductsModal)}
            >
              <strong>Ver pedido</strong>
            </button>
          )}

          <button
            type="button"
            disabled={!(selectedProducts && selectedProducts.length > 0)}
            onClick={handleSubmit}
          >
            <span>{total}</span>
            <strong>Registrar pedido</strong>
            <img src={ArrowRightIcon} alt="arrow" />
          </button>
        </DivFooter>
      )}
    </Container>
  );
}

export default MyOrdersDetailsModal;
