/* eslint-disable jsx-a11y/accessible-emoji */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  activatedFreightEvent,
  seePlansSidebarBtnEvent,
} from '~/functions/events';
import loader from '../../assets/icons/loader.gif';
import alterIcon from '../../assets/icons/menu/alterIcon.svg';
// import blueConfigurationIcon from '../../assets/icons/menu/blueConfiguration.svg';
import carIcon from '../../assets/icons/menu/car.svg';
import cardIcon from '../../assets/icons/menu/card.svg';
import instagramIcon from '../../assets/icons/menu/instagram.svg';
import labelIcon from '../../assets/icons/menu/label.svg';
// import ProIcon from '../../assets/icons/menu/pro.svg';
import menuMobileIcon from '../../assets/icons/menuMobile.svg';
import offstoreIcon from '../../assets/icons/offstore.svg';
import OrderIcon from '../../assets/icons/order/order.svg';
import api from '../../services/api';
import AddAddress from '../AddAddress';

import dataPlans from '../../data/plans.json';

import {
  ButtonFreight,
  Container,
  DivHelp,
  DivInviteFriends,
  DivLoader,
  DivMenu,
  MenuItem,
  PromoNotification,
} from './styles';

function Header({ boot, shutdown }) {
  const [orderNotification, setOrderNotification] = useState();
  // const [orderQuantity, setOrderQuantity] = useState(0);
  const [showMenu, setShowMenu] = useState(false);
  const [buttonFreightDisabled] = useState(false);
  const [pro, setPro] = useState();
  const [showAddAddress, setShowAddAddress] = useState(false);
  // const [showProPlanPopUp, setShowProPlanPopUp] = useState(false);
  const [environment, setEnvironment] = useState();

  const { cep, page_title } = useSelector(state => state.user.profile);

  const history = useHistory();

  useEffect(() => {
    async function load() {
      // await api.get('/orderrequestcount').then(response => {
      //   setOrderQuantity(response.data);
      // });

      await api.get('/environment').then(res => {
        if (res && res.data) {
          setEnvironment(res.data);
        }
      });

      await api.get('/orderrequestnotification').then(response => {
        setOrderNotification(response.data.length);
      });

      await api.get('/proplan').then(response => {
        setPro(response.data);
      });
    }

    load();
  }, []);

  useEffect(() => {
    if (showMenu) {
      boot();
    } else {
      shutdown();
    }
  }, [boot, showMenu, shutdown]);

  async function handleCreateTableFlatRate() {
    try {
      // setShowAddAddress(false);
      // setButtonFreightDisabled(true);
      const existsFreight = await api.get('/configfreight');

      if (existsFreight && existsFreight.data) {
        console.log('');
      } else {
        await api.post('/createfreight');
      }

      history.push('/freight/configuration');
    } catch (err) {
      // setButtonFreightDisabled(false);
      toast.error('Ocorreu um erro ao habilitar sistema de frete');
    }
  }

  async function handleShowAddAddress() {
    shutdown();

    if (cep) {
      handleCreateTableFlatRate();
    } else {
      activatedFreightEvent();
      setShowAddAddress(true);
    }
  }

  // eslint-disable-next-line no-unused-vars
  function handleSetShowAddAddress(value, onSubmit) {
    setShowAddAddress(value);
  }

  // function ProPlanPopUp(pro_modal) {
  //   setShowProPlanPopUp(pro_modal);
  // }

  function sendToRoute(route) {
    shutdown();

    history.push(route);
  }

  return (
    <>
      <Container environment={environment}>
        <div className="div-logo">
          <img src={offstoreIcon} className="logo" alt="logo" />
        </div>

        <div className="div-menu">
          <div onClick={() => sendToRoute('/myorders')}>
            <img src={OrderIcon} alt="order" />

            {orderNotification > 0 && (
              <PromoNotification color="#FF6C4B">
                <span>{orderNotification}</span>
              </PromoNotification>
            )}
          </div>

          <div onClick={() => setShowMenu(!showMenu)}>
            <img src={menuMobileIcon} className="menu" alt="menu" />
          </div>
        </div>
      </Container>
      {/* <ProPlanModal showModal={showProPlanPopUp} Modal={ProPlanPopUp} /> */}

      <DivMenu active={showMenu}>
        <header>
          {/* <Link to="/settings">
              <img src={blueConfigurationIcon} alt="blue configuration" />
              <span>Configurações</span>
            </Link> */}
          <div>
            <strong>{page_title}</strong>
          </div>

          <div onClick={() => setShowMenu(!showMenu)}>
            <img src={menuMobileIcon} className="menu" alt="menu" />
          </div>
        </header>

        <MenuItem>
          <Link to="#" onClick={() => sendToRoute('/personalize')}>
            <div>
              <img src={alterIcon} alt="" />
            </div>

            <span>Personalizar</span>
          </Link>

          {/* <Link to="/freight/configuration"> */}
          <ButtonFreight
            type="button"
            onClick={handleShowAddAddress}
            // onClick={() => setShowAddAddress(true)}
            disabled={buttonFreightDisabled}
          >
            <div>
              <img src={carIcon} alt="car" />
            </div>

            <span>Editar frete</span>

            {/* <span className="new-label">Novo</span> */}

            {buttonFreightDisabled && (
              <DivLoader>
                <img src={loader} alt="loader" />
                <span>Aguarde alguns segundos...</span>
              </DivLoader>
            )}
          </ButtonFreight>

          {/* </Link> */}

          <Link to="#" onClick={() => sendToRoute('/paymentmethods')}>
            <div>
              <img src={cardIcon} alt="" />
            </div>

            <span>Forma de pagamento</span>
            {pro === 0 && <span className="pro">PRO</span>}
          </Link>

          <Link to="#" onClick={() => sendToRoute('/coupons')}>
            <div>
              <img src={labelIcon} alt="coupons" />
            </div>

            <span>Cupons</span>

            {pro === 0 && <span className="pro">PRO</span>}
          </Link>

          <Link to="#" onClick={() => sendToRoute('/integrations/instagram')}>
            <div>
              <img src={instagramIcon} alt="car" />
            </div>

            <span>Vincular ao Instagram</span>

            {pro === 0 && <span className="pro">PRO</span>}
          </Link>

          <Link
            to="#"
            onClick={() => sendToRoute('/settings')}
            className="link"
          >
            <span>Mais opções</span>
          </Link>
          {/*  */}
          {/* <Link to="#">
              <div>
                <img src={myPlanIcon} alt="" />
              </div>

              <span>Pedidos</span>

              <span className="soon-label">Breve</span>
            </Link> */}

          {/* <Link to="/myplan">
              <div>
                <img src={myPlanIcon} alt="" />
              </div>

              <span>Meu plano</span>
            </Link> */}

          {/* <Link to="/myaccount">
              <div>
                <img src={myAccountIcon} alt="" />
              </div>

              <span>Minha conta</span>
            </Link> */}

          {/* <Link to="/myindications">
              <div>
                <img src={myIndicationsIcon} alt="" />
              </div>

              <span>Convidar amigos</span>
            </Link> */}
          {pro === 0 && (
            <>
              <DivInviteFriends
                onClick={() => {
                  seePlansSidebarBtnEvent();
                  sendToRoute('/pro');
                }}
              >
                <strong>Assinar plano {dataPlans.pro.name}</strong>

                <svg
                  width="18"
                  height="12"
                  viewBox="0 0 18 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.999726 6L16.7627 6"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12.0222 11L17 6L12.0222 1"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </DivInviteFriends>
              <span className="span-promo">
                Assine com {dataPlans.pro.price.yearly.economyPercent} de
                desconto
              </span>
            </>
          )}
        </MenuItem>

        <footer>
          {/* {pro === 0 && productQuantity >= 2 && (
              <Link to="/myindications">
                <div>
                  <img src={IndicationIcon} alt="config" />
                </div>

                <span>Indicações</span>
              </Link>
            )} */}

          {/* <Link to="/configurations">
              <div>
                <img src={ConfigurationIcon} alt="config" />
              </div>

              <span>Configurações</span>
            </Link> */}

          <DivHelp>
            {/* <strong>Precisa de ajuda ?</strong> */}

            <a
              href="https://instagram.com/offstore.me/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={instagramIcon} alt="instagram" />
              Suporte via Instagram
            </a>
          </DivHelp>
        </footer>
      </DivMenu>

      {showAddAddress && (
        <AddAddress
          handleCreateTableFlatRate={handleCreateTableFlatRate}
          handleSetShowAddAddress={handleSetShowAddAddress}
          hasFunction
          isModal={false}
        />
      )}
    </>
  );
}

export default Header;
