/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/prop-types */
import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import api from '../../../../services/api';

import loading from '../../../../assets/icons/loading-white.gif';
import DeleteModal from '../../../../components/DeleteModal';

import {
  ContainerGeral,
  Container,
  Div,
  DivTitle,
  Input,
  Button,
  StyledSelect,
  DivCategory,
  SeeMore,
  SpanPro,
} from './styles';

import { InsertOrUpdateTagRequest } from '../../../../store/modules/linkPrev/actions';

export default function TagModal({
  showModal,
  Modal,
  updateTagsComponent,
  handleClickDelete,
  TagId,
  pro,
}) {
  const dispatch = useDispatch();
  const [tagName, setTagName] = useState();
  const [seeMore, setSeeMore] = useState(false);
  const [allTags, setAllTags] = useState([]);
  const [tags, setTags] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleModal = useCallback(
    confirm => {
      if (confirm) {
        setTimeout(() => {
          setTagName('');
          Modal(false);
          updateTagsComponent((tags && tags.id) || null);
          setIsLoading(false);

          if (TagId > 0) {
            toast.success('Alteração salva');
          } else {
            toast.success('Categoria criada');
          }
        }, 2000);
      } else {
        setTagName('');
        Modal(false);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [Modal, TagId, tags, updateTagsComponent]
  );

  useEffect(() => {
    async function loadTag() {
      if (TagId > 0) {
        await api.get(`tags/${TagId}`).then(response => {
          setTagName(response.data.tag);

          if (response.data.master_id) {
            setTags(response.data.master);
            setSeeMore(true);
          } else {
            setSeeMore(false);
          }
        });
      } else {
        setTagName('');
        setTags([]);
        setSeeMore(false);
      }

      await api.get('/alltagsbyuser').then(response => {
        const tagsNoAll =
          response.data &&
          response.data.filter(
            tag =>
              tag.tag !== 'Todos' && tag.id !== TagId && tag.master_id !== TagId
          );

        const result = tagsNoAll.map(tag => {
          if (tag.isDuplicated && tag.master_id) {
            return {
              ...tag,
              label: `${tag.label} (${tag.master.label})`,
            };
          }

          return tag;
        });

        setAllTags(result);
      });
    }

    loadTag();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [TagId]);

  async function handleSubmit() {
    try {
      if (tagName) {
        setIsLoading(true);
        dispatch(
          InsertOrUpdateTagRequest(
            TagId,
            tagName,
            tags.length === 0 ? null : tags
          )
        );
        handleModal(true);
      } else {
        toast.error('Digite o nome da Categoria');
      }
    } catch (err) {
      toast.error('Erro ao inserir ou alterar Tag');
      setIsLoading(false);
    }
  }

  function verifyOnEnter(e) {
    if (e.key === 'Enter') {
      handleSubmit();
    }

    if (e.key === 'Escape') {
      handleModal();
    }
  }

  function handleClickDeleteTag() {
    setShowDeleteModal(true);
    // handleClickDelete(TagId);
  }

  function deleteModal(modal, canceled) {
    setShowDeleteModal(modal);

    if (canceled) {
      handleClickDelete(TagId);
    }
  }

  return (
    <ContainerGeral>
      <DeleteModal
        showModal={showDeleteModal}
        Modal={deleteModal}
        title="Excluir categoria?"
        body="Tem certeza que deseja excluir essa categoria?"
        textConfirmButton="Excluir categoria"
      />
      <Container showModal={showModal} onClick={() => handleModal(false)} />
      <Div showModal={showModal}>
        <DivTitle>
          <span>{TagId > 0 ? 'Alterar categoria:' : 'Inserir categoria:'}</span>

          {TagId > 0 && (
            <button type="button" onClick={handleClickDeleteTag}>
              Excluir
            </button>
          )}
        </DivTitle>

        <div className="div-body">
          <span>Nome da categoria</span>
          <Input
            type="text"
            placeholder="Ex: Calçados"
            value={tagName}
            onChange={e => setTagName(e.target.value)}
            onKeyDown={verifyOnEnter}
          />

          {seeMore && (
            <DivCategory>
              <div>
                <strong>Categoria mãe</strong>
                <span>Opcional</span>
                {pro === 0 && <SpanPro>PRO</SpanPro>}
              </div>

              <span>Exemplo: “Roupas” é a categoria mãe de “Calças”</span>

              <StyledSelect
                id="selected"
                placeholder="Selecionar"
                name="tg"
                // isMulti
                options={allTags}
                value={tags}
                onChange={e => setTags(e)}
                isClearable
                disabled={pro === 0}
              />
            </DivCategory>
          )}

          <SeeMore onClick={() => setSeeMore(!seeMore)}>
            {seeMore ? 'Mostrar menos' : 'Mostrar mais'}
          </SeeMore>

          <Button onClick={handleSubmit}>
            {isLoading ? <img src={loading} alt="" /> : 'Confirmar'}
          </Button>
        </div>
      </Div>
    </ContainerGeral>
  );
}
