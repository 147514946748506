/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unknown-property */
import React, { useEffect, useState, useRef } from 'react';
import { format, parseISO } from 'date-fns';
import { pt } from 'date-fns/locale';
import { toast } from 'react-toastify';
import { useReactToPrint } from 'react-to-print';

import api from '~/services/api';

import { formatPrice } from '~/util/format';
import LoadingContainer from '~/components/LoadingContainer';
import { Input } from '~/designsystem/Fonts';
import { isValidCnpj, isValidCpf } from '~/services/functions';
import PenBlue from '../../../../../assets/icons/order/penBlue.svg';
import {
  Container,
  DivHeader,
  DivAddress,
  DivSenderOrAddressee,
  DivProducts,
  DivTextDeclaration,
  DivComments,
  DivPageTitle,
  DivInfoInput,
  DivDisclaimer,
  ToggleButton,
  DivToggleButton,
  DivDeclarationContent,
  DivLeftContainer,
  DivRightContainer,
  Gap,
  ContainerGeneralModal,
  ContainerModal,
  TitleModal,
  InputContainer,
  ContainerButtonModal,
  Divider,
  DivEditButton,
  BodyContainer,
} from './styles';
import TicketIcon from '../../../../../assets/icons/order/ticket.svg';
import Header from '../Header';

export default function ContentDeclaration({ handleClickButton, id }) {
  const ref = useRef(null);
  const cnpjInputRef = useRef(null);
  const cpfInputRef = useRef(null);

  const [order, setOrder] = useState();
  const [products, setProducts] = useState();
  const [loading, setLoading] = useState(true);
  const [sumKeys, setSumKeys] = useState({});

  const [selectedType, setSelectedType] = useState('CNPJ');
  const [showModal, setShowModal] = useState(false);
  const [cpf, setCpf] = useState('');
  const [cnpj, setCnpj] = useState('');

  const types = {
    ALL: 'all',
    WEIGHT: 'weight',
    AMOUNT: 'amount',
    PRICE: 'price',
  };

  function handleCalculateTotal(array, keys) {
    const totals = {};

    keys.forEach(key => {
      const values = array.map(item => {
        if (key === types.AMOUNT) return Number(item[key]);
        return Number(item[key]) * Number(item.amount);
      });
      const sumTotal = values.reduce((total, value) => {
        return total + value;
      }, 0);
      totals[key] = sumTotal;
    });

    setSumKeys(totals);

    return totals;
  }

  async function handleSubmit(data) {
    await api
      .put('/userconfigurations', data)
      .then(() => {
        setCpf(data.cpf);
        setCnpj(data.cnpj);
        setShowModal(false);
        return toast.success('Informação salva!');
      })
      .catch(() => {
        setShowModal(false);
        return toast.error('Erro');
      });
  }

  useEffect(() => {
    async function getUserConfigurations() {
      await api.get('/userconfigurations').then(res => {
        setCnpj(res.data.cnpj);
        setCpf(res.data.cpf);
      });
    }

    getUserConfigurations();
  }, []);

  useEffect(() => {
    async function load() {
      await api.post(`/orderrequestbyid/${id}`).then(async response => {
        setOrder({
          ...response.data,
          date: format(parseISO(response.data.createdAt), "dd'/'MM'/'yyyy", {
            locale: pt,
          }),
        });

        handleCalculateTotal(response.data.products, [
          types.WEIGHT,
          types.PRICE,
          types.AMOUNT,
        ]);

        setProducts(
          response.data.products.map(r => ({
            ...r,
            totalPrice: formatPrice(r.price),
          }))
        );

        setLoading(false);
      });
    }

    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  function handleClickBack() {
    handleClickButton();
  }

  const handlePrint = useReactToPrint({
    content: () => ref.current,
    pageStyle: '@page { size: auto;  margin: 10mm;}',
  });

  const DeclarationContent = () => {
    return (
      <DivDeclarationContent ref={ref}>
        <DivHeader>
          <span>DECLARAÇÃO DE CONTEÚDO</span>
        </DivHeader>
        <DivAddress>
          <DivSenderOrAddressee>
            <div className="title">
              <strong>REMETENTE</strong>
            </div>
            <div className="label">
              <strong>NOME: </strong>
              <b>{order.User.page_title}</b>
            </div>
            <div className="label">
              <strong>ENDEREÇO: </strong>
              <b>
                {order.User.address}, {order.User.number}, {order.User.district}
              </b>
            </div>

            <div className="label" />

            <div className="labelCityUf">
              <div className="cityLabel">
                <strong className="titleText">CIDADE: </strong>
                <b> {order.User.city}</b>
              </div>
              <div className="ufLabel">
                <strong className="titleText">UF: </strong>
                <b>{order.User.uf}</b>
              </div>
            </div>
            <div className="label">
              <strong>CEP: </strong>
              <b>{order.User.cep}</b>
            </div>
            <div className="label">
              <strong>CPF/CNPJ: </strong>
              <b>{selectedType === 'CNPJ' ? cnpj : cpf}</b>
            </div>
          </DivSenderOrAddressee>
          <Gap />
          <DivSenderOrAddressee>
            <div className="title">
              <strong>DESTINATÁRIO</strong>
            </div>
            <div className="label">
              <strong>NOME:</strong>
              <b>{order && order.name}</b>
            </div>
            <div className="label">
              <strong>ENDEREÇO:</strong>
              <b>
                {order &&
                  (order.address ||
                    order.number ||
                    order.complement ||
                    order.neighborhood ||
                    order.city ||
                    order.uf ||
                    order.cep) &&
                  `${order.address}, ${order.number}
                  ${order.complement ? order.complement : ''}`}
              </b>
            </div>

            <div className="label" />

            <div className="labelCityUf">
              <div className="cityLabel">
                <strong className="titleText">CIDADE: </strong>
                <b> {order.city || ''}</b>
              </div>
              <div className="ufLabel">
                <strong className="titleText">UF: </strong>
                <b>{order.uf || ''}</b>
              </div>
            </div>

            <div className="label">
              <strong>CEP:</strong>
              <b>{order && order.cep}</b>
            </div>
            <div className="label">
              <strong>CPF/CNPJ:</strong>
              <b>{order.cpf ? order.cpf : ''}</b>
            </div>
          </DivSenderOrAddressee>
        </DivAddress>
        <DivProducts>
          <div className="coloredLabel">
            <strong>IDENTIFICAÇÃO DOS BENS</strong>
          </div>

          <div className="label" padding>
            <div className="label52">
              <strong className="titleText">CONTEÚDO</strong>
            </div>
            <div className="label16">
              <strong className="titleText">QUANTIDADE</strong>
            </div>
            <div className="label16">
              <strong className="titleText">PESO</strong>
            </div>
            <div className="label16">
              <strong className="titleText">VALOR</strong>
            </div>
          </div>

          {/* map dos produtos */}
          {products.map(product => (
            <div className="label" padding key={product.id}>
              <div className="label52">
                <strong className="titleText">{product.name}</strong>
              </div>
              <div className="label16">
                <strong className="titleText">{product.amount}</strong>
              </div>
              <div className="label16">
                <strong className="titleText">{product.weight}kg</strong>
              </div>
              <div className="label16">
                <strong className="titleText">R$ {product.price}</strong>
              </div>
            </div>
          ))}

          <div className="label" padding={false}>
            <div className="label52Colored">
              <strong className="totalText">TOTAL</strong>
            </div>
            <div className="label16Total">
              <strong>{sumKeys[types.AMOUNT] || ''}</strong>
            </div>
            <div className="label16Total">
              <strong>{sumKeys[types.WEIGHT] || ''}kg</strong>
            </div>
            <div className="label16Total">
              <strong>{formatPrice(sumKeys[types.PRICE]) || ''}</strong>
            </div>
          </div>
        </DivProducts>
        <DivTextDeclaration>
          <div className="coloredLabel">
            <strong>DECLARAÇÃO</strong>
          </div>
          <div className="textLabel">
            <p>
              Declaro que não me enquadro no conceito de contribuinte previsto
              no art. 4º da Lei Complementar nº 87/1996, uma vez que não
              realizo, com habitualidade ou em volume que caracterize intuito
              comercial, operações de circulação de mercadoria, ainda que se
              iniciem no exterior, ou estou dispensado da emissão da nota fiscal
              por força da legislação tributária vigente, responsabilizando-me,
              nos termos da lei e a quem de direito, por informações
              inverídicas. Declaro ainda que não estou postando conteúdo
              inflamável, explosivo, causador de combustão espontânea, tóxico,
              corrosivo, gás ou qualquer outro conteúdo que conste na lista de
              proibições e restrições disponível no site dos Correios:
              https://www.correios.com.br/enviar/proibicoes-e-restricoes/proibicoes-e-restricoes.
            </p>
            <div>
              <p className="paddingTop12">
                __________________________________________________, ___________
                de ________________________________ de ______________
                ____________________________________________________
              </p>
              <span className="padding40">
                Assinatura do declarante/Remetente
              </span>
            </div>
          </div>
        </DivTextDeclaration>
        <DivComments>
          <strong>OBSERVAÇÃO</strong>
          <p>
            Constitui crime contra a ordem tributária suprimir ou reduzir
            tributo, ou contribuição social e qualquer acessório (Lei 8.137/90
            Art. 1º, V).
          </p>
        </DivComments>
      </DivDeclarationContent>
    );
  };

  const EditDataModal = props => {
    const [newCpf, setNewCpf] = useState(props.oldCpf);
    const [newCnpj, setNewCnpj] = useState(props.oldCnpj);
    const [cpfError, setCpfError] = useState('');
    const [cnpjError, setCnpjError] = useState('');

    function onSubmit() {
      console.log('onSubmit data: ', newCpf, newCnpj);
      const data = {};
      let hasErrorCpf = false;
      let hasErrorCnpj = false;

      if (newCpf !== '' && newCpf !== undefined && newCpf !== null) {
        const isValidCpfVerification = isValidCpf(newCpf);
        if (isValidCpfVerification) {
          setCpfError('');
          hasErrorCpf = false;

          data.cpf = newCpf;
        } else {
          hasErrorCpf = true;
          setCpfError('CPF inválido');
        }
      }
      if (newCnpj !== '' && newCnpj !== undefined && newCnpj !== null) {
        const isValidCnpjVerification = isValidCnpj(newCnpj);
        if (isValidCnpjVerification) {
          setCnpjError('');
          hasErrorCnpj = false;

          data.cnpj = newCnpj;
        } else {
          hasErrorCnpj = true;
          setCnpjError('CNPJ inválido');
        }
      }
      if (!hasErrorCnpj && !hasErrorCpf) {
        if (!data.cnpj) data.cnpj = '';
        if (!data.cpf) data.cpf = '';

        handleSubmit(data);
      }
    }

    return (
      <ContainerGeneralModal
        onClick={e => {
          if (e.target === e.currentTarget) {
            setShowModal(false);
          }
        }}
      >
        <ContainerModal>
          <TitleModal>Dados do negócio</TitleModal>
          <InputContainer>
            <strong>CPF do responsável</strong>
            <Input
              ref={cpfInputRef}
              type="text"
              placeholder="CPF"
              autoComplete="no"
              maxLength={11}
              value={newCpf}
              onChange={e => setNewCpf(e.target.value.replace(/[^0-9]/g, ''))}
            />
            {cpfError && <span className="errorMessage">{cpfError}</span>}
          </InputContainer>
          <InputContainer>
            <strong>CNPJ</strong>
            <Input
              ref={cnpjInputRef}
              type="text"
              placeholder="CNPJ"
              autoComplete="no"
              maxLength={14}
              value={newCnpj}
              onChange={e => setNewCnpj(e.target.value.replace(/[^0-9]/g, ''))}
            />
            {cnpjError && <span className="errorMessage">{cnpjError}</span>}
          </InputContainer>
          <ContainerButtonModal>
            <button type="button" onClick={onSubmit}>
              Salvar alterações
            </button>
          </ContainerButtonModal>
        </ContainerModal>
      </ContainerGeneralModal>
    );
  };

  if (loading) return <LoadingContainer />;
  return (
    <Container>
      <Header
        title="Imprimir declaração de conteúdo"
        handleClickBack={handleClickBack}
      />
      <BodyContainer>
        <DivLeftContainer>
          <DivPageTitle>
            <strong>Informações da declaração de conteúdo</strong>
          </DivPageTitle>
          <DivInfoInput>
            <strong>Seu CPF ou CNPJ</strong>
            <p>
              É obrigatório informar seu CPF ou CNPJ na declaração de conteúdo
            </p>
            <DivToggleButton>
              <ToggleButton
                left
                active={selectedType === 'CPF'}
                selectedFontWeigth={selectedType}
                onClick={() => setSelectedType('CPF')}
              >
                CPF
              </ToggleButton>
              <ToggleButton
                right
                active={selectedType === 'CNPJ'}
                selectedFontWeigth={selectedType}
                onClick={() => setSelectedType('CNPJ')}
              >
                CNPJ
              </ToggleButton>
            </DivToggleButton>
            <DivEditButton>
              <button type="button" onClick={() => setShowModal(true)}>
                <img src={PenBlue} alt="editIcon" />
                Editar CPF/CNPJ
              </button>
            </DivEditButton>
            <Divider />
          </DivInfoInput>
          <button type="button" onClick={() => handlePrint()}>
            Imprimir declaração de conteúdo
            <img src={TicketIcon} alt="cancel" />
          </button>
        </DivLeftContainer>
        <DivRightContainer>
          <DivDisclaimer>
            <p>
              A declaração de conteúdo é preenchida automaticamente com os dados
              da compra
            </p>
          </DivDisclaimer>

          <DeclarationContent ref={ref} />
        </DivRightContainer>
      </BodyContainer>
      {showModal && <EditDataModal oldCpf={cpf} oldCnpj={cnpj} />}
    </Container>
  );
}
