/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable no-shadow */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { v4 as uuidv4 } from 'uuid';

import { toast } from 'react-toastify';

import AddMainModal from '~/components/Modals/AddMainModal';
import CollectionModal from '~/components/Modals/CollectionModal';

import api from '~/services/api';
import {
  Container,
  Header,
  Body,
  Loader,
  DivCards,
  Card,
  ContainerAddElement,
  ContainerBackground,
  DivAddElement,
  DivSelectElement,
  DivSelect,
  StyledSelect,
  DivButton,
} from './styles';

// import ImagePoints from '../../../../../assets/icons/personalize/points.svg';
import AnchorIcon from '../../../../../assets/icons/personalize/anchor.svg';
import ExitIcon from '../../../../../assets/icons/personalize/exit.svg';
import loaderGrey from '../../../../../assets/icons/loader-grey.gif';

export default function Elements({ handleSetPage }) {
  const [components, setComponents] = useState([]);
  const [selectedElement, setSelectedElement] = useState('Coleção');
  const [showModalAddElement, setShowModalAddElement] = useState(false);
  const [showModalEditElement, setShowModalEditElement] = useState(false);
  const [showModalCollection, setShowModalCollection] = useState(false);
  const [categories, setCategories] = useState([]);
  const [pageComponents, setPageComponents] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState();

  const [layoutComponent, setLayoutComponent] = useState();
  const [loader, setLoader] = useState(true);
  // const [deletingId, setDeletingId] = useState(0);

  const { id: userId } = useSelector(state => state.user.profile);

  async function loadData() {
    try {
      setLoader(true);

      await api.get('/userlayoutcomponents').then(async response => {
        if (response.data) {
          const comp = await Promise.all(
            response.data.map(async res => {
              if (res.PageComponent.title === 'Coleção') {
                const collection = await api.get(
                  `/collectionsnolinks/${res.id}`
                );

                return {
                  ...res,
                  collection: collection.data,
                };
              }

              return res;
            })
          );

          setComponents(comp);
        }
      });

      await api.get('/pagecomponents').then(async response => {
        if (response.data) {
          setPageComponents(response.data);
        }
      });

      await api.get('/tagsbyuser').then(async response => {
        if (response.data) {
          const result = response.data.filter(r => r.label !== 'Todos');
          setCategories(result);
        }
      });
    } finally {
      setLoader(false);
    }
  }

  useEffect(() => {
    loadData();
  }, []);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  async function handleSaveData(comp) {
    const response = await api.put('/userlayoutcomponents', {
      components: comp || components,
    });

    if (response.status === 200) {
      toast.success('Dados atualizados');
      // handleChangeShowModalUserComponents(false);
    } else {
      toast.error('Ocorreu um problema ao salvar os dados');
    }
  }

  function onDragEnd(result) {
    if (!result.destination || result.destination.index === 0) {
      return;
    }

    const componentsReordered = reorder(
      components,
      result.source.index,
      result.destination.index
    );

    const newOrderArray = componentsReordered.map((cr, index) => {
      return {
        ...cr,
        order: index,
      };
    });
    setComponents(newOrderArray);

    handleSaveData(newOrderArray);
  }

  function enableEditComponent(title) {
    if (
      title === 'Banner principal' ||
      title === 'Banner' ||
      title === 'Coleção'
      // title === 'Categoria em destaque'
    ) {
      return true;
    }

    return false;
  }

  function handleShowModalEditElement(value) {
    setShowModalEditElement(value);
  }

  function handleShowModalCollection(value, obj) {
    setShowModalCollection(value);

    if (obj) {
      const index = components.findIndex(
        c => c.id === obj.user_layout_component_id
      );

      const arr = [...components];
      arr[index].collection = obj;

      setComponents(arr);
    }
  }

  async function createCategory(component) {
    if (selectedCategory) {
      await api.post('/createuserlayoutcomponentstags', {
        user_layout_component_id: component.id,
        tag_id: selectedCategory.id,
      });
    }
  }

  function handleSelectModal(component) {
    setLayoutComponent(component);
    switch (component.PageComponent.title) {
      case 'Banner principal':
        handleShowModalEditElement(true);
        break;

      case 'Banner':
        handleShowModalEditElement(true);
        break;

      case 'Coleção':
        handleShowModalCollection(true);
        break;

      case 'Categoria em destaque':
        createCategory(component);
        break;

      default:
        break;
    }
  }

  function enableRemoveComponent(title) {
    if (title === 'Categoria em destaque') {
      return true;
    }

    return false;
  }

  async function handleDeleteCard(obj) {
    try {
      // setDeletingId(obj.id);

      const filteredComponents = components.filter(c => c.id !== obj.id);

      if (obj.isNew) {
        const newOrderArray = filteredComponents.map((cr, index) => {
          return {
            ...cr,
            order: index,
          };
        });

        setComponents(newOrderArray);

        return 0;
      }

      await api
        .post(`/deleteuserlayoutcomponents/${obj.id}`, {
          components: filteredComponents,
        })
        .then(response => {
          if (response.status === 200) {
            const newOrderArray = filteredComponents.map((cr, index) => {
              return {
                ...cr,
                order: index,
              };
            });

            setComponents(newOrderArray);
          }
        });

      return 0;
    } finally {
      // setDeletingId(0);
    }
  }

  async function onChangeCheck(componentId, index) {
    const array = [...components];

    const value = !array[index].active;
    array[index].active = value;

    setComponents(array);

    const response = await api.put(
      `/updateuserlayoutcomponents/${componentId}`,
      {
        active: value,
      }
    );

    if (array[index].PageComponent.title === 'Coleção') {
      await api.put(`/collections/${componentId}`, {
        active: value,
      });
    }

    if (response.status === 200) {
      toast.success('Dados atualizados');
    } else {
      toast.error('Ocorreu um problema ao salvar os dados');
    }
  }

  function getComponent(title) {
    const result = pageComponents.find(p => p.title === title);

    return result;
  }

  async function handleAddNewElement() {
    // collection === 6
    // banner === 4

    // const response = await api.post('/userlayoutcomponents', {
    //   page_components_id: selectedElement === 'collection' ? 6 : 4,
    // });

    const newComponent = {
      id: uuidv4(),
      order: components.length,
      active: true,
      is_deleteable: true,
      user_id: userId,
      page_components_id: getComponent(selectedElement).id,
      PageComponent: {
        id: getComponent(selectedElement).id,
        title: selectedElement,
      },
      isNew: true,
    };

    const response = await api.post('/userlayoutcomponents', {
      component: newComponent,
      element: selectedElement,
    });

    if (response.status === 200) {
      const arr = [...components, response.data];

      setComponents(arr);

      setShowModalAddElement(false);
      handleSelectModal(response.data);
    }
  }

  return (
    <>
      <Container>
        <Header>
          <div onClick={() => handleSetPage('main')}>
            <svg
              width="12"
              height="22"
              viewBox="0 0 12 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11 21L1 11L11 1"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>

          <h1>Página inicial</h1>
        </Header>

        <Body>
          <span>Arraste e solte para mudar a posição das seções da página</span>

          <button type="button" onClick={() => setShowModalAddElement(true)}>
            Adicionar seção
          </button>

          {loader && (
            <Loader>
              <img src={loaderGrey} alt="loader" />
            </Loader>
          )}

          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, _) => (
                <DivCards {...provided.droppableProps} ref={provided.innerRef}>
                  {components &&
                    components.map((c, index) => (
                      <Draggable
                        key={String(c.id)}
                        draggableId={String(c.id)}
                        index={index}
                        isDragDisabled={
                          c.PageComponent.title === 'Banner principal' ||
                          c.PageComponent.title === 'Avisos'
                        }
                      >
                        {(provided, __) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="div-linklist"
                          >
                            <Card
                              opacity={
                                c.PageComponent.title ===
                                ('Banner principal' || 'Avisos')
                                  ? '0.5'
                                  : '1'
                              }
                            >
                              <img src={AnchorIcon} alt="anchor" />

                              <div
                                className="middle"
                                onClick={() =>
                                  enableEditComponent(c.PageComponent.title)
                                    ? handleSelectModal(c)
                                    : {}
                                }
                              >
                                <div>
                                  <strong>{c.PageComponent.title}</strong>

                                  {c.PageComponent.title === 'Coleção' && (
                                    <span>
                                      {c.collection && c.collection.title}
                                    </span>
                                  )}
                                </div>

                                {!!enableEditComponent(
                                  c.PageComponent.title
                                ) && (
                                  <button
                                    type="button"
                                    onClick={() => handleSelectModal(c)}
                                  >
                                    <svg
                                      width="12"
                                      height="12"
                                      viewBox="0 0 12 12"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M4.46266 10.0308L1 11L1.96954 7.53869L8.31005 1.23143C8.38172 1.15817 8.4673 1.09996 8.56178 1.06021C8.65626 1.02047 8.75773 1 8.86023 1C8.96274 1 9.06421 1.02047 9.15869 1.06021C9.25316 1.09996 9.33875 1.15817 9.41041 1.23143L10.7724 2.60057C10.8445 2.67207 10.9018 2.75714 10.9408 2.85087C10.9799 2.94461 11 3.04514 11 3.14668C11 3.24822 10.9799 3.34876 10.9408 3.44249C10.9018 3.53622 10.8445 3.62129 10.7724 3.6928L4.46266 10.0308Z"
                                        stroke="#0D85FF"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                    Editar
                                  </button>
                                )}
                              </div>

                              <div className="right">
                                {!!enableRemoveComponent(
                                  c.PageComponent.title
                                ) && (
                                  <button
                                    type="button"
                                    onClick={() => handleDeleteCard(c)}
                                    className="delete"
                                  >
                                    Excluir
                                  </button>
                                )}

                                <label className="switch">
                                  <input
                                    type="checkbox"
                                    checked={c.active}
                                    onChange={() => onChangeCheck(c.id, index)}
                                  />
                                  <span className="slider round" />
                                </label>

                                {/* {c.is_deleteable && deletingId !== c.id && (
                                  <img
                                    src={ExitIcon}
                                    alt="exit"
                                    onClick={() => handleDeleteCard(c)}
                                  />
                                )}

                                {deletingId === c.id && (
                                  <img
                                    src={loaderGrey}
                                    alt="loader"
                                    className="loader"
                                  />
                                )} */}
                              </div>
                            </Card>
                          </div>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </DivCards>
              )}
            </Droppable>
          </DragDropContext>
        </Body>
      </Container>

      {showModalAddElement && (
        <ContainerAddElement>
          <ContainerBackground onClick={() => setShowModalAddElement(false)} />

          <DivAddElement>
            <div className="header">
              <h1>Adicionar seção</h1>

              <div onClick={() => setShowModalAddElement(true)}>
                <img src={ExitIcon} alt="exit" />
              </div>
            </div>

            <div className="body">
              <strong>Selecionar seções</strong>

              <span>Selecione a seção que você quer adicionar à página</span>

              <DivSelectElement>
                <div
                  className={selectedElement === 'Coleção' && 'selected'}
                  onClick={() => setSelectedElement('Coleção')}
                >
                  Coleção
                </div>
                <div
                  className={selectedElement === 'Banner' && 'selected'}
                  onClick={() => setSelectedElement('Banner')}
                >
                  Banner
                </div>
                {/* <div
                  className={
                    selectedElement === 'Categoria em destaque' && 'selected'
                  }
                  onClick={() => setSelectedElement('Categoria em destaque')}
                >
                  Categoria em destaque
                </div> */}
                {/* <div
                  className={selectedElement === 'category_group' && 'selected'}
                  onClick={() => setSelectedElement('category_group')}
                >
                  Grupo de categorias
                </div> */}
                {/* <div
                  className={selectedElement === 'category_group' && 'selected'}
                  onClick={() => setSelectedElement('category_group')}
                >
                  Grupo de categorias
                </div> */}
              </DivSelectElement>

              {selectedElement === 'Categoria em destaque' && (
                <DivSelect>
                  <strong>Selecionar categoria</strong>
                  <StyledSelect
                    id="selected"
                    placeholder="Selecionar"
                    name="categories"
                    // isMulti
                    options={categories}
                    value={selectedCategory}
                    onChange={e => setSelectedCategory(e)}
                    isClearable
                  />
                </DivSelect>
              )}
            </div>

            <DivButton>
              <button type="button" onClick={handleAddNewElement}>
                Adicionar
              </button>
            </DivButton>
          </DivAddElement>
        </ContainerAddElement>
      )}

      {showModalEditElement && (
        <AddMainModal
          handleShowModalEditElement={handleShowModalEditElement}
          component={layoutComponent}
          handleDeleteCard={handleDeleteCard}
        />
      )}

      {showModalCollection && (
        <CollectionModal
          handleShowModalCollection={handleShowModalCollection}
          component={layoutComponent}
          handleDeleteCard={handleDeleteCard}
        />
      )}
    </>
  );
}
