/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import ResponsiveHeader from '../../../../../../components/HeaderMobile';
import BankIc from '../../../../../../assets/icons/paymentmethods/bank.svg';

import {
  Container,
  Body,
  ConfigurationSection,
  ConfigurationSectionTitle,
  ConfigurationSectionSubtitle,
  GenericButton,
  BankDataWrapper,
  BankIcon,
  BankData,
  SelectButton,
  SelectButtonsWrapper,
  SelectButtonBottomInfoWrapper,
  SelectButtonWrapper,
  Card,
  CardsWrapper,
} from './styles';

const receivingMethods = [
  {
    id: 1,
    name: 'Em 30 dias',
    tax: '4.49%',
    way: 'no cartão',
  },
  {
    id: 2,
    name: 'Em 15 dias',
    tax: '4.99%',
    way: 'no cartão',
  },
  {
    id: 3,
    name: 'Na hora',
    tax: '4.99%',
    way: 'no cartão',
  },
];

const ReceivingMethods = ({ onSelectReceivingMethod }) => {
  const [selectedReceivingMethod, setSelectedReceivingMethod] = useState({
    id: 1,
    name: 'Em 30 dias',
    tax: '4.49%',
    way: 'no cartão',
  });

  const handleSelectReceivingMethod = method => {
    setSelectedReceivingMethod(method);
    onSelectReceivingMethod(method);
  };

  return (
    <SelectButtonsWrapper>
      {receivingMethods.map((method, index) => {
        if (index === 0) {
          return (
            <SelectButtonWrapper>
              <SelectButton
                onClick={() => handleSelectReceivingMethod(method)}
                className={
                  selectedReceivingMethod.id === method.id
                    ? 'left-edge is-selected'
                    : 'left-edge'
                }
                key={method.id}
              >
                <p>{method.name}</p>
              </SelectButton>
              <SelectButtonBottomInfoWrapper>
                <p>{method.tax}</p>
                <p>{method.way}</p>
              </SelectButtonBottomInfoWrapper>
            </SelectButtonWrapper>
          );
        }

        if (index === receivingMethods.length - 1) {
          return (
            <SelectButtonWrapper>
              <SelectButton
                onClick={() => handleSelectReceivingMethod(method)}
                className={
                  selectedReceivingMethod.id === method.id
                    ? 'right-edge is-selected'
                    : 'right-edge'
                }
                key={method.id}
              >
                <p>{method.name}</p>
              </SelectButton>
              <SelectButtonBottomInfoWrapper>
                <p>{method.tax}</p>
                <p>{method.way}</p>
              </SelectButtonBottomInfoWrapper>
            </SelectButtonWrapper>
          );
        }

        return (
          <SelectButtonWrapper>
            <SelectButton
              className={
                selectedReceivingMethod.id === method.id ? 'is-selected' : ''
              }
              key={method.id}
              onClick={() => handleSelectReceivingMethod(method)}
            >
              <p>{method.name}</p>
            </SelectButton>
            <SelectButtonBottomInfoWrapper>
              <p>{method.tax}</p>
              <p>{method.way}</p>
            </SelectButtonBottomInfoWrapper>
          </SelectButtonWrapper>
        );
      })}
    </SelectButtonsWrapper>
  );
};

export function Configuration() {
  return (
    <Container>
      <ResponsiveHeader
        title="Offstore Pay - Configurações"
        url="/paymentmethods"
      />
      <Body>
        <ConfigurationSection>
          <ConfigurationSectionTitle>
            Dados da sua conta bancária
          </ConfigurationSectionTitle>
          <ConfigurationSectionSubtitle>
            Insira os dados de sua conta bancária para receber os repasses dos
            pagamentos que você receber em sua loja
          </ConfigurationSectionSubtitle>
          <GenericButton>Alterar conta bancária</GenericButton>
          <BankDataWrapper>
            <BankIcon src={BankIc} />
            <BankData>
              <p>Banco C6 S.A / Ag: 0001 / Conta: 123456-7</p>
              <p>CPF/CNPJ: 123.456.789-12</p>
            </BankData>
          </BankDataWrapper>
        </ConfigurationSection>
        <ConfigurationSection>
          <ConfigurationSectionTitle>
            Forma de recebimento
          </ConfigurationSectionTitle>
          <ReceivingMethods onSelectReceivingMethod={() => {}} />
        </ConfigurationSection>
        <ConfigurationSection>
          <ConfigurationSectionTitle>
            Formas de pagamento
          </ConfigurationSectionTitle>
          <ConfigurationSectionSubtitle>
            Selecione as formas de pagamento que você irá aceitar
          </ConfigurationSectionSubtitle>
          <CardsWrapper>
            <Card>
              <div className="left" onClick={() => {}}>
                <strong>Cartão de crédito</strong>
                <span>4,49% por transação</span>
              </div>

              <div className="right">
                <label className="switch">
                  <input
                    type="checkbox"
                    // checked={checkWhatsapp}
                    // onChange={e => console.log(e)}
                  />
                  <span className="slider round" />
                </label>
              </div>
            </Card>
            <Card>
              <div className="left" onClick={() => {}}>
                <strong>PIX</strong>
                <span>1,99% por transação</span>
              </div>

              <div className="right">
                <label className="switch">
                  <input
                    type="checkbox"
                    // checked={checkWhatsapp}
                    // onChange={e => console.log(e)}
                  />
                  <span className="slider round" />
                </label>
              </div>
            </Card>
            <Card>
              <div className="left" onClick={() => {}}>
                <strong>Boleto</strong>
                <span>R$ 3,49 por transação</span>
              </div>

              <div className="right">
                <label className="switch">
                  <input
                    type="checkbox"
                    // checked={checkWhatsapp}
                    // onChange={e => console.log(e)}
                  />
                  <span className="slider round" />
                </label>
              </div>
            </Card>
          </CardsWrapper>
        </ConfigurationSection>
      </Body>
    </Container>
  );
}
