/* eslint-disable no-plusplus */
import produce from 'immer';

const INITIAL_STATE = {
  order: null,
  products: null,
  loading: false,
};

export default function freight(state = INITIAL_STATE, action) {
  // eslint-disable-next-line consistent-return
  return produce(state, draft => {
    switch (action.type) {
      case '@order/LOAD_ORDER_BY_ID_SUCCESS': {
        draft.order = action.payload.data;
        break;
      }

      case '@order/LOAD_PRODUCTS_SUCCESS': {
        draft.products = action.payload.data.map(p => {
          return { ...p, amount: 0 };
        });
        break;
      }

      case '@order/ADD_AMOUNT_PRODUCTS_SUCCESS': {
        const productIndex = draft.products.findIndex(
          p => p.id === action.payload.id
        );

        if (productIndex >= 0) {
          draft.products[productIndex].amount++;
        }

        break;
      }

      case '@order/REM_AMOUNT_PRODUCTS_SUCCESS': {
        const productIndex = draft.products.findIndex(
          p => p.id === action.payload.id
        );

        if (productIndex >= 0) {
          if (draft.products[productIndex].amount > 0) {
            draft.products[productIndex].amount--;
          }
        }

        break;
      }

      default:
        return state;
    }
  });
}
