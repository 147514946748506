/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { toast } from 'react-toastify';

import api from '../../../../services/api';

import CancelImage from '../../../../assets/icons/cancelx.svg';

import {
  insertPromotionRequest,
  updatePromotionRequest,
} from '../../../../store/modules/linkPrev/actions';

import {
  ContainerGeral,
  Container,
  Div,
  DivTitle,
  DivBody,
  Input,
  // DivFooter,
  DivButton,
} from './styles';

function PromotionModal({ showModal, Modal, id }) {
  const dispatch = useDispatch();
  const [promoName, setPromoName] = useState();
  const [promoDescription, setPromoDescription] = useState();

  const handleModal = useCallback(() => {
    Modal(false);
  }, [Modal]);

  useEffect(() => {
    async function load() {
      if (id > 0) {
        const promo = await api.get(`/promotionbyid/${id}`);

        setPromoName(promo.data.name);
        setPromoDescription(promo.data.description);
      } else {
        setPromoName('');
        setPromoDescription('');
      }
    }

    load();
  }, [id]);

  async function handleSubmit() {
    try {
      if (!promoName || !promoDescription) {
        toast.error('Preencha todos os campos');
        return;
      }

      if (id > 0) {
        const data = {
          id,
          name: promoName,
          description: promoDescription,
        };

        dispatch(updatePromotionRequest(data));
      } else {
        const data = {
          name: promoName,
          description: promoDescription,
        };

        dispatch(insertPromotionRequest(data));
      }

      handleModal();
    } catch (err) {
      toast.error('Erro ao inserir ou alterar Promoção');
    }
  }

  return (
    <ContainerGeral>
      <Container showModal={showModal} onClick={handleModal} />
      <Div showModal={showModal}>
        <DivTitle>
          <div className="div-space" />
          <div>
            <span>{id > 0 ? 'Editar promoção' : 'Adicionar promoção'}</span>
          </div>
          <div className="div-space">
            <img src={CancelImage} alt="cancelImage" onClick={handleModal} />
          </div>
        </DivTitle>
        <DivBody>
          <span>Titulo</span>
          <Input
            type="text"
            placeholder="Título da promoção"
            value={promoName}
            onChange={e => setPromoName(e.target.value)}
          />

          <span>Descrição</span>
          <textarea
            rows={5}
            placeholder="Descreva sua promoção"
            value={promoDescription}
            onChange={e => setPromoDescription(e.target.value)}
          />

          <DivButton>
            <button type="button" onClick={handleSubmit}>
              {id > 0 ? 'Salvar' : 'Adicionar'}
            </button>
          </DivButton>
        </DivBody>
      </Div>
    </ContainerGeral>
  );
}

export default PromotionModal;
