import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import pt from 'date-fns/locale/pt-BR';

export const { format: formatPrice } = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});

export const cpfMask = value => {
  return value
    .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
    .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
    .replace(/(\d{3})(\d{1,2})/, '$1-$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1 ou 2, apos capturar o primeiro grupo ele adiciona um traço antes do segundo grupo de numero
    .replace(/(-\d{2})\d+?$/, '$1'); // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
};

export const cnpjMask = value => {
  value = value.replace(/\D/g, ''); // Remove todos os caracteres não numéricos
  return `${value.slice(0, 2)}.${value.slice(2, 5)}.${value.slice(
    5,
    8
  )}/${value.slice(8, 12)}-${value.slice(12)}`;
};

export const formatUTC = pDate => {
  const utcDate = pDate;
  const parsedUtcDate = new Date(`${utcDate}Z`);

  // Converter a data UTC para o fuso horário UTC-3
  const timeZone = 'America/Sao_Paulo'; // Exemplo para UTC-3
  const zonedDate = utcToZonedTime(parsedUtcDate, timeZone);

  const formattedDate = format(zonedDate, "dd'/'MM'/'yy' às 'HH:mm'h'", {
    locale: pt,
  });

  return formattedDate;
};
