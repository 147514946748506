import { useEffect, useState } from 'react';
// import brasilApi from '~/services/brasilApi';
import { banklist } from './utils';

export function useGetBanksData() {
  const [banksData, setBanksData] = useState([]);
  // const handleGetBankData = async () => {
  //   const { data } = await brasilApi.get('/banks/v1');
  //   setBanksData(data);
  // };

  const compareByName = (a, b) => {
    if (a.name && b.name) {
      const nomeA = a.name.toUpperCase();
      const nomeB = b.name.toUpperCase();

      if (nomeA < nomeB) {
        return -1;
      }
      if (nomeA > nomeB) {
        return 1;
      }

      return 0;
    }

    return 0;
  };

  useEffect(() => {
    // handleGetBankData();
    const sortedData = banklist.slice().sort(compareByName);

    const newData = sortedData.map(d => {
      return {
        ...d,
        id: d.code,
        label: `${d.name} - ${d.code}`,
        value: `${d.name} - ${d.code}`,
      };
    });

    setBanksData(newData);
  }, []);

  return {
    banksData,
  };
}
