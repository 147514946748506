/* eslint-disable jsx-a11y/accessible-emoji */
import React, { useMemo } from 'react';
// import { useHistory } from 'react-router-dom';
import { H4, SecundaryCard } from '~/designsystem/Fonts';

import dataPlans from '../../../../data/plans.json';

import {
  Container,
  DivProPlan,
  DivPrice,
  DivItemsPro,
  DiscountLabel,
  // GreyLabel,
  BodySecundaryCard,
  // DivBolet,
  DivHeaderLabel,
} from './styles';

function CardFreePlan({ className, pro, showBanner, showBorder }) {
  const isMyPlan = useMemo(() => {
    return pro === dataPlans.free.id;
  }, [pro]);
  return (
    <Container className={className}>
      <DivProPlan showBorder={showBorder && isMyPlan}>
        {isMyPlan && showBanner && (
          <DivHeaderLabel>Seu plano atual</DivHeaderLabel>
        )}

        <body>
          {!isMyPlan && (
            <DiscountLabel color="#EFEFEF">
              <span>{dataPlans.free.label}</span>
            </DiscountLabel>
          )}

          <H4>Plano {dataPlans.free.name}</H4>

          <DivPrice color={dataPlans.free.color}>
            <strong>
              <span>R$</span>
              {dataPlans.free.price}
              <span>/mês</span>
            </strong>
          </DivPrice>

          <DivItemsPro>
            {/* {pro === 1 ? (
              <button type="button" disabled>
                Seu plano atual
              </button>
            ) : (
              <button type="button" onClick={handleBuy} disabled={loading}>
                {loading ? (
                  <LoaderComponent size={20} />
                ) : (
                  <>
                    Assinar plano {dataPlans.pro.name}
                    <img src={ArrowRightWhiteIcon} alt="arrow" />
                  </>
                )}
              </button>
            )} */}

            <strong>Recursos do plano:</strong>

            {dataPlans.free.items.map(d => (
              <div key={d}>
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ marginRight: '6px' }}>
                    <svg
                      width="11"
                      height="8"
                      viewBox="0 0 11 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10 1L3.8125 7L1 4.27273"
                        stroke="#838383"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>

                  {d}
                </span>
              </div>
            ))}
          </DivItemsPro>

          <SecundaryCard>
            <BodySecundaryCard>
              <strong>Comissão sobre vendas</strong>

              {/* <div>
                <span>Offstore Pay</span>
                <span className="red">{dataPlans.free.fees.offstorePay}</span>
              </div> */}

              <div>
                <span>Intermediadores de pagamento(ex: Mercado Pago)</span>
                <span className="red">{dataPlans.free.fees.gateways}</span>
              </div>

              <div>
                <span>Pagamento a combinar via Whatsapp</span>
                <span className="green">{dataPlans.free.fees.whatsapp}</span>
              </div>
            </BodySecundaryCard>
          </SecundaryCard>
        </body>
      </DivProPlan>

      {/* <DivBolet>
        <button
          type="button"
          onClick={() => history.push('/subscriptionperiod')}
        >
          <img src={BarCodeIcon} alt="código de barras" />
          Pagar via boleto
        </button>
      </DivBolet> */}
    </Container>
  );
}

export default CardFreePlan;
