/* eslint-disable react/prop-types */
import React from 'react';
// import MyAccountDesktop from '../MyAccount';
import MyAccountResponsive from '../Responsive/MyAccount';
import Maintenance from '../Maintenance';

function MyAccount({ location }) {
  return (
    <>
      {window.innerWidth > 720 ? (
        <Maintenance />
      ) : (
        <MyAccountResponsive location={location} />
      )}
    </>
  );
}

export default MyAccount;
