import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { toast } from 'react-toastify';

import api from '../../../../../services/api';

import Plans from '../../Plans';

import ImageInstagram from '../../../../../assets/icons/integrations/imageInstagram.png';
import CopyIcon from '../../../../../assets/icons/copyIcon.svg';
import ArrowRight from '../../../../../assets/icons/arrowRight.svg';

import {
  Container,
  DivTitle,
  DivBody,
  // LabelPro,
  DivInfo,
  DivUrlFeed,
  DivCodeHtml,
  ContainerModal,
  Modal,
} from './styles';

// import ResponsiveHeader from '../../../../../components/HeaderMobile';

function Instagram() {
  const [showModal, setShowModal] = useState(false);
  const [domain, setDomain] = useState();
  const [inputMetatag, setInpuMetatag] = useState('');
  const [pro, setPro] = useState(0);
  const [showCardPro, setShowCardPro] = useState(false);

  const username = useSelector(
    state => state.user && state.user.profile && state.user.profile.username
  );

  useEffect(() => {
    async function load() {
      await api.get(`/domain/${username}`).then(response => {
        setInpuMetatag(
          (response && response.data && response.data.metatag) || ''
        );
        setDomain(response && response.data && response.data);
      });

      await api.get(`/proplan/${username}`).then(response => {
        setPro(response && response.data);
      });
    }

    load();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function handleInsertHtmlCode() {
    if (inputMetatag && inputMetatag.length > 0) {
      await api.post('/domain', {
        metatag: inputMetatag,
      });
    }

    setShowModal(false);
  }

  return (
    <>
      {(showCardPro && <Plans />) || (
        <Container>
          <DivTitle>
            <strong>Integração com Instagram</strong>
            {/* <LabelPro>
              <span>Beta</span>
            </LabelPro> */}
          </DivTitle>

          {pro >= 1 ? (
            <DivBody>
              <img src={ImageInstagram} alt="instagram" />

              <DivInfo>
                <strong>
                  Conecte seu catálogo de produtos da Offstore com o Facebook e
                  Instagram
                </strong>

                <span>
                  Clique em <span>Veja como integrar</span> e siga o passo a
                  passo que preparamos para ativar a função Compras do
                  Instagram.
                </span>

                <DivUrlFeed>
                  {(pro >= 1 && domain && domain.active && (
                    <div>https://{domain.domain}/feed</div>
                  )) || <div>https://{username}.offstore.me/feed</div>}

                  <button
                    type="button"
                    onClick={() => {
                      navigator.clipboard.writeText(
                        pro >= 1 && domain && domain.active
                          ? `https://${domain.domain}/feed`
                          : `https://${username}.offstore.me/feed`
                      );
                      toast.success('Copiado');
                    }}
                  >
                    <img src={CopyIcon} alt="copy" /> copiar
                  </button>
                </DivUrlFeed>

                <a
                  href="https://intercom.help/offstore/pt-BR/articles/6463778-como-vincular-sua-loja-da-offstore-com-o-facebook-e-instagram"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Veja como integrar
                </a>

                <DivCodeHtml onClick={() => setShowModal(true)}>
                  {inputMetatag && inputMetatag.length > 0
                    ? 'Editar código html'
                    : 'Adicionar código html'}
                </DivCodeHtml>
              </DivInfo>
            </DivBody>
          ) : (
            <DivBody>
              <DivInfo>
                <strong>
                  Você precisar ser PRO para fazer a integração com o Facebook e
                  Instagram
                </strong>

                <span>Clique no botão abaixo e assine o plano PRO</span>

                <button type="button" onClick={() => setShowCardPro(true)}>
                  Quero ser PRO <img src={ArrowRight} alt="arrow" />
                </button>
              </DivInfo>
            </DivBody>
          )}
        </Container>
      )}

      {showModal && (
        <>
          <ContainerModal onClick={() => setShowModal(false)} />
          <Modal>
            {inputMetatag && inputMetatag.length > 0 ? (
              <strong>Editar código HTML</strong>
            ) : (
              <strong>Adicionar código HTML</strong>
            )}

            <span>
              Cole abaixo o código HTML do Facebook para fazer a verificação do
              domínio
            </span>

            <input
              type="text"
              placeholder="Insira o código HTML aqui"
              value={inputMetatag}
              onChange={e => setInpuMetatag(e.target.value)}
            />
            <button type="button" onClick={handleInsertHtmlCode}>
              Continuar
            </button>
          </Modal>
        </>
      )}
    </>
  );
}

export default Instagram;
