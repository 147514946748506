import styled from 'styled-components';
import { device } from '../../../config/device';

export const ContainerGeral = styled.div`
  display: flex;
  justify-content: center;
`;

export const Container = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  float: 1;
  z-index: 99990;
  background: rgba(238, 239, 239, 0.7);
  position: fixed;
  display: ${props => (props.showModal ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
`;

export const Div = styled.div`
  width: 440px;
  background: #fff;
  border-radius: 16px;
  z-index: 99991;
  position: fixed;
  display: ${props => (props.showModal ? 'flex' : 'none')};
  flex-direction: column;
  box-shadow: 15px 31px 59px rgba(0, 0, 0, 0.1);
  top: calc(50vh - (252px / 2));
  left: calc(50vw - (440px / 2));

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 32px 20px 20px 20px;

    > h3 {
      text-align: center;
      margin-bottom: 24px;
      font-size: 16px;
      font-weight: 500;

      > span {
        color: #0d85ff;
      }
    }

    .secundary {
      background: #efefef;
      color: #101010;
    }

    .primary {
      background: #0d85ff;
      color: #ffffff;
    }

    > button {
      width: 100%;
      height: 48px;
      border-radius: 8px;
      border: none;
      font-size: 13px;
      font-weight: 500;

      & + button {
        margin-top: 8px;
      }
    }
  }

  @media ${device.mobile} {
    width: 90%;
    left: auto;
  }
`;
