/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
// import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import api from '~/services/api';
import DeleteModal from '~/components/DeleteModal';
import {
  AddDistanceButton,
  AddressContainer,
  Body,
  Container,
  ContainerHeader,
  ContainerBody,
  DivInputPrice,
  ItemDistance,
  TableContainer,
  TextIntroContainer,
  ContainerGeneralModal,
  Background,
  ContainerModal,
  HeaderModal,
  InputContainer,
  ContainerButtonModal,
  Divider,
  TitleContainer,
  SaveButton,
} from './styles';

import Header from '../Header';

import dropdownIcon from '../../../../../assets/icons/freight/dropdown.svg';
import deleteIcon from '../../../../../assets/icons/freight/trash.svg';
import closeIcon from '../../../../../assets/icons/freight/delete.svg';

const apiKey = 'AIzaSyAwFChk2BaCcDvZhtId7trVSCnJPdZNc2g';
const mapApiJs = 'https://maps.googleapis.com/maps/api/js';
const geocodeJson = 'https://maps.googleapis.com/maps/api/geocode/json';

function loadAsyncScript(src) {
  return new Promise(resolve => {
    const script = document.createElement('script');
    Object.assign(script, {
      type: 'text/javascript',
      async: true,
      src,
    });
    script.addEventListener('load', () => resolve(script));
    document.head.appendChild(script);
  });
}

const extractAddress = place => {
  const address = {
    full_address: '',
    address: '',
    number: null,
    complement: '',
    neighborhood: '',
    city: '',
    country: '',
    uf: '',
    cep: null,
    latitude: null,
    longitude: null,
  };

  if (!Array.isArray(place.address_components)) {
    return address;
  }

  address.full_address = place.formatted_address;

  place.address_components.forEach(component => {
    const { types } = component;
    const value = component.long_name;

    if (types.includes('route')) {
      address.address = value;
    }

    if (types.includes('street_number')) {
      address.number = value;
    }

    if (types.includes('administrative_area_level_2')) {
      address.city = value;
    }

    if (types.includes('sublocality')) {
      address.neighborhood = value;
    }

    if (types.includes('administrative_area_level_1')) {
      address.uf = value;
    }

    if (types.includes('postal_code')) {
      address.cep = value;
    }

    // if (types.includes('country')) {
    //   address.country = value;
    // }
  });

  return address;
};

export default function ConfigurationFreightPerKm({ handleClickBackButton }) {
  const { id } = useSelector(state => state.user.profile);

  const initialData = {
    full_address: '',
    number: '',
    complement: '',
    cep: '',
  };
  const [deleteItemSelection, setDeleteItemSelection] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [modalAddDistance, setModalAddDistance] = useState(false);
  const [records, setRecords] = useState([]);
  const [recordsPayload, setRecordsPayload] = useState([]);

  const [addressData, setAddressData] = useState(initialData);
  const [oldAddressData, setOldAddressData] = useState(initialData);
  const [addressId, setAddressId] = useState(null);
  const [hasErrorPrice, setHasErrorPrice] = useState(false);
  const [errorNullLabel, setErrorNullLabel] = useState(false);

  const searchInput = useRef(null);

  const initMapScript = () => {
    // if (window.google) {
    //   return Promise.resolve();
    // }
    const src = `${mapApiJs}?key=${apiKey}&libraries=places&language=pt-BR`;
    return loadAsyncScript(src);
  };

  const onChangeAddress = autocomplete => {
    const place = autocomplete.getPlace();

    setAddressData(extractAddress(place));
    setOldAddressData(extractAddress(place));

    const geocodeUrl = `${geocodeJson}?address=${encodeURIComponent(
      place.formatted_address
    )}&key=${apiKey}`;

    axios
      .get(geocodeUrl)
      .then(response => {
        const { data } = response;
        if (data.status === 'OK' && data.results.length > 0) {
          const { location } = data.results[0].geometry;
          setAddressData(prevData => ({
            ...prevData,
            latitude: location.lat,
            longitude: location.lng,
          }));
        } else {
          console.log('Coordenadas não encontradas.');
        }
      })
      .catch(error => {
        console.error('Erro na solicitação de geocodificação', error);
      });
  };

  const initAutocomplete = () => {
    if (!searchInput.current) return;

    const autocomplete = new window.google.maps.places.Autocomplete(
      searchInput.current
    );
    autocomplete.setFields([
      'address_component',
      'geometry',
      'formatted_address',
    ]);
    autocomplete.setComponentRestrictions({
      country: ['br'],
    });
    autocomplete.addListener('place_changed', () =>
      onChangeAddress(autocomplete)
    );
  };

  useEffect(() => {
    initMapScript().then(() => initAutocomplete());
  }, [initAutocomplete]);

  useEffect(() => {
    async function loadData() {
      await api
        .get('/google-addresses/list')
        .then(res => {
          if (res.data) {
            setAddressData(res.data);
            setOldAddressData(res.data);

            if (res.data.id) setAddressId(res.data.id);
          }
        })
        .catch(err => console.log('err:', err));

      await api
        .post('/freight-per-km/list', { user_id: id })
        .then(res => {
          setRecords(res.data);
          setRecordsPayload(res.data);
        })
        .catch(err => console.log(err));
    }

    loadData();
  }, [id]);

  async function updateData() {
    const invalidActivePrices = recordsPayload.filter(item => {
      console.log(item.price);
      if ((item.active && !item.price) || item.price === '0.00') return item;
    });

    if (
      addressData.full_address !== '' &&
      !addressData.number &&
      addressData.latitude !== oldAddressData.latitude &&
      !addressData.latitude
    ) {
      toast.error('Selecione um endereço do Google');
      setErrorNullLabel(true);
      return;
    }
    if (
      addressData.full_address !== oldAddressData.full_address &&
      addressData.full_address !== ''
    ) {
      toast.error('Endereço divergente. Selecione um endereço válido');
      setErrorNullLabel(true);
      return;
    }
    if (addressData.full_address === '' || addressData.number === null) {
      toast.error('Informe o endereço completo para salvar');
      setErrorNullLabel(true);
      return;
    }
    setErrorNullLabel(false);

    if (invalidActivePrices.length > 0) {
      setHasErrorPrice(true);
      toast.error('Informe os preços para salvar');
      return;
    }

    setHasErrorPrice(false);

    const newDistanceData = recordsPayload.filter(item => {
      if (!item.id) return item;
    });

    const updatedDistances = recordsPayload.filter(item => {
      if (item.id) return item;
    });

    if (newDistanceData.length)
      await api.post('/freight-per-km/create', {
        records: newDistanceData,
      });

    await api
      .post(`/google-addresses/${addressId ? 'update' : 'create'}`, {
        ...addressData,
        user_id: id,
        id: addressId,
      })
      .then(res => console.log(res))
      .catch(err => console.log(err));

    await api
      .put('/freight-per-km/update', {
        records: updatedDistances,
      })
      .then(() => toast.success('Dados salvos!'))
      .catch(err => {
        console.log(err);
        toast.error('Erro ao salvar dados');
      });

    handleClickBackButton();
  }

  function handleClickBack() {
    handleClickBackButton();
  }

  const AddDistanceModal = () => {
    const startDistance = records.length
      ? Number(records[records.length - 1].end_distance)
      : '1';

    console.log(Number(records[records.length - 1].end_distance));
    const [endDistance, setEndDistance] = useState('');

    async function onSubmit() {
      const data = {
        start_distance: startDistance + 1,
        end_distance: endDistance,
        price: null,
        delivery_time: '60',
        type_delivery_time: 'minutos',
        title: 'Entrega normal',
        active: false,
        user_id: id,
      };

      if (Number(endDistance) < Number(startDistance + 1))
        return toast.error('A distância final precisa ser maior que a inicial');

      // await api.post('/freight-per-km/create', data);
      recordsPayload.push(data);
      setModalAddDistance(false);
      return toast.success('Distância adicionada');
    }

    return (
      <>
        <Background
          onClick={e => {
            if (e.target === e.currentTarget) {
              setModalAddDistance(false);
            }
          }}
        >
          <ContainerGeneralModal isModal>
            <ContainerModal>
              <ContainerHeader>
                <HeaderModal>
                  <strong>Adicionar distância</strong>
                  <button
                    type="button"
                    onClick={() => setModalAddDistance(false)}
                  >
                    <img src={closeIcon} alt="close-modal" />
                  </button>
                </HeaderModal>
              </ContainerHeader>
              <Divider />
              <ContainerBody>
                <TitleContainer>
                  <div>
                    <strong>Distância</strong>
                    <span>(Obrigatório)</span>
                  </div>
                  <span>Digite a distância abaixo</span>
                </TitleContainer>
                <InputContainer>
                  <strong>De</strong>
                  <input
                    type="text"
                    placeholder={startDistance + 1}
                    disabled
                    autoComplete="no"
                    value=""
                  />
                  <strong>à</strong>
                  <input
                    type="text"
                    placeholder=""
                    autoComplete="no"
                    value={endDistance}
                    onChange={e =>
                      setEndDistance(e.target.value.replace(/[^0-9]/g, ''))
                    }
                  />
                  <strong>Km</strong>
                </InputContainer>

                <ContainerButtonModal>
                  <button type="button" onClick={onSubmit}>
                    Confirmar
                  </button>
                </ContainerButtonModal>
              </ContainerBody>
            </ContainerModal>
          </ContainerGeneralModal>
        </Background>
      </>
    );
  };

  const handleUpdateRecord = updatedData => {
    const updatedRecords = recordsPayload.map(record =>
      record.id === updatedData.id ? updatedData : record
    );

    setRecordsPayload(updatedRecords);
  };

  async function deleteModal(modal, canceled) {
    setShowDeleteModal(modal);

    if (canceled) {
      const longerDistanceFilter = recordsPayload.filter(item => {
        if (deleteItemSelection.end_distance <= item.end_distance) return item;
      });

      const smallerDistanceFilter = recordsPayload.filter(item => {
        if (deleteItemSelection.end_distance > item.end_distance) return item;
      });

      await api
        .post('/freight-per-km/delete', {
          records: longerDistanceFilter,
        })
        .then(() => toast.success('Registro removido!'))
        .catch(() => toast.error('Erro ao excluir registro'));

      setRecords(smallerDistanceFilter);
      setRecordsPayload(smallerDistanceFilter);
    }
  }

  function longerActiveDistancesVerification(data, value) {
    if (value === false) {
      const longerFilter = recordsPayload.map(item => {
        if (item.active && item.end_distance >= data.end_distance) {
          return {
            ...item,
            active: false,
          };
        }
        return item;
      });

      setRecords(longerFilter);
      setRecordsPayload(longerFilter);
    } else {
      const smallerFilter = recordsPayload.map(item => {
        if (!item.active && item.end_distance <= data.end_distance) {
          return {
            ...item,
            active: true,
          };
        }
        return item;
      });

      setRecords(smallerFilter);
      setRecordsPayload(smallerFilter);
    }
  }

  const DistanceItem = props => {
    const { data, updateRecord } = props;
    const newValues = data;

    const [active] = useState(data.active);
    const [price, setPrice] = useState(data.price);
    const [deliveryTime, setDeliveryTime] = useState(data.delivery_time);
    const [typeDeliveryTime, setTypeDeliveryTime] = useState(
      data.type_delivery_time
    );
    const [title, setTitle] = useState(data.title);

    const handleChangeValue = (field, value) => {
      switch (field) {
        case 'price':
          setPrice(value);
          break;
        case 'delivery_time':
          setDeliveryTime(value);
          break;
        case 'type_delivery_time':
          setTypeDeliveryTime(value);
          break;
        case 'title':
          setTitle(value);
          break;
        default:
          break;
      }
    };

    const handleFieldChange = (field, value) => {
      newValues[field] = value;
      updateRecord(newValues);
    };

    const handleFieldBlur = (key, value) => {
      handleFieldChange(key, value);
    };

    const handleDeleteItem = async () => {
      const lastDistance = records[records.length - 1].end_distance;
      if (data.end_distance === lastDistance) {
        const filteredRecords = recordsPayload.filter(
          item => item.id !== data.id
        );

        await api
          .post('/freight-per-km/delete', {
            records: [data],
          })
          .then(() => toast.success('Registro removido!'))
          .catch(() => toast.error('Erro ao excluir registro'));

        setRecords(filteredRecords);
        setRecordsPayload(filteredRecords);
      } else {
        setDeleteItemSelection(data);
        setShowDeleteModal(true);
      }
    };

    return (
      <ItemDistance active={newValues.active}>
        <div className="container">
          <div className="active-switch">
            <label className="switch">
              <input
                type="checkbox"
                checked={active}
                onChange={e =>
                  longerActiveDistancesVerification(data, e.target.checked)
                }
                // setDistanceEnabled(e.target.checked)
              />
              <span className="slider round" />
            </label>
          </div>
          <div className="distance">
            <strong>
              {data.start_distance} a {data.end_distance} km
            </strong>
          </div>
          <div className="value">
            <DivInputPrice
              disabled={!active}
              warning={
                active && (price === '0,00' || (hasErrorPrice && !price))
              }
            >
              <div>R$</div>

              <input
                key={`price-${data.id}`}
                type="number"
                placeholder="0,00"
                className="currency-input"
                disabled={!active}
                value={price}
                onChange={e => handleChangeValue('price', e.target.value)}
                onBlur={e => handleFieldBlur('price', e.target.value)}
              />
            </DivInputPrice>
          </div>
          <div className="delivery-time">
            <input
              placeholder="0"
              type="number"
              disabled={!active}
              value={deliveryTime}
              onChange={e => handleChangeValue('delivery_time', e.target.value)}
              onBlur={e => handleFieldBlur('delivery_time', e.target.value)}
            />
            <div>
              <select
                disabled={!active}
                name="select"
                value={typeDeliveryTime}
                onChange={e => {
                  handleChangeValue('type_delivery_time', e.target.value);
                  handleFieldBlur('type_delivery_time', e.target.value);
                }}
              >
                <option value="minutos">minuto(s)</option>
                <option value="horas">hora(s)</option>
                <option value="dias">dia(s)</option>
              </select>
              <img src={dropdownIcon} alt="drop" />
            </div>
          </div>
          <div className="title">
            <input
              placeholder=""
              disabled={!active}
              className="delivery-time-input"
              value={title}
              onChange={e => handleChangeValue('title', e.target.value)}
              onBlur={e => handleFieldBlur('title', e.target.value)}
            />
          </div>
          <div className="delete">
            <button type="button" onClick={handleDeleteItem}>
              <img src={deleteIcon} alt="delete-icon" />
            </button>
          </div>
        </div>
      </ItemDistance>
    );
  };

  return (
    <>
      {modalAddDistance && <AddDistanceModal />}
      <Container>
        <Header
          title="Frete por km de distância"
          handleClickBack={handleClickBack}
        />

        <DeleteModal
          showModal={showDeleteModal}
          Modal={deleteModal}
          title="Excluir distância?"
          body="Ao excluir essa distância, as distâncias maiores que essa também serão excluídas"
          textConfirmButton="Excluir distância"
        />

        <Body>
          <AddressContainer>
            <strong>Endereço da loja</strong>
            <span>Adicione o endereço de onde sairão suas entregas</span>
            <div className={errorNullLabel ? 'error-border' : ''}>
              <input
                ref={searchInput}
                placeholder="Endereço com número"
                className={errorNullLabel ? 'error-text' : ''}
                value={addressData.full_address}
                onChange={e =>
                  setAddressData({
                    ...initialData,
                    full_address: e.target.value,
                  })
                }
              />
            </div>
            {errorNullLabel && (
              <span className="error">
                {addressData.full_address !== '' && addressData.number === null
                  ? 'Endereço incompleto. Por favor, insira um endereço com número'
                  : addressData.full_address !== '' && addressData.number === ''
                  ? 'Endereço inválido, selecione um endereço do Google'
                  : ''}
              </span>
            )}
            {/* <div className="double-input-div">
              <input
                className="number-address-input"
                placeholder="Nº"
                value={addressData.number}
                onChange={e =>
                  setAddressData({
                    ...addressData,
                    number: e.target.value,
                  })
                }
              />
              <input
                placeholder="Complemento"
                value={addressData.complement}
                onChange={e =>
                  setAddressData({
                    ...addressData,
                    complement: e.target.value,
                  })
                }
              />
            </div>
            <div>
              <input
                placeholder="CEP"
                value={addressData.cep}
                onChange={e =>
                  setAddressData({
                    ...addressData,
                    cep: e.target.value,
                  })
                }
              />
            </div> */}
          </AddressContainer>
          <TextIntroContainer>
            <strong>Defina o valor da entrega por km de distância</strong>
            <p>
              Defina o valor que você quer cobrar por distância de entrega, o
              cálculo será feito automaticamente quando seu cliente for fazer a
              compra. Preencha apenas o preço das distâncias em que você
              entrega.
            </p>
          </TextIntroContainer>
          <TableContainer>
            <div className="table-header">
              <div className="first-column">
                <strong>Distância</strong>
              </div>
              <div className="second-column">
                <strong>Valor</strong>
              </div>
              <div className="third-column">
                <strong>Prazo de entrega</strong>
              </div>
              <div className="fourth-column">
                <strong>Título</strong>
              </div>
            </div>
            {records.map(item => (
              <DistanceItem
                key={item.id}
                data={item}
                updateRecord={handleUpdateRecord}
              />
            ))}
          </TableContainer>
          <AddDistanceButton onClick={() => setModalAddDistance(true)}>
            <strong>+ Adicionar nova distância</strong>
          </AddDistanceButton>
          <SaveButton>
            <button type="button" onClick={updateData}>
              Salvar alterações
            </button>
          </SaveButton>
        </Body>
      </Container>
    </>
  );
}
