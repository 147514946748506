/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';

import { formatUTC } from '~/util/format';
import * as S from './styles';
import { UniversalLoader } from '../UniversalLoader/UniversalLoader';

export default function PaymentStatusSteps({
  // orderId,
  listOfStatus,
  creditData,
  detailsStatus,
  setModalRepaymentActive,
}) {
  const [initialLoading] = useState(false);

  return (
    <>
      <UniversalLoader open={initialLoading} />
      <S.MainContainer>
        <S.Title>Histórico</S.Title>
        {/* <S.Subtitle>Offstore Pay</S.Subtitle> */}
        <S.StatusContainer>
          {listOfStatus.map((list, index) => {
            return (
              <>
                <S.StatusLineWrapper key={list.id}>
                  <S.StatusDot />
                  <S.DivStatus>
                    <S.StatusTitle>
                      {list.label === 'disputed'
                        ? 'Pagamento contestado'
                        : list.label}{' '}
                      - <S.StatusDescription>Offstore Pay</S.StatusDescription>
                    </S.StatusTitle>

                    <S.StatusDescription>
                      {list.created_at && formatUTC(list.created_at)}
                    </S.StatusDescription>

                    {list.payment_type === 'credit' && (
                      <S.StatusDescription>
                        {creditData &&
                          creditData.id &&
                          `Cartão de crédito (${creditData.card_brand} **** ${creditData.last4_digits})`}
                      </S.StatusDescription>
                    )}

                    {list.payment_type === 'pix' && (
                      <S.StatusDescription>via Pix</S.StatusDescription>
                    )}
                  </S.DivStatus>
                </S.StatusLineWrapper>
                {index !== listOfStatus.length - 1 && <S.LineStroke />}
              </>
            );
          })}
        </S.StatusContainer>

        {detailsStatus !== 'Cancelado' &&
          detailsStatus !== 'Pagamento pendente' && (
            <S.ButtonRepayment
              type="button"
              onClick={() => setModalRepaymentActive(true)}
            >
              Reembolsar{' '}
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.125 7.25L1 4.125L4.125 1"
                  stroke="black"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M11 11V6.625C11 5.96196 10.7366 5.32607 10.2678 4.85723C9.79893 4.38839 9.16304 4.125 8.5 4.125H1"
                  stroke="black"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </S.ButtonRepayment>
          )}
      </S.MainContainer>
    </>
  );
}
