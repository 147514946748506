/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-console */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useCallback } from 'react';

import { toast } from 'react-toastify';

import { useSelector } from 'react-redux';
import { Button, Divider } from '~/designsystem/Fonts';
// import LoadingContainer from '~/components/LoadingContainer';
import LoaderComponent from '~/components/Loader';
import ResponsiveHeader from '../../../../components/HeaderMobile';

// import CheckIcon from '../../../../assets/icons/checkIntegration.svg';
import dropdownIcon from '../../../../assets/icons/freight/dropdown.svg';

import api from '../../../../services/api';

import {
  Container,
  Body,
  // RedLabel,
  // DivBlueLabel,
  Div,
  DivCards,
  DivDimensions,
  DivInputDimension,
  Card,
  DeleteButtonContainer,
  TextDeleteButton,
  ContainerCallToIntegration,
  CallToIntegrationText,
  ContainerButton,
  DivTextDimensions,
  DivExcludeCity,
  DivInitialLoader,
} from './styles';

function ConfigurationMelhorEnvio() {
  const [services, setServices] = useState([]);
  const [token, setToken] = useState('');
  const [active, setActive] = useState(false);
  const [activeTokenME, setActiveTokenME] = useState(false);
  const [cep, setCep] = useState('');
  const [weight, setWeight] = useState();
  const [length, setLength] = useState();
  const [height, setHeight] = useState();
  const [width, setWidth] = useState();
  const [loading, setLoading] = useState(true);
  const [loadingIntegration, setLoadingIntegration] = useState(false);
  // const [showModal, setShowModal] = useState(false);
  const [valueMoreDays, setValueMoreDays] = useState();
  const [startIntegrationUrl, setStartIntegrationUrl] = useState('');
  const [hasRefreshToken, setHasRefreshToken] = useState(false);
  const [activeExcludeCity, setActiveExcludeCity] = useState(false);
  // const [deleted, setDeleted] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get('code');

  const userId = useSelector(
    state => state.user && state.user.profile && state.user.profile.id
  );
  // const [clientSecret, setClientSecret] = useState('');

  useEffect(() => {
    async function createToken() {
      if (code && !active) {
        setLoadingIntegration(true);

        const payload = {
          type: 'authorization_code',
          // id: userId,
          code,
        };

        await api
          .post('/melhorenvio/token', payload)
          .then(() => {})
          .catch(err => console.log(err));

        const servicesList = [
          {
            company_id: 1,
            service_id: 1,
            active: true,
          },
          {
            company_id: 1,
            service_id: 2,
            active: true,
          },
        ];

        await Promise.all(
          servicesList.map(async item => {
            const serviceData = {
              company_id: item.company_id,
              service_id: item.service_id,
              active: item.active,
            };

            const response = await api.post(
              '/melhorenviocreateorupdate',
              serviceData
            );
            return response;
          })
        ).finally(() => {
          toast.success('Melhor Envio ativo com sucesso.');
          window.history.replaceState(null, '', '/freight/melhorenviofreight');
          setActive(true);
          setHasRefreshToken(true);
        });

        setLoadingIntegration(false);
      }
    }

    createToken();
  }, [code, active, userId]);

  useEffect(() => {
    async function load() {
      await api
        .get('/melhorenviogetuser')
        .then(() => {
          setActive(true);
          return 0;
        })
        .catch(err => {
          if (err.response.status === 401) {
            setActive(false);
          }

          return 0;
        });

      await api
        .get('/melhorenvio/auth')
        .then(res => setStartIntegrationUrl(res.data.url));

      await api.get(`/proplan`).then(response => {
        const pro = response && response.data;

        if (pro === 0) {
          window.location.href =
            'https://app.offstore.me/freight/configuration';
        }
      });

      await api
        .post('/melhorenvio/checkrefreshtoken')
        .then(res => {
          if (res.data === 'Has refresh token') setHasRefreshToken(true);
          else setHasRefreshToken(false);
        })
        .catch(() => setHasRefreshToken(false));

      await api.get('/configfreight').then(response => {
        setValueMoreDays(response.data.more_days);
        setWeight(response.data.weight);
        setLength(response.data.length);
        setHeight(response.data.height);
        setWidth(response.data.width);
        setActiveExcludeCity(response.data.exclude_my_city);
      });

      await api
        .get('/melhorenvio')
        .then(async response => {
          setCep(response.data.cep_origin);
          if (response.data.token) {
            setToken(true);
          } else setToken(false);
        })
        .catch(err => console.log(err));

      await api
        .post('/melhorenvio/checktokenisvalid')
        .then(res => {
          if (res.data === 'Success') setActiveTokenME(true);
          else setActiveTokenME(false);
        })
        .catch(() => setActiveTokenME(false));

      const list = await api.get('/melhorenviolist');
      try {
        await api.get('/melhorenvioservices').then(response => {
          console.log(response.data);
          setServices([
            ...services,
            ...response.data.map(r => {
              const find =
                list &&
                list.data &&
                list.data.find(
                  us => us.company_id === r.company.id && us.service_id === r.id
                );
              return {
                ...r,
                active: (find && find.active) || false,
              };
            }),
          ]);
        });
      } catch (err) {
        console.log(err);
      }

      setLoading(false);
    }
    if (loadingIntegration) return;
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function onChangeCheck(companyId, serviceId, value) {
    const data = {
      company_id: companyId,
      service_id: serviceId,
      active: value,
    };
    await api.post('/melhorenviocreateorupdate', data);
  }

  // async function DisableAllServices() {
  //   const list = await api.get('/melhorenviolist');

  //   if (list && list.data) {
  //     list.data.map(l => {
  //       onChangeCheck(l.company_id, l.service_id, false);

  //       return l;
  //     });
  //   }
  // }

  async function onHandleDelete() {
    await api.delete('/melhorenvio/removeintegration');
    // setDeleted(true);
    setActive(false);
    setHasRefreshToken(false);
    setLoading(false);
  }

  const DeleteButtonComponent = () => {
    return (
      <DeleteButtonContainer onClick={onHandleDelete}>
        <TextDeleteButton>Remover integração</TextDeleteButton>
      </DeleteButtonContainer>
    );
  };

  async function saveData() {
    try {
      if (cep && cep.length < 8) {
        toast.error('Preencha um cep válido para salvar as alterações');
        return 0;
      }

      const data = {
        weight,
        length,
        height,
        width,
      };

      await api.post('/updatefreight', data);
      await api.put('/melhorenvio', { cep_origin: cep });
      toast.success('Dados salvos');

      return 0;
    } catch (err) {
      toast.error('Erro ao salvar informações');
      return 0;
    }
  }

  async function saveMoreDays(selected) {
    setValueMoreDays(Number(selected));

    const data = {
      more_days: selected,
    };

    await api.post('/updatefreight', data);
    toast.success('Dados salvos');
  }

  async function saveExcludeMyCity(checked) {
    try {
      const data = {
        exclude_my_city: checked,
      };
      await api.post('/updatefreight', data);
      toast.success('Dados salvos');
    } catch (err) {
      toast.error('Erro ao salvar informações');
    }
  }

  const onChangeActiveExcludeCity = useCallback(
    e => {
      setActiveExcludeCity(e.target.checked);
      saveExcludeMyCity(e.target.checked);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  if (loading)
    return (
      <DivInitialLoader>
        <LoaderComponent size={32} white={false} />
      </DivInitialLoader>
    );
  return (
    <Container>
      <ResponsiveHeader title="Melhor Envio" url="/freight/configuration" />
      <Body>
        {((!active && !code) ||
          (!hasRefreshToken && !loading && !code) ||
          (!loading && !activeTokenME && token)) && (
          <ContainerCallToIntegration>
            <CallToIntegrationText>
              <span>
                Clique no botão abaixo para ativar a integração com o Melhor
                Envio, e o cálculo de frete do Melhor Envio já estará disponível
                na sua loja
              </span>
            </CallToIntegrationText>
            <ContainerButton>
              <Button
                active
                onClick={() => {
                  if (!hasRefreshToken || !activeTokenME) {
                    onHandleDelete();
                    // setDeleted(false);
                    window.location.href = startIntegrationUrl;
                  } else {
                    // setDeleted(false);
                    window.location.href = startIntegrationUrl;
                  }
                }}
              >
                Ativar Melhor Envio
              </Button>
            </ContainerButton>
          </ContainerCallToIntegration>
        )}

        {/* <Div marginBottom="24px" active={active}>
          <header>
            <strong>Cep de origem</strong>
            <span>Obrigatório</span>
          </header>

          <span>
            Digite o CEP do local de onde suas entregas partirão. Por exemplo, o
            CEP da sua loja.
          </span>

          <input
            type="text"
            placeholder="CEP"
            value={cep}
            onChange={e => setCep(e.target.value)}
            onBlur={saveData}
            disabled={!token}
          />
        </Div> */}

        {active && (
          <>
            <Div active={active}>
              <header>
                <strong>Selecionar transportadoras</strong>
                <span>Obrigatório</span>
              </header>

              <span>
                Selecione as transportadoras que você irá utilizar. Somente as
                transportadoras que você selecionar serão exibidas para seus
                clientes.
              </span>
            </Div>

            <DivCards active={active}>
              {services &&
                services.map(s => (
                  <Card key={s.id}>
                    <strong>{s.name}</strong>
                    <span>{s.company.name}</span>

                    <div>
                      <label className="switch">
                        <input
                          type="checkbox"
                          defaultChecked={s.active}
                          onChange={e =>
                            onChangeCheck(s.company.id, s.id, e.target.checked)
                          }
                          disabled={!token}
                        />
                        <span className="slider round" />
                      </label>
                    </div>
                  </Card>
                ))}
            </DivCards>

            <Div marginBottom="32px" active={active}>
              <header>
                <strong>Peso e dimensões para todos os produtos</strong>
              </header>

              <span>
                Para fazer o cálculo de frete, é obrigatório que os produtos
                tenham peso e dimensões definidas. Para facilitar, você pode
                definir abaixo o peso e dimensões para todos os seus produtos de
                uma vez só. Você pode editar o peso e as dimensões de cada
                produto depois, se precisar.
              </span>

              <DivDimensions weight={weight}>
                <div>
                  <strong>Peso</strong>

                  <DivInputDimension>
                    <input
                      type="number"
                      placeholder="0,00"
                      value={weight}
                      onChange={e => setWeight(e.target.value)}
                      onBlur={saveData}
                      disabled={!token}
                    />
                    <div>kg</div>
                  </DivInputDimension>

                  {weight && weight >= 1 && <span>{weight} quilo(s)</span>}

                  {weight && weight < 1 && (
                    <span>{weight * 1000} grama(s)</span>
                  )}
                </div>

                <div>
                  <strong>Comprim.</strong>

                  <DivInputDimension>
                    <input
                      type="number"
                      placeholder="0"
                      value={length}
                      onChange={e => setLength(e.target.value)}
                      onBlur={saveData}
                      disabled={!token}
                    />
                    <div>cm</div>
                  </DivInputDimension>
                </div>

                <div>
                  <strong>Altura</strong>

                  <DivInputDimension>
                    <input
                      type="number"
                      placeholder="0"
                      value={height}
                      onChange={e => setHeight(e.target.value)}
                      onBlur={saveData}
                      disabled={!token}
                    />
                    <div>cm</div>
                  </DivInputDimension>
                </div>

                <div>
                  <strong>Largura</strong>

                  <DivInputDimension>
                    <input
                      type="number"
                      placeholder="0"
                      value={width}
                      onChange={e => setWidth(e.target.value)}
                      onBlur={saveData}
                      disabled={!token}
                    />
                    <div>cm</div>
                  </DivInputDimension>
                </div>
              </DivDimensions>
            </Div>

            <DivTextDimensions>
              <strong>Tempo adicional de entrega</strong>
              <span>
                Quanto tempo você leva para preparar e enviar seus produtos?
                Adicione esse tempo abaixo:
              </span>
              <div>
                <select
                  name="select"
                  value={valueMoreDays}
                  onChange={e => saveMoreDays(e.target.value)}
                >
                  <option value="0">0 dias</option>
                  <option value="1">1 dia</option>
                  <option value="2">2 dias</option>
                  <option value="3">3 dias</option>
                  <option value="4">4 dias</option>
                  <option value="5">5 dias</option>
                  <option value="6">6 dias</option>
                  <option value="7">7 dias</option>
                  <option value="8">8 dias</option>
                  <option value="9">9 dias</option>
                  <option value="10">10 dias</option>
                  <option value="11">11 dias</option>
                  <option value="12">12 dia</option>
                  <option value="13">13 dias</option>
                  <option value="14">14 dias</option>
                  <option value="15">15 dias</option>
                  <option value="16">16 dias</option>
                  <option value="17">17 dias</option>
                  <option value="18">18 dias</option>
                  <option value="19">19 dias</option>
                  <option value="20">20 dias</option>
                  <option value="21">21 dia</option>
                  <option value="22">22 dias</option>
                  <option value="23">23 dias</option>
                  <option value="24">24 dias</option>
                  <option value="25">25 dias</option>
                  <option value="26">26 dias</option>
                  <option value="27">27 dias</option>
                  <option value="28">28 dias</option>
                  <option value="29">29 dias</option>
                  <option value="30">30 dias</option>
                </select>
                <img src={dropdownIcon} alt="drop" />
              </div>
            </DivTextDimensions>

            <DivExcludeCity>
              <strong>Desabilitar Melhor Envio para minha cidade</strong>

              <label className="switch">
                <input
                  type="checkbox"
                  checked={activeExcludeCity}
                  onChange={onChangeActiveExcludeCity}
                />
                <span className="slider round" />
              </label>
            </DivExcludeCity>

            <Divider />

            <DeleteButtonComponent />
          </>
        )}

        {/* <DivBlueLabel>
          <span>
            <b>Importante:</b> <br />
            <br />
            Essa é a <b>primeira fase</b> da integração com o Melhor Envio, em
            alguns dias seus pedidos também serão{' '}
            <b>enviados automaticamente para o Painel do Melhor Envio</b> para
            facilitar.
          </span>
        </DivBlueLabel> */}
      </Body>
    </Container>
  );
}

export default ConfigurationMelhorEnvio;
