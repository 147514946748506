/* eslint-disable react/prop-types */
import React from 'react';
// import SignupDesktop from '../Signup';
import SignupResponsive from '../Responsive/Signup';
// import Maintenance from '../Maintenance';

function Signup({ match }) {
  const { username } = match.params;
  return <SignupResponsive indicatedUser={username || ''} />;
}

export default Signup;
