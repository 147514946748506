/* eslint-disable consistent-return */
import React from 'react';
import { Switch } from 'react-router-dom';
import RedirectURIMercadoPago from '~/pages/RedirectURIMercadoPago';
import StripeRouterDeepLink from '~/pages/StripeRouterDeepLink';
import ConfigurationOffstorePay from '~/pages/Responsive/PaymentMethods/ConfigurationOffstorePay';
import { AccountBalance } from '~/pages/Responsive/PaymentMethods/AccountBalance';
import { OffstorePayEdit } from '~/pages/Responsive/PaymentMethods/OffstorePayEdit';
import { OrderDetails } from '~/pages/Responsive/OrderDetails';
import { ChangeBankAccount } from '~/pages/Responsive/PaymentMethods/OffstorePayEdit/Components/ChangeBankAccount';
import { PaymentPage } from '~/pages/Responsive/PaymentMethods/PaymentPage';
import TransferPage from '~/pages/Responsive/PaymentMethods/TransferPage';
import ContentDeclaration from '~/pages/Responsive/ContentDeclaration';
import DisputePage from '~/pages/Responsive/PaymentMethods/PaymentPage/DisputePage';
import ConfigurationFreightPerKm from '~/pages/Responsive/Freight/ConfigurationsFreightPerKm';
import ConfigurationPagBank from '~/pages/Responsive/PaymentMethods/ConfigurationPagBank';
import RedirectURIPagBank from '~/pages/RedirectURIPagBank';
import SubscriptionForms from '~/pages/Responsive/SubscriptionForms';
import SubscriptionPeriod from '~/pages/Responsive/SubscriptionForms/SubscriptionPeriod';
import Route from './Route';

import Signin from '../pages/Type/signin';
import Signup from '../pages/Type/signup';
import Dash from '../pages/Type/dashboard';
import ProductRegister from '../pages/Responsive/ProductRegister';
import PromotionRegister from '../pages/Responsive/PromotionRegister';
import Personalize from '../pages/Type/personalize';
import PersonalizeTheme from '../pages/Responsive/PersonalizeTheme';
import Elements from '../pages/Responsive/Personalize/Elements';

import MyAccount from '../pages/Type/myaccount';
import Settings from '../pages/Responsive/Settings';
// import Configurations from '../pages/Responsive/Configurations';
import ConfigurationOrder from '../pages/Responsive/Configurations/ConfigurationOrder';
import ForgotPassword from '../pages/Responsive/ForgotPassword';
import ChangePassword from '../pages/Responsive/ChangePassword';
import MyPlan from '../pages/Responsive/MyPlan';
import Plans from '../pages/Responsive/Plans';
import DesktopPlans from '../pages/Desktop/Dashboard/Plans';

// import MyIndications from '../pages/Responsive/MyIndications';
import MyOrders from '../pages/Responsive/MyOrders';
import MyOrdersDetails from '../pages/Responsive/MyOrdersDetails';
import OrderRegister from '../pages/Responsive/OrderRegister';
import PaymentSuccess from '../pages/Responsive/PaymentSuccess';
import Coupons from '../pages/Responsive/Coupons';
import CoupomRegister from '../pages/Responsive/CoupomRegister';
import ViewLabel from '../pages/Responsive/ViewLabel';
import ConversionPixel from '../pages/Responsive/ConversionPixel';

import PaymentMethods from '../pages/Responsive/PaymentMethods';
import ConfigurationMercadoPago from '../pages/Responsive/PaymentMethods/ConfigurationMercadoPago';
import ConfigurationWhatsApp from '../pages/Responsive/PaymentMethods/ConfigurationWhatsapp';

import IntegrationWithInstagram from '../pages/Responsive/Integrations/Instagram';
import Domain from '../pages/Responsive/Integrations/Domain';

import FlatRate from '../pages/Responsive/Freight/FlatRate';
import FreightConfiguration from '../pages/Responsive/Freight/Configuration';
import FreightConfigurationCorreios from '../pages/Responsive/Freight/ConfigurationCorreios';
// import FreightConfigurationSedex from '../pages/Responsive/Freight/ConfigurationSedex';
import FreeFreight from '../pages/Responsive/Freight/ConfigurationFreeFreight';
import CustomFreight from '../pages/Responsive/Freight/ConfigurationCustomFreight';
import MelhorEnvioFreight from '../pages/Responsive/Freight/ConfigurationMelhorEnvio';
import AddAddress from '../components/AddAddress';

import Teste from '../pages/Teste';

import Customize from '../pages/Type/customize';

// import ResponsiveCustomizeName from '../pages/Responsive/Signup/ResponsiveCustomizeName';
import ResponsiveCustomizeSocialMedia from '../pages/Responsive/Signup/ResponsiveCustomizeSocialMedia';
import ResponsiveCustomizeCategory from '../pages/Responsive/Signup/ResponsiveCustomizeCategory';
// import ResponsiveCustomizeBusinessType from '../pages/Responsive/Signup/ResponsiveCustomizeBusinessType';
import ResponsiveCustomizePlan from '../pages/Responsive/Signup/ResponsiveCustomizePlan';

// import CustomizeName from '../pages/Signup/CustomizeName';
// import CustomizeSocialMedia from '../pages/Signup/CustomizeSocialMedia';
// import CustomizeCategory from '../pages/Signup/CustomizeCategory';

import Maintenance from '../pages/Maintenance';
//
export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={Signin} />
      <Route path="/register" exact component={Signup} />
      <Route path="/invite/:username" exact component={Signup} />
      <Route path="/forgotpassword" exact component={ForgotPassword} />
      <Route
        path="/changepassword/:token"
        exact
        component={ChangePassword}
        isPublic
      />
      <Route path="/teste" exact component={Teste} isPrivate />
      <Route path="/dashboard" exact component={Dash} isPrivate />
      {/* <Route path="/dashboard" exact component={Maintenance} isPrivate /> */}
      <Route path="/personalize" exact component={Personalize} isPrivate />
      <Route
        path="/personalizetheme"
        exact
        component={PersonalizeTheme}
        isPrivate
      />
      <Route path="/elements" exact component={Elements} isPrivate />
      <Route path="/myaccount" exact component={MyAccount} isPrivate />

      <Route path="/settings" exact component={Settings} isPrivate />

      <Route
        path="/conversionpixel"
        exact
        component={ConversionPixel}
        isPrivate
      />
      {/* <Route
        path="/configurations"
        exact
        component={Configurations}
        isPrivate
      /> */}
      <Route
        path="/configurations"
        exact
        component={ConfigurationOrder}
        isPrivate
      />

      <Route path="/maintenance" exact component={Maintenance} isPrivate />
      <Route path="/coupons" exact component={Coupons} isPrivate />
      <Route path="/coupons/:id" exact component={CoupomRegister} isPrivate />
      <Route path="/viewlabel/:id" exact component={ViewLabel} isPrivate />
      <Route path="/myplan" exact component={MyPlan} isPrivate />
      <Route path="/pro" exact component={Plans} isPrivate />
      <Route path="/plans" exact component={DesktopPlans} isPrivate />
      <Route
        path="/paymentmethods"
        exact
        component={PaymentMethods}
        isPrivate
      />
      <Route
        path="/paymentmethods/configurationoffstorepay/edit"
        exact
        component={OffstorePayEdit}
        isPrivate
      />
      <Route
        path="/paymentmethods/configurationoffstorepay/accountbalance"
        exact
        component={AccountBalance}
        isPrivate
      />
      <Route path="/orderdetails" exact component={OrderDetails} isPrivate />
      <Route
        path="/paymentmethods/configurationoffstorepay/changebankaccount"
        exact
        component={ChangeBankAccount}
        isPrivate
      />

      {/* <Route
        path="/paymentmethods/offstorepay/accountbalance"
        exact
        component={AccountBalance}
        isPrivate
      /> */}
      <Route
        path="/paymentmethods/configurationwhatsapp"
        exact
        component={ConfigurationWhatsApp}
        isPrivate
      />
      <Route
        path="/paymentmethods/configurationoffstorepay"
        exact
        component={ConfigurationOffstorePay}
        isPrivate
      />
      {/* <Route
        path="/paymentmethods/configurationoffstorepay"
        exact
        component={ConfigurationOffstorePay}
        isPrivate
      /> */}
      {/* <Route
        path="/paymentmethods/configurationmercadopago"
        exact
        component={ConfigurationMercadoPago}
        isPrivate
      /> */}
      <Route
        path="/integrations/instagram"
        exact
        component={IntegrationWithInstagram}
        isPrivate
      />
      <Route path="/integrations/domain" exact component={Domain} isPrivate />
      {/* <Route path="/myindications" exact component={MyIndications} isPrivate /> */}
      <Route path="/myorders" exact component={MyOrders} isPrivate />
      <Route path="/myorders/:id" exact component={MyOrdersDetails} isPrivate />
      <Route
        path="/content-declaration/:id"
        exact
        component={ContentDeclaration}
        isPrivate
      />
      <Route path="/orderregister" exact component={OrderRegister} isPrivate />
      <Route
        path="/paymentsuccess"
        exact
        component={PaymentSuccess}
        isPrivate
      />
      <Route
        path="/freight/configuration"
        exact
        component={FreightConfiguration}
        isPrivate
      />
      <Route path="/freight/flatrate" exact component={FlatRate} isPrivate />
      <Route
        path="/freight/configurationcorreios"
        exact
        component={FreightConfigurationCorreios}
        isPrivate
      />
      <Route
        path="/freight/configurationfreightperkm"
        exact
        component={ConfigurationFreightPerKm}
        isPrivate
      />
      {/* <Route
        path="/freight/configurationsedex"
        exact
        component={FreightConfigurationSedex}
        isPrivate
      /> */}
      <Route
        path="/freight/freefreight"
        exact
        component={FreeFreight}
        isPrivate
      />
      <Route
        path="/freight/customfreight"
        exact
        component={CustomFreight}
        isPrivate
      />
      <Route
        path="/freight/melhorenviofreight"
        exact
        component={() => {
          if (window.innerWidth > 720) {
            window.location.href = `${window.location.origin}/dashboard${window.location.search}`;
          } else {
            return <MelhorEnvioFreight />;
          }
        }}
        isPrivate
      />
      <Route
        path="/freight/addaddress"
        exact
        component={AddAddress}
        isPrivate
      />

      <Route path="/customizeavatar" exact component={Customize} isPrivate />
      {/* <Route path="/customizename" exact component={CustomizeName} isPrivate /> */}
      {/* <Route
        path="/customizesocialmedia"
        exact
        component={CustomizeSocialMedia}
        isPrivate
      />
      <Route
        path="/customizecategory"
        exact
        component={CustomizeCategory}
        isPrivate
      /> */}
      {/* <Route
        path="/responsivecustomizename"
        exact
        component={ResponsiveCustomizeName}
        isPrivate
      /> */}
      <Route
        path="/responsivecustomizesocialmedia"
        exact
        component={ResponsiveCustomizeSocialMedia}
        isPrivate
      />
      <Route
        path="/responsivecustomizecategory"
        exact
        component={ResponsiveCustomizeCategory}
        isPrivate
      />
      {/* <Route
        path="/responsivecustomizebusinesstype"
        exact
        component={ResponsiveCustomizeBusinessType}
        isPrivate
      /> */}
      <Route
        path="/responsivecustomizeplan"
        exact
        component={ResponsiveCustomizePlan}
        isPrivate
      />
      <Route
        path="/productregister/:id"
        component={ProductRegister}
        isPrivate
      />
      <Route
        path="/promotionregister/:id"
        component={PromotionRegister}
        isPrivate
      />
      {/* Rota criada dessa forma, pois o Mercado Pago possui apenas umas URL de
      retorno, então foi feita uma verificação para saber se a ativação foi
      solicitada do desktop ou do mobile */}
      <Route
        path="/paymentmethods/configurationmercadopago"
        exact
        component={() => {
          if (window.innerWidth > 720) {
            window.location.href = `${window.location.origin}/dashboard${window.location.search}`;
          } else {
            return <ConfigurationMercadoPago />;
          }
        }}
        isPrivate
      />

      <Route
        path="/paymentmethods/configurationpagbank"
        exact
        component={() => {
          if (window.innerWidth > 720) {
            window.location.href = `${window.location.origin}/dashboard${window.location.search}`;
          } else {
            return <ConfigurationPagBank />;
          }
        }}
        isPrivate
      />

      <Route
        path="/redirectmp"
        exact
        component={RedirectURIMercadoPago}
        isPublic
      />
      <Route path="/redirectpb" exact component={RedirectURIPagBank} isPublic />
      <Route path="/stripemobile" exact component={StripeRouterDeepLink} />
      <Route
        path="/paymentmethods/configurationoffstorepay/payment"
        isPrivate
        component={PaymentPage}
      />
      <Route
        path="/paymentmethods/configurationoffstorepay/transfer"
        isPrivate
        component={TransferPage}
      />
      <Route
        path="/paymentmethods/configurationoffstorepay/disputePage/:id"
        isPrivate
        component={DisputePage}
      />
      <Route
        path="/subscriptionforms"
        exact
        component={SubscriptionForms}
        isPrivate
      />
      <Route
        path="/subscriptionperiod"
        exact
        component={SubscriptionPeriod}
        isPrivate
      />
    </Switch>
  );
}
