/* eslint-disable require-yield */
import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

// import { apiResize } from '../../../services/api-gateway';

import history from '../../../services/history';

import api from '../../../services/api';

import {
  ListLinkPreviewSuccess,
  ListTagsSuccess,
  ListCountSuccess,
  // saveImageLinkSuccess,
  removeImageLinkSuccess,
  updateTypeLinkSuccess,
  SaveLinkSuccess,
  UpdateLinkSuccess,
  ListTagsRequest,
  removeTagSuccess,
  InsertTagSuccess,
  UpdateTagSuccess,
  LoadingCreateOrUpdateTagSuccess,
  AddSubCategoriesOnCategoriesSuccess,
  UpdateActiveLinkSuccess,
  UpdateHighlightedLinkSuccess,
  listPromotionSuccess,
  insertPromotionSuccess,
  updatePromotionSuccess,
  removePromotionSuccess,
  listPaymentMethodsSuccess,
  updatePaymentMethodsSuccess,
  InsertOrUpdateLinkFailure,
  updateStockSuccess,
  // updatePageSuccess,
  updateTotalCountSuccess,
  updateCountSuccess,
  // updateHasMoreSuccess,
  updateInputSearchSuccess,
} from './actions';

// import { signOut } from '../auth/actions';

export function* ListPreview() {
  try {
    // const [responseLinks, responseTags] = yield all([
    //   call(api.get, 'links'),
    //   call(api.get, `tagsbyuser`),
    // ]);
    // const links = responseLinks.data;
    // const tags = responseTags.data;
    // yield put(ListLinkPreviewSuccess(links));
    // yield put(ListTagsSuccess(tags));
  } catch (err) {
    // toast.error('Falha na busca dos links do preview, faça o login novamente');
    // yield put(signOut());
  }
}

export function* ListProductsWithPagination({ payload }) {
  try {
    const data = {
      tagId: (payload && payload.tagId) || 0,
      stringSearch: (payload && payload.stringSearch) || '',
    };

    const response = yield call(
      api.post,
      `linkspagination/?page=${(payload && payload.page) || 1}`,
      data
    );

    if (response && response.data) {
      if (payload && payload.page > 1) {
        // yield delay(1500);

        yield put(
          updateCountSuccess(payload.count + response.data.rows.length)
        );

        yield put(
          ListLinkPreviewSuccess([...payload.products, ...response.data.rows])
        );
      } else {
        yield put(ListLinkPreviewSuccess(response.data.rows));
        yield put(updateCountSuccess(response.data.rows.length));
      }

      yield put(updateTotalCountSuccess(response.data.count));
      yield put(
        updateInputSearchSuccess((payload && payload.stringSearch) || '')
      );
    }
  } catch (err) {
    // toast.error('Falha na busca dos produtos, faça o login novamente');
    // yield put(signOut());
  }
}

export function* ListCount() {
  try {
    const response = yield call(api.get, 'linkscount');

    const count = response.data;
    yield put(ListCountSuccess(count));
  } catch (err) {
    // toast.error('Falha na busca dos links do preview, faça o login novamente');
    // yield put(signOut());
  }
}

export function* ListTagsByUser() {
  try {
    const responseTags = yield call(api.get, `tagsbyuser`);

    const tags = responseTags.data;

    yield put(ListTagsSuccess(tags));
  } catch (err) {
    // console.log(err.response);
    // toast.error(
    //   'Falha na atualização das tags do preview, verifique seus dados'
    // );
  }
}

export function* InsertOrUpdateTag({ payload }) {
  try {
    const { tagId, tagName: tag, subcategories } = payload;
    yield put(LoadingCreateOrUpdateTagSuccess(true));

    if (tagId > 0) {
      const data = {
        id: tagId,
        tag,
      };
      const responseTag = yield call(api.put, 'tags', data);

      // if (subcategories) {
      yield call(api.put, `updatemastertags/${tagId}`, { subcategories });
      // }

      yield put(UpdateTagSuccess(responseTag.data));

      // toast.success('Categoria alterada!');
      yield put(LoadingCreateOrUpdateTagSuccess(false));
    } else {
      const responseTag = yield call(api.post, `tags/${tag}`);
      const newTag = responseTag.data;

      // if (subcategories) {
      yield call(api.put, `updatemastertags/${newTag.id}`, { subcategories });
      // }

      yield put(InsertTagSuccess(newTag));

      // toast.success('Categoria criada!');
      yield put(LoadingCreateOrUpdateTagSuccess(false));
    }
  } catch (err) {
    toast.error('Falha na atualização das tags, verifique seus dados');
    yield put(LoadingCreateOrUpdateTagSuccess(false));
  }
}

export function* removeTag({ payload }) {
  try {
    const { tagId } = payload;

    // Essa api deleta a tag e retorna todas as suas subcategorias que ficaram soltas
    const subcategories = yield call(api.delete, `tags/${tagId}`);
    yield put(removeTagSuccess(tagId));

    if (subcategories) {
      yield put(AddSubCategoriesOnCategoriesSuccess(subcategories));
    }

    toast.success('Categoria excluída!');
  } catch (err) {
    toast.error('Erro ao remover tag, entre em contato com o administrador');
  }
}

async function sendImages(productId, images) {
  try {
    const allImages = await Promise.all(
      images.map(async (image, index) => {
        const imageData = {
          imageIndex: index,
          productId,
        };

        if (image.file) {
          const data = new FormData();
          data.append('file', image.file, image.file.name);
          const createdFile = await api.post(`filelinks/${productId}`, data);

          if (createdFile.status === 200) {
            await api.put(`orderimagelink/${createdFile.data.id}`, imageData);

            // await apiResize({ key: createdFile.data.path });

            URL.revokeObjectURL(image.preview);

            if (index === 0) {
              return createdFile.data;
            }
          }
        } else {
          await api.put(`orderimagelink/${image.id}`, imageData);

          if (index === 0) {
            return image;
          }
        }
        return 0;
      })
    );
    return allImages;
  } catch (err) {
    toast.error('Erro ao salvar imagem, entre em contato com administrador');
  }
  return 0;
}

async function insertVariations(arrayVariations, productId) {
  await api.delete(`/gridattributes/${productId}`);

  arrayVariations.map(async (arr, index) => {
    const data = {
      ...arr,
      order: index + 1,
      product_id: productId,
    };

    await api.post('/gridattributes', data);
  });
}

function updateVariations(arrayVariations) {
  if (arrayVariations && arrayVariations.length > 0) {
    arrayVariations.map(async arr => {
      const data = {
        promotion_price: arr.promotion_price,
        price: arr.price,
        stock_quantity: arr.stock_quantity,
        active: arr.active,
      };

      await api.put(`/gridattributes/${arr.id}`, data);
    });
  }
}

export function* insertOrUpdateLink({ payload }) {
  try {
    const { link, productId, images, arrayVariations } = payload;

    if (productId > 0) {
      const response = yield call(api.put, `links/${productId}`, link);

      const newResponse = { ...response.data, gridattributes: arrayVariations };

      // verifica se durante a alteração do produto, o usuário gerou um novo grid
      const newArrayVariations = arrayVariations.filter(arr => arr.id === 0);

      const gridProduct = yield call(api.get, `gridattributes/${productId}`);

      if (
        (newArrayVariations && newArrayVariations.length > 0) ||
        arrayVariations.length !== gridProduct.data.length
      ) {
        insertVariations(arrayVariations, productId);
      } else {
        updateVariations(arrayVariations);
      }

      const img = yield sendImages(response.data.id, images);

      yield put(UpdateLinkSuccess(newResponse, img));
    } else {
      const response = yield call(api.post, 'links', link);

      if (response && response.data && response.data.error) {
        // toast.error(response.data.error);
        yield put(InsertOrUpdateLinkFailure(true));
        return 0;
      }

      const newResponse = { ...response.data, gridattributes: arrayVariations };

      insertVariations(arrayVariations, response.data.id);

      const img = yield sendImages(response.data.id, images);

      const product = newResponse;
      product.images = img;
      yield put(SaveLinkSuccess(product));
    }

    yield put(ListTagsRequest());

    history.push('/dashboard');

    if (productId > 0) {
      toast.success('Produto alterado!');
    } else {
      toast.success('Produto criado!');
    }
  } catch (err) {
    toast.error('Erro ao alterar produto, confira seus dados');
    yield put(InsertOrUpdateLinkFailure());
  }

  return 0;
}

export function* updateActiveLink({ payload }) {
  try {
    const { linkId, isActive } = payload;
    const data = { active: isActive };

    yield call(api.put, `updateactivelink/${linkId}`, data);
    yield put(UpdateActiveLinkSuccess(linkId, isActive));
  } catch (err) {
    toast.error('Erro ao alterar produto, confira seus dados');
  }
}

export function* updateHighlightedLink({ payload }) {
  try {
    const { linkId, isHighlighted } = payload;
    const data = { highlighted: isHighlighted };

    yield call(api.put, `updatehighlightedlink/${linkId}`, data);
    yield put(UpdateHighlightedLinkSuccess(linkId, isHighlighted));
  } catch (err) {
    toast.error('Erro ao alterar produto, confira seus dados');
  }
}

export function* updateLink({ payload }) {
  try {
    const { link, productId } = payload;

    const response = yield call(api.put, `links/${productId}`, link);
    yield put(UpdateLinkSuccess(response.data, response.data.images));
    yield put(ListTagsRequest());
  } catch (err) {
    toast.error('Erro ao alterar produto, confira seus dados');
  }
}

export function* updateOrder({ payload }) {
  try {
    const { links } = payload;

    yield call(api.put, 'updateorder', links);

    // yield put(ListLinkPreviewSuccess(response.data));
  } catch (err) {
    toast.error('Erro ao alterar ordem do produto, confira seus dados');
  }
}

export function* removeImageLink({ payload }) {
  try {
    const { linkId } = payload;
    yield call(api.post, `deletefileslinks/${linkId}`);

    yield put(removeImageLinkSuccess(linkId));
  } catch (err) {
    toast.error('Erro ao remover imagem, confira seus dados');
  }
}

export function* updateTypeLink({ payload }) {
  try {
    const { linkId, typeId } = payload;
    const link = yield call(api.put, `/linktype/${linkId}`, typeId);

    yield put(updateTypeLinkSuccess(link.data));
  } catch (err) {
    toast.error('Erro ao remover imagem, confira seus dados');
  }
}

export function* listPromotion() {
  try {
    const response = yield call(api.get, 'promotions');

    const promotions = response.data;

    yield put(listPromotionSuccess(promotions));
  } catch (err) {
    // toast.error('Erro ao listar promoção, confira seus dados');
  }
}

export function* insertPromotion({ payload }) {
  try {
    const { data } = payload;

    const response = yield call(api.post, 'promotion', data);

    const promotion = response.data;

    yield put(insertPromotionSuccess(promotion));

    history.push('/dashboard');
    toast.success('Promoção criada!');
  } catch (err) {
    toast.error('Erro ao inserir promoção, confira seus dados');
  }
}

export function* updatePromotion({ payload }) {
  try {
    const { data } = payload;

    const response = yield call(api.put, `promotion`, data);

    const promotion = response.data;

    yield put(updatePromotionSuccess(promotion));

    history.push('/dashboard');
    toast.success('Promoção alterada!');
  } catch (err) {
    toast.error('Erro ao alterar produto, confira seus dados');
  }
}

export function* removePromotion({ payload }) {
  try {
    const { promotionId } = payload;

    yield call(api.post, `deletepromotion/${promotionId}`);

    yield put(removePromotionSuccess(promotionId));
    toast.success('Promoção removida!');
  } catch (err) {
    toast.error('Erro ao alterar produto, confira seus dados');
  }
}

export function* listPaymentMethods() {
  try {
    const response = yield call(api.get, 'paymentmethods');

    const paymentmethods = response.data;

    yield put(listPaymentMethodsSuccess(paymentmethods));
  } catch (err) {
    // toast.error('Erro ao listar métodos de pagamento, confira seus dados');
  }
}

export function* updatePaymentMethods({ payload }) {
  try {
    const { data } = payload;

    const response = yield call(api.put, `paymentmethods`, data);

    const paymentmethods = response.data;

    yield put(updatePaymentMethodsSuccess(paymentmethods));
  } catch (err) {
    // toast.error('Erro ao alterar métodos de pagamento, confira seus dados');
  }
}

export function* AlterStock({ payload }) {
  try {
    const { productId, amount } = payload;
    yield call(api.put, `linksfield/${productId}`, { stock_quantity: amount });

    // const products = response.data;

    yield put(updateStockSuccess(productId, amount));
  } catch (err) {
    toast.error('Falha na alteração da quantidade do produto');
  }
}

export default all([
  takeLatest('@linkPrev/LIST_LINK_PREVIEW', ListPreview),
  takeLatest('@linkPrev/LIST_COUNT_REQUEST', ListCount),
  takeLatest('@linkPrev/LIST_TAGS_REQUEST', ListTagsByUser),
  takeLatest(
    '@linkPrev/LIST_PRODUCTS_PAGINATION_REQUEST',
    ListProductsWithPagination
  ),
  takeLatest('@linkPrev/INSERT_OR_UPDATE_TAG_REQUEST', InsertOrUpdateTag),
  takeLatest('@linkPrev/REMOVE_TAG_REQUEST', removeTag),
  takeLatest('@linkPrev/UPDATE_LINK_REQUEST', updateLink),
  takeLatest('@linkPrev/UPDATE_ACTIVE_LINK_REQUEST', updateActiveLink),
  takeLatest(
    '@linkPrev/UPDATE_HIGHLIGHTED_LINK_REQUEST',
    updateHighlightedLink
  ),
  takeLatest('@linkPrev/UPDATE_ORDER_REQUEST', updateOrder),
  takeLatest('@linkPrev/INSERT_UPDATE_LINK_REQUEST', insertOrUpdateLink),
  takeLatest('@linkPrev/REMOVE_IMAGE_LINK_REQUEST', removeImageLink),
  takeLatest('@linkPrev/UPDATE_TYPE_LINK_REQUEST', updateTypeLink),
  takeLatest('@linkPrev/LIST_PROMOTION_REQUEST', listPromotion),
  takeLatest('@linkPrev/INSERT_PROMOTION_REQUEST', insertPromotion),
  takeLatest('@linkPrev/UPDATE_PROMOTION_REQUEST', updatePromotion),
  takeLatest('@linkPrev/REMOVE_PROMOTION_REQUEST', removePromotion),
  takeLatest('@linkPrev/LIST_PAYMENT_METHODS_REQUEST', listPaymentMethods),
  takeLatest('@linkPrev/UPDATE_PAYMENT_METHODS_REQUEST', updatePaymentMethods),
  takeLatest('@linkPrev/UPDATE_STOCK_REQUEST', AlterStock),
]);
