/* eslint-disable no-await-in-loop */
import React, { useState, useRef, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { SelectButtons } from '~/components/SelectButtons';
// eslint-disable-next-line import/no-extraneous-dependencies
// import { jsPDF } from 'jspdf';

import { Button } from '~/designsystem/Fonts';
import { uniqueId } from 'lodash';
import { toast } from 'react-toastify';
import api from '~/services/api';
import offstorePay from '~/services/offstorePay';
import LoaderComponent from '~/components/Loader';
import * as S from './styles';

export default function DisputePageComponent({ transactionId, paymentId }) {
  const history = useHistory();
  const [documents, setDocuments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const types = [
    {
      id: 1,
      name: 'Físico',
      value: 'physical',
    },
    {
      id: 2,
      name: 'Virtual',
      value: 'virtual',
    },
  ];

  // const [productType, setProductType] = useState(types[0].value);
  const refUploadDocument = useRef(null);

  // const handleSelectProductType = type => {
  //   setProductType(type);
  // };

  // async function handleConvertToPDF() {
  //   const pdf = new jsPDF();

  //   for (let i = 0; i < documents.length; i++) {
  //     const img = documents[i];
  //     const imgData = await new Promise((resolve, reject) => {
  //       const reader = new FileReader();
  //       reader.onload = e => resolve(e.target.result);
  //       reader.onerror = e => reject(e);
  //       reader.readAsDataURL(img.file);
  //     });

  //     const imgObj = new Image();
  //     imgObj.src = imgData;
  //     await new Promise(resolve => {
  //       imgObj.onload = resolve;
  //     });

  //     // Obter dimensões da página
  //     const pageWidth = pdf.internal.pageSize.getWidth();
  //     const pageHeight = pdf.internal.pageSize.getHeight();

  //     // Calcular a proporção de redimensionamento
  //     let imgWidth = imgObj.width;
  //     let imgHeight = imgObj.height;
  //     const ratio = Math.min(pageWidth / imgWidth, pageHeight / imgHeight);
  //     imgWidth *= ratio;
  //     imgHeight *= ratio;

  //     if (i > 0) {
  //       pdf.addPage();
  //     }

  //     // Adicionar imagem redimensionada ao PDF
  //     pdf.addImage(imgData, 'JPEG', 0, 0, imgWidth, imgHeight);
  //   }

  //   pdf.save('documentos.pdf');
  // }

  // async function handleConvertToPDF() {
  //   // eslint-disable-next-line new-cap
  //   const pdf = new jsPDF();
  //   const padding = 16; // padding de 16px

  //   for (let i = 0; i < documents.length; i++) {
  //     const img = documents[i];
  //     const imgData = await new Promise((resolve, reject) => {
  //       const reader = new FileReader();
  //       reader.onload = e => resolve(e.target.result);
  //       reader.onerror = e => reject(e);
  //       reader.readAsDataURL(img.file);
  //     });

  //     const imgObj = new Image();
  //     imgObj.src = imgData;
  //     await new Promise(resolve => {
  //       imgObj.onload = resolve;
  //     });

  //     // Obter dimensões da página considerando o padding
  //     const pageWidth = pdf.internal.pageSize.getWidth() - 2 * padding;
  //     const pageHeight = pdf.internal.pageSize.getHeight() - 2 * padding;

  //     // Calcular a proporção de redimensionamento
  //     let imgWidth = imgObj.width;
  //     let imgHeight = imgObj.height;
  //     const ratio = Math.min(pageWidth / imgWidth, pageHeight / imgHeight);
  //     imgWidth *= ratio;
  //     imgHeight *= ratio;

  //     if (i > 0) {
  //       pdf.addPage();
  //     }

  //     // Adicionar imagem redimensionada ao PDF com padding
  //     pdf.addImage(imgData, 'JPEG', padding, padding, imgWidth, imgHeight);
  //   }

  //   // pdf.save('documentos.pdf');
  //   const pdfBlob = pdf.output('blob');

  //   const formData = new FormData();
  //   formData.append('file', pdfBlob, 'documentos.pdf');
  //   formData.append('email', 'heeitorpacheco@gmail.com');

  //   await api.post('/offstorepay/transaction/dispute/sendmail', formData, {
  //     headers: {
  //       'Content-Type': 'multipart/form-data',
  //     },
  //   });
  // }

  async function handleChangeDocument(e) {
    const selectedDocuments = e.target.files;
    const arr = [];

    const maxSizeDocuments = documents.length >= 0 ? 8 - documents.length : 0;

    const sizeArray =
      selectedDocuments.length >= maxSizeDocuments
        ? maxSizeDocuments
        : selectedDocuments.length;

    for (let i = 0; i < sizeArray; i += 1) {
      if (selectedDocuments[i].size < 20480000) {
        // 5120000 = 5mb

        // const convertedImage = await fixRotation.fixRotation(selectedImages[i]);
        // const resizedImage = await resizeFile(convertedImage);

        arr.push({
          id: uniqueId('image_'),
          file: selectedDocuments[i],
          preview: URL.createObjectURL(selectedDocuments[i]),
        });
      } else {
        toast.error('Arquivo muito grande');
        break;
      }
    }

    setDocuments(documents.concat(arr));
  }

  const filterDocuments = useCallback(
    image => {
      const newImages = documents.filter(img => img.id !== image.id);
      setDocuments(newImages);
    },
    [documents]
  );

  async function deleteDocument(document) {
    URL.revokeObjectURL(document.preview);
    filterDocuments(document);
  }

  async function handleSubmit() {
    try {
      setIsLoading(true);

      if (documents.length === 0) {
        toast.error('Anexe o PDF para enviar a disputa');
        return 0;
      }

      if (documents.length > 1) {
        toast.error('É permitido enviar apenas 1 PDF');
        return 0;
      }

      const transaction = await offstorePay.get(
        `/transactions/${transactionId}`
      );

      const {
        zoop_transaction_id,
        zoop_seller_id,
      } = transaction.data.transaction;

      const formData = new FormData();
      formData.append('file', documents[0].file, `${zoop_transaction_id}.pdf`);
      formData.append('transactionId', zoop_transaction_id);
      formData.append('sellerId', zoop_seller_id);

      await api.post('/offstorepay/transaction/dispute/sendmail', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      toast.success('Resposta enviada');

      history.push(
        `/paymentmethods/configurationoffstorepay/payment/${paymentId}`
      );

      return 0;
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <S.ContainerWrapper>
      <S.Header>Que tipo de produto você vende?</S.Header>

      <SelectButtons
        marginTop={12}
        // onSelect={option => handleSelectProductType(option.value)}
        options={types}
      />

      <S.ContainerInput>
        <S.Header>Descrição do seu produto ou serviço</S.Header>

        <S.TextArea placeholder="Digite aqui" />
      </S.ContainerInput>

      <S.ContainerReceipt>
        <S.Header>Enviar comprovantes</S.Header>

        <S.Description>
          Reúna todos os comprovantes de que a compra foi legítima em um único
          arquivo PDF, com cada imagem em uma página. <br /> <br /> Os
          comprovantes pode ser, por exemplo, print da tela do pedido recebido,
          conversas que comprovem que a compra foi legitima e entregue como
          prometido, prints de rastreamento do envio do pedido, entre outros.
        </S.Description>

        <S.DivAttachment>
          <button
            type="button"
            onClick={() => refUploadDocument.current.click()}
          >
            <svg
              width="14"
              height="15"
              viewBox="0 0 14 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13 6.96485L7.32667 12.6382C6.63165 13.3332 5.68899 13.7237 4.70608 13.7237C3.72317 13.7237 2.78051 13.3332 2.08549 12.6382C1.39046 11.9431 1 11.0005 1 10.0176C1 9.03466 1.39046 8.09201 2.08549 7.39698L7.75881 1.72366C8.22216 1.26031 8.8506 1 9.50587 1C10.1611 1 10.7896 1.26031 11.2529 1.72366C11.7163 2.18701 11.9766 2.81544 11.9766 3.47072C11.9766 4.126 11.7163 4.75443 11.2529 5.21778L5.57344 10.8911C5.34176 11.1228 5.02754 11.2529 4.69991 11.2529C4.37227 11.2529 4.05805 11.1228 3.82638 10.8911C3.5947 10.6594 3.46455 10.3452 3.46455 10.0176C3.46455 9.68994 3.5947 9.37572 3.82638 9.14405L9.06756 3.90903"
                stroke="black"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            Carregar PDF
          </button>

          <S.LabelImage htmlFor="image">
            <input
              type="file"
              ref={refUploadDocument}
              id="image"
              className="custom-file-input"
              accept="pdf/*"
              // multiple
              onChange={handleChangeDocument}
              style={{ display: 'none' }}
            />
          </S.LabelImage>

          {documents && documents.length > 0 && (
            <span className="documents-length">
              {documents.length} arquivo(s) adicionado(s)
            </span>
          )}

          {documents &&
            documents.map(doc => (
              <S.DivDocuments>
                <svg
                  width="12"
                  height="13"
                  viewBox="0 0 12 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11 5.97071L6.27223 10.6985C5.69304 11.2777 4.90749 11.603 4.0884 11.603C3.26931 11.603 2.48376 11.2777 1.90457 10.6985C1.32538 10.1193 1 9.33374 1 8.51465C1 7.69555 1.32538 6.91001 1.90457 6.33082L6.63234 1.60305C7.01847 1.21692 7.54216 1 8.08823 1C8.63429 1 9.15799 1.21692 9.54411 1.60305C9.93024 1.98917 10.1472 2.51287 10.1472 3.05893C10.1472 3.605 9.93024 4.12869 9.54411 4.51482L4.8112 9.24259C4.61814 9.43565 4.35629 9.54411 4.08326 9.54411C3.81022 9.54411 3.54838 9.43565 3.35531 9.24259C3.16225 9.04953 3.05379 8.78768 3.05379 8.51465C3.05379 8.24162 3.16225 7.97977 3.35531 7.7867L7.72297 3.42419"
                    stroke="#0D85FF"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>

                <span>{doc.file.name}</span>
                <button type="button" onClick={() => deleteDocument(doc)}>
                  x
                </button>
              </S.DivDocuments>
            ))}

          {/* <button type="button" onClick={handleConvertToPDF}>
            Converter Imagens para PDF
          </button> */}
        </S.DivAttachment>
      </S.ContainerReceipt>
      {/* <S.ContainerInput>
          <S.Header>Enviar comprovantes</S.Header>
          <S.Span>
            Envie comprovantes para o emissor do titulo do cartão, como por
            exemplo, recibos, imagens de conversas com o cliente, entre outros
            arquivos que possam comprovar que a compra é legítima.
          </S.Span>
        </S.ContainerInput> */}
      <Button onClick={handleSubmit} disabled={isLoading}>
        {isLoading ? <LoaderComponent size={20} /> : 'Enviar resposta'}
      </Button>
    </S.ContainerWrapper>
  );
}
