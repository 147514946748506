import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { toast } from 'react-toastify';

import { updateProfileRequest } from '../../store/modules/user/actions';
import CancelImage from '../../assets/icons/cancelx.svg';

import {
  ContainerGeral,
  Container,
  Div,
  DivTitle,
  DivBody,
  DivInput,
} from './styles';

function CallModal({
  showModal,
  Modal,
  url_whatsapp,
  url_telegram,
  url_messenger,
  email,
  user,
}) {
  const [callButton, setCallButton] = useState('whatsapp');
  const [text, setText] = useState();
  const [inputFocus, setInputFocus] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (url_messenger) {
      setCallButton('messenger');
      setText(url_messenger);
    } else if (url_telegram) {
      setCallButton('telegram');
      setText(url_telegram);
    } else {
      setCallButton('whatsapp');
      const string =
        url_whatsapp &&
        url_whatsapp.substring(
          url_whatsapp.length === 13
            ? url_whatsapp.length - 11
            : url_whatsapp.length - 10
        );

      setText(string);
    }
  }, [url_messenger, url_telegram, url_whatsapp, showModal]);

  const handleModal = useCallback(() => {
    Modal(false);
  }, [Modal]);

  async function handleSubmit() {
    try {
      if (callButton === 'whatsapp') {
        // eslint-disable-next-line no-restricted-globals
        const isNotNumber = isNaN(text);

        if (isNotNumber || text.trim().length < 10) {
          toast.error('Digite um whatsapp válido Ex: 51999999999');
          return;
        }
      }

      const data = {
        whatsapp: `55${text}`,
        email,
        user,
      };
      dispatch(updateProfileRequest(data));
      handleModal();
    } catch (err) {
      toast.error('Erro ao processar requisição');
    }
  }

  function inputValidate(value) {
    const newText = value && value.replace(/[^0-9]+/g, '');

    if (newText && newText.charAt(0) === '0') {
      const hasZero = newText.substr(1);
      setText(hasZero);
    } else {
      setText(newText);
    }
  }

  // useEffect(() => {
  //   const newText = text && text.replace(/[^0-9]+/g, '');

  //   if (newText && newText.charAt(0) === '0') {
  //     const hasZero = newText.substr(1);
  //     setText(hasZero);
  //   } else {
  //     setText(newText);
  //   }
  // }, [text]);

  return (
    <ContainerGeral>
      <Container showModal={showModal} onClick={handleModal} />
      <Div showModal={showModal}>
        <DivTitle>
          <div className="div-space" />
          <div>
            <span>Botão de chamada</span>
          </div>
          <div className="div-space">
            <img src={CancelImage} alt="cancelImage" onClick={handleModal} />
          </div>
        </DivTitle>
        <DivBody block={!(text && text.length >= 10)}>
          <strong>Adicione seu número do Whatsapp</strong>
          <span>
            Através desse número seus clientes entrarão em contato e enviarão
            pedidos
          </span>

          <DivInput inFocus={inputFocus}>
            <div>
              <span>+55</span>
            </div>

            <input
              type="tel"
              placeholder="51999999999"
              value={text}
              onChange={e => inputValidate(e.target.value)}
              maxLength={11}
              onFocus={() => setInputFocus(true)}
              onBlur={() => setInputFocus(false)}
            />
          </DivInput>

          <button
            type="button"
            onClick={handleSubmit}
            disabled={!(text && text.length >= 10)}
          >
            Salvar alterações
          </button>
        </DivBody>
      </Div>
    </ContainerGeral>
  );
}

export default CallModal;
