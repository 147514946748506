/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useKeenSlider } from 'keen-slider/react';
import 'keen-slider/keen-slider.min.css';

import {
  ButtonBack,
  ButtonSeeMore,
  Card,
  H2,
  H3,
  Span,
} from '~/designsystem/Fonts';
import { handleBuyStripe } from '~/functions/stripe';

// import BuyButtonStripe from '../../../../components/BuyButtonStripe';

// import arrowRightIcon from '../../../../assets/icons/plan/arrowRight.svg';

import api from '~/services/api';
import CardProPlan from '~/components/ProPlan/Cards/CardProPlan';
import CardPremiumPlan from '~/components/ProPlan/Cards/CardPremiumPlan';
import CardUltraPlan from '~/components/ProPlan/Cards/CardUltraPlan';
import UpdatePlanModal from '~/components/Popups/UpdatePlan';
import LoaderComponent from '~/components/Loader';
import dataPlans from '../../../../data/plans.json';
import {
  Container,
  // DivProPlan,
  // DivPrice,
  // DivItemsPro,
  // DivFAQ,
  DivCards,
  DivImage,
  DivBodyCard,
  DivSubscriptionForms,
  DivHeaderPayment,
  SelectButton,
  // DiscountLabel,
  // GreyLabel,
  // BodySecundaryCard,
  // CardPlan,
  // DivColumn,
  // DivBolet,
  DivPlanOptions,
  DivButtonsPlanOptions,
  MonthlyButton,
  YearlyButton,
  DivDiscount,
  DivProCards,
  DivLoader,
} from './styles';
import boletIcon from '../../../../assets/icons/plan/bolet.svg';
import cardIcon from '../../../../assets/icons/plan/card.svg';

function Plans() {
  const [showMoreCards, setShowMoreCards] = useState(false);
  const [page, setPage] = useState(0);
  // const [currentSlide, setCurrentSlide] = useState(0);
  const [pro, setPro] = useState();
  const [typePlan, setTypePlan] = useState('monthly');
  const [loading, setLoading] = useState();
  const [planObject, setPlanObject] = useState({});
  const [showUpdatePlanModal, setShowUpdatePlanModal] = useState(false);
  const [activePlanObject, setActivePlanObject] = useState({});
  const [userConfigurations, setUserConfigurations] = useState();

  const email = useSelector(
    state => state.user && state.user.profile && state.user.profile.email
  );

  // async function handleBuy() {
  //   try {
  //     setLoading(true);
  //     await handleBuyStripe(email, null, null, null);
  //   } catch (err) {
  //     console.log(err);
  //   } finally {
  //     setLoading(false);
  //   }
  // }

  const [ref] = useKeenSlider({
    slides: {
      perView: 3,
      // spacing: 16,
    },
    initial: 0,
    slideChanged(_) {
      // setCurrentSlide(slider.track.details.rel);
    },
  });

  useEffect(() => {
    async function loadPro() {
      await api.get('/proplan').then(response => {
        setPro(response.data);
      });

      await api.get('/userconfigurations').then(response => {
        setUserConfigurations(response.data);
      });
    }

    loadPro();
  }, []);

  useEffect(() => {
    async function load() {
      try {
        setLoading(true);

        await api.get('/stripe/plan/active').then(response => {
          setActivePlanObject(response.data);
        });
      } catch (err) {
        // console.log(err);
      } finally {
        setLoading(false);
      }
    }

    load();
  }, []);

  async function handleClickCreatePortalSession() {
    const subscription = await api.get('/subscription');

    if (subscription && subscription.data && subscription.data.customer_id) {
      await api
        .post('/createportalsession', {
          customerId: subscription.data.customer_id,
        })
        .then(response => {
          window.location.href = response.data.url;
        });
    }
  }

  function handleUpdatePlan({
    plan_name,
    stripe_price_id,
    selectedTypePlan,
    price,
  }) {
    setShowUpdatePlanModal(true);
    // console.log('plan_name', plan_name, 'stripe_price_id', stripe_price_id);
    setPlanObject({ plan_name, stripe_price_id, selectedTypePlan, price });
  }

  function handleShowUpdatePlanModal(value) {
    setShowUpdatePlanModal(value);
    setPlanObject({});
  }

  return (
    <Container>
      {page === 0 && (
        <>
          <H3>Selecionar plano</H3>

          <Span>Você pode alterar o plano quando quiser</Span>

          <DivPlanOptions>
            <DivButtonsPlanOptions>
              <MonthlyButton
                type="button"
                onClick={() => setTypePlan('monthly')}
                active={typePlan === 'monthly'}
              >
                Mensal
              </MonthlyButton>

              <YearlyButton
                type="button"
                onClick={() => setTypePlan('yearly')}
                active={typePlan === 'yearly'}
              >
                Anual
                <div>até 63% OFF</div>
              </YearlyButton>
            </DivButtonsPlanOptions>
          </DivPlanOptions>

          <DivDiscount>
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17Z"
                stroke="#FF6C4B"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9 4.2002V9.0002L12.2 10.6002"
                stroke="#FF6C4B"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>

            <span>Descontos por tempo limitado</span>
          </DivDiscount>

          {loading && (
            <DivLoader>
              <LoaderComponent size={32} />
            </DivLoader>
          )}

          {!loading && (
            <DivProCards>
              <div className="keen-slider" ref={ref}>
                <CardProPlan
                  className="keen-slider__slide"
                  pro={pro}
                  typePlan={typePlan}
                  handleClickCreatePortalSession={
                    handleClickCreatePortalSession
                  }
                  handleUpdatePlan={handleUpdatePlan}
                  showBanner
                  showBorder
                  activePlanObject={activePlanObject}
                  isMyPlan={
                    activePlanObject &&
                    (dataPlans.pro.price[typePlan].stripe_price_id ===
                      activePlanObject.id ||
                      dataPlans.oldPro.stripe_price_id === activePlanObject.id)
                  }
                  suspendedStore={
                    userConfigurations &&
                    userConfigurations.status === 'suspended'
                  }
                />

                <CardPremiumPlan
                  className="keen-slider__slide"
                  pro={pro}
                  typePlan={typePlan}
                  handleClickCreatePortalSession={
                    handleClickCreatePortalSession
                  }
                  handleUpdatePlan={handleUpdatePlan}
                  showBanner
                  showBorder
                  activePlanObject={activePlanObject}
                  isMyPlan={
                    activePlanObject &&
                    dataPlans.premium.price[typePlan].stripe_price_id ===
                      activePlanObject.id
                  }
                  suspendedStore={
                    userConfigurations &&
                    userConfigurations.status === 'suspended'
                  }
                />

                <CardUltraPlan
                  className="keen-slider__slide"
                  pro={pro}
                  typePlan={typePlan}
                  handleClickCreatePortalSession={
                    handleClickCreatePortalSession
                  }
                  handleUpdatePlan={handleUpdatePlan}
                  showBanner
                  showBorder
                  activePlanObject={activePlanObject}
                  isMyPlan={
                    activePlanObject &&
                    dataPlans.ultra.price[typePlan].stripe_price_id ===
                      activePlanObject.id
                  }
                  suspendedStore={
                    userConfigurations &&
                    userConfigurations.status === 'suspended'
                  }
                />
              </div>
            </DivProCards>
          )}
        </>
      )}

      {page === 1 && (
        <>
          <DivHeaderPayment>
            <ButtonBack onClick={() => setPage(0)} />
            <H2>Formas de pagamento</H2>
          </DivHeaderPayment>

          <DivSubscriptionForms>
            <DivCards>
              <Card>
                <DivImage>
                  <img src={cardIcon} alt="cartão de crédito" />
                </DivImage>
                <DivBodyCard>
                  <strong>Cartão</strong>
                  <span>Plano mensal</span>
                  <span className="green">Recomendado</span>
                </DivBodyCard>

                <SelectButton
                  type="button"
                  onClick={() => handleBuyStripe(email, null, null, null)}
                >
                  Selecionar
                </SelectButton>
                {/* <img src={arrowRightIcon} alt="seta para a direita" /> */}
              </Card>

              {showMoreCards && (
                <Card>
                  <DivImage>
                    <img src={boletIcon} alt="cartão de crédito" />
                  </DivImage>
                  <DivBodyCard>
                    <strong>Boleto</strong>
                    <span>Plano trimestral, semestral e anual</span>
                  </DivBodyCard>

                  <SelectButton type="button" onClick={() => setPage(2)}>
                    Selecionar
                  </SelectButton>
                  {/* <img src={arrowRightIcon} alt="seta para a direita" /> */}
                </Card>
              )}
            </DivCards>

            <ButtonSeeMore
              type="button"
              onClick={() => setShowMoreCards(!showMoreCards)}
            >
              {showMoreCards ? (
                <>
                  Menos formas de pagamento{' '}
                  <svg
                    width="14"
                    height="9"
                    viewBox="0 0 14 9"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 7.5L7 1.5L13 7.5"
                      stroke="#0D85FF"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </>
              ) : (
                <>
                  Mais formas de pagamento{' '}
                  <svg
                    width="14"
                    height="9"
                    viewBox="0 0 14 9"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1.5L7 7.5L13 1.5"
                      stroke="#0D85FF"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </>
              )}
            </ButtonSeeMore>
          </DivSubscriptionForms>
        </>
      )}

      {/* {page === 2 && (
        <>
          <DivHeaderPayment>
            <ButtonBack onClick={() => setPage(0)} />
            <H2>Pagamento via Boleto</H2>
          </DivHeaderPayment>

          <DivSubscriptionForms>
            <DivCards>
              <Card>
                <DivBodyCard>
                  <strong>Trimestral</strong>
                  <span>R$ 19,90/mês(R$ 79,70 no total)</span>
                  <span className="green">50% de desconto</span>
                </DivBodyCard>

                <SelectButton
                  type="button"
                  onClick={() =>
                    handleBuyStripe(email, 'bolet', '3months', null)
                  }
                >
                  Selecionar
                </SelectButton>
              </Card>

              <Card>
                <DivBodyCard>
                  <strong>Semestral</strong>
                  <span>R$ 19,90/mês(R$ 119,40 no total)</span>
                  <span className="green">50% de desconto</span>
                </DivBodyCard>

                <SelectButton
                  type="button"
                  onClick={() =>
                    handleBuyStripe(email, 'bolet', '6months', null)
                  }
                >
                  Selecionar
                </SelectButton>
              </Card>

              <Card>
                <DivBodyCard>
                  <strong>Anual</strong>
                  <span>R$ 19,90/mês(R$ 238,80 no total)</span>
                  <span className="green">50% de desconto</span>
                </DivBodyCard>

                <SelectButton
                  type="button"
                  onClick={() => handleBuyStripe(email, 'bolet', 'year', null)}
                >
                  Selecionar
                </SelectButton>
              </Card>
            </DivCards>
          </DivSubscriptionForms>
        </>
      )} */}

      <UpdatePlanModal
        active={showUpdatePlanModal}
        setActive={handleShowUpdatePlanModal}
        planObject={planObject}
        isDesktop
      />
    </Container>
  );
}

export default Plans;
